import {
  ReactElement,
  ReactNode,
  useContext
} from 'react'
import { SelectionBoxContext, SlideDownTransition } from '@web/shared-ui-components'

/*
 * SelectionBoxDropDown Props
 */
interface SelectionBoxDropDownContentProps {
  /*
  * Determine if drop down content needs to scroll
  */
  allowScroll?: boolean

  /*
   * Children
   */
  children: ReactNode
}

/*
 * SelectionBoxDropDownContent component
 */
function SelectionBoxDropDownContent ({
  allowScroll = false,
  children
}: SelectionBoxDropDownContentProps): ReactElement | null {
  const { isOpen } = useContext(SelectionBoxContext)

  return (
    <SlideDownTransition isOpen={isOpen} allowScroll={allowScroll} className='c-bg-white u-border-radius-medium'>{children}</SlideDownTransition>
  )
}

export { SelectionBoxDropDownContent }
