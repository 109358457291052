import { ReactElement, useCallback, useEffect, useState, useRef, useMemo } from 'react'
import { CheckboxOld } from '@web/shared-ui-components'
import { NavigationSection, SearchPresentationContext } from '@web/shared-data-access-queries'
import { faTimes } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { usePopper } from 'react-popper'
import clsx from 'clsx'
import '@web/styles/InStockFilter.less'
import { createCookie, readCookie } from '@web/shared-util-cookies'
import { NavigationItemRouterLink } from '@web/product-navigation-feature'

import { useNavigationItemTracking } from '@web/product-navigation-hooks'

interface InStockFilterProps {
  /**
   * The in stock section of navigation sections
   */
  inStockSection: NavigationSection
}

function InStockFilter ({
  inStockSection
}: InStockFilterProps): ReactElement | null {
  const { trackClickEvent } = useNavigationItemTracking()

  const inStockNavItem = useMemo(() => {
    return inStockSection.NavigationItems.find(x => x.Value?.toLocaleLowerCase() === 'true')
  }, [inStockSection.NavigationItems])

  const isSelected = useMemo(() => inStockNavItem?.IsSelected ?? false, [inStockNavItem])

  const handleClick = useCallback(() => {
    trackClickEvent('filter', 'Ready to Ship', 'toggle', 'Ready to Ship', !isSelected)
  }, [trackClickEvent, isSelected, SearchPresentationContext])

  const handleShowReadyToShipPopover = useCallback((e) => {
    if (e.target.checked === true) {
      createCookie(searchInStockCookieName, true, 30)
      handleClosePopover()
    }
  }, [])

  const [popoverIsVisible, setPopoverVisibility] = useState(false)
  const handleClosePopover = (): void => {
    setPopoverVisibility(false)
  }

  /**
   * React-popper configuration
   */
  const referenceRef = useRef(null)
  const popperRef = useRef(null)
  const [arrowElement, setArrowElement] = useState<HTMLElement | null>(null)
  const inStockFilterToggleContainer = useMemo(() => clsx('c-toggle-container-with-down-arrow p-4 d-flex align-items-center justify-content-center u-border-radius-medium', isSelected && 'toggle-on'), [isSelected])

  const searchInStockCookieName = 'in-stock-popover-search'
  const searchInStockCookie = readCookie(searchInStockCookieName)

  const { styles, attributes } = usePopper(
    referenceRef.current,
    popperRef.current,
    {
      placement: 'top', // preferred placement of popper
      modifiers: [
        {
          name: 'arrow',
          options: {
            padding: 5,
            element: arrowElement
          }
        }
      ]
    }
  )

  useEffect(() => {
    setPopoverVisibility(isSelected)
  }, [isSelected])

  if (inStockNavItem == null) return null

  return (
    <div className='position-relative react-in-stock-filter'>
      {isSelected && popoverIsVisible && searchInStockCookie == null && (
        <div
          ref={popperRef}
          {...attributes.popper}
          className='w-50 mx-auto c-gray-dk-3 c-bg-gray-lt-3 position-absolute ready-to-ship-popover'
        >
          <div className='p-4'>
            <div className='d-flex justify-content-between align-items-center'>
              <span className='c-gray-dk-3 font-weight-bold'>Now Showing Ready to Ship</span>
              <div className='u-text-align-right'>
                <span className='c-gray-dk-1' role='button'>
                  <FontAwesomeIcon icon={faTimes} className='mr-2' onClick={handleClosePopover} />
                </span>
              </div>
            </div>
            <div className='u-text-align-center mt-4 pt-2'>
              <span>Turn toggle on when you need to quickly find in-stock options only.  Turn off to see all available options.</span>
              <div className='mt-4'>
                <CheckboxOld id='dontShowInStockAlert' type='checkbox' value='dontShowInStockAlert' defaultChecked={false} onChange={handleShowReadyToShipPopover}>
                  <label htmlFor='dontShowInStockAlert'>Don't show this again</label>
                </CheckboxOld>
              </div>
            </div>
          </div>
          <div className='arrow' ref={setArrowElement} style={styles.arrow} />
        </div>
      )}
      <div className='pt-3 in-stock-filter'>
        <div id='in-stock-filter' className={inStockFilterToggleContainer}>
          <NavigationItemRouterLink navigationItem={inStockNavItem} section={inStockSection} onClick={handleClick}>
            <div className='mx-auto'>
              <CheckboxOld id='in-stock-only-option' type='checkbox' isSwitch checked={isSelected}>
                <label htmlFor='in-stock-only-option' data-test='in-stock-only-option-label' className='m-0 text-align-xs-left d-flex align-items-center justify-content-center'>
                  <span>
                    <span className='font-weight-bold'>Ready to Ship</span>
                    <span> - Only show products that have at least one in-stock option</span>
                  </span>
                </label>
              </CheckboxOld>
            </div>
          </NavigationItemRouterLink>
        </div>
      </div>
    </div>
  )
}

export {
  InStockFilter
}
