import { useEffect, useMemo } from 'react'

function useOnElementExist (selector: string, onElementExist: Function): () => void {
  const observer = useMemo(() => new MutationObserver((mutations, obs) => {
    if (document.querySelector(selector) != null) {
      onElementExist()
      obs.disconnect()
    }
  }), [])

  useEffect(() => {
    observer.observe(document.body, {
      childList: true,
      subtree: true
    })
  }, [])

  return () => {
    observer.disconnect()
  }
}

export { useOnElementExist }
