import { ReactiveVar, makeVar } from '@apollo/client'
import { useCallback } from 'react'

export const svgsVar: ReactiveVar<ILoadedSvg[]> = makeVar<ILoadedSvg[]>([])

export interface ILoadedSvg{
  /*
  * The url path of the svg we need to load
  */
  url: string

  /*
  * The state of whether or not the svgs were loaded/embedded on the page via the template loader
  */
  isLoaded: boolean
}

function useSvg (): [(url: string) => void, (url: string, isLoaded: boolean) => void] {
  const addSvg = useCallback((url: string) => {
    const svgs = [...svgsVar()]
    if (svgs.find(svg => svg.url === url) == null) {
      svgsVar([...svgs, { url: url, isLoaded: false }])
    }
  }, [])

  const updateSvg = useCallback((url: string, isLoaded: boolean) => {
    const currentSvgs = svgsVar()
    const svgToUpdateIndex = currentSvgs.map(svg => svg.url).indexOf(url)
    if (svgToUpdateIndex >= 0) {
      currentSvgs[svgToUpdateIndex].isLoaded = isLoaded
      svgsVar(currentSvgs)
    }
  }, [])

  return [addSvg, updateSvg]
}

export {
  useSvg
}
