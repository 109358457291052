import { ReactElement, useMemo } from 'react'
import { CmsContent } from '@web/shared-ui-components'
import { GetSearchNavigationQuery } from '@web/shared-data-access-queries'
import { CategoryImageBox, CategoryContentBlockCreation, SearchBySerialNumber } from '@web/product-navigation-feature'
import { useUserContext } from '@web/shared-data-access-context'
import { ContentBlockLocation } from '@web/product-navigation-types'

interface CategoryIndexViewProps {
  navProps: GetSearchNavigationQuery['getSearchNavigation']
}

function CategoryIndexView ({ navProps }: CategoryIndexViewProps): ReactElement | null {
  const { context } = useUserContext()
  const childCategories = useMemo(() => navProps.ChildCategories?.filter(x => x.CDNImageName != null), [navProps.ChildCategories])
  const showSearchBySerialNumber = useMemo(() => context?.IsSterling === false && (navProps.CategoryId === 3484 || navProps.CategoryId === 3478), [context?.IsSterling, navProps.CategoryId])

  return (
    <div id='content'>
      {showSearchBySerialNumber &&
        <SearchBySerialNumber />}
      <div className='card-row mt-4' data-test='category-image-box' data-gtm-click-section='bucket'>
        {childCategories?.map(x => (
          <CategoryImageBox
            key={x.Id}
            categoryItem={x}
            searchSource={navProps.SearchSource}
            hideSideNav={navProps.HideSideNav}
            parentCategoryName={navProps.Category}
          />
        ))}
      </div>
      {navProps.AllowNewContentBlockCreation &&
        <CategoryContentBlockCreation
          newContentBlockName={navProps.NewContentBlockName}
          categoryId={navProps.CategoryId}
          cmsContentType={navProps.CmsContentType}
          contentBlockLocation={ContentBlockLocation.Bottom}
        />}
      {navProps.BottomNavigationSectionContentId != null && context?.IsSterling === false && !context?.IsInShowcase &&
        <CmsContent contentContainerId={navProps.BottomNavigationSectionContentId} />}
    </div>
  )
}

export { CategoryIndexView }
