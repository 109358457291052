import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { CategoryOrSearchViewModel } from '@web/shared-data-access-queries'
import { faCaretRight } from '@fortawesome/pro-solid-svg-icons'

import { ReactElement } from 'react'

interface RelatedSearchTermsProps {
  terms: CategoryOrSearchViewModel['RelatedSearchTerms']
  originalQuery: string
}

function RelatedSearchTerms ({ terms, originalQuery }: RelatedSearchTermsProps): ReactElement|null {
  return (
    <div className='suggestedSearches border c-border-gray-lt-1 px-4 py-3 mb-3 c-bg-gray-lt-3'>
      <h3 className='pb-3 border-bottom c-border-gray-lt-1'>Related Search Terms</h3>
      <ul className='suggestions m-0 pt-3 list-unstyled u-flex-grid-row'>
        {terms?.map((term, index) => (
          <li key={index} className='p-0 mb-3 u-flex-grid-col-12 u-flex-grid-col-sm-6 u-flex-grid-col-md-4 u-flex-grid-col-lg-3'>
            <a href={`/search/results/?suggested=True&query=${term?.Suggestion ?? ''}&originalQuery=${originalQuery}&suggestionRank=${term?.Rank ?? ''}`}>{term?.Suggestion ?? ''}<FontAwesomeIcon className='ml-2 c-primary t-4 align-middle' icon={faCaretRight} /></a>
          </li>
        ))}
      </ul>
    </div>
  )
}

export { RelatedSearchTerms }
