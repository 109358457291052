import { ReactElement, useMemo, ReactNode, MouseEventHandler } from 'react'
import clsx from 'clsx'
import { NavigationItem, NavigationSection } from '@web/shared-data-access-queries'
import { Link } from 'react-router-dom'
import '@web/styles/NavigationItemRouterLink.less'

interface NavigationItemLinkProps {
  /*
  * One instance of the the facet value collection coming from the Navigation Section
  */
  navigationItem: NavigationItem

  /*
   * NavigationSection
   */
  section: NavigationSection

  /**
   * css classes to be added
   */
  className?: string

  /**
   * the inner components to be rendered as part of route link
   */
  children: ReactNode
  /**
   * normal onClick event
   */
  onClick?: MouseEventHandler
  /**
   * show the font awesome side arrow thing
   */
  showPopoutArrow?: boolean
}

function NavigationItemRouterLink ({
  navigationItem,
  section,
  className,
  children,
  onClick,
  showPopoutArrow = true
}: NavigationItemLinkProps): ReactElement {
  const linkClass = useMemo(() => {
    const addSubClass = showPopoutArrow &&
      (navigationItem.Children != null) &&
      (navigationItem.Children.length > 0) &&
      section.TopLabel != null &&
      navigationItem.LinkText.toLowerCase() !== 'show all'

    return clsx(addSubClass && 'sub', className, navigationItem.IsSelected && 'isSelected')
  }, [navigationItem, section.TopLabel, className, showPopoutArrow])

  const rel = useMemo((): string => {
    return section.Title.toLowerCase() === 'category' ? 'nofollow' : ''
  }, [section.Title])

  return (
    <Link to={navigationItem.LinkUrl} className={linkClass} onClick={onClick} {...rel !== '' && { rel }}>
      {children}
    </Link>
  )
}

export { NavigationItemRouterLink }
