import { ReactElement, useMemo } from 'react'
import { GetSearchNavigationQuery } from '@web/shared-data-access-queries'
import { Link } from 'react-router-dom'
import clsx from 'clsx'
import { useUserContext } from '@web/shared-data-access-context'

interface CategoryImageBoxProps {
  searchSource: GetSearchNavigationQuery['getSearchNavigation']['SearchSource']
  categoryItem: NonNullable<GetSearchNavigationQuery['getSearchNavigation']['ChildCategories']>[number]
  hideSideNav: NonNullable<GetSearchNavigationQuery['getSearchNavigation']['HideSideNav']>
  parentCategoryName: GetSearchNavigationQuery['getSearchNavigation']['Category']
}

function CategoryImageBox ({ categoryItem, hideSideNav, searchSource, parentCategoryName }: CategoryImageBoxProps): ReactElement | null {
  const { context } = useUserContext()
  const catWidthCssClasses = hideSideNav ? 'card-md-3' : 'card-md-4'
  const catImageSrc = useMemo(() => `https://meteor.stullercloud.com/das/${categoryItem?.CDNImageName}?$260x155$`, [categoryItem])
  const catUrlPath = useMemo(() => {
    const delim = categoryItem.OverrideUrl != null && categoryItem.OverrideUrl !== '' && !categoryItem.OverrideUrl.includes('?')
      ? '?'
      : '&'
    let rootPath = '/browse'
    let searchTypeSuffix = ''
    if (searchSource != null && searchSource !== '') {
      if (searchSource?.toLocaleLowerCase() === 'deals') {
        rootPath = '/deals'
        searchTypeSuffix = `${delim}searchType=deals`
      }
      if (searchSource?.toLocaleLowerCase() === 'salesevent') {
        rootPath = '/salesevent'
        searchTypeSuffix = `${delim}searchType=salesevent`
      }
      if (searchSource?.toLocaleLowerCase() === 'stullerfirstsale') {
        rootPath = '/stullerfirstsale'
        searchTypeSuffix = `${delim}searchType=stullerfirstsale`
      }
    }
    return categoryItem.OverrideUrl != null && categoryItem.OverrideUrl !== ''
      ? `${categoryItem.OverrideUrl}${searchTypeSuffix}`
      : `${rootPath}/${categoryItem.SanitizedPath}`
  }, [categoryItem, searchSource])

  return (
    <Link
      className={clsx('card card-category', catWidthCssClasses)}
      to={catUrlPath}
      data-gtm-click-subsection1={parentCategoryName}
    >
      <div className='card-category-image-container'>
        <img src={catImageSrc} />
      </div>
      <span className='card-category-name' data-test={(context?.IsInShowcase === true || context?.IsSterling === true) ? categoryItem?.ShowcaseDisplayName : categoryItem?.StandardDisplayName}>
        {(context?.IsInShowcase === true || context?.IsSterling === true) ? categoryItem?.ShowcaseDisplayName : categoryItem?.StandardDisplayName}
      </span>
    </Link>
  )
}

export { CategoryImageBox }
