import { Currency, RadioOld, RadioGroupOld } from '@web/shared-ui-components'
import { ReactElement } from 'react'
import clsx from 'clsx'

import '@web/styles/StullerPayTermsOptions.less'
import { TermOption } from '@web/shared-data-access-queries'

interface StullerPayTermsOptionsProps {
  termsOptions: TermOption[]
  selectedTermId: string
  onChange: (termId: string) => void
}

function StullerPayTermsOptions ({ termsOptions, selectedTermId, onChange }: StullerPayTermsOptionsProps): ReactElement {
  return (
    <>
      <div className='u-flex-grid-row u-bold mb-3'>
        <div className='col-4'>Monthly Payment</div>
        <div className='col-4 text-right'>Term</div>
        <div className='col-4 text-right'>Fee</div>
      </div>
      <RadioGroupOld layout='vertical' className='m-0 stullerPayTermsRadioGroup' containerClassName='stuller-pay-input-group'>
        {termsOptions.map((term) =>
          <StullerPayTermsRadioOrUnavailable key={term.Id} term={term} selectedTermOption={selectedTermId} onChange={onChange} />
        )}
      </RadioGroupOld>
    </>
  )
}

interface StullerPayTermsRadioOrUnavailableProps {
  term: TermOption
  selectedTermOption: string
  onChange: (termId: string) => void
}

function StullerPayTermsRadioOrUnavailable ({ term, selectedTermOption, onChange }: StullerPayTermsRadioOrUnavailableProps): ReactElement {
  const checkTermAvailable = (term: TermOption): boolean => term.MinimumPrice <= term.Price
  const isSelected = selectedTermOption === term.Option
  return (
    <>
      {checkTermAvailable(term)
        ? (
          <RadioOld
            id={term.Id.toString()}
            value={term.Id.toString()}
            checked={isSelected}
            onChange={() => onChange(term.Option)}
            data-test='term-selection-radio-button'
          >
            <label
              htmlFor={term.Id.toString()}
              className={clsx(isSelected ? 'c-bg-stullerpay-lt-2 c-border-stullerpay-lt-1 c-stullerpay-dk-2' : 'c-border-gray-lt-1', 'd-block mb-2 py-4 u-border-radius-medium u-border-all')}
              style={{ marginRight: '0px !important' }}
            >
              <StullerPayTermsOptionRow term={term} />
            </label>
          </RadioOld>
          )
        : (
          <li>
            <StullerPayTermsOptionRow term={term} className='c-bg-gray-lt-3 mb-2 py-4 u-border-radius-medium u-border-all c-border-gray-lt-1' />
          </li>
          )}
    </>
  )
}
interface StullerPayTermsOptionProps {
  term: TermOption
  className?: string
}

function StullerPayTermsOptionRow ({ term, className = '' }: StullerPayTermsOptionProps): ReactElement {
  return (
    <div className={clsx(className, 'd-flex flex-row align-items-center')}>
      <div className='col-4' data-test='monthly-price-or-minimun-price-message'><MonthlyPriceOrMinimumPriceMessage itemPrice={term.Price} monthlyPrice={term.MonthlyPrice} minimumPrice={term.MinimumPrice} /></div>
      <div className='col-4 text-right' data-test='term-display'>{term.TermDisplay}</div>
      <div className='col-4 text-right' data-test='fee-percent-or-fee'><FeePercentOrFree feePercentDecimal={term.FeePercentDecimal} /></div>
    </div>
  )
}

interface MonthlyPriceOrMinimumPriceMessageProps {
  itemPrice: number
  monthlyPrice: number
  minimumPrice: number
}

function MonthlyPriceOrMinimumPriceMessage ({ itemPrice, monthlyPrice, minimumPrice }: MonthlyPriceOrMinimumPriceMessageProps): ReactElement {
  return itemPrice > minimumPrice
    ? <Currency amount={monthlyPrice} />
    : (
      <div>
        <div className='u-bold t-5'>Available for items</div>
        <div data-test='available-for-items-price'><Currency amount={minimumPrice} /> or higher</div>
      </div>
      )
}

interface FeePercentOrFreeProps {
  feePercentDecimal: number
}

function FeePercentOrFree ({ feePercentDecimal }: FeePercentOrFreeProps): ReactElement {
  return feePercentDecimal > 0 ? <span>{Math.round(((feePercentDecimal * 100) + Number.EPSILON) * 100) / 100}%</span> : <span>FREE</span>
}

export { StullerPayTermsOptions }
