import { ReactElement, ReactNode } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconDefinition } from '@fortawesome/fontawesome-common-types'
import clsx from 'clsx'

interface InputGroupProps {
  type: 'prepend' | 'append'
  icon: IconDefinition | string
  children: ReactNode
  spanClassName?: string
}

/*
 * InputGroup component
 */
function InputGroup ({ type, icon, children, spanClassName }: InputGroupProps): ReactElement {
  const showIcon = (icon: IconDefinition | string): ReactNode => {
    if (typeof icon === 'string') {
      return <span className='u-regular-font-size'>{icon}</span>
    }

    return <FontAwesomeIcon icon={icon} className='t-5' />
  }

  const inputGroupClasses = 'u-input-group position-relative d-flex align-items-stretch w-100'
  const inputGroupAddonClasses = 'input-group-addon d-flex align-items-center justify-content-center w-auto'

  if (type === 'prepend') {
    return (
      <div className={clsx(inputGroupClasses, 'u-input-group-prepend')}>
        <span
          className={clsx(
            inputGroupAddonClasses,
            spanClassName,
            'input-group-addon-prepend'
          )}
        >
          {showIcon(icon)}
        </span>
        {children}
      </div>
    )
  } else {
    return (
      <div className={clsx(inputGroupClasses, 'u-input-group-append')}>
        {children}
        <span
          className={clsx(
            inputGroupAddonClasses,
            spanClassName,
            'input-group-addon-append'
          )}
        >
          {showIcon(icon)}
        </span>
      </div>
    )
  }
}

export { InputGroup }
