import { ReactElement, useState } from 'react'
import { Badge, IndividualBadgeProps, Popover } from '@web/shared-ui-components'

function NewDeal ({ popoverZIndex, ...otherAttributes }: IndividualBadgeProps): ReactElement {
  const [reference, setReference] = useState<HTMLElement | null>(null)
  const [open, setOpen] = useState(false)

  return (
    <>
      <Badge
        ref={setReference}
        pill
        badgeColor='new-deal'
        {...otherAttributes}
      >
        New Deal
      </Badge>
      <Popover
        placement='top'
        style={{ maxWidth: '300px', zIndex: popoverZIndex }}
        hover
        hoverProps={{ delay: { open: 300 } }}
        offset={10}
        open={open}
        onOpenChange={setOpen}
        reference={reference}
      >
        <span className='font-weight-bold'>New Deal: </span>Products that have been recently added to deals.
      </Popover>
    </>
  )
}

export {
  NewDeal
}
