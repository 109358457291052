import { ReactElement, useCallback, useMemo } from 'react'
import { GetSearchNavigationQuery } from '@web/shared-data-access-queries'
import '@web/styles/Permalink.less'
import { useUserContext } from '@web/shared-data-access-context'

interface PermalinkProps {
  categoryId: NonNullable<GetSearchNavigationQuery['getSearchNavigation']['CategoryId']>
  categoryPermalinkUrl: NonNullable<GetSearchNavigationQuery['getSearchNavigation']['CategoryPermalinkUrl']>
}

function Permalink ({ categoryId, categoryPermalinkUrl }: PermalinkProps): ReactElement | null {
  const { context } = useUserContext()
  const handlePermalinkClick = useCallback((e) => {
    window.prompt('Copy to clipboard with Ctrl+C', `/categories/${categoryId}`)
  }, [categoryId])
  const userIsSalesPerformanceViewer = context?.IsSalesPerformanceViewer ?? false
  const showPermalink = useMemo(() => context?.IsGroupCodeViewer === true && !context?.IsSterling, [context])

  return (
    <>
      {showPermalink &&
        <h4 className='permalink-container react-permalink-container'>
          <span className='groupCode'>[Permalink: <a href='#' onClick={handlePermalinkClick}>{`/categories/${categoryId}`}</a>, </span>
          <a href={categoryPermalinkUrl} target='_blank' rel='noreferrer'>Webadmin</a>
          {userIsSalesPerformanceViewer &&
            <a href={`/categoryperformance/?category=${categoryId}`}>, Category Sales Performance</a>}]
        </h4>}
    </>
  )
}

export { Permalink }
