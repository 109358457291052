import { ReactElement } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle } from '@fortawesome/pro-regular-svg-icons'

interface ConfirmationMessageProps {
  children: any
}

function ConfirmationMessage ({ children }: ConfirmationMessageProps): ReactElement {
  return (
    <div className='text-center t-leading-loose'>
      <div>
        <FontAwesomeIcon icon={faCheckCircle} size='4x' className='p-4 c-green-lt-1' />
      </div>
      {children}
    </div>
  )
}

export {
  ConfirmationMessage
}
