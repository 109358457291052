/**
 * Stuller event names
 */
enum stullerEvents {
  'ping',
  'react-mount',
  'form-builder-submission',
  'track-event',
  'track-universal-event',
  'live-chat-open',
  'cms-content-recommendations',
  'add-a-head-modal-scrolltop',
  'add-a-head-react-remount',
  'cms-preview-tmc',
  'digital-goldsmith-modal-launch'
}

// Generics for type checking
type StullerEventKeys = Record<keyof typeof stullerEvents, any>
type StullerEventKeysable<T extends StullerEventKeys> = T
// eslint-disable-next-line @typescript-eslint/no-unused-vars
type StullerEventsable<T extends StullerEvents> = StullerEvents

/**
 * Checks that there are no extra keys on `StullerEvents` type that aren't in the `stullerEvents` enum
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
type StullerEventsExactCheck = StullerEventsable<StullerEventKeys>

/**
 * Type for type checking name and detail value of a Stuller event
 *
 * Checks that there are no extra keys on `stullerEvents` enum that aren't in the `StullerEvents` type
 */
export type StullerEvents = StullerEventKeysable<{
  ping: boolean
  'react-mount': {
    /**
     * Element to attach the component to
     */
    target: HTMLElement
    /**
     * Name of the component to mount
     */
    name: string
    /**
     * Props to pass the component
     */
    props: any
  }
  'form-builder-submission': {
    /**
     * The target element that triggered the form submission. Typically a form submit button.
     */
    target: HTMLButtonElement
    /**
     * An optional callback function to be called after form submission takes place.
     */
    callback?: (data: {
      /**
       * Indicates whether or not the form submission passed validation.
       */
      success: boolean
      /**
       * The success/error message to display to the user. May contain HTML.
       */
      message: string
      /**
       * The JSON data that was stored on the submission.
       * Only available when success = true
       */
      submission?: string
      /**
       * The current logged in user's login id.
       * Only available when success = true
       */
      user?: string
      /**
       * The date when the submission was last updated.
       * Only available when success = true
       */
      last_update?: string
      /**
       * The date when the submission was first submitted.
       * Only available when success = true
       */
      first_submit?: string
      /**
       * When marked as true, the message will be a url in which the client should redirect to on success.
       */
      is_url?: boolean
    }) => any
    /**
     * Additional data to submit along with the form data
     */
    data?: any
  }
  /**
   * triggers digital goldsmith modal
   */

  'digital-goldsmith-modal-launch': undefined
  /**
   * Tracks/sends a Google Tag Manager event
   */
  'track-event': {
    /**
     * Keys can be sent, like category, label, value, etc.
     */
    [key: string]: any
  }
  /**
   * Tracks/sends a Universal Google Tag Manager event
   * Sends to GAU and GA4 (as universal event)
   */
  'track-universal-event': {
    /**
     * Universal Google Analytics category
     */
    category: string
    /**
     * Universal Google Analytics action
     */
    action: string
    /**
     * Universal Google Analytics label
     */
    label?: string
    /**
     * Universal Google Analytics value
     */
    value?: number
  }
  /**
   * Opens the live chat window
   */
  'live-chat-open': undefined
  /**
   * Triggers content loading from recommendations engine
   */
  'cms-content-recommendations': {
    /**
     * A list of contents to be loaded from the recommendations engine
     */
    contentParamArray: any[]
  }
  /**
   * Scrolls the Add a Head modal to top any time filters or pages change
   */
  'add-a-head-modal-scrolltop': undefined
  /**
   * Remounts the Add a Head React component
   */
  'add-a-head-react-remount': undefined
  /**
   * An event to preview targeting marking content in cms
   */
  'cms-preview-tmc': {
    target: HTMLElement
    previewUrl: string
    contentContainerId: number
    parentContentContainerId: number
    instanceId: any
  }
}>

export {
  stullerEvents
}
