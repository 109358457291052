import { ReactElement, useEffect } from 'react'
import { Alert } from '@web/shared-ui-components'
import { DeleteCredtCardResponse } from '@web/shared-data-access-queries'
import { useToggle } from '@web/shared-util-hooks'

interface DeleteCardAlertProps {
  /**
    * Is card being deleted
    */
  deletingCard: boolean

  /**
    * Response from deleting a card
    */
  deleteCardResponse?: DeleteCredtCardResponse | null

}

/**
 * Deleting card alert component
 */
function DeleteCardAlert ({ deletingCard, deleteCardResponse }: DeleteCardAlertProps): ReactElement | null {
  const [show, toggleShow, setShow] = useToggle(false)

  useEffect(() => {
    setShow(!deletingCard && deleteCardResponse != null)
  }, [deletingCard, deleteCardResponse])

  if (deletingCard || deleteCardResponse == null) {
    return null
  }

  if (!deleteCardResponse.success) {
    return (
      <Alert alertType='error' className='my-4'>Could not delete card. </Alert>
    )
  }

  return (
    <Alert show={show} onToggle={toggleShow} alertType='success' className='my-4' data-test='card-removal-successful-alert'>Your card has been deleted!</Alert>
  )
}

export {
  DeleteCardAlert
}
