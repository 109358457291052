import clsx from 'clsx'
import { ReactElement, ReactNode } from 'react'
import '@web/styles/StepCard.less'

interface StepCardHeaderProps {
  stepNumber?: string
  header: string | ReactNode
}

function StepCardHeader ({ stepNumber, header }: StepCardHeaderProps): ReactElement {
  return (
    <div className='d-flex align-items-center'>
      {stepNumber != null &&
        <div className='rounded-circle c-bg-primary c-white d-flex align-items-center justify-content-center t-3 mr-3' style={{ minWidth: '40px', minHeight: '40px' }}>
          {stepNumber}
        </div>}
      <div className='t-h2'>
        {header}
      </div>
    </div>
  )
}

interface StepCardProps {
  /**
   * Step number to show in blue bubble
   */
  stepNumber: string
  /**
   * Header as either a plain string or a JSX element
   */
  header: string | ReactNode
  /**
   * <Badge> element, to show at top left of step card
   */
  badge?: ReactNode
  /**
   * Background color of card.  For when padding is added to the right of the badge, and the background color needs to match the background of the parent
   */
  badgeBackgroundColor?: string
  /**
   * Class overrides
   */
  className?: string
  /**
   * Render content here.  Multiple JSX elements will result in separate columns across the width of the step card
   */
  children: ReactNode | ReactNode[]
}

/*
 * StepCard component
 */
function StepCard ({ stepNumber, header, badge, badgeBackgroundColor = '#FFFFFF', className, children }: StepCardProps): ReactElement {
  const containerClasses = clsx('react-step-card', className)
  const badgeStyles = {
    top: '-10px',
    backgroundColor: badgeBackgroundColor
  }

  return (
    <div className={containerClasses}>
      {badge != null &&
        <div className='position-absolute pr-3' style={badgeStyles}>
          {badge}
        </div>}
      <div className='u-border u-border-top u-padding-top-20'>
        <StepCardHeader
          stepNumber={stepNumber}
          header={header}
        />
        <div className='mt-3'>
          {children}
        </div>
      </div>
    </div>
  )
}

export { StepCard }
