import {
  ReactElement,
  ChangeEventHandler,
  forwardRef,
  ForwardedRef,
  KeyboardEventHandler
} from 'react'
import clsx from 'clsx'

interface InputProps {
  /*
   * Id of the input
   */
  id?: string
  /*
   * Type of the input
   */
  type?: 'text' | 'checkbox' | 'radio' | 'file' | 'email' | 'password' | 'number' | 'hidden'
  /*
   * Name of the input
   */
  name?: string
  /*
   * Value of the input
   */
  value?: any
  /*
   * Default value of the input
   */
  defaultValue?: any
  /*
   * Whether the command or control is checked
   */
  checked?: boolean
  /*
   * Indicates the value is checked by default
   */
  defaultChecked?: boolean
  /*
   * Indicates the input is invalid to show fail style
   */
  invalid?: boolean | string
  /*
   * Indicates the input is disabled
   */
  disabled?: boolean
  /*
   * Function to call on input change
   */
  onChange?: ChangeEventHandler
  /**
   * Function to call on key press
   */
  onKeyPress?: KeyboardEventHandler
  onKeyDown?: KeyboardEventHandler
  /*
   * Class overrides
   */
  className?: string
  /*
   * Hint for expected file type in file upload controls
   */
  accept?: string
  /*
   * Hint for form autofill feature (see https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/autocomplete#values)
   */
  autoComplete?: string
  /*
   * Automatically focus the form control when the page is loaded
   */
  autoFocus?: boolean
  /*
   * Browser hint to the type of virtual keyboard to use
   */
  inputMode?: 'none' | 'text' | 'tel' | 'url' | 'email' | 'numeric' | 'decimal' | 'search'
  /*
   * Maximum value
   */
  max?: number
  /*
   * Maximum length (number of characters) of value
   */
  maxLength?: number
  /*
   * Minimum value
   */
  min?: number
  /*
   * Minimum length (number of characters) of value
   */
  minLength?: number
  /*
   * User can enter multiple answers for email/file
   */
  multiple?: boolean
  /*
   * Pattern the input must match
   */
  pattern?: string
  /*
   * Placeholder text for hint
   */
  placeholder?: string
  /*
   * Indicates input is readonly
   */
  readOnly?: boolean
  /*
   * Indicates the input is required
   */
  required?: boolean
  /*
   * Indicates the granularity of the value
   */
  step?: number
  /*
   * Title of the input as tooltip
   */
  title?: string
  /*
   * All other attributes
   */
  [key: string]: any
  /**
   * Override .form-input-group margin with 0px !important
   */
  noWrapperMargin?: boolean
}

/*
 * Input component
 */
function Input ({
  id,
  type = 'text',
  name,
  value,
  defaultValue,
  checked,
  defaultChecked,
  invalid,
  disabled,
  onChange,
  onKeyPress,
  onKeyDown,
  className,
  accept,
  autoComplete,
  autoFocus,
  inputMode,
  max,
  maxLength,
  min,
  minLength,
  multiple,
  pattern,
  placeholder,
  readOnly,
  required,
  step,
  title,
  ...otherAttributes
}: InputProps, ref: ForwardedRef<HTMLInputElement>): ReactElement {
  const classNames = clsx(
    invalid != null && invalid !== false && invalid !== '' && 'validation-input-error',
    className
  )

  return (
    <input
      id={id}
      type={type}
      name={name}
      value={value}
      defaultValue={defaultValue}
      checked={checked}
      defaultChecked={defaultChecked}
      disabled={disabled}
      onKeyPress={onKeyPress}
      onKeyDown={onKeyDown}
      onChange={onChange}
      className={classNames}
      accept={accept}
      autoComplete={autoComplete}
      autoFocus={autoFocus}
      inputMode={inputMode}
      max={max}
      maxLength={maxLength}
      min={min}
      minLength={minLength}
      multiple={multiple}
      pattern={pattern}
      placeholder={placeholder}
      readOnly={readOnly}
      required={required}
      step={step}
      title={title}
      ref={ref}
      {...otherAttributes}
    />
  )
}

/*
 * Input with forwardRef
 */
const InputWrapped = forwardRef(Input)

export {
  InputWrapped as Input,
  InputProps
}
