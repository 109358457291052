import { ReactElement, ChangeEvent, useEffect } from 'react'
import { Checkbox, Button } from '@web/shared-ui-components'
import { ContactViewModel } from '@web/shared-data-access-queries'
import { ContactInformationDropdown } from './ContactInformationDropdown'
import { ContactInformationInput, ContactInformationInputId } from './ContactInformationInput'
import clsx from 'clsx'
import { useId } from '@floating-ui/react-dom-interactions'

interface ContactInformationFormInput {
  ContactId: number
  FirstName: string
  LastName: string
  BusinessPhone: string
  MobilePhone: string
  EmailAddress: string
  SaveAsDefaultContact: boolean
  CadCamAcceptAllModFees?: boolean
}

interface ContactInformationFormErrors {
  FirstName: string | undefined
  LastName: string | undefined
  BusinessPhone: string | undefined
  MobilePhone: string | undefined
  EmailAddress: string | undefined
}

interface ContactInformationProps {
  contacts?: ContactViewModel[]
  formInput: ContactInformationFormInput
  formErrors: ContactInformationFormErrors
  onInputChange?: (formInput: Partial<ContactInformationFormInput>) => void

  // CadCam
  showCadCamAcceptAllModFees?: boolean
  readOnly?: boolean
  showContactDropdown?: boolean
  onSetShowContactDropdown?: (showContactDropdown: boolean) => void
}

function ContactInformation ({
  contacts,
  formInput,
  formErrors,
  onInputChange,
  showCadCamAcceptAllModFees = false,
  readOnly,
  showContactDropdown,
  onSetShowContactDropdown
}: ContactInformationProps): ReactElement {
  const uniqueID = useId()

  const contactAmount: number = contacts?.length ?? 0
  const saveAsDefaultContactId: string = `SaveAsDefaultContactId-${uniqueID}`

  const changeContact = (contact: ContactViewModel | undefined): void => {
    onInputChange?.({
      ContactId: contact?.Id ?? 0,
      FirstName: contact?.FirstName ?? '',
      LastName: contact?.LastName ?? '',
      BusinessPhone: contact?.BusinessPhoneNumber ?? '',
      MobilePhone: contact?.MobilePhoneNumber ?? '',
      EmailAddress: contact?.EmailAddress ?? '',
      CadCamAcceptAllModFees: contact?.CadCamAcceptAllModFees ?? false
    })
  }
  const handleEditContact = (): void => {
    onSetShowContactDropdown?.(true)
  }
  const handleDropdownSelect = (contact: ContactViewModel | undefined): void => {
    changeContact(contact)
  }
  const handleCheckboxClick = (event: ChangeEvent<HTMLInputElement>): void => {
    onInputChange?.({ ...formInput, [event.target.name]: event.target.checked })
  }

  const toggleConfirmEditContactModal = (): JQuery<HTMLElement> => $('#confirmCadCamEditContactModal').modal('toggle')

  const handleOnCancel = (): void => {
    onSetShowContactDropdown?.(false)
  }

  useEffect(() => {
    // Set contact information based off of the first contact in the list
    // If there are no contacts, set the contact information to empty strings
    const firstContact: ContactViewModel | undefined = contacts?.[0]

    changeContact(firstContact)
  }, [])

  const createFormInput = (label: string, id: ContactInformationInputId, dataTest?: string): ReactElement => {
    return (
      <div className={clsx('u-flex-grid-col-12 u-flex-grid-col-md-4', id !== 'EmailAddress' && 'u-flex-grid-col-sm-6')}>
        <ContactInformationInput
          label={label}
          id={id}
          formInput={formInput}
          formErrors={formErrors}
          onInputChange={onInputChange}
          readOnly={readOnly}
          data-test={dataTest}
        />
      </div>
    )
  }

  return (
    <div>
      <div className='u-flex-grid-row'>
        {contactAmount === 0 &&
          <>
            {createFormInput('First Name', 'FirstName', 'first-name')}
            {createFormInput('Last Name', 'LastName', 'last-name')}
          </>}

        {contactAmount >= 1 && contacts != null && contacts[0] != null &&
          <div className='u-flex-grid-col-12 u-flex-grid-col-sm-6 u-flex-grid-col-md-4'>
            {showContactDropdown === true && contactAmount > 1
              ? (
                <div>
                  <ContactInformationDropdown
                    contacts={contacts}
                    formInput={formInput}
                    onSelect={handleDropdownSelect}
                  />
                </div>
                )
              : (
                <>
                  <label>Name</label>
                  <div>
                    <span>{formInput.FirstName} {formInput.LastName}</span>

                    {contactAmount > 1 && <span className='ml-2 primary-link' data-test='edit-contact' onClick={handleEditContact}>Edit Contact</span>}
                  </div>
                </>
                )}
          </div>}
      </div>

      <div className='u-flex-grid-row'>
        {createFormInput('Business Phone', 'BusinessPhone', 'business-phone')}
        {createFormInput('Mobile Phone', 'MobilePhone', 'mobile-phone')}
        {createFormInput('Email Address', 'EmailAddress', 'email-address')}
      </div>

      <div className='mt-2 mb-4'>
        {showCadCamAcceptAllModFees &&
          <Checkbox
            className='mt-2 mb-2'
            id={`CadCamAcceptAllModFees-${uniqueID}`}
            name='CadCamAcceptAllModFees'
            value='CadCamAcceptAllModFees'
            checked={formInput.CadCamAcceptAllModFees}
            onChange={handleCheckboxClick}
          >
            Automatically accept model modification charges
          </Checkbox>}

        <Checkbox
          className='mt-2 mb-2'
          id={saveAsDefaultContactId}
          name='SaveAsDefaultContact'
          value='SaveAsDefaultContact'
          checked={formInput.SaveAsDefaultContact}
          onChange={handleCheckboxClick}
        >
          Save as default contact
        </Checkbox>
        {readOnly === false &&
          <div className='d-flex flex-row align-items-center justify-content-start mt-3'>
            <Button color='primary' onClick={toggleConfirmEditContactModal} loading={false} data-test='save-contact-changes-button'>
              Save Changes
            </Button>
            <a className='dark-link ml-5' onClick={handleOnCancel}>Cancel</a>
          </div>}
      </div>
    </div>
  )
}

export { ContactInformation, ContactInformationFormErrors, ContactInformationFormInput }
