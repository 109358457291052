import { ReactElement, useCallback, useMemo } from 'react'
import { SortByFilterOptions, PageSizeFilterOptions, Pager } from '@web/product-navigation-types'
import { Button } from '@web/shared-ui-components'
import { AddCategoryToProductFeedModal } from '@web/product-navigation-feature'
import { useQueryParams } from '@web/shared-util-hooks'
import { useHistory, useLocation } from 'react-router-dom'
import '@web/styles/SearchFilter.less'
import { useUserContext } from '@web/shared-data-access-context'
import { useNavigationItemTracking } from '@web/product-navigation-hooks'

interface SearchFilterProps{
  /**
   * Will determine whether or not some page size textual information will be shown
   */
  showPageSize: boolean
  /**
   * A list of all sort by options coming from search/category results
   */
  sortByOptions: SortByFilterOptions
  /**
   * A list of all page size options coming from search/category results
   */
  pageSizeOptions: PageSizeFilterOptions
  /**
   * Will determine whether or not the currently signed in user has ecommerce merchandising mode enabled via webadmin
   */
  merchandisingMode: boolean
  /**
   * The current pager information for product results
   */
  pager?: Pager
  /**
   * The id of the current category for the page
   */
  categoryId?: number
}

function SearchFilter ({
  showPageSize,
  sortByOptions,
  pageSizeOptions,
  merchandisingMode,
  pager,
  categoryId
}: SearchFilterProps): ReactElement | null {
  const { trackClickEvent } = useNavigationItemTracking()

  const { context } = useUserContext()

  const selectedSortByOption = useMemo(() => sortByOptions.find(option => option.Selected)?.Value ?? '', [sortByOptions])
  const selectedItemsPerPage = useMemo(() => pageSizeOptions.find(option => option.Selected)?.Value ?? '', [pageSizeOptions])

  const { urlSearchParams } = useQueryParams()
  const history = useHistory()
  const { pathname } = useLocation()

  const handleSelectedSortByOption = useCallback((e) => {
    urlSearchParams.set('sortby', e.target.value)
    urlSearchParams.set('page', '1')
    history.push(`${pathname}?${urlSearchParams.toString()}`)
    const selectionText = sortByOptions.find(x => x.Value === e.target.value)?.Text ?? ''
    trackClickEvent('sort', 'Sort by', 'dropdown', selectionText)
  }, [urlSearchParams, history])

  const handleSelectedItemsPerPage = useCallback((e) => {
    urlSearchParams.set('pageSize', e.target.value)
    urlSearchParams.set('page', '1')
    history.push(`${pathname}?${urlSearchParams.toString()}`)
  }, [urlSearchParams, history])

  const jumpToFilters = useCallback((): void => document.querySelector('.sideNavigation')?.scrollIntoView({ behavior: 'smooth', block: 'start' }), [])
  const canShowProductFeedButton = useMemo(() => merchandisingMode && context?.IsSterling === false && !context?.IsInShowcase, [merchandisingMode, context?.IsSterling, context?.IsInShowcase])

  return (
    <>
      <div className='search-filter-wrapper d-flex justify-content-between filterTable border-bottom-0 c-gray-dk-3 flex-wrap'>
        <div className='d-flex align-items-center' data-test='search-filter-container'>
          <div className='d-block d-md-flex align-content-between justify-content-center align-items-baseline'>
            <div className='text-nowrap d-none d-md-block'>Sort by</div>
            <div className='text-nowrap font-weight-bold pl-2 d-block d-md-none'>Sort by<span className='font-weight-bold'>:</span></div>
            <div className='d-block d-md-flex c-modern-input-wrapper dark-arrow'>
              <select className='pr-5 p-2 pl-3 ml-2 c-border-gray-lt-1 c-gray-dk-3 font-weight-bold u-border-radius-small' data-test='sort-by-select' defaultValue={selectedSortByOption} onChange={handleSelectedSortByOption}>
                {sortByOptions?.map((x, index) =>
                  <option key={index} value={x.Value ?? ''}>{x.Text}</option>)}
              </select>
            </div>
          </div>
          {canShowProductFeedButton && categoryId != null &&
            <AddCategoryToProductFeedModal categoryId={categoryId} />}
          <div className='mt-4 ml-3 d-block d-md-none'>
            <Button
              tag='button'
              size='micro'
              color='secondary-inverse'
              className='d-block d-md-none u-border-radius-xlarge ml-4 mt-2'
              onClick={jumpToFilters}
            >
              <span className='t-5 font-weight-bold px-4'>Filters</span>
            </Button>
          </div>
        </div>
        <div className='d-block d-md-flex ml-auto align-content-between justify-content-center align-items-baseline'>
          {showPageSize && pager != null &&
            <div className='d-none d-md-block' data-test='total-number-of-products'>
              {pager.ShowingText} <span className='t-4 c-gray'> &nbsp;|&nbsp; </span> Items per page &nbsp;
            </div>}
          <div className='d-flex justify-content-end d-md-none font-weight-bold'>Items:</div>
          <div className='c-modern-input-wrapper dark-arrow'>
            <select className='pr-5 p-2 pl-3 c-border-gray-lt-1 c-gray-dk-3 font-weight-bold u-border-radius-small' defaultValue={selectedItemsPerPage} onChange={handleSelectedItemsPerPage}>
              {pageSizeOptions?.map((x, index) =>
                <option key={index} value={x.Value ?? ''}>{x.Text}</option>)}
            </select>
          </div>
        </div>
      </div>
    </>
  )
}

export { SearchFilter }
