import { CategoryOrSearchViewModel, SearchPresentationContext } from '@web/shared-data-access-queries'
import { ReactElement, useCallback } from 'react'
import { Pager } from '@web/shared-ui-components'
import { useQueryParams } from '@web/shared-util-hooks'
import { useHistory, useLocation } from 'react-router-dom'
import '@web/styles/ProductResultsPager.less'
import { useNavigationItemTracking } from '../util/hooks/useNavigationItemTracking'

interface ProductResultsPageProps {
  pager: CategoryOrSearchViewModel['Pager']
  className?: string
  onPageChange?: (page: number) => void
}

function ProductResultsPager ({ pager, className, onPageChange }: ProductResultsPageProps): ReactElement | null {
  const { trackClickEvent } = useNavigationItemTracking()
  const { urlSearchParams } = useQueryParams()
  const history = useHistory()
  const { pathname } = useLocation()

  const handlePageChange = useCallback((page: number, nextOrPrev?: string) => {
    urlSearchParams.set('page', page.toString())
    history.push(`${pathname}?${urlSearchParams.toString()}`)
    onPageChange?.(page)
    trackClickEvent('paginate', 'Results', 'pagination', nextOrPrev != null ? nextOrPrev : page.toString())
  }, [urlSearchParams, history, onPageChange, pathname, trackClickEvent, SearchPresentationContext])

  if (pager == null) {
    return null
  }
  return (
    <Pager
      className={className} page={pager.CurrentPage} perPage={pager.RecordsPerPage}
      total={pager.TotalRecords} onChange={handlePageChange}
      data-test='product-result-pagination'
    />
  )
}

export { ProductResultsPager }
