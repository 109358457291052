/**
 * We need to define env placeholder var with type for webpack DefinePlugin to replace.
 */
interface env {
  CARD_CONNECT_URL: string
}
declare const process: { env: env }

/**
* Environment variables set by webpack DefinePlugin.
*/
const _env = {
  CARD_CONNECT_URL: process.env.CARD_CONNECT_URL
}

export { _env as env }
