import { ReactElement, useMemo, useCallback, MouseEventHandler, MouseEvent } from 'react'
import { NavigationItem, NavigationSection, SearchPresentationContext } from '@web/shared-data-access-queries'
import { NavigationItemIcon, NavigationItemRouterLink } from '@web/product-navigation-feature'
import { useNavigationItemTracking } from '@web/product-navigation-hooks'
import clsx from 'clsx'
import '@web/styles/ImageGridItem.less'

interface ImageGridItemProps {
  /*
  * This is actually the Facet Value that will be selected on click
  */
  navigationItem: NavigationItem

  /*
  * Tells us the appropriate layout for the icon itself
  */
  layout: string

  /*
  * The parent section to the navigationItem
  */
  section: NavigationSection

  onClick?: MouseEventHandler
}

function ImageGridItem ({
  navigationItem,
  layout,
  section,
  onClick
}: ImageGridItemProps): ReactElement | null {
  const isSelected = useMemo(() => navigationItem.IsSelected, [navigationItem])

  const classes = useMemo(() => clsx(
    isSelected && 'selected', 'd-flex flex-column align-items-center pb-4'
  ), [isSelected])

  const { trackClickEvent } = useNavigationItemTracking()

  const handleLinkClick = useCallback((e: MouseEvent) => {
    trackClickEvent('filter',
      section.Title,
      section.IsMultiSelect ? 'checkbox' : 'link',
      navigationItem.TrackingName,
      !isSelected
    )
    onClick?.(e)
  }, [trackClickEvent, isSelected, SearchPresentationContext, section, navigationItem.TrackingName])

  return (
    <div className='image-grid-item col-6 u-flex-grid-col-sm-3'>
      <NavigationItemRouterLink navigationItem={navigationItem} section={section} onClick={handleLinkClick} className={classes}>
        <div className='image-grid-label-wrapper d-flex align-items-end w-100'>
          <span className='image-grid-label pb-2 w-100' dangerouslySetInnerHTML={{ __html: navigationItem.LinkText }} />
        </div>
        <NavigationItemIcon isSelected={isSelected} imageAsset={navigationItem.ImageAsset} layout={layout} />
      </NavigationItemRouterLink>
    </div>
  )
}

export {
  ImageGridItem
}
