import clsx from 'clsx'
import { HTMLAttributes, ReactElement, ReactNode, useMemo, forwardRef, ForwardedRef } from 'react'
import { BootstrapThemeColors, SearchResultBadgeColors } from '@web/shared-ui-components'
import '@web/styles/Badge.less'
interface BadgeProps extends HTMLAttributes<HTMLElement> {
  /**
   * bootstrap theme and stuller search result badge color name
   */
  badgeColor?: BootstrapThemeColors | SearchResultBadgeColors
  /**
   * bool for whether or not to show the badge as a pill
   */
  pill?: boolean
  /**
   * css className pass-thru
   */
  className?: string
  /**
   * inner content rendered into the badge
   */
  children: ReactNode
}

function Badge ({
  badgeColor = 'secondary',
  pill = false,
  className,
  children,
  ...otherAttributes
}: BadgeProps, ref: ForwardedRef<any>): ReactElement {
  const classes = useMemo(() => clsx(pill && 'badge-pill', `badge-${badgeColor}`, 'badge', className), [pill, badgeColor, className])

  return (
    <div
      className={classes}
      {...otherAttributes}
      ref={ref}
    >
      {children}
    </div>
  )
}
interface IndividualBadgeProps {
  /**
   * css className pass-thru
   */
  className?: string
  /**
   * z-index for popover
   */
  popoverZIndex?: number
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function BadgeWrapper ({ badgeColor = 'secondary', pill = false, className, children, ...otherAttributes }: BadgeProps): ReactElement {
  const classes = useMemo(() => clsx(pill && 'badge-pill', `badge-suffix-${badgeColor}`, 'badge', 'p-0', className), [pill, badgeColor, className])

  return <span className={classes} {...otherAttributes}>{children}</span>
}

/* Badge with forwardRef */
const BadgeWrapped = forwardRef(Badge)
BadgeWrapped.displayName = 'Badge'

export {
  BadgeWrapped as Badge,
  IndividualBadgeProps
}
