import { useCallback, useState } from 'react'

function useToggle (initialToggled = false): [boolean, () => void, (t: boolean) => void] {
  const [toggled, setToggled] = useState(initialToggled)
  const toggle = useCallback(() => setToggled(toggled => !toggled), [])

  return [toggled, toggle, setToggled]
}

export {
  useToggle
}
