import { ReactElement } from 'react'
import { ErrorPageProps } from './ErrorPage'
import { GenericErrorPage } from './GenericErrorPage'

export interface DynamicErrorPageProps extends Partial<ErrorPageProps> {
  error?: Error
}

function DynamicErrorPage ({ error, ...props }: DynamicErrorPageProps): ReactElement {
  if (error != null) {
    /*
     * TODO: Return customized ErrorPage based on error hur
     * Ex: <ErrorPage {...props} sideImage='https://emoji.slack-edge.com/T045RBXCG/omega-party-chris%2527s-mom/7f3ad92e77fe1ffc.gif' />
     */
  }

  return <GenericErrorPage {...props} />
}

export { DynamicErrorPage }
