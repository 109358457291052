import { ReactElement, useMemo, useCallback, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import {
  CategoryOrSearchViewModel, SearchResult, GetProductNavigationParams,
  SearchScaffoldViewModel,
  useGetSystemSettingValueQuery
} from '@web/shared-data-access-queries'
import { faInfoCircle } from '@fortawesome/pro-regular-svg-icons'
import { serializeQuery } from '@web/shared-util-querystring'
import { ProductResult, ProductResultCard, ProductResultsCMSSection, ProductResultsPager } from '@web/product-navigation-feature'
import { Alert } from '@web/shared-ui-components'
import '@web/styles/ProductResults.less'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinnerThird } from '@fortawesome/pro-solid-svg-icons'
import { ProductTracking, trackEcommerceEvent } from '@web/shared-util-google-analytics'
import { useUserContext } from '@web/shared-data-access-context'
import { useDownloadFile } from '@web/shared-util-hooks'

interface ProductResultsProps {
  viewModel: CategoryOrSearchViewModel
  scaffold: SearchScaffoldViewModel
}

interface ResultsDownloadQueryString{
  facetValues: GetProductNavigationParams['facetValues']
  query: string
  searchSource: string
}

function GroupDownloadProgress (): ReactElement | null {
  return (
    <div className='d-block ml-2'>
      <FontAwesomeIcon spin icon={faSpinnerThird} className='mr-2 c-primary' />
      <span>Downloading... this may take up to 2 minutes.</span>
    </div>
  )
}

/*
  This component loops over the product search results and generates a product "card" for each one
*/
function ProductResults ({ viewModel, scaffold }: ProductResultsProps): ReactElement| null {
  const { context } = useUserContext()
  const productTrackings = useMemo(() => viewModel.SearchResults?.map(x => x.ProductTracking as ProductTracking), [viewModel.SearchResults])

  useEffect(() => {
    trackEcommerceEvent('view_item_list', productTrackings ?? [])
  }, [productTrackings])

  const handleProductClick = useCallback((productResult: SearchResult, position: number, overrideItemId?: string) => {
    trackEcommerceEvent('select_item', [productResult.ProductTracking as ProductTracking])
    if (productResult?.ClickLoggingPayload != null) {
      const url = `/search/logsearchclick?payload=${productResult?.ClickLoggingPayload}`
      fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        }
      })
        .catch(err => {
          throw new Error(err)
        })
    }
  }, [viewModel])

  const { search } = useLocation()

  const { data, loading } = useGetSystemSettingValueQuery({
    fetchPolicy: 'network-only',
    variables: {
      settingName: 'Feature-ShowReactProductResultCards',
      appendSearch: search !== '' ? `${search.slice(1)}` : ''
    }
  })

  const userIsGroupCodeViewer: boolean = context?.IsGroupCodeViewer ?? false
  const useProductCards: boolean = useMemo(() => data?.systemSettingValue?.value === 'true', [data])
  const userCanViewDownloadLink: boolean = useMemo(() => userIsGroupCodeViewer && context != null && !context.IsInShowcase, [userIsGroupCodeViewer, context])
  const resultsDownloadQueryString: string = useMemo(() => {
    const queryStringObject: ResultsDownloadQueryString = {
      facetValues: viewModel.FacetValues,
      query: scaffold.query,
      searchSource: viewModel.SearchSource ?? ''
    }
    return serializeQuery(queryStringObject)
  }, [scaffold, viewModel.FacetValues, viewModel.SearchSource])
  const queryText: string = useMemo(() => viewModel.Query ?? '', [viewModel.Query])
  const originalQueryText: string = useMemo(() => viewModel.OriginalQuery ?? '', [viewModel.OriginalQuery])
  const isChathamSearch = useMemo(() => viewModel.OriginalQuery?.search(/chatham/i) !== -1, [viewModel.OriginalQuery])

  // Download group results logic
  const [handleDownloadFile, { loading: isDownloading }] = useDownloadFile()
  const handleDownloadButtonClick = (e): void => {
    e.preventDefault()
    handleDownloadFile(`/search/export/?${resultsDownloadQueryString}`)
  }

  return (
    <div className='productResults'>
      {/* preloaded image for the image re-render placeholder (this will need to change when the results image sizes change) */}
      <link rel='preload' href='https://meteor.stullercloud.com/?$list$' as='image' />
      <div className='pt-2 d-flex flex-column flex-sm-row mb-4' data-test='pagination-and-group-download-row'>
        {userCanViewDownloadLink &&
          <>
            {!isDownloading && <a onClick={handleDownloadButtonClick} data-test='group-download-link'>Download Group CSV</a>}
            {isDownloading && <GroupDownloadProgress />}
          </>}
        <ProductResultsPager
          className='productResultsTopPager mr-0 ml-auto ml-md-0 ml-md-auto mx-md-0'
          pager={viewModel.Pager}
        />
      </div>
      {viewModel.OriginalQuery != null && viewModel.Query != null &&
        <Alert alertType='primary' icon={faInfoCircle} showBackground className='my-3'>
          {isChathamSearch
            ? <span>We do not carry Chatham products. However, you may want to consider the following items.</span>
            : <span>Showing results for <b>{queryText}</b>. Original search was for <a href={`/search/results?query=${originalQueryText}&overrideSpellingSuggestion=True`}><b>{originalQueryText}</b></a>.</span>}
        </Alert>}
      <div id='results'>
        {!loading &&
          <>
            {viewModel.Pager?.CurrentPage === 1 &&
              <ProductResultsCMSSection
                topRowInResultsContentId={viewModel.TopRowInResultsContentID}
                presentationContext={viewModel.PresentationContext}
                category={viewModel.Category}
                categoryId={viewModel.CategoryId}
              />}
            <div className='productResultGrid u-flex-grid-row pt-sm-4'>
              {useProductCards
                ? viewModel.SearchResults?.map((productResult, index) =>
                  <ProductResultCard<SearchResult>
                    key={`${productResult.Id}-${productResult.SerialNumber ?? ''}-productCard`}
                    productResult={productResult}
                    searchSource={viewModel.SearchSource ?? null}
                    showDiscounts={viewModel.ShowDiscounts}
                    onProductSelected={handleProductClick}
                    position={index}
                    className='u-flex-grid-col-xl-3 u-flex-grid-col-lg-4 u-flex-grid-col-sm-6 col-12'
                    directToPDP={false}
                    groupId={null}
                  />)
                : viewModel.SearchResults?.map((productResult, index) =>
                  <ProductResult
                    key={index}
                    productResult={productResult}
                    viewModel={viewModel}
                    onProductSelected={() => handleProductClick(productResult, index, productResult?.OverrideItemId?.toString())}
                  />)}
            </div>
          </>}
        <div className='d-flex'>
          <ProductResultsPager
            className='ml-0 ml-md-auto mx-auto mx-md-0' pager={viewModel.Pager}
          />
        </div>
        <div className='clear' />
      </div>
    </div>
  )
}

export { ProductResults }
