import { StullerEvents } from './types'

/**
 * Helper function to dispatch Stuller events
 * This function just calls the function in `common.js`
 */
function dispatchStullerEvent<T extends keyof StullerEvents> (eventName: T, detail?: StullerEvents[T]): boolean {
  return window.dispatchStullerEvent(eventName, detail)
}

export {
  dispatchStullerEvent
}
