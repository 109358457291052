import { MouseEventHandler, ReactElement, useMemo } from 'react'
import { Button } from '@web/shared-ui-components'
import { faChevronRight, faChevronDown } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import '@web/styles/FlexedOrderLines.less'
import clsx from 'clsx'

interface MobileRowButtonProps {
  /**
   * The different type of button that will be rendered
   */
  buttonStyle: 'link' | 'toggle'
  /**
   * Link for the button
   */
  href?: string
  /**
   * Extra class for the button
   */
  className?: string
  /**
   * If the button is toggled or not
   */
  toggled?: boolean
  /**
   * Click handler for the button
   */
  handleClick?: MouseEventHandler
  /**
   * Extra class for the icon
   */
  iconClass?: string
}

/**
 * Button attached to the end of a row, can be a link or a toggle.
 */
function MobileRowButton ({
  buttonStyle,
  href,
  toggled,
  className,
  handleClick,
  iconClass
}: MobileRowButtonProps): ReactElement {
  const toggleClass = useMemo(() =>
    clsx('u-all-short-ease-in-transition m-auto',
      (toggled != null && toggled) && 'fa-rotate-180',
      iconClass
    ), [toggled, iconClass])

  return (
    <Button
      tag={buttonStyle === 'link' ? 'a' : undefined}
      target={buttonStyle === 'link' ? '_blank' : undefined}
      href={href ?? undefined}
      className={clsx('button-action d-flex rounded-right rounded-sm', className)}
      onClick={handleClick}
    >
      <FontAwesomeIcon
        icon={buttonStyle === 'link' ? faChevronRight : faChevronDown}
        size='lg'
        className={buttonStyle === 'link' ? iconClass : toggleClass}
      />
    </Button>
  )
}

export { MobileRowButton }
