import { CreditCardType, PaymentMethod, PaymentMethodType } from '@web/shared-data-access-queries'
import { createContext } from 'react'

interface PaymentMethodSelectorContextProps {
  /**
   * Allowed payment methods from parent to determine if it's possible to add a new bank account
   */
  allowedMethods: PaymentMethodType[]
  allowedMethodsForConsultantOnly: PaymentMethodType[]
  allowedCreditCardTypes: CreditCardType[]
  /**
   * Payment methods available to payment method selector
   */
  paymentMethods: PaymentMethod[] | null
  userCanUseTerms: boolean
  userCanUseCOD: boolean
  userCanUseCreditCards: boolean
  userCanUseDebitCards: boolean
  userCanUseACH: boolean
  isLoggedIn: boolean
  /**
   * Allow editing of cards/bank accounts
   */
  allowEditing: boolean
  /**
   * Allow adding of cards
   */
  allowAdding: boolean
  /**
   * Allow deleting
   */
  allowDeleting: boolean
  /**
   * Allow setting default while adding/editing
   */
  allowSettingDefault: boolean
  /**
   * Set the selected payment method
   */
  handleSelectedPaymentMethod: (selectedPaymentMethod: PaymentMethod | undefined | null) => void
  /**
   * Ability to refetch the list of payment methods to repopulate the list
   */
  refetchPaymentMethods: () => Promise<PaymentMethod[]>
  onLoggedOutSubmit?: (any) => void
  collapseAddCard?: boolean

  collapseAddBankAccount?: boolean
}

const PaymentMethodSelectorContext = createContext<PaymentMethodSelectorContextProps>({
  allowedMethods: [],
  allowedMethodsForConsultantOnly: [],
  allowedCreditCardTypes: [],
  paymentMethods: null,
  userCanUseTerms: false,
  userCanUseCOD: false,
  userCanUseCreditCards: false,
  userCanUseDebitCards: false,
  userCanUseACH: false,
  isLoggedIn: false,
  allowEditing: false,
  allowAdding: false,
  allowDeleting: false,
  allowSettingDefault: true,
  handleSelectedPaymentMethod: () => {},
  refetchPaymentMethods: async () => [],
  onLoggedOutSubmit: () => {},
  collapseAddCard: true,
  collapseAddBankAccount: true
})

export { PaymentMethodSelectorContext, PaymentMethodSelectorContextProps }
