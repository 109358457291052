import '@web/styles/LaserEngraving.less'
import { ReactElement, useState } from 'react'
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  LaserEngravingColor,
  LaserEngravingFont,
  LaserEngravingText,
  LaserEngravingPreview,
  LaserEngravingType,
  EngravingTypeOption,
  EngravingColorOption,
  EngravingFontOption
} from '@web/shared-ui-components'
import { FontViewModel } from '@web/shared-data-access-queries'

interface LaserEngravedModalValue {
  EngravingType: EngravingTypeOption
  EngravingText: string
  FontFaceOptions: EngravingFontOption
  EngravingColor: EngravingColorOption
}

interface LaserEngravedModalProps<ModalValue extends LaserEngravedModalValue> {
  fonts: FontViewModel[]
  input: ModalValue
  blankInput: ModalValue
  onToggle: () => void
  onCancel?: () => void
  onConfirmation: (formInput: ModalValue) => void
  onResetLocation?: () => void
}

function LaserEngravedModal<ModalValue extends LaserEngravedModalValue> ({ fonts, input, blankInput, onToggle, onCancel, onConfirmation, onResetLocation }: LaserEngravedModalProps<ModalValue>): ReactElement {
  const [temporaryInput, setTemporaryInput] = useState<ModalValue>(input)
  const handleTemporaryInputChange = <K extends keyof ModalValue>(key: K, value: ModalValue[K]): void => {
    setTemporaryInput(oldTempInput => ({ ...oldTempInput, [key]: value }))
  }

  // Assume that the user is editing because it is the only way to have
  // a non-blank EngravingText in the input while viewing the modal
  const isEditing: boolean = input.EngravingText.trim() !== ''
  const isValidTemporaryEngraving: boolean = temporaryInput.EngravingText.trim() !== ''

  const handleToggle = (): void => onToggle()
  const handleCancel = (): void => {
    onCancel?.()
    onToggle()
    setTemporaryInput(input)
  }
  const handleConfirmation = (): void => {
    let newInput = temporaryInput

    if (!isValidTemporaryEngraving) {
      if (isEditing) {
        // If user is editing, and removes the message, pass the blank input as the new input
        newInput = blankInput
      } else {
        // If user didn't add a message, and hits save, pass the original input as the new input
        newInput = input
      }
    }

    onConfirmation(newInput)
    onToggle()
  }
  const handleResetLocation = (): void => {
    onResetLocation?.()
    setTemporaryInput(blankInput)
  }

  const handleShow = (): void => {
    // Ensure that the temporary input is always up to date with the input
    // This is mainly used for when the input is reset outside of the modal
    // Ex: Hitting 'Remove' button on the Modify a Style Engraing Step
    setTemporaryInput(input)
  }

  return (
    <Modal id='laserEngravedModal' size='xlarge' onShow={handleShow}>
      <ModalHeader onToggle={handleToggle}>
        <div className='t-6 text-uppercase'>Precision laser engraving</div>
        <div className='t-h1'>Inside Band Engraving</div>
      </ModalHeader>

      <ModalBody className='my-3 pt-0 u-flex-grid-row'>
        {/* Inputs */}
        <div className='u-flex-grid-col-12 u-flex-grid-col-md-6'>
          <div className=''>
            <LaserEngravingType
              input={temporaryInput}
              onInputChange={handleTemporaryInputChange}
            />
          </div>
          <div className='mt-4'>
            <LaserEngravingText
              input={temporaryInput}
              onInputChange={handleTemporaryInputChange}
            />
          </div>
          <div className='my-4'>
            <LaserEngravingFont
              fonts={fonts}
              input={temporaryInput}
              onInputChange={handleTemporaryInputChange}
            />
          </div>
          <div className='my-4'>
            <LaserEngravingColor
              input={temporaryInput}
              onInputChange={handleTemporaryInputChange}
            />
          </div>
        </div>

        {/* Preview */}
        <div className='u-flex-grid-col-12 u-flex-grid-col-md-6'>
          <LaserEngravingPreview
            input={temporaryInput}
          />
        </div>
      </ModalBody>

      <ModalFooter>
        <div className='floatRight bottomPadding u-no-float-xs'>
          <Button
            tag='a'
            className='c-red u-margin-right-20 u-display-block-xs text-center-xs u-margin-bottom-15-xs'
            color='link'
            onClick={handleResetLocation}
            data-test='reset-location-button'
          >
            Reset This Location
          </Button>

          <div className='u-flex u-inline-block-sm'>
            <Button className='u-flex-child' color='secondary' data-test='cancel-engraving-button' onClick={handleCancel}>Cancel</Button>
            <Button className='u-flex-child u-margin-left-10' color='primary' data-test='save-engraving-button' onClick={handleConfirmation}>Save</Button>
          </div>
        </div>
      </ModalFooter>
    </Modal>
  )
}

export { LaserEngravedModal, LaserEngravedModalValue }
