import { ReactElement, useMemo } from 'react'
import { Button } from '@web/shared-ui-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUpload } from '@fortawesome/pro-solid-svg-icons'
import clsx from 'clsx'

interface FileUploadButtonProps {
  files: File[]
  fileLimit: number
  className?: string
}

function FileUploadButton ({ files, fileLimit, className, ...otherAttributes }: FileUploadButtonProps): ReactElement {
  const hasMaxFiles = useMemo(() => files.length >= fileLimit, [files, fileLimit])

  return (
    <Button className={clsx('mt-2', className)} color='primary' disabled={hasMaxFiles} {...otherAttributes}>
      <FontAwesomeIcon className='mr-2' icon={faUpload} /> Upload File{fileLimit > 1 && 's'}
    </Button>
  )
}

export { FileUploadButton }
