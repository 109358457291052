export interface ICurrency {
  locale?: string | null
  currency?: string | null
}

/*
 * Helper currency class to format according to locale, currency, separator, etc.
 */
class Currency implements ICurrency {
  locale!: string
  currency!: string

  private readonly currencyFormatter!: Intl.NumberFormat
  private readonly currencyCodeFormatter!: Intl.NumberFormat
  private readonly wholeNumberFormater!: Intl.NumberFormat

  constructor (data?: ICurrency) {
    Object.assign(this, {
      locale: 'en-US',
      currency: 'USD'
    }, data) as ICurrency

    const wholeNumberFormatterOptions = {
      maximumFractionDigits: 0,
      minimumFractionDigits: 0
    }

    if ('Intl' in window) {
      this.currencyFormatter = new Intl.NumberFormat(this.locale, {
        style: 'currency',
        currency: this.currency
      })
      this.currencyCodeFormatter = new Intl.NumberFormat(this.locale, {
        style: 'currency',
        currency: this.currency,
        currencyDisplay: 'code'
      })
      this.wholeNumberFormater = new Intl.NumberFormat(this.locale, {
        ...wholeNumberFormatterOptions,
        style: 'currency',
        currency: this.currency,
        currencyDisplay: 'code'
      })
    }
  }

  formatMoney = (value: number): string => {
    return this.currencyFormatter?.format(value) ?? value
  }

  formatMoneyWithoutSymbol = (value: number): string => {
    return this.currencyCodeFormatter?.format(value).replace(this.currency, '').trim() ?? value
  }

  formatMoneyWithoutFractionOrSymbol = (value: number): string => {
    return this.wholeNumberFormater?.format(value).replace(this.currency, '').trim() ?? value
  }
}

export {
  Currency
}
