import { MouseEventHandler, ReactElement, ReactNode } from 'react'
import { TableRowToggleButton } from './index'

interface TableBodyRowWithToggleProps {
  /*
   * Table body rows
   */
  children: ReactNode

  /*
   * Initial state of the toggle for showing expanded info and direction of toggle icon
   */
  toggledOpen: boolean

  /*
   * Onclick handler for toggle button on row
   */
  onClick: MouseEventHandler<HTMLTableDataCellElement>

  /*
   * Is this row disabled
   */
  isDisabled?: boolean

  className?: string
}

function TableBodyRowWithToggle ({ toggledOpen, children, onClick, isDisabled = false, className, ...otherAttributes }: TableBodyRowWithToggleProps): ReactElement {
  return (
    <>
      {children}
      <TableRowToggleButton className={className} toggledOpen={toggledOpen} onClick={onClick} isDisabled={isDisabled} {...otherAttributes} />
    </>
  )
}

export { TableBodyRowWithToggle }
