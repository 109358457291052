import {
  Modal,
  ModalBody,
  ModalHeader,
  CmsContent
} from '@web/shared-ui-components'
import { ReactElement, useRef } from 'react'
import { StullerpayIneligibleAlert } from './StullerPayIneligibleAlert'

/**
 * Modal component for StullerPay ineligible alert from redirect
 */
function StullerPayIneligibleModal (): ReactElement {
  const documentBody = useRef(document.body)

  return (
    <Modal portalRef={documentBody} id='stuller-pay-not-eligible-notification'>
      <ModalHeader onToggle={() => $('#stuller-pay-not-eligible-notification').modal('toggle')} />
      <ModalBody className='text-left'>
        <StullerpayIneligibleAlert />
        <CmsContent contentContainerName='stullerpay-contact-form-content' />
      </ModalBody>
    </Modal>
  )
}

export { StullerPayIneligibleModal }
