import { ReactElement, useState } from 'react'
import { Badge, IndividualBadgeProps, Popover } from '@web/shared-ui-components'

function Three02 ({ popoverZIndex, ...otherAttributes }: IndividualBadgeProps): ReactElement {
  const [reference, setReference] = useState<HTMLElement | null>(null)
  const [open, setOpen] = useState(false)

  return (
    <>
      <Badge
        ref={setReference}
        pill
        badgeColor='302'
        {...otherAttributes}
      >
        302<sup>&reg;</sup> Fine Jewelry
      </Badge>
      <Popover
        placement='top'
        style={{ maxWidth: '300px', zIndex: popoverZIndex }}
        hover
        hoverProps={{ delay: { open: 300 } }}
        offset={5}
        open={open}
        onOpenChange={setOpen}
        reference={reference}
      >
        <span className='font-weight-bold'>302<sup>&reg;</sup> Fine Jewelry: </span>
        Curated collections of trending styles with new product releases every year, ready-to-post social media content, and marketing support. <a href='/302finejewelry' className='primary-link'>Learn More</a>
      </Popover>
    </>
  )
}

export {
  Three02
}
