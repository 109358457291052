import { ReactElement } from 'react'
import { Badge, IndividualBadgeProps } from '@web/shared-ui-components'

function ImprintingAvailable ({ popoverZIndex, ...otherAttributes }: IndividualBadgeProps): ReactElement {
  // const [reference, setReference] = useState<HTMLElement | null>(null)
  // const [open, setOpen] = useState(false)

  return (
    <>
      <Badge
        // ref={setReference}
        pill
        badgeColor='imprinting-available'
        {...otherAttributes}
      >
        Imprinting Available
      </Badge>
      {/* <Popover
        placement='top'
        style={{ maxWidth: '300px', zIndex: popoverZIndex }}
        hover
        hoverProps={{ delay: { open: 300 } }}
        offset={5}
        open={open}
        onOpenChange={setOpen}
        reference={reference}
      >
        <span className='font-weight-bold'>Imprinting Available: </span>Products that can have a logo imprinted on them.
      </Popover> */}
    </>
  )
}

export {
  ImprintingAvailable
}
