import {
  ReactElement,
  ReactNode,
  forwardRef,
  ForwardedRef,
  useEffect,
  useRef,
  useImperativeHandle,
  useMemo
} from 'react'
import {
  Input, InputProps
} from '@web/shared-ui-components'
import clsx from 'clsx'
import { faCheckSquare, faToggleLargeOn, faSquareMinus } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSquare } from '@fortawesome/pro-regular-svg-icons'
import '@web/styles/Checkbox.less'
import { FlipProp } from '@fortawesome/fontawesome-svg-core'

interface CheckboxProps extends InputProps {
  /*
   * Indicates the input should be rendered as a switch (for checkbox, radio)
   */
  isSwitch?: boolean
  /*
   * Options for the select as children
   */
  children?: ReactNode
  /*
   * Options for the select as children
   */
  indeterminate?: boolean
}

/*
 * Creates a checkbox out of the Input wrapper component
 */
function Checkbox ({
  id,
  name,
  checked = false,
  className,
  labelClass,
  iconClassNameToggledOn,
  iconFixedWidth = false,
  disabled = false,
  onChange,
  children,
  indeterminate = false,
  isSwitch = false,
  ...otherAttributes
}: CheckboxProps, ref: ForwardedRef<HTMLInputElement>): ReactElement {
  const containerClasses = clsx('react-checkbox', disabled && 'react-checkbox-disabled')
  const inputClasses = clsx(className, indeterminate && 'indeterminate')
  const labelClasses = clsx('d-flex align-items-center u-cursor-pointer', labelClass)

  const icon = useMemo(() => {
    if (isSwitch) {
      return faToggleLargeOn
    }

    if (indeterminate) {
      return faSquareMinus
    }

    return checked ? faCheckSquare : faSquare
  }, [isSwitch, indeterminate, checked])

  const iconClasses = clsx(children != null && 'mr-3',
    checked && iconClassNameToggledOn,
    isSwitch && 'react-checkbox-switch',
    indeterminate && 'indeterminate'
  )

  const iconFlip = useMemo<FlipProp | undefined>(() => isSwitch && !checked ? 'horizontal' : undefined, [isSwitch, checked])

  const innerRef = useRef<HTMLInputElement>(null)
  useImperativeHandle(ref, () => innerRef.current as HTMLInputElement)

  useEffect(() => {
    if (innerRef.current == null || indeterminate == null) {
      return
    }

    innerRef.current.indeterminate = indeterminate
  }, [indeterminate, innerRef.current])

  return (
    <div className={containerClasses}>
      <Input
        type='checkbox'
        id={id}
        name={name}
        checked={checked}
        onChange={onChange}
        className={inputClasses}
        ref={innerRef}
        {...otherAttributes}
      />
      <label
        className={labelClasses}
        htmlFor={id}
      >
        <FontAwesomeIcon
          icon={icon}
          className={iconClasses}
          style={{ fontSize: '22px' }}
          flip={iconFlip}
          fixedWidth={iconFixedWidth}
        />
        {children != null &&
          <div>{children}</div>}
      </label>
    </div>
  )
}

/* Checkbox with forwardRef */
const CheckboxWrapped = forwardRef(Checkbox)

export { CheckboxWrapped as Checkbox }
