import { TermOption } from '@web/shared-data-access-queries'

/*
 * Function to sort monthly price for stullerpay terms
 */
function sortStullerPayTermByMonthlyPrice (a: TermOption, b: TermOption): number {
  return a.MonthlyPrice > b.MonthlyPrice ? 1 : -1
}

export {
  sortStullerPayTermByMonthlyPrice
}
