import { ReactElement, useCallback, ChangeEvent, useMemo, ReactNode } from 'react'
import { Checkbox } from '@web/shared-ui-components'
import { useId } from '@floating-ui/react-dom-interactions'

interface SelectAllProps {
  selectedCount: number
  totalCount: number
  checked: boolean
  indeterminate: boolean
  handleChecked: (e: boolean) => void
  disabled?: boolean
  children?: ReactNode
}

function SelectAll ({
  selectedCount,
  totalCount,
  checked,
  indeterminate,
  handleChecked,
  disabled,
  children
}: SelectAllProps): ReactElement | null {
  const uniqueID = useId()
  const checkboxId = useMemo(() => `checkbox-${uniqueID}`, [uniqueID])

  const handleSelectAllChecked = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    handleChecked(e.target.checked)
  }, [handleChecked])

  const label = useMemo(() => {
    if (indeterminate || checked) {
      return <span className='u-bold'>Deselect All</span>
    } else {
      return <span>Select All</span>
    }
  }, [checked, indeterminate])

  return (
    <>
      <Checkbox
        name={checkboxId}
        id={checkboxId}
        type='checkbox'
        checked={checked}
        onChange={handleSelectAllChecked}
        className='pl-4 select-all-checkbox'
        labelClass='mr-3 mb-0'
        indeterminate={indeterminate}
        disabled={disabled}
        data-test='select-all-checkbox'
      >
        {label}
        <span className='t-4 c-gray mx-3'>|</span>
        <div className='d-inline-block'>
          {children != null
            ? (<>{children}</>)
            : (<div>{selectedCount} of {totalCount} selected</div>)}
        </div>
      </Checkbox>
    </>
  )
}

export {
  SelectAll
}
