import {
  Modal,
  ModalBody,
  ModalHeader,
  CmsContent,
  Alert
} from '@web/shared-ui-components'
import { ReactElement, useRef } from 'react'
import { faWrench } from '@fortawesome/pro-solid-svg-icons'

/**
 * Modal component for Oracle down alert from stullerpay redirect
 */
function StullerPayOracleDownModal (): ReactElement {
  const documentBody = useRef(document.body)

  return (
    <Modal portalRef={documentBody} id='stuller-pay-oracle-down-notification'>
      <ModalHeader onToggle={() => $('#stuller-pay-oracle-down-notification').modal('toggle')} />
      <ModalBody className='text-left'>
        <Alert alertType='warning-high' icon={faWrench} className='my-4 text-left'>
          <div className='f700'>
            An information system is currently down for maintenance
          </div>
          <div className='body'>
            Some content may not load, including content related to eligibility for benefits, memberships, and services
          </div>
        </Alert>
        <CmsContent contentContainerName='stullerpay-contact-form-content' />
      </ModalBody>
    </Modal>
  )
}

export { StullerPayOracleDownModal }
