import {
  ReactElement,
  useCallback,
  ChangeEvent,
  useMemo,
  useEffect
} from 'react'
import { Input } from '@web/shared-ui-components'
import InputMask from 'react-input-mask'

interface ExpirationDateShorthandInputProps{
  /**
   * Input name field
   */
  name: string
  /**
   * Input value field
   */
  value: string
  /**
   * Input required field
   */
  required?: boolean
  /**
   * Input onChange event
   */
  onChange: (date: string) => void
  /**
   * Invalid styling
   */
  invalid?: boolean | string
}

function ExpirationDateShorthandInput ({ name, value, required, onChange, invalid }: ExpirationDateShorthandInputProps): ReactElement {
  const expirationMask = useMemo(() => {
    if (value === '') {
      return [/[0-1]/]
    }

    if (/^0/.test(value)) {
      return [/[0-1]/, /[1-9]/, '/', /[0-9]/, /[0-9]/]
    }

    return [/1/, /[0-2]/, '/', /[0-9]/, /[0-9]/]
  }, [value])

  const handleExpirationDateChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.value)
  }, [onChange])

  useEffect(() => {
    onChange(value)
  }, [value])

  const beforeMaskedStateChange = useCallback(({ currentState, nextState }) => {
    if (currentState?.value != null && currentState.value.length === 7) {
      const currentStateSplit = currentState.value.split('/')
      const mm: string = currentStateSplit != null && currentStateSplit.length > 0 ? currentStateSplit[0].toString() : ''
      const year: string = currentStateSplit != null && currentStateSplit.length > 1 ? currentStateSplit[1].toString() : ''
      return {
        ...currentState,
        value: `${mm}/${year.substring(2)}`
      }
    }
    return nextState
  }, [])

  return (
    <InputMask
      mask={expirationMask}
      maskPlaceholder={null}
      beforeMaskedStateChange={beforeMaskedStateChange}
      onChange={handleExpirationDateChange}
      value={value}
    >
      <Input
        name={name}
        placeholder='MM/YY'
        required={required}
        type='tel'
        autoComplete='cc-exp'
        autoCorrect='off'
        spellCheck='false'
        className='c-gray-dk-3'
        invalid={invalid}
        data-test='expiry-date'
      />
    </InputMask>
  )
}

export { ExpirationDateShorthandInput }
