import { ReactElement, useMemo } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFile, faFileImage, faFilePdf, faFileWord, IconDefinition } from '@fortawesome/pro-solid-svg-icons'

interface FileUploadPreviewCardImageProps {
  file: File
}

function FileUploadPreviewCardImage ({ file }: FileUploadPreviewCardImageProps): ReactElement {
  const fileUrl = useMemo(() => getFileUrl(file), [file])
  const fileType = useMemo(() => file.name.split('.').pop()?.toLocaleUpperCase() ?? '', [file.name])
  const fileIcon = useMemo(() => getFileIcon(fileType), [fileType])

  return (
    <>
      {fileUrl != null &&
        <img
          className='w-100 h-100 file-upload-preview-card-image'
          style={{ objectFit: 'cover' }}
          src={fileUrl}
          alt={file.name}
        />}

      {fileUrl == null &&
        <FontAwesomeIcon
          icon={fileIcon}
          className='c-gray-dk-3 w-100 h-100 file-upload-preview-card-image-icon'
        />}
    </>
  )
}

function getFileUrl (file: File): string | undefined {
  const fileType = file.name.split('.').pop()?.toLocaleUpperCase() ?? ''

  switch (fileType.toLowerCase()) {
    case 'jpeg':
    case 'jpg':
    case 'png': {
      return URL.createObjectURL(file)
    }
    default: {
      return undefined
    }
  }
}

function getFileIcon (fileType: string): IconDefinition {
  switch (fileType.toLowerCase()) {
    case 'jpeg':
    case 'jpg':
    case 'png': {
      return faFileImage
    }
    case 'pdf': {
      return faFilePdf
    }
    case 'docx': {
      return faFileWord
    }
    default: {
      return faFile
    }
  }
}

export { FileUploadPreviewCardImage }
