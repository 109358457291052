import { ProductSearchAttribute, ImageUrlsDto } from '@web/shared-data-access-queries'
import { Link } from 'react-router-dom'
import React, { ReactElement, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { SearchAttributeDropdown, ProductCardBadgeSection, ProductResultCardImageLoader, placeholderImage } from '@web/product-navigation-feature'
import { SearchAttributeIcon, SearchImageAsset } from '@web/search-feature'
import { useViewport, useToggle, useSvg, useImagePreloader } from '@web/shared-util-hooks'
import clsx from 'clsx'
import { PriceDisplay } from 'libs/search/feature/PriceDisplay'
import { useUserContext } from '@web/shared-data-access-context'
import { ProductCardImage } from '@web/products'
import { RecycledContentIcon } from 'libs/search/feature/RecycledContentIcon'

export interface IProductResult {
  ClickLoggingPayload?: string | null
  Code?: string | null
  Description?: string | null
  DiscountIsFlashSaleAndStullerFirst?: boolean | null
  DisplayPrice?: string | null
  FeaturedSearchAttribute?: string | null
  FeaturedSearchAttributeValue?: string | null
  FeaturedSearchAttributeValues?: ProductSearchAttribute[] | null
  GroupDetailsUrl?: string | null
  Id: number
  ImageUrls?: ImageUrlsDto | null
  ImageUrlsSide?: ImageUrlsDto | null
  IsDiscounted?: boolean | null
  IsValidFor3CBadge?: boolean
  IsValidForBestSellerBadge?: boolean
  IsValidForCoinedBadge?: boolean
  IsValidForDieStruckBadge?: boolean
  IsValidForDigitalImprintingBadge?: boolean
  IsValidForEverAndEverBadge?: boolean
  IsValidForHotStampingBadge?: boolean
  IsValidForLabGrownBadge?: boolean
  IsValidForNotableGemsBadge?: boolean
  IsValidForLimitedTimeOnlyBadge?: boolean
  IsSCSCertified?: boolean
  IsMadeWithCycledMaterials?: boolean
  IsPrincipledSourcing?: boolean
  IsValidForMachinedBadge?: boolean
  IsValidForMetalMoldBadge?: boolean
  IsValidForNewDealBadge?: boolean
  IsValidForNewItemBadge?: boolean
  MaxDiscount?: number | null
  MinDiscount?: number | null
  OverrideItemId?: number | null
  ParentCode?: string | null
  Rank?: string | null
  SearchId?: string | null
  TemplatePage?: string | null
  WholeGroupIsDiscounted?: boolean | null
  ProductState?: string | null
  SerialNumber?: number | null
}
interface ProductResultCardProps<TProductResult extends IProductResult = IProductResult> {
  productResult: TProductResult
  searchSource: string | null
  showDiscounts: boolean
  controllerName?: string
  actionName?: string
  onProductSelected: (productResult: TProductResult, position: number, overrideItemId?: string) => void
  position: number
  className?: string
  directToPDP: boolean
  groupId: number | null
}
const dealsArray = ['Deals', 'FlashSale', 'StullerFirstSale']

function ProductResultCard<TProductResult extends IProductResult = IProductResult> ({
  productResult,
  searchSource,
  showDiscounts,
  onProductSelected,
  position,
  className,
  directToPDP = false,
  groupId
}: ProductResultCardProps<TProductResult>): ReactElement | null {
  const { context } = useUserContext()
  const { imagesPreloaded: placeholderImagePreloaded } = useImagePreloader([placeholderImage.Size190Url ?? ''])
  const [selectedAttribute, setSelectedAttribute] = useState<ProductSearchAttribute | undefined>()
  const attributesElement = useRef<HTMLDivElement>(null)
  const dropdownButtonElement = React.createRef<HTMLAnchorElement>()
  const [isShowDropdown, toggleIsShowDropdown] = useToggle(false)
  const [addSvg] = useSvg()
  const { isViewportXSmall, isViewportSmallAndUp } = useViewport()
  const showPrice = useMemo(() => context?.HidePrices === false && productResult.DisplayPrice != null, [context?.HidePrices, productResult.DisplayPrice])
  const searchSourceIsDeals: boolean = useMemo(() => searchSource != null && dealsArray.includes(searchSource), [searchSource])
  const imageKey = useMemo(() => {
    const productKey = productResult.ImageUrls?.FullSizeUrl ?? productResult.Id
    let overrideKey = selectedAttribute?.ImageUrls?.FullSizeUrl ?? selectedAttribute?.ItemId
    if (productKey === overrideKey || overrideKey === undefined) {
      overrideKey = 'no-override'
    }
    return `${productKey}-${overrideKey}`
  }, [productResult.ImageUrls, selectedAttribute, productResult.Id])
  const link = useMemo(() => {
    let imgLink = productResult.GroupDetailsUrl
    if (productResult.SerialNumber != null && imgLink != null) {
      return imgLink
    }
    if (showDiscounts && context?.HidePrices === false && searchSourceIsDeals) {
      imgLink = `/deals/discountedproductsingroup/?groupid=${productResult.Id}&searchsource=${searchSource ?? ''}`
    }
    if (directToPDP && groupId != null) {
      imgLink = `/products/details?iid=${productResult.Id}&gid=${groupId}&recommendationSource=Web_Overstock`
    }

    return imgLink ?? ''
  }, [productResult.GroupDetailsUrl, searchSource, showDiscounts, context?.HidePrices, searchSourceIsDeals])
  const featuredAttributes = useMemo(() => {
    if (searchSourceIsDeals) {
      return []
    }
    return productResult.FeaturedSearchAttributeValues ?? []
    //  THIS IS THE BIT THAT WAS SWAPPING OUT THE PRODUCT BEING SHOWN WHEN HOVERING FEATURED SEARCH ATTRIBUTE ICONS (WHEN THE MAIN CARD ITEM "MATCHED" ONE OF THEM)
    // const featuredArr = productResult.FeaturedSearchAttributeValues?.slice() ?? []
    // if (productResult.FeaturedSearchAttributeValue != null) {
    //   const redundantProduct = productResult.FeaturedSearchAttributeValues?.find(x => x.AttributeValue === productResult.FeaturedSearchAttributeValue)
    //   if (redundantProduct != null) {
    //     const redundantProductIdx = featuredArr.indexOf(redundantProduct)

    //     featuredArr[redundantProductIdx] = {
    //       ...redundantProduct,
    //       ItemId: productResult.Id, // this is wrong because productResult.Id is the groupId NOT the itemId
    //       ImageUrls: productResult.ImageUrls,
    //       ItemDetailsUrl: link
    //     }
    //   }
    // }
    // return featuredArr
  }, [productResult, link, searchSourceIsDeals])
  const numAttributesToShow = useMemo(() => isViewportXSmall ? 3 : 6, [isViewportXSmall])
  const slicedAttributes: ProductSearchAttribute[] = useMemo(() => featuredAttributes.slice(0, numAttributesToShow), [featuredAttributes, numAttributesToShow])
  const dropDownText = useMemo(() => {
    const extraAttrLength = featuredAttributes.length - numAttributesToShow
    if (!isViewportXSmall && extraAttrLength <= 0) {
      return null
    }
    return isViewportXSmall ? `${featuredAttributes.length} Option${featuredAttributes.length === 1 ? '' : 's'}` : `+${extraAttrLength}`
  }, [featuredAttributes, numAttributesToShow])

  const handleUnselectAttribute = useCallback((attribute: ProductSearchAttribute) => {
    if (attribute === selectedAttribute) {
      setSelectedAttribute(undefined)
    }
  }, [selectedAttribute, setSelectedAttribute])

  useEffect(() => {
    featuredAttributes.forEach(x => {
      if (x.AttributeImageAsset?.type.toLowerCase() === 'embedded svg') {
        addSvg(x.AttributeImageAsset?.source)
      }
    })
  }, [featuredAttributes])

  const [loadImages, setLoadImages] = useState<boolean>(false)

  const attributeImages = useMemo<string[]>(() => {
    const images: string[] = []
    for (const attribute of featuredAttributes ?? []) {
      if (attribute.ImageUrls?.Size190Url != null) {
        images.push(attribute.ImageUrls?.Size190Url)
      }
    }
    return images
  }, [featuredAttributes])

  const onAttributeHovered = useCallback(() => {
    if (!loadImages) {
      setLoadImages(true)
    }
  }, [loadImages])

  const mainImage = useMemo(() => {
    if (placeholderImagePreloaded) {
      return selectedAttribute?.ImageUrls?.Size190Url ?? productResult?.ImageUrls?.Size190Url ?? ''
    } else {
      return productResult.ImageUrls?.Size190Url ?? ''
    }
  }, [productResult, selectedAttribute])

  const productCardClickHandler: React.MouseEventHandler<HTMLAnchorElement> = () => onProductSelected(productResult, position, productResult.OverrideItemId?.toString())

  return (
    <div className={clsx('productResultCard px-0 px-sm-1 mb-sm-3 mb-0', className)}>
      <div className='p-4 border productResultCardBorder h-100'>
        <div className='u-flex-grid-row'>
          <div className='u-flex-grid-col-sm-12 col-8 position-static order-2'>
            <div className='mt-sm-3 position-static' data-test='product-info-container'>
              <ProductCardBadgeSection
                productResult={productResult}
                searchSourceIsDeals={searchSourceIsDeals}
              />
              {showPrice && productResult.DisplayPrice != null &&
                <PriceDisplay
                  price={productResult.DisplayPrice}
                  minDiscount={productResult.MinDiscount ?? undefined}
                  maxDiscount={productResult.MaxDiscount ?? undefined}
                  isInShowcase={context?.IsInShowcase ?? false}
                  discountIsFlashSaleAndStullerFirst={productResult.DiscountIsFlashSaleAndStullerFirst ?? false}
                />}
              <Link className='productCardDesc stretched-link' to={link} onClick={productCardClickHandler}>
                <div className='mt-1' data-test='product-group-text'>{productResult.Description}</div>
              </Link>
            </div>
            {loadImages &&
              <ProductResultCardImageLoader urls={attributeImages} />}
            <div className='position-static productFeaturedAttributes' data-test='product-featured-attributes'>
              {featuredAttributes.length > 0 &&
                <div className='featured-attribute-icon-height w-100 d-flex mt-1 mx-0 position-relative' ref={attributesElement} onMouseEnter={onAttributeHovered}>
                  {isViewportSmallAndUp && slicedAttributes.map((attr, index) =>
                    <SearchAttributeIcon
                      key={`${productResult.Id}-${attr.ItemId}-${index}`}
                      {...attr}
                      onSelect={() => setSelectedAttribute(attr)}
                      onUnselect={() => handleUnselectAttribute(attr)}
                      onSearchAttrSelected={() => onProductSelected(productResult, index, attr.ItemId.toString())}
                      className='h-100 featured-attribute-icon-width mr-1'
                    />
                  )}
                  {dropDownText != null &&
                    <a
                      className='ml-1 d-flex'
                      onClick={toggleIsShowDropdown}
                      ref={dropdownButtonElement}
                      aria-label={dropDownText}
                      tabIndex={0}
                    >
                      {isViewportXSmall && slicedAttributes.map(attr =>
                        (attr.AttributeImageAsset != null &&
                          <div key={`${attr.ItemId}-xSmallIcons`} className='h-100 featured-attribute-icon-width mr-1'>
                            <SearchImageAsset AttributeValue={attr.AttributeValue} {...attr.AttributeImageAsset} className='h-100 w-100' />
                          </div>)
                      )}

                      <span className='ml-1 ml-sm-0'>{dropDownText}</span>
                    </a>}
                </div>}
              {isShowDropdown &&
                attributesElement != null &&
                  <SearchAttributeDropdown
                    onSelect={setSelectedAttribute}
                    onUnselect={handleUnselectAttribute}
                    productResult={productResult}
                    attributesElement={attributesElement.current}
                    dropdownButtonElement={dropdownButtonElement}
                    featuredAttributes={featuredAttributes}
                    selectedAttribute={selectedAttribute}
                    handleClose={toggleIsShowDropdown}
                    onSearchAttrSelected={onProductSelected}
                    className='featuredAttrDropdown border c-bg-white u-shadow-low-light rounded-lg d-flex-column'
                  />}
            </div>
            <Link className='text-decoration-none c-hover-gray-dk-3' to={link} onClick={productCardClickHandler}>
              <span className='mt-2 u-bold f6 order-4 position-relative' data-test='product-code' style={{ zIndex: 1 }}>
                {productResult.ParentCode ?? productResult.Code}
              </span>
            </Link>
            <RecycledContentIcon
              isSCSCertified={productResult.IsSCSCertified}
              isPrincipledSourcing={productResult.IsPrincipledSourcing}
              isMadeWithCycledMaterials={productResult.IsMadeWithCycledMaterials}
            />
          </div>
          <div className='u-flex-grid-col-sm-12 col-4 order-1 productImage'>
            <Link aria-label='image' to={link} onClick={productCardClickHandler}>
              <ProductCardImage
                key={imageKey}
                mainImage={mainImage}
                placeholderImage={placeholderImage.Size190Url}
                sideImage={productResult.ImageUrlsSide?.Size190Url}
                imageAlt={productResult.Description ?? 'product-image'}
              />
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}

export { ProductResultCard }
