import { ReactElement, useMemo, useCallback, useEffect } from 'react'
import { FancySelect, FancySelectOption, FancySelectProps } from '@web/shared-ui-components'
import { MetalQuality, useGetMetalQualitiesQuery } from '@web/shared-data-access-queries'

function groupMetalQualities (metalQualities: MetalQuality[]): Map<string, MetalQuality[]> {
  const groupedMetalQualities: Map<string, MetalQuality[]> = new Map()

  for (const metalQuality of metalQualities) {
    const groupName: string | null | undefined = metalQuality.BaseMetal

    if (groupName != null) {
      const metalQualities: MetalQuality[] = [metalQuality]

      if (groupedMetalQualities.has(groupName)) {
        const existingMetalQualities: MetalQuality[] = groupedMetalQualities.get(groupName) as MetalQuality[]

        metalQualities.push(...existingMetalQualities)
      }

      groupedMetalQualities.set(groupName, metalQualities)
    }
  }

  return groupedMetalQualities
}

function sortParentGroup (name: string): number {
  switch (name) {
    case 'Gold':
      return 1
    case 'Premium':
      return 2
    case 'Silver':
      return 3
    default:
      return 999
  }
}

function sortMetalQualities (a: MetalQuality, b: MetalQuality): number {
  const karatComparison: number = a.Karat?.localeCompare(b.Karat ?? '') ?? 1
  const colorNameComparison: number = a.ColorName?.localeCompare(b.ColorName ?? '') ?? 1
  const nameComparison: number = a.Name?.localeCompare(b.Name ?? '') ?? 1

  if (karatComparison === 0) {
    if (colorNameComparison === 0) {
      return nameComparison
    } else {
      return colorNameComparison
    }
  }

  return karatComparison
}

interface MetalQualityOptionProps {
  metalQuality: MetalQuality
}

function MetalQualityOption ({ metalQuality }: MetalQualityOptionProps): ReactElement | null {
  return (
    <div className='d-flex align-items-center' data-test='metal-quality-dropdown'>
      {metalQuality.IconPath != null &&
        <img
          src={metalQuality.IconPath}
          style={{ width: '25px', height: '25px' }}
          className='mr-2'
        />}
      {metalQuality?.Name}
    </div>
  )
}

interface MetalType {
  metal: string
  metalVerbose: string
}

interface MetalQualityDropdownProps extends FancySelectProps {
  metalQualityCodes: Array<string | null>
  selectedValue: string | null
  label?: string | null
  invalid?: boolean
  onSelect: (code: string | null, category: string | null, actionMeta) => void
  onMetalQualitiesLoaded?: (metalQualities: MetalQuality[]) => void
}

function MetalQualityDropdown ({ metalQualityCodes, selectedValue: selectedMetalQuality, label, invalid, onSelect, onMetalQualitiesLoaded, ...otherAttributes }: MetalQualityDropdownProps): ReactElement | null {
  const { data } = useGetMetalQualitiesQuery({
    variables: {
      query: `codes=${metalQualityCodes?.filter(x => x != null)?.join('&codes=')}`
    }
  })

  const handleSelect = useCallback((newValue: FancySelectOption<MetalType> | null, actionMeta) => {
    if (onSelect != null && newValue != null && actionMeta.action === 'select-option') {
      onSelect(newValue.value.metalVerbose, newValue.value.metal, actionMeta)
    }
  }, [onSelect])

  const options = useMemo<Array<FancySelectOption<MetalType>>>(() => {
    const dropdownOptions: Array<FancySelectOption<MetalType>> = []
    const metalQualities = data?.getMetalQualities

    if (metalQualities != null) {
      const groupedMetalQualities: Map<string, MetalQuality[]> = groupMetalQualities(metalQualities as MetalQuality[])

      groupedMetalQualities.forEach((metalQualities, groupName) => {
        // Only show group if it has more than 1 more quality inside it
        if (metalQualities.length > 0) {
          // 1) Sort all metal qualities inside current group by Karat then ColorName then Name
          // 2) Remove any metal quality with an undefined 'Code' property
          // 3) Turn already sorted metal qualities into selectable options (FancySelectOption)
          // 4) Add sorted, filtered, & mapped metal quality options to group's options
          // 5) Put only the group option (that has child options) into the master list of shown options
          // 6) React-Select handles the rest
          const groupOptions: Array<FancySelectOption<MetalType>> = metalQualities
            .sort((a, b) => sortMetalQualities(a, b))
            .filter((metalQuality) => metalQuality?.Code != null)
            .map((metalQuality) => {
              const metal: MetalType = { metal: groupName, metalVerbose: metalQuality.Code ?? '' }
              return {
                value: metal,
                label: <MetalQualityOption metalQuality={metalQuality} />
              }
            })

          dropdownOptions.push({ label: groupName, value: { metal: groupName, metalVerbose: '' }, options: groupOptions })
        }
      })
    }

    // Gold, Premium, Silver
    return dropdownOptions.sort((a, b) =>
      sortParentGroup(a.label as string) - sortParentGroup(b.label as string))
  }, [data])

  const value = useMemo(() => {
    if (selectedMetalQuality != null) {
      for (const groupOption of options) {
        if (groupOption.value.metalVerbose.toUpperCase() === selectedMetalQuality.toUpperCase()) {
          return groupOption
        }
        if (groupOption.options != null) {
          for (const childOption of groupOption.options) {
            if (childOption.value.metalVerbose.toUpperCase() === selectedMetalQuality.toUpperCase()) {
              return childOption
            }
          }
        }
      }
    }
    return null
  }, [options, selectedMetalQuality])

  useEffect(() => {
    if (onMetalQualitiesLoaded != null && data?.getMetalQualities != null) {
      const metalQualities = data.getMetalQualities as MetalQuality[]

      onMetalQualitiesLoaded(metalQualities)
    }
  }, [options])

  return (
    <FancySelect
      options={options}
      placeholder='Select...'
      value={value}
      isSearchable={false}
      color='secondary'
      onChange={handleSelect}
      menuPortalTarget={null}
      optionBorders
      invalid={invalid}
      styles={({
        menu: base => ({
          ...base,
          zIndex: 9999
        }),
        menuPortal: base => ({
          ...base,
          zIndex: 9999
        })
      })}
      {...otherAttributes}
    />
  )
}

export { MetalQualityDropdown, MetalType }
