import {
  ReactElement, ReactNode, useMemo
} from 'react'
import { Card } from '@web/shared-ui-components'
import clsx from 'clsx'

interface CardSummaryProps {
  className?: string
  children?: ReactNode
  format?: 'default' | 'primary' | 'error'
}

function CardSummary ({ format = 'default', className, children }: CardSummaryProps): ReactElement {
  const textColor = useMemo(() => clsx(
    format === 'default' && 'c-gray-dk-3',
    format === 'error' && 'c-red-dk-1',
    format === 'primary' && 'c-primary-dk-3'
  ), [])

  const backgroundColor = useMemo(() => clsx(
    format === 'default' && 'c-bg-gray-lt-3',
    format === 'error' && 'c-bg-red-lt-2',
    format === 'primary' && 'c-bg-primary-lt-2'
  ), [])

  return (
    <Card className={clsx('u-margin-bottom-10 d-flex flex-wrap flex-fill text-center border-0 u-border-radius-medium', textColor, backgroundColor, className)}>
      <div className='p-4 w-100 mx-2 d-flex flex-wrap d-md-block justify-content-between align-items-center'>
        {children}
      </div>
    </Card>
  )
}

export { CardSummary }
