import {
  ReactElement
} from 'react'
import { CardGroup } from './CardGroup'
import { GetConfigItemSummaryQuery } from '@web/shared-data-access-queries'

interface ConfigurationGroupProps {
  configDetails: NonNullable<NonNullable<GetConfigItemSummaryQuery['getConfigItemSummary']>['configDetails']>
}

/**
 * Parent component that houses all of the card groups for a set of configuration
 */
function ConfigurationDetails ({
  configDetails
}: ConfigurationGroupProps): ReactElement {
  return (
    <div>
      {configDetails.map((configurationGroup, index) =>
        <CardGroup configurationGroup={configurationGroup} key={index} />
      )}
    </div>
  )
}

export { ConfigurationDetails }
