import { PaymentMethod, PaymentMethodType } from '@web/shared-data-access-queries'
/**
 * Allowed payment methods for when adding a new card
 */
export const newCardAllowedMethods = [
  PaymentMethodType.Debitcard,
  PaymentMethodType.Creditcard
]

/**
     * Allowed payment methods for when adding a new bank account
     */
export const newBankAccountAllowedMethods = [
  PaymentMethodType.Ach
]

export function isBankOrCardPaymentType (paymentMethodType: string): boolean {
  return paymentMethodType === PaymentMethodType.Ach ||
        paymentMethodType === PaymentMethodType.Creditcard ||
        paymentMethodType === PaymentMethodType.Debitcard
}

export function isCardPaymentType (paymentMethodType: string): boolean {
  return paymentMethodType === PaymentMethodType.Creditcard ||
        paymentMethodType === PaymentMethodType.Debitcard
}

export function mapPaymentMethod (paymentMethod: PaymentMethod): PaymentMethod {
  return {
    ...paymentMethod,
    UniqueKey: paymentMethod.Method === PaymentMethodType.Creditcard && paymentMethod.CreditCard != null
      ? `${paymentMethod.Method}-${paymentMethod.CreditCard.DataSource === 'Oracle' ? paymentMethod.CreditCard.InstrumentId : paymentMethod.CreditCard.Id}`
      : paymentMethod.Method,
    DisplayCardType: paymentMethod.Method === PaymentMethodType.Creditcard && paymentMethod.CreditCard != null
      ? (paymentMethod.CreditCard.CardType.toLowerCase().replace(/[ _]/g, '-') === 'americanexpress' ? 'American Express' : paymentMethod.CreditCard.CardType)
      : '',
    CardTypeIcon: paymentMethod.CreditCard != null
      ? (paymentMethod.CreditCard.CardType.toLowerCase().replace(/[ _]/g, '-') === 'americanexpress' ? 'american-express' : paymentMethod.CreditCard.CardType.toLowerCase().replace(/[ _]/g, '-'))
      : '',
    SelectableValue: paymentMethod.CreditCard != null
      ? paymentMethod.CreditCard.Id
      : (paymentMethod.BankAccount != null
          ? paymentMethod.BankAccount.Id
          : paymentMethod.Method)
  }
}
