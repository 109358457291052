import {
  ReactNode,
  ReactElement,
  ForwardedRef,
  forwardRef,
  useContext,
  useMemo
} from 'react'
import { DropdownContext } from './DropdownContext'
import '@web/styles/Dropdown.less'
import clsx from 'clsx'

interface DropdownHeaderProps {
  /**
   * Id of element
   */
  id?: string
  /**
      * Additional class name(s) to give to the containing element
      */
  className?: string
  /**
      * Children of element
      */
  children?: ReactNode
}

/**
 * Dropdown header component that holds the toggle for dropdown
 */
function DropdownHeader ({
  id,
  className,
  children
}: DropdownHeaderProps, ref: ForwardedRef<any>): ReactElement {
  const { isCollapsed, onToggle } = useContext(DropdownContext)
  const classNames = useMemo(() => clsx(
    'react-dropdown-header',
    !isCollapsed && 'react-dropdown-header-shadow',
    className
  ), [className, isCollapsed])

  return (
    <div
      id={id}
      className={classNames}
      onClick={onToggle}
      ref={ref}
    >
      {children}
    </div>
  )
}

/**
 * DropdownHeader with forwardRef
 */
const DropdownHeaderWrapped = forwardRef(DropdownHeader)

export { DropdownHeaderWrapped as DropdownHeader }
