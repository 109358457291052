import {
  MouseEventHandler,
  ReactElement,
  useCallback,
  useMemo,
  useState
} from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, LoadingIndicator } from '@web/shared-ui-components'
import { useGetContextMinimalQuery, useFindAllActiveAutorenewalsByCreditCardIdLazyQuery, LoyaltyProgramDetails } from '@web/shared-data-access-queries'
import { dayjs } from '@web/shared-util-dayjs'

interface DeletePaymentProps {
  /**
    * event for remove button
    */
  removeHandler: MouseEventHandler

  /**
    *  display name for payment method
    */
  displayName?: string

  /**
    * ID for modal to confirm payment method deletion
    */
  deleteModalId: string
}

/**
 * Deleting payment method component
 */
function DeletePaymentMethod ({ removeHandler, displayName, deleteModalId }: DeletePaymentProps): ReactElement {
  const [renewalDetails, setRenewalDetails] = useState<LoyaltyProgramDetails>()

  const { data: userContextData } = useGetContextMinimalQuery()
  const [findAllActiveAutorenewalsByCreditCardId, { loading }] = useFindAllActiveAutorenewalsByCreditCardIdLazyQuery({ fetchPolicy: 'network-only' })

  // Prevent payment method deletion if it is linked to StullerFirst membership
  const stullerFirstRenewal = useMemo(() => {
    return renewalDetails?.Renewals?.find(md => md?.IsStullerFirst)
  }, [renewalDetails?.Renewals])

  const handleDeleteModal = useCallback(() => {
    if (deleteModalId != null) {
      // StullerFirst check
      if (userContextData?.context.IsInStullerFirst === true) {
        void findAllActiveAutorenewalsByCreditCardId({
          variables: {
            params: {
              creditCardId: deleteModalId
            }
          }
        })
          .then(resp => {
            const membershipDetails = resp.data?.findAllActiveAutorenewalsByCreditCardId
            setRenewalDetails({
              Renewals: membershipDetails?.Renewals
            })
          })
      }
      $(`#delete-payment-${deleteModalId}`).modal('toggle')
    }
  }, [deleteModalId, userContextData?.context.IsInStullerFirst])

  return (
    <div>
      <Button onClick={handleDeleteModal} color='negative' className='f6' style={{ minWidth: '90px', maxHeight: '30px' }} data-test='remove-payment-method'>Remove</Button>
      <Modal id={`delete-payment-${deleteModalId}`} size='default'>
        <ModalHeader title='Remove Payment Method' onToggle={handleDeleteModal} />
        <ModalBody>
          <LoadingIndicator loading={loading} position='center'>
            {stullerFirstRenewal != null
              ? (
                <div className='f400'>
                  <div>This card is currently linked to the following {stullerFirstRenewal.Name}<sup>®</sup> membership:</div>
                  <ul className='my-3'>
                    <li>
                      <div>{stullerFirstRenewal.Name}<sup>®</sup> | {userContextData?.context.DefaultShipToAccountNumber}</div>
                      <div className='font-weight-bold'>Renewal Date: <span className='f400'>{dayjs(stullerFirstRenewal.DateForAutoRenewal).format('LL')}</span></div>
                      <a className='primary-link' href='/myaccount/stullerfirst/managememberships'>Manage Membership</a>
                    </li>
                  </ul>
                  <div>To remove this card, please update the payment method for the membership to avoid deactivation and loss of benefits.</div>
                </div>
                )
              : (
                <p>Are you sure you want to delete <span className='font-weight-bold'>{displayName}</span> as a payment method?</p>
                )}
          </LoadingIndicator>
        </ModalBody>
        <ModalFooter>
          <Button color='link' className='mr-5 t-4' onClick={handleDeleteModal} data-test='cancel-button-in-remove-card-modal'>Cancel</Button>
          <Button onClick={removeHandler} color='primary' disabled={stullerFirstRenewal != null || loading} data-test='remove-button-in-remove-card-modal'>Remove</Button>
        </ModalFooter>
      </Modal>
    </div>
  )
}

export {
  DeletePaymentMethod
}
