import {
  ReactElement,
  ReactNode,
  useMemo
} from 'react'
import { AddPaymentMethodContext, AddPaymentMethodContextProps } from '@web/payment-methods'

interface AddPaymentMethodWrapperProps extends AddPaymentMethodContextProps {
  /**
   * Allowed payment methods for when adding a new payment method
   */
  allowedMethods: string[]
  children: ReactNode
}

/**
 * AddPaymentMethodWrapper component
 */
function AddPaymentMethodWrapper ({ isCollapsed, onToggleCollapse, allowedMethods, children }: AddPaymentMethodWrapperProps): ReactElement | null {
  const addPaymentMethodContext: AddPaymentMethodContextProps = useMemo(() => ({ isCollapsed, onToggleCollapse }), [isCollapsed, onToggleCollapse])

  if (allowedMethods == null) {
    return null
  }

  return (
    <div className='u-flex-grid-col-12 form-input-group mt-3 p-0 u-border-radius-medium u-border-all u-margin-bottom-20' style={{ minHeight: '50px' }}>
      <AddPaymentMethodContext.Provider value={addPaymentMethodContext}>
        {children}
      </AddPaymentMethodContext.Provider>
    </div>
  )
}

export {
  AddPaymentMethodWrapper
}
