import { useCallback, useEffect } from 'react'

function useToggleArray (toggleArray: boolean[], setToggleArray: any, numberOfResults: number, defaultToggle: boolean = false): any {
  /*
   * Initialize an array with the number of toggles that will be on the page (results)
   */
  useEffect(() => {
    const initialTableToggleState = new Array(numberOfResults).fill(defaultToggle)
    setToggleArray(initialTableToggleState)
  }, [numberOfResults, setToggleArray])

  /*
   * Handle the click of each toggle individually
   */
  const handleRowToggleClick = useCallback((i) => {
    const newArr = [...toggleArray]
    newArr[i] = !newArr[i]
    setToggleArray(newArr)
  }, [toggleArray, setToggleArray])

  return handleRowToggleClick
}

export {
  useToggleArray
}
