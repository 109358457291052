import { ReactElement, useCallback, useEffect, useState, useMemo, useRef } from 'react'
import { FeedProductCard } from './FeedProductCard'
import { Button, Modal, ModalBody, ModalHeader, ModalFooter, LoadingIndicator, Alert } from '@web/shared-ui-components'
import '@web/styles/AddProductToProductFeedModal.less'
import { ProductFeedList } from './ProductFeedList'
import { toast, ToastContainer } from 'react-toastify'
import { useGetDataExportFeedsLazyQuery, useGetContextMinimalQuery, ProductDataFeed, useSaveSeriesMutation, useSaveSkuMutation } from '@web/shared-data-access-queries'
import { faCheck, faWarning, faLayerGroup } from '@fortawesome/pro-solid-svg-icons'
import { UserContextProvider } from 'libs/shared/ui/components/context/UserContextProvider'
import { useLock } from '@web/shared-util-hooks'
import { FeedModalPageType } from './types'
import { AddProductFeedDropdown, AddProductFeedDropdownProps } from './AddProductFeedDropdown'
import { dispatchStullerEvent } from '@web/shared-util-stuller-events'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

interface AddProductToProductFeedModalProps {
  productSku: string
  productSeries: string
  productImage: string
  productUrl: string
  productTitle: string
}

interface MainModalPageProps {
  productSku: string
  productSeries: string
  productImage: string
  productUrl: string
  productTitle: string
  onAddItem: () => void
  onAddSeries: () => void
}

interface SeriesModalPageProps extends AddProductFeedDropdownProps {
  productSeries: string
  savedDataFeeds: ProductDataFeed[] | undefined
}

interface ItemModalPageProps extends AddProductFeedDropdownProps {
  productUrl: string
  productImage: string
  productTitle: string
  productSku: string
  productSeries: string
  savedDataFeeds: ProductDataFeed[] | undefined
}

function MainModalPage ({ productSku, productSeries, productImage, productUrl, productTitle, onAddItem, onAddSeries }: MainModalPageProps): ReactElement | null {
  return (
    <>
      <div className='c-bg-gray-lt-3 u-padding-all-20 mb-3'>
        <FeedProductCard
          productImage={productImage}
          title={productTitle}
          sku={productSku}
          url={productUrl}
        />
        <div className='d-flex pt-2 mt-3 u-border-radius-medium'>
          <div className='u-flex-grid-col-8 pl-0'>
            <div>
              <span className='h3'>Add this Item</span>
            </div>
            <div>
              <span>Add this product to a data feed</span>
            </div>
          </div>
          <div>
            <Button color='primary' rounded onClick={onAddItem}>Add to a feed</Button>
          </div>
        </div>
      </div>
      <div className='c-bg-gray-lt-3 u-padding-all-20 u-border-radius-medium'>
        <div className='d-flex pt-2'>
          <div className='u-flex-grid-col-8 pl-0'>
            <div>
              <span className='h3'>Add this series</span>
            </div>
            <div>
              <span>Add all product variations of the series {productSeries} to a data feed</span>
            </div>
          </div>
          <div>
            <Button color='primary' rounded onClick={onAddSeries}>Add to a feed</Button>
          </div>
        </div>
      </div>
    </>
  )
}

function AddSeriesModalPage ({ productSeries, savedDataFeeds, productFeeds }: SeriesModalPageProps): ReactElement | null {
  const [saveSeries, { loading: savingSeries, data: saveSeriesData }] = useSaveSeriesMutation()
  const buttonText = 'Add series to this feed'
  useEffect(() => {
    if (saveSeriesData?.saveSeries != null) {
      const data = saveSeriesData.saveSeries
      const toastType = data.success === true ? toast.success : toast.error

      toastType(
        <>
          <Alert
            data-test='product-feed-success-or-error-message'
            alertType={data.success === true ? 'success' : 'error'}
            showBackground={false}
            showBorder={false}
            icon={data.success === true ? faCheck : faWarning}
          >
            {data.message}
          </Alert>
        </>, {
          position: 'top-right'
        })
    }
  }, [saveSeriesData])

  const [handleSave] = useLock(useCallback(async (event: React.SyntheticEvent<HTMLFormElement>, productDataFeedId: number) => {
    await saveSeries({
      variables: {
        input: {
          ProductDataFeedId: productDataFeedId,
          Series: productSeries
        }
      }
    })
  }, [productSeries, saveSeriesData]))

  return (
    <>
      <h3>Item Series {productSeries}</h3>
      <p>After adding products, you can process the feed on the&nbsp;
        <a className='primary-link' href='/myaccount/ecommerceselfservice/productdatafeeds/'>saved product feed page</a>
      </p>
      <AddProductFeedDropdown productFeeds={productFeeds} />
      {savedDataFeeds != null &&
        <LoadingIndicator loading={savingSeries}>
          <ProductFeedList
            productFeeds={savedDataFeeds}
            buttonText={buttonText}
            onItemSave={handleSave}
          />
        </LoadingIndicator>}
    </>
  )
}

function AddItemModalPage ({ productSeries, productSku, productImage, productTitle, productUrl, savedDataFeeds, productFeeds }: ItemModalPageProps): ReactElement | null {
  const [saveSku, { loading: savingSku, data: saveSkuData }] = useSaveSkuMutation()
  const buttonText = 'Add item to this feed'
  useEffect(() => {
    if (saveSkuData?.saveSku != null) {
      const data = saveSkuData.saveSku
      const toastType = data.success === true ? toast.success : toast.error

      toastType(
        <>
          <Alert
            data-test='product-feed-success-or-error-message'
            alertType={data.success === true ? 'success' : 'error'}
            showBackground={false}
            showBorder={false}
            icon={data.success === true ? faCheck : faWarning}
          >
            {data.message}
          </Alert>
        </>, {
          position: 'top-right'
        })
    }
  }, [saveSkuData])

  const [handleSave] = useLock(useCallback(async (event: React.SyntheticEvent<HTMLFormElement>, productDataFeedId: number) => {
    await saveSku({
      variables: {
        input: {
          ProductDataFeedId: productDataFeedId,
          Sku: productSku
        }
      }
    })
  }, [productSku, productSeries, saveSkuData]))

  return (
    <>
      <FeedProductCard
        productImage={productImage}
        title={productTitle}
        sku={productSku}
        url={productUrl}
      />
      <p className='pt-3'>After adding products, you can process the feed on the&nbsp;
        <a className='primary-link' href='/myaccount/ecommerceselfservice/productdatafeeds/'>saved product feed page</a>
      </p>
      <AddProductFeedDropdown productFeeds={productFeeds} />
      {savedDataFeeds != null &&
        <LoadingIndicator loading={savingSku}>
          <ProductFeedList
            productFeeds={savedDataFeeds}
            buttonText={buttonText}
            onItemSave={handleSave}
          />
        </LoadingIndicator>}
    </>
  )
}

function AddProductToProductFeedModal ({ productSku, productSeries, productImage, productUrl, productTitle }: AddProductToProductFeedModalProps): ReactElement | null {
  const { data: userContextData } = useGetContextMinimalQuery()
  const handleAddProductFeed = useCallback((): void => getCategories(), [])
  const [getDataExportFeeds, { loading, error, data }] = useGetDataExportFeedsLazyQuery()

  const productFeeds = useMemo(() => {
    return data?.getDataExportFeeds?.SavedExports
  }, [data])

  const [modalTitle, setModalTitle] = useState('Add to product feed')
  const [modalPage, setModalPage] = useState<FeedModalPageType>('main')
  const canShowProductFeedButton = useMemo(() => userContextData?.context.IsEcommMerchandisingMode === true &&
    userContextData?.context.LoginId !== null &&
    !userContextData?.context.IsSterling && !userContextData?.context.IsInShowcase, [userContextData?.context.IsSterling, userContextData?.context.IsInShowcase])

  const handleAddSeries = useCallback(() => {
    setModalTitle('Add series to data feed')
    setModalPage('series')
    dispatchStullerEvent('track-universal-event', {
      category: 'CTA',
      action: 'AddSeriesToProductFeed',
      label: productSeries
    })
  }, [])
  const handleAddItem = useCallback(() => {
    setModalTitle('Add item to data feed')
    setModalPage('product')
    dispatchStullerEvent('track-universal-event', {
      category: 'CTA',
      action: 'AddItemToProductFeed',
      label: productSku
    })
  }, [])
  const handleBackButton = useCallback(() => {
    setModalTitle('Add to product feed')
    setModalPage('main')
  }, [])

  const getCategories = useCallback(() => {
    void getDataExportFeeds({})
    handleToggle()
  }, [])

  const handleToggle = useCallback(() => {
    setModalPage('main')
    $('#add-product-to-product-modal').modal('toggle')
  }, [])

  const createToastError = useCallback((message: string) => {
    toast.error(
      <>
        <Alert
          data-test='product-feed-success-or-error-message'
          alertType='error'
          showBackground={false}
          showBorder={false}
          icon={faWarning}
        >
          {message}
        </Alert>
      </>, {
        position: 'top-right'
      })
  }, [])

  useEffect(() => {
    if (error !== null && error !== undefined) {
      createToastError('There was an error getting your feeds')
    }
  }, [error])
  const documentBody = useRef(document.body)

  return (
    <UserContextProvider>
      {canShowProductFeedButton &&
        <>
          <ToastContainer
            position='top-right'
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            icon={false}
          />
          <div>
            <Button
              tag='a'
              color='link'
              className='d-block d-md-inline c-gray-dk-3 c-hover-gray-dk-1'
              data-test='add-category-to-product-feed-button'
              onClick={handleAddProductFeed}
            >
              <FontAwesomeIcon icon={faLayerGroup} className='c-gray-dk-1' />
              <span className='u-underline ml-2'>Add to a data feed</span>
            </Button>
          </div>
          <Modal portalRef={documentBody} id='add-product-to-product-modal' size='default'>
            <ModalHeader title={modalTitle} onToggle={handleToggle} />
            <LoadingIndicator loading={loading}>
              <ModalBody className='d-flex flex-column'>
                {modalPage === 'main' &&
                  <MainModalPage
                    productTitle={productTitle}
                    productSku={productSku}
                    productSeries={productSeries}
                    productImage={productImage}
                    productUrl={productUrl}
                    onAddItem={handleAddItem}
                    onAddSeries={handleAddSeries}
                  />}
                {modalPage === 'series' &&
                  <AddSeriesModalPage
                    productSeries={productSeries}
                    savedDataFeeds={data?.getDataExportFeeds?.SavedExports}
                    productFeeds={productFeeds}
                  />}
                {modalPage === 'product' &&
                  <AddItemModalPage
                    productImage={productImage}
                    productSeries={productSeries}
                    productTitle={productTitle}
                    productUrl={productUrl}
                    productSku={productSku}
                    savedDataFeeds={data?.getDataExportFeeds?.SavedExports}
                    productFeeds={productFeeds}
                  />}
              </ModalBody>
              <ModalFooter>
                {modalPage !== 'main' &&
                  <div className='float-left d-flex flex-row align-items-center'>
                    <Button color='secondary' onClick={handleBackButton}>Back</Button>
                  </div>}
                <div className='float-right d-flex flex-row align-items-center'>
                  <Button color='secondary' onClick={handleToggle}>Close</Button>
                </div>
              </ModalFooter>
            </LoadingIndicator>
          </Modal>
        </>}
    </UserContextProvider>
  )
}

export { AddProductToProductFeedModal }
