import { faAngleDown, faAngleUp } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ProductDataFeed, useCreateProductDataFeedMutation } from '@web/shared-data-access-queries'
import { Button, Collapse, Input } from '@web/shared-ui-components'
import { useToggle, useToast } from '@web/shared-util-hooks'
import { ReactElement, ChangeEvent, useCallback, useState } from 'react'

export interface AddProductFeedDropdownProps {
  productFeeds: ProductDataFeed[] | undefined
}
function AddProductFeedDropdown ({ productFeeds }: AddProductFeedDropdownProps): ReactElement {
  const [collapse, toggleCollapse] = useToggle(true)
  const [newProductFeedName, setNewProductFeedName] = useState('')
  const handleSetNewProductName = useCallback((e: ChangeEvent<HTMLInputElement>): void => setNewProductFeedName(e.target.value), [])
  const [createFeed, { loading: isCreatingFeed }] = useCreateProductDataFeedMutation({
    refetchQueries: [
      'GetDataExportFeeds'
    ]
  })

  const createToastSuccess = useToast({ status: 'success' })
  const createToastError = useToast({ status: 'error' })
  const handleCreateNewFeed = useCallback(async () => {
    if (newProductFeedName.length === 0) {
      createToastError({ message: 'The name of the product feed cannot be empty. Product feed was not created' })
    } else {
      if (productFeeds != null) {
        if (productFeeds?.some(x => x.Name === newProductFeedName.trim())) {
          createToastError({ message: 'The name of the product feed already exists. Product feed was not created.' })
        } else {
          createToastSuccess({ message: 'Product feed was created' })
        }
      }
    }

    await createFeed({
      variables: {
        input: {
          name: newProductFeedName
        }
      }
    })
  }, [newProductFeedName, productFeeds])
  return (
    <div className='c-bg-gray-lt-3 mb-3 u-border-radius-small'>
      <div className='u-cursor-pointer px-4 py-2' onClick={toggleCollapse}>
        <div className='d-flex align-items-center flex-wrap justify-content-between'>
          <span className='font-weight-bold'>Create a new product data feed </span>
          <FontAwesomeIcon className='p-2 t-2' size='2x' icon={collapse ? faAngleDown : faAngleUp} />
        </div>
      </div>
      <Collapse isCollapsed={collapse}>
        <div className='d-flex align-items-center px-4 pb-4 flex-wrap'>
          <label className='mr-3 mb-0 w-100'>Name</label>
          <Input type='text' name='product-feed-name' data-test='product-feed-name' minLength={1} maxLength={50} autoFocus value={newProductFeedName} onChange={handleSetNewProductName} className='p-3 flex-grow-1 border-radius-sm' />
          <Button loading={isCreatingFeed} className='ml-3' onClick={handleCreateNewFeed} data-test='create-button' tag='button' color='primary' type='submit' disabled={isCreatingFeed}>Create</Button>
        </div>
      </Collapse>
    </div>
  )
}

export { AddProductFeedDropdown }
