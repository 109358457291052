import { ReactChild, ReactElement } from 'react'
import { GetContextMinimalQuery } from '@web/shared-data-access-queries'
import { CmsContent } from '@web/shared-ui-components'
import '@web/styles/ErrorPage.less'
import clsx from 'clsx'

export interface ErrorPageSideImage {
  src: string
  alt: string
}

export interface ErrorPageProps {
  context?: GetContextMinimalQuery['context']
  header: string
  children?: ReactChild|ReactChild[]
  sideImage?: ErrorPageSideImage
  icon?: ReactElement
  isFormAllowed?: boolean
}

function ErrorPage ({ header, children, sideImage, icon, context, isFormAllowed = true }: ErrorPageProps): ReactElement {
  const isSideForm = context?.LoginId != null && isFormAllowed
  const isSideImage = !isSideForm && sideImage != null
  const isSideSection = isSideForm || isSideImage

  return (
    <div id='main'>
      <h2 className='text-center'>{header}</h2>
      <div className='u-flex-grid-row'>
        <div className={clsx(isSideSection ? 'u-flex-grid-col-sm-6' : 'u-flex-grid-col-sm-12')}>
          {icon != null && <div className='react-error-page-icon'>{icon}</div>}
          {children}
          <p>
            You may be able to find the information you were looking for with one of these links, or try using the search bar.
          </p>
          <ul>
            <li><a href='/'>Return to the Homepage</a></li>
            <li><a href='/browse/'>Products</a></li>
            <li><a href='/services'>Services</a></li>
            <li><a href='/events'>Events</a></li>
            <li><a href='/education'>Education</a></li>
          </ul>
          <p />
          <p>Still can't find what you're looking for?<br />Call 1-800-877-7777.</p>
        </div>
        {isSideSection &&
          <div className={clsx('u-flex-grid-col-sm-6', isSideImage && 'text-center d-none d-sm-block ')}>
            {isSideImage &&
              <img
                className='image-responsive'
                {...sideImage}
                alt='error'
              />}
            {isSideForm &&
              <div id='serverErrorForm' className='smallBorder'>
                <CmsContent contentContainerName='500-error-form' />
              </div>}
          </div>}
      </div>
    </div>
  )
}

export { ErrorPage }
