import clsx from 'clsx'
import { ReactElement, ReactNode } from 'react'
import '@web/styles/SearchPriceDisplay.less'

export interface PriceDisplayProps {
  children?: ReactNode
  price: string
  minDiscount?: number
  maxDiscount?: number
  className?: string
  isInShowcase: boolean
  discountIsFlashSaleAndStullerFirst: boolean
}

function PriceDisplay (props: PriceDisplayProps): ReactElement {
  const { price, minDiscount, maxDiscount, className, isInShowcase, discountIsFlashSaleAndStullerFirst } = props
  const isUpTo = maxDiscount !== minDiscount
  const discountText = maxDiscount != null ? Math.round(maxDiscount) : undefined
  return (
    <div className={clsx(className, 'priceDisplay')}>
      <div dangerouslySetInnerHTML={{ __html: price }} className='u-bold' />
      {maxDiscount != null && maxDiscount > 0 && !isInShowcase &&
        <div className={clsx(discountIsFlashSaleAndStullerFirst && 'stullerFirstDiscountText', 'text-danger u-bold u-small-font-size discountDisplay')}>
          {isUpTo && (<>Up to</>)} {discountText}% Off
        </div>}
    </div>
  )
}

export { PriceDisplay }
