import clsx from 'clsx'
import { ReactNode, useState, forwardRef, useRef, useEffect, useCallback, useImperativeHandle } from 'react'
import { CarouselContext, CarouselContextType } from './CarouselContext'
import { useEventListener, usePrevious } from '@web/shared-util-hooks'
import '@web/styles/Carousel.less'

interface CarouselProps extends Omit<CarouselContextType, 'sliding'> {

  fade?: boolean
  dark?: boolean
  className?: string

  popout?: boolean | 'indicators' | 'controls'
  /**
   * The amount of time to delay between automatically cycling an item
   * Set to `false` to turn off automatic cycle
   */
  interval?: number | false
  /**
   * Whether the carousel should react to keyboard events
   */
  keyboard?: boolean
  /**
   * Indicates when to pause the automatic cycling
   * Use 'hover' to pause on hover
   */
  pause?: 'hover'
  /**
   * Autoplays the carousel after the user manually cycles the first item (or hovering if `pause` is set to `'hover'`)
   * Set to 'carousel' to play on load.
   */
  ride?: 'carousel' | boolean
  /**
   * Indicates the carousel should cycle continuously or have hard stop
   */
  wrap?: boolean
  /**
   * Indicates the carousel should support left/right swipe interactions on touchscreen devices
   */
  touch?: boolean
  /**
   * Handler called when carousel is sliding
   */
  onSlide?: (event: Event) => void
  /**
   * Handler called when carousel is done sliding
   */
  onSlid?: (event: Event) => void

  children: ReactNode
}

const Carousel = forwardRef<HTMLDivElement, CarouselProps>(({
  className,
  children,
  index,
  length,
  onChange,
  fade = false,
  dark = false,
  popout = false,
  interval = 5000,
  keyboard = true,
  pause = 'hover',
  ride = false,
  wrap = true,
  touch = true,
  onSlide = () => { },
  onSlid = () => { },
  ...otherAttributes
}, ref) => {
  const [carousel, setCarousel] = useState<any>()
  const [sliding, setSliding] = useState(false)
  const innerRef = useRef<HTMLDivElement>(null)
  const context = { index, length, onChange, sliding }
  useImperativeHandle(ref, () => innerRef.current as HTMLDivElement, [])

  const previousIndex = usePrevious(index)

  const classNames = clsx(
    'carousel',
    fade && 'carousel-fade',
    dark && 'carousel-dark',
    (popout === true || popout === 'controls') && 'carousel-popout-controls',
    (popout === true || popout === 'indicators') && 'carousel-popout-indicators',
    className
  )
  useEffect(() => {
    if (innerRef?.current != null && carousel == null) {
      setCarousel($(innerRef.current).carousel({
        interval: interval,
        keyboard: keyboard,
        pause: pause,
        wrap: wrap
      }))
    }
  }, [innerRef.current, carousel, interval, keyboard, pause, wrap])

  // Handle slide
  const handleSlide = useCallback((event: any) => {
    setSliding(true)
    onChange?.(event.to)
    onSlide(event)
  }, [onChange, onSlide])

  // Handle slid
  const handleSlid = useCallback((event: any) => {
    setSliding(false)
    onSlid(event)
  }, [onSlid])

  // Assign event listeners
  useEventListener('slide.bs.carousel', handleSlide, innerRef)
  useEventListener('slid.bs.carousel', handleSlid, innerRef)

  // Dispose carousel
  useEffect(() => {
    return () => {
      carousel?.dispose?.()
    }
  }, [carousel])

  // Go to index
  useEffect(() => {
    if (carousel != null && index != null && index !== previousIndex) {
      carousel.carousel(index)
    }
  }, [carousel, index, previousIndex])

  // const [touchStart, setTouchStart] = useState(null)
  // const [touchEnd, setTouchEnd] = useState(null)

  // // the required distance between touchStart and touchEnd to be detected as a swipe
  // const minSwipeDistance = 50

  function onTouchStart (e): void {
    // setTouchEnd(null) // otherwise the swipe is fired even with usual touch events
    // setTouchStart(e.changedTouches[0].clientX)
  }

  function onTouchMove (e): void {
    // setTouchEnd(e.changedTouches[0].clientX)
  }

  function onTouchEnd (): void {
    // if ((touchStart == null || !touchStart) || (touchEnd == null || !touchEnd)) return
    // const distance = touchStart - touchEnd
    // const isLeftSwipe = distance > minSwipeDistance
    // const isRightSwipe = distance < -minSwipeDistance
    // if (isLeftSwipe || isRightSwipe) {
    //   if (isLeftSwipe) {
    //     onChange?.((index ?? 0) + 1)
    //   } else {
    //     onChange?.((index ?? 0) - 1)
    //   }
    // }
  }

  return (
    <CarouselContext.Provider value={context}>
      <div
        onTouchStart={onTouchStart}
        onTouchMove={onTouchMove}
        onTouchEnd={onTouchEnd}
        className={classNames}
        ref={innerRef}
        {...otherAttributes}
      >
        {children}
      </div>
    </CarouselContext.Provider>
  )
})

export {
  Carousel
}
