import { GetContextMinimalQuery } from '@web/shared-data-access-queries'
import { createContext, useContext } from 'react'

export interface IUserContext {
  loading: boolean
  context: GetContextMinimalQuery['context'] | undefined
}

export const UserContext = createContext<IUserContext | undefined>(undefined)

export function useUserContext (): IUserContext {
  const userContext: IUserContext | undefined = useContext(UserContext)

  if (userContext == null) {
    throw new Error('No UserContext.Provider found when calling useUserContext.')
  }

  return userContext
}
