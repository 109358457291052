import { Button, CmsContent, Alert, LoadingIndicator } from '@web/shared-ui-components'
import { useLock } from '@web/shared-util-hooks'
import { ReactElement, useState, useCallback } from 'react'
import { StullerPayFinancingTerms } from '@web/payment-methods'
import { TermOption, useStullerPayAddToCartMutation } from '@web/shared-data-access-queries'
import { dispatchStullerEvent } from '@web/shared-util-stuller-events'
import { AddToCartDto } from 'libs/quote/get-a-quote/feature/types'
import { sortStullerPayTermByMonthlyPrice } from 'libs/paymentmethods/util/stuller-pay'

interface StullerPayModalInnerProps {
  termsOptions: TermOption[]
  productItemNumber: string
  productImageUrl: string
  productTitle: string
  addToCartData: AddToCartDto
}

function StullerPayFinancingModalInner ({ termsOptions, productItemNumber, productImageUrl, productTitle, addToCartData }: StullerPayModalInnerProps): ReactElement {
  const [selectedTermId, setSelectedTermId] = useState([...termsOptions].sort(sortStullerPayTermByMonthlyPrice).find(term => term.Price >= term.MinimumPrice)?.Option ?? termsOptions[0].Option)
  const [checkoutLoading, setCheckoutLoading] = useState(false)
  const [errors, setErrors] = useState<string[]>([])
  const [addToStullerPayCart, { loading: addToCartLoading }] = useStullerPayAddToCartMutation()
  const showLoading = addToCartLoading || checkoutLoading
  const selectedTerm = termsOptions.find(x => x.Option === selectedTermId)

  const [handleSubmit] = useLock(useCallback(async () => {
    const addToStullerPayCartParams = {
      variables: {
        input: {
          addToCart: addToCartData
        }
      }
    }

    const result = await addToStullerPayCart(addToStullerPayCartParams)
    const actionErrors = result.data?.stullerPayAddToCart?.Errors
    const callErrors = result.errors?.map(error => error.message)
    const sessionId = result.data?.stullerPayAddToCart?.SessionId

    const errorCollection: string[] = []
    if (actionErrors != null) errorCollection.push(...actionErrors)
    if (callErrors != null) errorCollection.push(...callErrors)

    setErrors(errorCollection)

    if (errorCollection.length === 0 && sessionId != null && selectedTerm != null) {
      setCheckoutLoading(true)
      dispatchStullerEvent('track-universal-event', {
        category: 'ProductPage',
        action: 'StullerPayButton',
        label: 'Continue to Checkout'
      })
      window.location.href = `/checkout?sessionId=${sessionId}&cartType=StullerPay&selectedStullerPayTermOptionId=${selectedTerm.Option}`
    }
  }, [selectedTerm, addToCartData]))

  return (
    <LoadingIndicator loading={showLoading} position='center'>
      <div className='mb-4'>
        <img height='40' src='//assets.stullercloud.com/das/116145457.svg' alt='StullerPay' />
      </div>

      <div className='mb-4'>
        <CmsContent contentContainerName='stullerpay-financing-terms' />
      </div>

      <StullerPayFinancingTerms
        termsOptions={termsOptions}
        productItemNumber={productItemNumber}
        productImageUrl={productImageUrl}
        productTitle={productTitle}
        selectedTermId={selectedTermId}
        onChange={setSelectedTermId}
      />
      {errors.length > 0 && (
        <Alert alertType='error' className='mb-4'>
          <div className='f700 f5-5'>Unable to Continue to Checkout</div>
          {errors.map((error, index) => <div key={`stullerpay-err-${index}`}>{error}</div>)}
        </Alert>
      )}
      <div className='d-flex flex-column flex-md-row justify-content-md-end'>
        <Button color='stullerpay' onClick={handleSubmit} data-test='continue-to-checkout-button'>
          <span className='d-block px-5'>
            <span className='u-bold d-block'>Continue to Checkout</span>
            <span className='t-5 d-block'>cart items are not included</span>
          </span>
        </Button>
      </div>

    </LoadingIndicator>
  )
}

export { StullerPayFinancingModalInner }
