import '@web/styles/FileUpload.less'
import { ReactElement, useState, useCallback, useMemo } from 'react'
import { FileUploadDropzone, FileUploadError, FileUploadInformation, FileUploadPreview, ViewType } from '@web/shared-ui-components'
import { FileRejection } from 'react-dropzone'
import clsx from 'clsx'

interface FilesTypesAccepted {
  fileTypes?: { [key: string]: string[] }
  label?: string
}

interface FileUploadProps {
  viewType?: ViewType
  fileLimit?: number
  fileTypesAccepted?: FilesTypesAccepted
  minFileSizeInBytes?: number
  maxFileSizeInBytes?: number
  previewColClasses?: string
  files: File[]
  onFilesChange?: (files: File[], rejectedFiles: FileRejection[]) => void
}

function FileUpload ({
  viewType = 'DEFAULT',
  fileLimit = 10,
  fileTypesAccepted = {
    fileTypes: {
      'image/png': ['.png'],
      'image/jpeg': ['.jpeg', '.jpg'],
      'application/pdf': ['.pdf']
    },
    label: 'PNG, JPG, PDF'
  },
  minFileSizeInBytes = 0, // 0 Bytes
  maxFileSizeInBytes = 104857600, // 100MB
  previewColClasses = 'u-flex-grid-col-4 u-flex-grid-col-md-3 u-flex-grid-col-lg-2',
  onFilesChange,
  files,
  ...otherAttributes
}: FileUploadProps): ReactElement {
  const [rejectedFiles, setRejectedFiles] = useState<FileRejection[]>([])

  const handleFilesChange = useCallback((files: File[], rejectedFiles: FileRejection[]) => {
    setRejectedFiles(rejectedFiles)

    onFilesChange?.(files, rejectedFiles)
  }, [files, rejectedFiles, setRejectedFiles])
  const handleRemoveFile = useCallback((file: File) => {
    handleFilesChange(files.filter(f => f !== file), rejectedFiles)
  }, [files, rejectedFiles, handleFilesChange])

  const hasMaxFiles = useMemo(() => files.length >= fileLimit, [files, fileLimit])
  const hasAnyFiles = useMemo(() => files.length > 0, [files])

  const fileUploadDropzone = useMemo(() => {
    return (
      <FileUploadDropzone
        viewType={viewType}
        files={files}
        rejectedFiles={rejectedFiles}
        fileLimit={fileLimit}
        fileTypesAccepted={fileTypesAccepted}
        minFileSizeInBytes={minFileSizeInBytes}
        maxFileSizeInBytes={maxFileSizeInBytes}
        onFilesChange={handleFilesChange}
        {...otherAttributes}
      />
    )
  }, [files, fileLimit, fileTypesAccepted, handleFilesChange, rejectedFiles])

  const fileUploadInformation = useMemo(() => {
    return (
      <FileUploadInformation
        files={files}
        fileLimit={fileLimit}
        fileTypesAccepted={fileTypesAccepted}
      />
    )
  }, [viewType, files, fileLimit, fileTypesAccepted])

  return (
    <div className={clsx('react-file-upload w-100', viewType !== 'SINGLE_COMPACT' && 'c-bg-gray-lt-3 p-4 u-border-radius-medium')}>
      {rejectedFiles.length > 0 &&
        <div className='mb-4'>
          <FileUploadError
            rejectedFiles={rejectedFiles}
            fileTypesAccepted={fileTypesAccepted}
          />
        </div>}

      {hasAnyFiles &&
        <FileUploadPreview
          viewType={viewType}
          files={files}
          previewColClasses={previewColClasses}
          onRemoveFile={handleRemoveFile}
        />}

      {viewType !== 'SINGLE_COMPACT' &&
        <>
          {/* X-Small */}
          <div className='d-block d-sm-none' data-test='viewport-xsmall'>
            {!hasAnyFiles &&
              <div className='p-5 text-center'>
                No file{fileLimit > 1 && 's'} uploaded yet
              </div>}
            {fileUploadInformation}
            {fileUploadDropzone}
          </div>

          {/* Small & Up */}
          <div className='d-none d-sm-block' data-test='viewport-small-and-up'>
            {!hasMaxFiles && fileUploadDropzone}

            <div className='mt-3'>
              {fileUploadInformation}
            </div>
          </div>
        </>}

      {!hasMaxFiles && viewType === 'SINGLE_COMPACT' &&
        <div className='u-flex-grid-row mx-0'>
          <div className='w-100'>No file chosen</div>
          {fileUploadDropzone}
          <div className='mt-2 w-100'>
            {fileTypesAccepted.label != null &&
              <div className='f-robot-light u-small-font-size'>
                File types accepted: {fileTypesAccepted.label}
              </div>}
          </div>
        </div>}
    </div>
  )
}

export { FileUpload, FilesTypesAccepted }
