import { ReactElement } from 'react'
import { Button } from '@web/shared-ui-components'
import { ProductDataFeed } from '@web/shared-data-access-queries'
import 'react-toastify/dist/ReactToastify.css'

interface ProductFeedListRowProps {
  productDataFeed: ProductDataFeed
  buttonText: string
  onItemSave: any
}

function ProductFeedListRow ({ productDataFeed, buttonText = 'Add Item to feed', onItemSave }: ProductFeedListRowProps): ReactElement | null {
  return (
    <>
      <tr>
        <td><span>{productDataFeed.Name}</span></td>
        <td className='text-right'>
          <Button
            size='micro'
            color='secondary-inverse'
            onClick={(e) => onItemSave(e, productDataFeed.Id)}
            rounded
          >
            {buttonText}
          </Button>
        </td>
      </tr>
    </>
  )
}

export { ProductFeedListRow }
