import { createContext } from 'react'

export interface CarouselContextType {
  /**
   * Index of the current item in the carousel
   */
  index?: number
  /**
   * Number of items in the carousel
   */
  length?: number
  /**
   * Handle when index is changed (from button or timer etc.)
   */
  onChange?: (index: number) => void
  /**
   * Indicates the carousel is currently sliding
   */
  sliding: boolean
}

const CarouselContext = createContext<CarouselContextType>({ sliding: false })

export {
  CarouselContext
}
