import {
  ReactElement, useMemo
} from 'react'
import { Button } from '@web/shared-ui-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSync } from '@fortawesome/pro-solid-svg-icons'
import clsx from 'clsx'

interface EndOfResultsProps {
  totalResults?: number
  currentResults?: number
  hasMore: boolean
  onLoadMore: () => void
  className?: string
}
/*
   * End of component
   */
function EndOfResults ({ totalResults, currentResults, hasMore, onLoadMore, className }: EndOfResultsProps): ReactElement | null {
  const EndOfResultClasses = useMemo(() => clsx('end-of-results text-center position-relative py-3 font-weight-bold', currentResults === totalResults && 'all-results-loaded', className), [currentResults, totalResults, className])
  if (currentResults == null || totalResults == null) {
    return null
  }

  return (
    <div className={EndOfResultClasses}>
      {currentResults} of {totalResults} results loaded
      <div className='text-center mt-3'><Button color='primary' onClick={onLoadMore} disabled={!hasMore} data-test='load-more-button'> <FontAwesomeIcon icon={faSync} className='mr-2' /> Load More</Button></div>
    </div>
  )
}

export {
  EndOfResults
}
