import {
  ReactElement,
  ReactNode,
  useMemo
} from 'react'
import clsx from 'clsx'
import { SelectionBoxContext, SelectionBoxContextProps } from '@web/shared-ui-components'

/*
 * SelectionBox Props
 */
interface SelectionBoxProps extends SelectionBoxContextProps {
  openDropDownWhenSelected?: boolean
  /**
   * Custom Classes (ie: for expired payment methods)
   */
  className?: string
  /**
   * Children
   */
  children?: ReactNode
  /**
   * does the selection box have an error
   */
  error?: boolean
}

/*
 * SelectionBox component
 */
function SelectionBox ({
  format = 'simple',
  name,
  selectedValue,
  className,
  children,
  value,
  onChange,
  error,
  disabled = false,
  isOpen = false,
  openDropDownWhenSelected = false,
  ...otherAttributes
}: SelectionBoxProps): ReactElement | null {
  const isSelected = useMemo(() => value === selectedValue, [value, selectedValue])

  const selectionBoxContext: SelectionBoxContextProps = useMemo(() => ({
    format, name, selectedValue, isOpen: isOpen || (openDropDownWhenSelected && isSelected), onChange, value, disabled
  }), [format, name, selectedValue, isOpen, openDropDownWhenSelected, isSelected, value, disabled, onChange])

  const outerBorder = useMemo(() => clsx(
    selectionBoxContext.format === 'largeContent' && selectionBoxContext.value === selectedValue && 'u-border-selected',
    selectionBoxContext.format === 'simple' && 'u-border-all',
    selectionBoxContext.format === 'simple' && selectionBoxContext.value === selectedValue && 'c-border-primary-lt-1',
    error === true && 'u-border-error'
  ), [selectionBoxContext.format, selectionBoxContext.value, selectedValue, error])

  const boxColor = useMemo(() => clsx(
    selectionBoxContext.format === 'largeContent' && 'c-bg-gray-lt-3',
    selectionBoxContext.value === selectedValue && 'c-bg-primary-lt-2'
  ), [selectionBoxContext.format, selectionBoxContext.value, selectedValue])

  return (
    <SelectionBoxContext.Provider value={selectionBoxContext}>
      <div className={clsx('u-flex-grid-col-12 form-input-group u-margin-vertical-10 p-0 u-border-radius-medium', className, outerBorder, boxColor)} {...otherAttributes}>
        {children}
      </div>
    </SelectionBoxContext.Provider>
  )
}

export { SelectionBox }
