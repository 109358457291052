import {
  ReactElement,
  KeyboardEventHandler,
  ForwardedRef,
  forwardRef,
  MouseEventHandler,
  SetStateAction,
  Dispatch
} from 'react'
import { Input } from '@web/shared-ui-components'
import DatePicker, { ReactDatePickerProps } from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import '@web/styles/DateSelector.less'
import clsx from 'clsx'
import { faCalendarAlt } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

interface DateSelectorProps extends ReactDatePickerProps {
  /*
  * Extra Attributes
  */
  [key: string]: any
}

interface CustomInputProps {
  /*
   * Value from selector
   */
  value?: string
  /*
   * onClick from selector
   */
  onClick?: MouseEventHandler
  /*
   * Function to call on select change
   */
  onChange?: Dispatch<SetStateAction<Date>> | Function
  /**
   * When pressing enter this action will trigger
   */
  onKeyDown?: KeyboardEventHandler
  /*
   * Custom class name
   */
  className?: string
}

/*
 * Custom Input for the date selector
 */
const CustomInput = forwardRef(({
  value,
  onClick,
  onChange,
  onKeyDown,
  className,
  ...otherAttributes
}: CustomInputProps, ref: ForwardedRef<HTMLInputElement>) => (
  <div className='form-input-group custom-input-container'>
    <div className='input-group'>
      <Input
        onKeyDown={onKeyDown}
        name='Date'
        type='text'
        className={clsx(className, 'date-picker')}
        onChange={onChange}
        value={value}
        onClick={onClick}
        ref={ref}
        {...otherAttributes}
      />
      <label
        htmlFor='Date'
        className='input-group-addon u-cursor-pointer'
        onClick={onClick}
      >
        <FontAwesomeIcon icon={faCalendarAlt} />
      </label>
    </div>
  </div>
))

/*
 * Wrapper for the date picker, used to select a date
 */
function DateSelector ({
  selectedDate,
  minDate,
  maxDate,
  excludeDates,
  fixedHeight,
  showPopperArrow = true,
  showYearDropdown = true,
  showMonthDropdown = true,
  disabled,
  onChange,
  onKeyDown,
  onCalendarClose,
  onCalendarOpen,
  container,
  children,
  inputClassName,
  ...otherAttributes
}: DateSelectorProps): ReactElement {
  return (
    <DatePicker
      showPopperArrow={showPopperArrow}
      dropdownMode='select'
      showYearDropdown={showYearDropdown}
      showMonthDropdown={showMonthDropdown}
      selected={selectedDate}
      minDate={minDate}
      maxDate={maxDate}
      excludeDates={excludeDates}
      fixedHeight={fixedHeight}
      disabled={disabled}
      onChange={onChange}
      onKeyDown={onKeyDown}
      calendarContainer={container}
      onCalendarClose={onCalendarClose}
      onCalendarOpen={onCalendarOpen}
      customInput={<CustomInput onChange={onChange} className={inputClassName} {...otherAttributes} />}
    >
      {children}
    </DatePicker>
  )
}

export { DateSelector }
