import { Alert } from '@web/shared-ui-components'
import { ReactElement } from 'react'
import { faCircleExclamation } from '@fortawesome/pro-solid-svg-icons'
import { dispatchStullerEvent } from '../../../shared/util/stuller-events'

/**
 * Modal component for Oracle down alert from stullerpay redirect
 */
function StullerpayIneligibleAlert (): ReactElement {
  return (
    <Alert alertType='warning-low' icon={faCircleExclamation} className='my-4 text-left'>
      <div className='f700'>
        Unable to proceed with your StullerPay&trade; order
      </div>
      <div className='body'>
        For more information, <a className='primary-link' onClick={() => dispatchStullerEvent('live-chat-open')}>start a live chat</a> or speak with a Customer Finance expert by calling 800-877-777, ext. 4300.
      </div>
    </Alert>
  )
}

export { StullerpayIneligibleAlert }
