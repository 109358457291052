import { MouseEvent, MouseEventHandler, ReactElement, useMemo, useCallback } from 'react'
import { faChevronDown } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import clsx from 'clsx'

interface TableRowToggleButtonProps {
  className?: string
  /*
   * Initial state of the toggle for showing expanded info and direction of toggle icon
   */
  toggledOpen: boolean

  /*
   * Onclick handler for toggle button on row
   */
  onClick: MouseEventHandler<HTMLTableDataCellElement>
  /*
   * Is this row disabled
   */
  isDisabled?: boolean
}

function TableRowToggleButton ({ className, toggledOpen, onClick, isDisabled = false, ...otherAttributes }: TableRowToggleButtonProps): ReactElement {
  const iconClasses = useMemo(() =>
    clsx('c-white u-all-short-ease-in-transition',
      toggledOpen && 'fa-rotate-180'
    ), [toggledOpen])

  const handleClick = useCallback((event: MouseEvent<HTMLTableDataCellElement>) => !isDisabled ? onClick(event) : () => { }, [isDisabled, onClick])

  return (
    <td className={clsx('stbl-row-toggle', className)} onClick={handleClick} {...otherAttributes}>
      <FontAwesomeIcon icon={faChevronDown} size='lg' className={iconClasses} />
    </td>
  )
}

export { TableRowToggleButton }
