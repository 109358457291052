import { ChangeEvent, ReactElement } from 'react'
import { Input, LaserEngravedModalValue } from '@web/shared-ui-components'

interface LaserEngravingTextProps {
  input: LaserEngravedModalValue
  onInputChange?: (key: 'EngravingText', value: string) => void
}

function LaserEngravingText ({ input, onInputChange }: LaserEngravingTextProps): ReactElement {
  const handleInputChange = (event: ChangeEvent<HTMLInputElement>): void => {
    onInputChange?.('EngravingText', event.target.value)
  }

  return (
    <div>
      <div className='t-tiny-upper mb-2'>Message</div>

      <Input
        className='w-100 u-border-radius-medium px-4'
        maxLength={255}
        value={input.EngravingText}
        onChange={handleInputChange}
        style={{ height: '40px' }}
        data-test='laser-engraving-text'
      />
    </div>
  )
}

export { LaserEngravingText }
