import { forwardRef, useContext } from 'react'
import clsx from 'clsx'
import { CarouselContext } from './CarouselContext'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronCircleLeft, faChevronCircleRight } from '@fortawesome/pro-duotone-svg-icons'
import { Button } from '../Button'

export interface CarouselControlProps {
  /**
   * Id of element
   */
  id?: string
  /**
   * Additional class name(s) to give to the containing element
   */
  className?: string
  /**
   * Direction of the carousel control
   */
  direction?: 'prev' | 'next'
  /**
   * Handle when button is pressed to move index (send `handlePrev` or `handleNext`)
   */
  onClick?: () => void
  /**
   * Indicates to use the dark variant
   */
  dark?: boolean
  iconStyles?: React.CSSProperties
}

/**
 * Carousel control component for previous and next buttons
 */
const CarouselControl = forwardRef<HTMLButtonElement, CarouselControlProps>(({
  className,
  direction = 'prev',
  onClick,
  dark = false,
  iconStyles,
  ...otherAttributes
}, ref) => {
  const { sliding } = useContext(CarouselContext)
  const classNames = clsx(
    `carousel-control-${direction}`,
    dark && 'carousel-dark',
    className
  )

  // Handle clicking the button to update the current index
  function handleClick (): void {
    if (!sliding) {
      onClick?.()
    }
  }

  const controlIcon = direction === 'prev' ? faChevronCircleLeft : faChevronCircleRight

  return (
    <Button
      type='button'
      tag='a'
      color='link'
      className={classNames}
      onClick={handleClick}
      data-bs-slide={direction}
      ref={ref}
      {...otherAttributes}
    >
      <FontAwesomeIcon icon={controlIcon} size='2x' aria-hidden='true' className='c-black' style={iconStyles} />
      <span className='sr-only'>{direction}</span>
    </Button>
  )
})

export {
  CarouselControl
}
