import { useCallback, useMemo } from 'react'

export interface CreateEvent<T> {
  /*
   * Custom event that is to be returned
   */
  event: Event
  /*
   * Callback for adding event listeners
   */
  addEventListener: (eventListener: T) => void
  /*
   * Callback for removing event listeners
   */
  removeEventListener: (eventListener: T) => void
  /*
   * Callback for dispatching an event
   */
  dispatchEvent: () => void
}

function useCreateEvent<T> (eventName: string, element: any = window, options?: CustomEventInit<T>): CreateEvent<T> {
  const event = useMemo(() => new CustomEvent(eventName, options), [])

  const addEventListener = useCallback((eventListener: T) => element.addEventListener(eventName, eventListener), [])

  const removeEventListener = useCallback((eventListener: T) => element.removeEventListener(eventName, eventListener), [])

  const dispatchEvent = useCallback(() => element.dispatchEvent(event), [])

  return { event, addEventListener, removeEventListener, dispatchEvent }
}

export { useCreateEvent }
