import { KeyboardEvent, ReactElement, useCallback, useEffect, useState } from 'react'
import { MinMaxDateRange, DateRangeSelector, Button } from '@web/shared-ui-components'
import { dayjs } from '@web/shared-util-dayjs'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/pro-solid-svg-icons'
import { DateRangeSimple } from './DateRangeSelector'
import { TrackEvent } from '@web/shared-util-google-analytics'

interface SearchByDateRangeProps {
  dateRange: DateRangeSimple
  /*
   * Object that contains minDate and MaxDate
   */
  minMaxDateRange?: MinMaxDateRange
  onSubmit: (value: DateRangeSimple) => void
  trackingCategory?: string
  trackingEvent?: string
}

/*
 * The search by date component
 */
function SearchByDateRange ({
  dateRange,
  minMaxDateRange = {
    minDate: dayjs().add(-10, 'year').toDate(),
    maxDate: new Date()
  },
  onSubmit,
  trackingCategory,
  trackingEvent
}: SearchByDateRangeProps): ReactElement {
  const [selectedDateRange, setSelectedDateRange] = useState<DateRangeSimple>(dateRange)

  useEffect(() => {
    setSelectedDateRange(dateRange)
  }, [setSelectedDateRange, dateRange])

  /**
  * Handler for start date range change event
  */
  const handleStartDateChange = useCallback((startDate: Date) => {
    setSelectedDateRange(dr => ({ ...dr, startDate }))
  }, [setSelectedDateRange])

  /**
    * Handler for end date range change event
    */
  const handleEndDateChange = useCallback((endDate: Date) => {
    setSelectedDateRange(dr => ({ ...dr, endDate }))
  }, [setSelectedDateRange])

  const submitWithTracking = useCallback((dr: DateRangeSimple) => {
    if (trackingCategory != null && trackingEvent != null) {
      TrackEvent(trackingCategory, trackingEvent, `${dayjs(dr.startDate).format('L')}-${dayjs(dr.endDate).format('L')}`)
    }

    onSubmit(dr)
  }, [trackingCategory, trackingEvent, TrackEvent, onSubmit])

  const handleKeyDown = useCallback((event: KeyboardEvent) => {
    if (event.key !== 'Enter') return

    submitWithTracking(selectedDateRange)
  }, [submitWithTracking, selectedDateRange])

  return (
    <div className='d-flex flex-column justify-content-between noPrint'>
      <div className='u-flex-grid-row mx-0'>
        <div className='u-flex-grid-col-12 px-0'>
          <DateRangeSelector
            onKeyDown={handleKeyDown}
            startDate={selectedDateRange.startDate}
            onStartDateChange={handleStartDateChange}
            endDate={selectedDateRange.endDate}
            onEndDateChange={handleEndDateChange}
            maxDate={minMaxDateRange.maxDate}
            minDate={minMaxDateRange.minDate}
          >
            <Button className='search-by-date-button u-width-100-small-down mt-3 mt-sm-0' color='primary' onClick={() => submitWithTracking(selectedDateRange)} data-test='search-by-date-button'>
              <FontAwesomeIcon icon={faSearch} className='d-none d-sm-block' />
              <span className='d-block d-sm-none'>Search</span>
            </Button>
          </DateRangeSelector>
        </div>
      </div>
    </div>
  )
}

export { SearchByDateRange }
