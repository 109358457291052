import { useCallback, useState } from 'react'

/*
 * Callback lock to prevent multiple simultaneous function calls
 */
function useLock (handler: Function): [any, boolean] {
  const [locked, setLocked] = useState(false)
  const handleLock = useCallback(async (...args) => {
    if (locked) {
      return
    }

    setLocked(true)
    const result = await handler(...args)
    setLocked(false)

    return result
  }, [handler, locked])

  return [handleLock, locked]
}

export {
  useLock
}
