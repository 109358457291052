import { createContext } from 'react'

interface DropdownContextType {
  /**
   * Is the dropdown collapsed or not
   */
  isCollapsed: boolean
  /**
   * Callback to call when the dropdown is toggled
   */
  onToggle?: () => void
}

const DropdownContext = createContext<DropdownContextType>({ isCollapsed: true })

export {
  DropdownContextType,
  DropdownContext
}
