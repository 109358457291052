import { ReactElement } from 'react'
import '@web/styles/AddProductToProductFeedModal.less'
import { Table } from '@web/shared-ui-components'
import { ProductFeedListRow } from './ProductFeedListRow'
import { ProductDataFeed } from '@web/shared-data-access-queries'

interface ProductFeedListProps {
  productFeeds: ProductDataFeed[]
  buttonText: string
  onItemSave: any
}

function ProductFeedList ({ productFeeds, buttonText, onItemSave }: ProductFeedListProps): ReactElement | null {
  return (
    <>
      {productFeeds.length > 0 &&
        <div className='u-fancy-scroll' style={{ maxHeight: '300px', overflowY: 'auto' }}>
          <Table tableStyle='white'>
            <thead>
              <tr>
                <th className='font-weight-bold'>Name</th>
                <th />
              </tr>
            </thead>
            <tbody>
              {productFeeds.map((x: ProductDataFeed, index: number) => (
                <ProductFeedListRow
                  key={index}
                  productDataFeed={x}
                  buttonText={buttonText}
                  onItemSave={onItemSave}
                />
              ))}
            </tbody>
          </Table>
        </div>}
    </>
  )
}

export { ProductFeedList }
