import { ReactElement, useMemo } from 'react'
import { CheckboxOld } from '@web/shared-ui-components'
import { NavigationItemIcon } from '@web/product-navigation-feature'
import { NavigationItem, NavigationSection } from '@web/shared-data-access-queries'
import '@web/styles/NavigationItemRowData.less'
import clsx from 'clsx'

interface NavigationItemRowProps{
  /*
  * One instance of the the facet value collection coming from the Navigation Section
  */
  navigationItem: NavigationItem

  /*
   * NavigationSection
   */
  section: NavigationSection

  /*
   * Flag for whether or not to show a checkbox as a part of the selection (for multi-select Navigation Sections)
   */
  showCheckbox: boolean

  /*
   * The layout governing the display of all the facet value options
   */
  layout?: string | null
}

function NavigationItemRowData ({
  showCheckbox = false,
  navigationItem,
  section,
  layout = section.Layout
}: NavigationItemRowProps): ReactElement {
  const isSelected = useMemo(() => navigationItem.IsSelected, [navigationItem.IsSelected])

  const linkText = useMemo(() =>
    navigationItem?.LinkText ?? ''
  , [navigationItem])

  const checkboxID = useMemo(() => {
    if (section.CssClass != null && navigationItem.CssClass != null) {
      return `${section.CssClass}-${navigationItem.CssClass}`
    } else {
      return `_${section.SearchField?.replace(/ /gi, '_')?.replace(/\//gi, ' ')?.replace(/,/gi, ' ') ?? 'no_search_field'}-${navigationItem.Value?.replace(/ /gi, '_')?.replace(/\//gi, ' ')?.replace(/,/gi, ' ') ?? 'no-item-value'}`
    }
  }, [section.CssClass, navigationItem.CssClass, section.SearchField, navigationItem.Value])

  return (
    <>
      {showCheckbox
        ? (
          <CheckboxOld id={checkboxID} type='checkbox' checked={isSelected} onChange={() => {}}>
            <label htmlFor={checkboxID} dangerouslySetInnerHTML={{ __html: linkText }} />
          </CheckboxOld>
          )
        : <span className={clsx(isSelected && 'isSelected')} dangerouslySetInnerHTML={{ __html: linkText }} />}
      <NavigationItemIcon
        imageAsset={navigationItem.ImageAsset}
        isSelected={isSelected}
        layout={layout}
      />
    </>
  )
}

export { NavigationItemRowData }
