import {
  ReactElement,
  useCallback,
  useContext
} from 'react'
import { PaymentMethod, PaymentMethodType, useDeleteCredtCardMutation, useDeleteBankAccountMutation } from '@web/shared-data-access-queries'
import { DeleteBankAlert, DeleteCardAlert, PaymentMethodSelectorContext } from '@web/payment-methods'
import { CardSelectionBoxes } from './CardSelectionBoxes'
import { BankSelectionBoxes } from './BankSelectionBoxes'
import { TermsSelectionBoxes } from './TermsSelectionBoxes'
import { CodSelectionBoxes } from './CodSelectionBoxes'

/*
 * PaymentMethodSelectionBoxes Props
 */
interface PaymentMethodSelectionBoxesProps {
  /*
  * Currently selected value
  */
  selectedValue?: PaymentMethod | null
}

/*
 * Payment Method Selector Selection Boxes component
 */
function PaymentMethodSelectionBoxes ({ selectedValue }: PaymentMethodSelectionBoxesProps): ReactElement {
  const { paymentMethods } = useContext(PaymentMethodSelectorContext)

  const mapPaymentMethodSelectionBoxOptions = useCallback((paymentMethods: PaymentMethod[] | null | undefined, selectedValue?: PaymentMethod | null) => {
    return paymentMethods?.map(pm => ({
      paymentMethod: pm,
      value: pm.SelectableValue,
      isSelected: selectedValue == null ? pm.IsSelected : (selectedValue.SelectableValue === pm.SelectableValue),
      disabled: pm.CreditCard != null ? pm.CreditCard.IsExpired : false,
      isEditing: false
    }))
  }, [])

  const [deleteCard, { loading: loadingDeleteCard, data: deleteCreditCardData }] = useDeleteCredtCardMutation()
  const [deleteBank, { data: deleteBankdData, loading: loadingDeleteBank }] = useDeleteBankAccountMutation()

  return (
    <>
      <TermsSelectionBoxes
        termsPaymentMethods={paymentMethods?.filter(x => x.Method === PaymentMethodType.Terms)}
        selectedValue={selectedValue}
        mapOptions={mapPaymentMethodSelectionBoxOptions}
      />
      <CardSelectionBoxes
        cardPaymentMethods={paymentMethods?.filter(x => x.Method === PaymentMethodType.Debitcard || x.Method === PaymentMethodType.Creditcard)}
        selectedValue={selectedValue}
        deleteCard={deleteCard}
        mapOptions={mapPaymentMethodSelectionBoxOptions}
      />
      <BankSelectionBoxes
        bankPaymentMethods={paymentMethods?.filter(x => x.Method === PaymentMethodType.Ach)}
        selectedValue={selectedValue}
        deleteBank={deleteBank}
        mapOptions={mapPaymentMethodSelectionBoxOptions}
      />
      <CodSelectionBoxes
        codPaymentMethods={paymentMethods?.filter(x => x.Method === PaymentMethodType.Cod)}
        selectedValue={selectedValue}
        mapOptions={mapPaymentMethodSelectionBoxOptions}
      />

      <DeleteBankAlert deleteBankResponse={deleteBankdData?.deleteBankAccount} deletingBank={loadingDeleteBank} />
      <DeleteCardAlert deleteCardResponse={deleteCreditCardData?.deleteCreditCard} deletingCard={loadingDeleteCard} />
    </>
  )
}

export {
  PaymentMethodSelectionBoxes
}
