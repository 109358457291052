export const VisaCardIcon = 'https://assets.stullercloud.com/das/107103319.svg'
export const AmericanExpressCardIcon = 'https://assets.stullercloud.com/das/107103331.svg'
export const MastercardCardIcon = 'https://assets.stullercloud.com/das/107103328.svg'
export const DiscoverCardIcon = 'https://assets.stullercloud.com/das/107103332.svg'

export const UPSIcon = 'https://assets.stullercloud.com/das/101673367.svg'
export const FedExIcon = 'https://assets.stullercloud.com/das/101673051.svg'
export const USPSIcon = 'https://assets.stullercloud.com/das/101673370.svg'
export const DHLIcon = 'https://assets.stullercloud.com/das/101673369.svg'
export const PurolatorIcon = 'https://assets.stullercloud.com/das/107466413.svg'
