import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import localeData from 'dayjs/plugin/localeData'
import localeOverrides from './plugins/localeOverrides'
import 'dayjs/locale/en'
import advancedFormat from 'dayjs/plugin/advancedFormat'

// Install plugins
dayjs.locale('en')
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(localizedFormat)
dayjs.extend(localeData)
dayjs.extend(localeOverrides)
dayjs.extend(advancedFormat)

dayjs.localeData()

export { dayjs }
