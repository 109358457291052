import { ChangeEvent, ReactElement, useCallback } from 'react'
import { Select } from '@web/shared-ui-components'
import '@web/styles/TableSummary.less'

interface TableSummaryProps {
  /*
   * Number of items to be displayed per page
   */
  perPage: number
  /*
   * Available selection of paging amounts
   */
  pagingCounts?: number[]
  /*
   * Total number of items that can be shown in the results
   */
  total: number
  /*
   * Current page that the user is on
   */
  page: number
  /*
   * Event that will happen when the per page value is changed
   */
  onChange?: (pageSize: number) => void
  /*
   * Message to show summary of paged results
   */
  summaryMessage?: ReactElement
}
/*
 * Summary above table that displays total results and lets user pick how many results are showed
 */
function TableSummary ({ perPage = 10, pagingCounts = [10, 25, 50], total, page, summaryMessage, onChange }: TableSummaryProps): ReactElement {
  if (!pagingCounts.includes(perPage)) {
    perPage = pagingCounts[0]
  }

  const startingDisplayNumber = (page * perPage) - perPage + 1
  const endingDisplayNumber = (page * perPage) < total ? page * perPage : total

  const onPageSizeChange = useCallback((event: ChangeEvent<HTMLSelectElement>) => {
    if (onChange != null) {
      onChange(parseInt(event.target.value))
    }
  }, [])

  return (
    <div className='react-table-summary d-flex flex-wrap justify-content-between align-items-center my-4'>
      <div className='c-gray-dk-3 font-weight-light'>
        {summaryMessage}
      </div>
      <div className='c-gray-dk-3'>
        {total > 0 && (
          <div className='d-flex flex-wrap justify-content-end align-items-center font-weight-light action-container'>
            <span className='pr-2 total-count' data-test='total-count'>Showing {startingDisplayNumber}&#8211;{endingDisplayNumber} of {total}</span>
            <span className='c-gray divider'>|</span>
            <span className='px-2 items-per-page'>Items per page</span>
            <span className='pagination-selection-container' data-test='pagination-selection-container'>
              <Select
                style={{
                  width: '52px',
                  height: '30px'
                }}
                className='px-2 py-1'
                onChange={onPageSizeChange}
                value={perPage}
                data-test='select-results-per-page'
              >
                {pagingCounts.map((pc, i) => (
                  <option key={`pager-count-${i}`} value={pc}>{pc}</option>
                ))}
              </Select>
            </span>
          </div>
        )}
      </div>
    </div>
  )
}

export { TableSummary, TableSummaryProps }
