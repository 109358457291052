import { ReactElement, ReactNode, useMemo } from 'react'
import clsx from 'clsx'
import { Button } from '@web/shared-ui-components'
import '@web/styles/ButtonToggleGroup.less'

interface ButtonToggleOptions {
  label: string | ReactNode
  value: string
}

interface ButtonToggleProps {
  options: ButtonToggleOptions
  currentValue: string | undefined
  setCurrentValue: (val: string) => void
  roundedButton: boolean
  buttonClassName?: string
}
/*
 * Button toggle
 */
function ButtonToggle ({
  options,
  currentValue,
  setCurrentValue,
  roundedButton,
  buttonClassName
}: ButtonToggleProps): ReactElement {
  const classes = useMemo(() =>
    clsx(roundedButton && 'sbtn-rounded',
      options.value === currentValue && 'button-toggle-active c-white c-bg-primary-dk-1 c-border-primary-dk-1',
      buttonClassName
    ), [currentValue])

  return (
    <Button data-test={options.value} className={classes} color='secondary' onClick={() => setCurrentValue(options.value)}>
      {options.label}
    </Button>
  )
}

interface ButtonToggleGroupProps {
  className?: string
  gridTemplateColumns?: string
  options: ButtonToggleOptions[]
  currentValue: string | undefined
  setCurrentValue: (val: string) => void
  makeButtonsSameWidth?: boolean
  roundedButtons?: boolean
  buttonClassName?: string
}
/*
 * Toggle group of buttons
 */
function ButtonToggleGroup ({
  className,
  gridTemplateColumns = 'repeat(auto-fit, minMax(100px, 1fr))',
  options,
  currentValue,
  setCurrentValue,
  makeButtonsSameWidth = true,
  roundedButtons = true,
  buttonClassName,
  ...otherAttributes
}: ButtonToggleGroupProps): ReactElement {
  const classes = useMemo(() =>
    clsx(makeButtonsSameWidth ? 'react-button-toggle-group-same-widths' : 'react-button-toggle-group-diff-widths', className),
  [makeButtonsSameWidth, className])

  const styles = makeButtonsSameWidth && { gridTemplateColumns: gridTemplateColumns }

  return (
    <div className={classes} style={{ ...styles }} {...otherAttributes}>
      {options.map((x, i) => (
        <ButtonToggle
          key={i}
          options={x}
          currentValue={currentValue}
          setCurrentValue={setCurrentValue}
          roundedButton={roundedButtons}
          buttonClassName={buttonClassName}
        />
      ))}
    </div>
  )
}

export { ButtonToggleGroup, ButtonToggle, ButtonToggleOptions }
