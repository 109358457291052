import { DOMAttributes, useMemo, MouseEvent, FocusEvent } from 'react'

export type SameInteractionEventSelectPicks = 'onMouseEnter' | 'onFocus'
export type SameInteractionEventUnselectPicks = 'onMouseLeave' | 'onBlur'
export type SameInteractionMap<T = HTMLElement> = Pick<DOMAttributes<T>, SameInteractionEventSelectPicks & SameInteractionEventUnselectPicks>
export type SameInteractionEventHandler<T = HTMLElement> = (e: MouseEvent<T>|FocusEvent<T>) => void

function useSameInteractionEvents<T = HTMLElement> (
  onSelect?: SameInteractionEventHandler<T>,
  onUnselect?: SameInteractionEventHandler<T>
): SameInteractionMap<T> {
  const map = useMemo<SameInteractionMap<T>>(() => ({
    onFocus: onSelect,
    onMouseEnter: onSelect,
    onBlur: onUnselect,
    onMouseLeave: onUnselect
  }), [onSelect, onUnselect])

  return map
}

export { useSameInteractionEvents }
