import {
  ReactElement
} from 'react'
import { InputGroup, NumberInput } from '@web/shared-ui-components'
import { faDollarSign } from '@fortawesome/pro-regular-svg-icons'

interface CurrencyUSDInputProps{
  /**
   * Input name field
   */
  name: string
  /**
   * Input value field
   */
  value: number | undefined
  /**
   * Input onChange event
   */
  onChange: (amount: number | undefined, name: string) => void
  allowDecimal?: boolean
  precision?: number
  allowZero?: boolean
  placeholder?: string
  invalid?: boolean
  maxLength?: number
  className?: string
}

function CurrencyUSDInput ({
  name,
  value,
  onChange,
  allowDecimal = true,
  precision = 2,
  allowZero = false,
  placeholder,
  invalid = false,
  maxLength,
  className,
  ...props
}: CurrencyUSDInputProps): ReactElement {
  const currencyPlaceholder = placeholder != null ? placeholder : allowDecimal ? `0.${new Array(precision + 1).join('0')}` : '0'

  return (
    <InputGroup type='prepend' icon={faDollarSign}>
      <NumberInput
        {...props}
        name={name}
        placeholder={currencyPlaceholder}
        value={value}
        onChange={onChange}
        allowDecimal={allowDecimal}
        precision={precision}
        allowZero={allowZero}
        autoCorrect='off'
        spellCheck='false'
        invalid={invalid}
        maxLength={maxLength}
        className={className}
      />
    </InputGroup>
  )
}

export { CurrencyUSDInput }
