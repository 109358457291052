import {
  ReactElement,
  ReactNode,
  forwardRef,
  ForwardedRef
} from 'react'
import {
  Input,
  InputProps
} from '@web/shared-ui-components'
import clsx from 'clsx'

interface RadioProps extends InputProps {
  /*
   * Indicates the input should be rendered as a switch (for checkbox, radio)
   */
  isSwitch?: boolean
  /*
   * Options for the select as children
   */
  children?: ReactNode
  /*
   * Class override for container
   */
  containerClassName?: string
}

/*
 * Creates a radio out of the Input wrapper component
 */
function Radio ({
  id,
  name,
  value,
  checked,
  className,
  containerClassName,
  onChange,
  children,
  isSwitch = false,
  ...otherAttributes
}: RadioProps, ref: ForwardedRef<HTMLInputElement>): ReactElement {
  const classNames = clsx(className, isSwitch && 'switch', 'radio-item')

  return (
    <li className={containerClassName}>
      <Input
        type='radio'
        id={id}
        name={name}
        value={value}
        checked={checked}
        className={classNames}
        onChange={onChange}
        ref={ref}
        {...otherAttributes}
      />
      {children}
    </li>
  )
}

/* Radio with forwardRef */
const RadioWrapped = forwardRef(Radio)

export { RadioWrapped as RadioOld }
