/*
 * Generic function to be called within a javascript Array.sort function
 */
function sort<T> (a: T, b: T, propertyName: string, sortAscending: boolean = true): number {
  let compareA: T|any
  let compareB: T|any
  let moveUp: boolean = false

  if (propertyName == null || typeof a[propertyName] === 'undefined' || typeof b[propertyName] === 'undefined') {
    compareA = a
    compareB = b
  } else {
    compareA = a[propertyName]
    compareB = b[propertyName]
  }

  if (typeof compareA === 'boolean') {
    moveUp = (compareA && sortAscending) || (!compareA && !sortAscending)
  } else {
    moveUp = (compareA < compareB && sortAscending) || (compareA > compareB && !sortAscending)
  }

  if (compareA === compareB) {
    return 0
  }

  return moveUp ? -1 : 1
}

export {
  sort
}
