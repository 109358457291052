import {
  ReactElement,
  ReactNode,
  MouseEventHandler,
  useMemo,
  ForwardedRef,
  forwardRef
} from 'react'
import clsx from 'clsx'
import { faExclamationTriangle, faClose } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconDefinition } from '@fortawesome/fontawesome-common-types'

interface AlertProps {
  /*
   * Alert type
   * Default: 'primary'
   */
  alertType?: 'primary' | 'success' | 'warning-low' | 'warning-high' | 'error'

  /*
   * Icon
   * Default: 'fa-exclamation-triangle'
   */
  icon?: IconDefinition

  /*
   * Show icon
   * Default: true
   */
  showIcon?: boolean

  /*
   * Show background or transparent
   * Default: true
   */
  showBackground?: boolean

  /*
   * Show border
   * Default: true
   */
  showBorder?: boolean

  /*
   * Show alert component
   * Default: true
   */
  show?: boolean

  /*
   * Handle when toggled from within the alert (if sent, shows close button that calls this)
   */
  onToggle?: MouseEventHandler<HTMLButtonElement> & MouseEventHandler<HTMLAnchorElement> & MouseEventHandler<SVGSVGElement>

  /*
   * Override close button or null
   */
  close?: ReactNode

  /*
   * Alignment
   * Default: 'left'
   */
  align?: 'left' | 'center' | 'right'

  /*
   * Size
   * Default: 'small'
   */
  size?: 'micro' | 'small' | 'large'

  /*
   * Classes
   */
  className?: string

  /*
   * Render content here
   */
  children?: ReactNode
}

/*
 * Properties tied to alert type
 */
interface AlertColorClasses {
  backgroundClass: string
  iconClass: string
  alertClass: string
}

const alertTypeConfig: { [key: string]: AlertColorClasses } = {
  success: {
    backgroundClass: 'c-bg-green-lt-2 c-border-green-lt-1',
    iconClass: 'c-green',
    alertClass: 'c-green-dk-1'
  },
  'warning-low': {
    backgroundClass: 'c-bg-yellow-lt-2 c-border-yellow-lt-1',
    iconClass: 'c-yellow',
    alertClass: 'c-yellow-dk-3'
  },
  'warning-high': {
    backgroundClass: 'c-bg-orange-lt-2 c-border-orange-lt-1',
    iconClass: 'c-orange',
    alertClass: 'c-orange-dk-1'
  },
  error: {
    backgroundClass: 'c-bg-red-lt-2 c-border-red-lt-1',
    iconClass: 'c-red',
    alertClass: 'c-red-dk-1'
  },
  primary: {
    backgroundClass: 'c-bg-primary-lt-2 c-border-primary-lt-1',
    iconClass: 'c-primary',
    alertClass: 'c-primary-dk-1'
  }
}

/*
 * Properties tied to alert size
 */
interface AlertSizeClasses {
  backgroundClass: string
  iconSize: string
  iconSpacing: string
  fontClass: string
}

const sizeConfig: { [key: string]: AlertSizeClasses } = {
  micro: {
    backgroundClass: 'alert-micro',
    iconSize: '12px',
    iconSpacing: 'u-margin-right-5',
    fontClass: 't-5'
  },
  small: {
    backgroundClass: 'u-padding-all-10',
    iconSize: '20px',
    iconSpacing: 'u-margin-right-10',
    fontClass: ''
  },
  large: {
    backgroundClass: 'u-padding-all-20',
    iconSize: '30px',
    iconSpacing: 'u-margin-right-10',
    fontClass: 't-3'
  }
}

/*
 * Properties tied to alert alignment
 */
const alignmentConfig: { [key: string]: string} = {
  left: 'u-flex u-align-items-center u-justify-content-start',
  center: 'u-flex-center',
  right: 'u-flex u-align-items-center u-justify-content-end'
}

function getAlertClass (alertType: string): string {
  return alertTypeConfig[alertType].alertClass
}

function getAlertBackgroundClass (alertType: string, size: string, showBackground: boolean, showBorder: boolean): string {
  return clsx(
    sizeConfig[size].backgroundClass,
    showBackground && [
      'u-border-radius-small',
      showBorder && 'u-border-all',
      alertTypeConfig[alertType].backgroundClass
    ]
  )
}

function getAlignmentClass (align: string): string {
  return alignmentConfig[align]
}

function getAlertIconClass (alertType: string, size: string): string {
  return clsx(sizeConfig[size].iconSpacing, alertTypeConfig[alertType].iconClass)
}

function getAlertIconSize (size: string): string {
  return sizeConfig[size].iconSize
}

function getAlertFontSize (size: string): string {
  return sizeConfig[size].fontClass
}

/*
 * Alert component
 */
function Alert ({
  alertType = 'primary',
  icon = faExclamationTriangle,
  showIcon = true,
  showBackground = true,
  showBorder = true,
  show = true,
  onToggle,
  close,
  align = 'left',
  size = 'small',
  className,
  children,
  ...otherAttributes
}: AlertProps, ref: ForwardedRef<any>): ReactElement | null {
  const alertClasses = getAlertClass(alertType)
  const alertBackgroundClasses = getAlertBackgroundClass(alertType, size, showBackground, showBorder)
  const alignmentClasses = getAlignmentClass(align)

  const fontSizeClasses = getAlertFontSize(size)

  const iconClasses = getAlertIconClass(alertType, size)
  const iconStyles = getAlertIconSize(size)

  const closeButton = useMemo(() => {
    if (close != null) {
      return close
    } else if (onToggle != null) {
      return <FontAwesomeIcon icon={faClose} role='button' style={{ fontSize: iconStyles }} onClick={onToggle} />
    }

    return null
  }, [close, onToggle])

  if (!show) {
    return null
  }

  return (
    <div ref={ref} className={clsx(alertClasses, alertBackgroundClasses, alignmentClasses, className)} {...otherAttributes}>
      {showIcon && (
        <FontAwesomeIcon icon={icon} className={iconClasses} style={{ fontSize: iconStyles }} />
      )}
      <div className='d-flex flex-column w-100'>
        <div className={fontSizeClasses}>
          {children}
        </div>
      </div>
      {closeButton}
    </div>
  )
}

const AlertWrapped = forwardRef(Alert)
export { AlertWrapped as Alert }
