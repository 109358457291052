import {
  ReactElement
} from 'react'
import { GetConfigItemSummaryQuery } from '@web/shared-data-access-queries'
import { DefaultCard } from './DefaultCard'
import { ImprintingCard } from './ImprintingCard'
import { EngravingCard } from './EngravingCard'

interface ConfigurationGroupProps {
  configurationGroup: NonNullable<NonNullable<GetConfigItemSummaryQuery['getConfigItemSummary']>['configDetails']>[number]
}

const configurationMapping = {
  Default: DefaultCard,
  CustomImprints: ImprintingCard,
  Engraving: EngravingCard
}

function BuildCard ({ config }: any): ReactElement | null {
  if (Object.prototype.hasOwnProperty.call(configurationMapping, config.TemplateName)) {
    const Component = configurationMapping[config.TemplateName]
    return <Component config={config} />
  }
  return null
}

/**
 * A group of multiple cards that are spaced closer together in the configuration details
 */
function CardGroup ({
  configurationGroup
}: ConfigurationGroupProps): ReactElement {
  return (
    <div className='mb-3'>
      {configurationGroup.map((config, index) =>
        <BuildCard config={config} key={index} />
      )}
    </div>
  )
}

export { CardGroup }
