import {
  Button,
  Modal,
  ModalBody,
  ModalHeader
} from '@web/shared-ui-components'
import { ReactElement, useRef } from 'react'
import { OracleDownAlert } from '../../../oracle/feature/oracle-down-alert'

interface StullerPayCheckoutOracleDownModalProps {
  onHide: (event: Event) => void
}

/**
 * Modal component shown before redirect on Oracle down in checkout
 */
function StullerPayCheckoutOracleDownModal ({ onHide }: StullerPayCheckoutOracleDownModalProps): ReactElement {
  const documentBody = useRef(document.body)

  return (
    <Modal portalRef={documentBody} id='stuller-pay-oracle-down-notification' onHide={onHide}>
      <ModalHeader onToggle={() => $('#stuller-pay-oracle-down-notification').modal('toggle')} />
      <ModalBody className='text-left'>
        <h1>Unable to checkout</h1>
        <OracleDownAlert className='u-margin-bottom-20' />
        <div className='text-right'>
          <Button color='primary' onClick={() => $('#stuller-pay-oracle-down-notification').modal('toggle')} className='u-margin-bottom-10'>Continue</Button>
          <p className='robotoLight'>You'll be taken back to this item's product page</p>
        </div>
      </ModalBody>
    </Modal>
  )
}

export { StullerPayCheckoutOracleDownModal }
