import { ReactElement, ReactNode } from 'react'
import { useIsOracleUpQuery } from '@web/shared-data-access-queries'
import { OracleDownAlert } from './OracleDownAlert'

interface OracleDownAlertWrapperProps {
/*
 * Additional classes
 */
  className?: string
  isOracleDown?: boolean | null
  children: ReactNode
}

function OracleDownAlertWrapper ({ className, isOracleDown = null, children }: OracleDownAlertWrapperProps): ReactElement | null {
  if (isOracleDown == null) {
    const { data, loading, error } = useIsOracleUpQuery({ fetchPolicy: 'no-cache' })

    if (loading) {
      return null
    }

    if (data == null || !data.isOracleUp || error != null) {
      return <OracleDownAlert className={className} />
    }

    return (
      <>
        {children}
      </>
    )
  }

  if (isOracleDown) {
    return <OracleDownAlert className={className} />
  }

  return (
    <>
      {children}
    </>
  )
}

export { OracleDownAlertWrapper }
