import { CSSProperties, ReactElement, ReactNode, useCallback, useEffect, useMemo, useState } from 'react'
import clsx from 'clsx'
import { useOnElementExist, useCreateEvent } from '@web/shared-util-hooks'
import { faChevronUp } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { CSSTransition } from 'react-transition-group'
import '@web/styles/Dock.less'
import { TrackEvent } from '@web/shared-util-google-analytics'

interface DockProps {
  /**
   * Class overrides
   */
  className?: string
  /**
   * Class name for the dock
   */
  dockTabClassName?: string
  /**
   * Class name for the dock body
   */
  dockBodyClassName?: string
  dockBodyStyle?: CSSProperties

  /**
   * Show or hide dock
   */
  showDock: boolean
  /**
   * Duration in MS for slide transition of dock
   */
  slideDurationInMS?: number
  /**
   * Callback for when dock tab is clicked
   */
  onTabClicked?: (val?: boolean) => void
  /**
   * Appended text for the tab on the dock
   */
  dockTabText?: string
  /**
   * Display the toggle button
   */
  showToggleButton?: boolean
  /**
   * Conent to be shown when the dock is in the opened state
   */
  dockContentFull: ReactNode
  /**
   * If this param is passed in, when the dock is considered closed, the dock will not close all the way and render given content
   */
  dockContentDense?: ReactNode
  /**
   * Pass this category if you want to track it in GA
   */
  trackingCategory?: string
}

/*
 * Dock component
 */
function Dock ({
  className,
  dockTabClassName = 'c-bg-primary-dk-2 c-white',
  dockBodyClassName = 'c-bg-primary-dk-2 c-white',
  dockBodyStyle,
  showDock,
  slideDurationInMS = 300,
  dockTabText,
  onTabClicked,
  showToggleButton = true,
  dockContentFull,
  dockContentDense,
  trackingCategory,
  ...otherAttributes
}: DockProps): ReactElement {
  useOnElementExist('#metalPrices', () => document.querySelector('#metalPrices')?.classList.add('d-none'))
  const [dockContent, setDockContent] = useState<ReactNode>(dockContentFull)

  /**
   * When dockContentDense or dockContentFull content changes, re-render dock with newly updated content
   */
  useEffect(() => {
    handleEnteredAndExited()
  }, [dockContentDense, dockContentFull])

  const { dispatchEvent } = useCreateEvent('liveChatButton', window)

  const dockTabClasses = useMemo(() => clsx(dockTabClassName, 'py-3 px-4 u-cursor-pointer user-select-none dock-tab'), [dockTabClassName])
  const dockBodyClasses = useMemo(() => clsx(dockBodyClassName, 'w-100'), [dockBodyClassName])
  const dockContainerClasses = useMemo(() => clsx(className, 'fixed-bottom react-dock', dockContentDense != null ? 'react-dock-dense' : 'react-dock-full'), [className, dockContentDense])
  const iconStyle = useMemo(() => {
    return { transition: `transform ${slideDurationInMS}ms`, transform: showDock ? 'rotate(180deg)' : 'rotate(0deg)' }
  }, [slideDurationInMS, showDock])

  const tabText = useMemo(() => `${showDock ? 'Hide' : 'Show'}${dockTabText != null ? ' ' + dockTabText : ''}`, [showDock, dockTabText])

  const handleTrackEvent = useCallback((label: string) => {
    if (trackingCategory == null) return
    TrackEvent(trackingCategory, 'DockToggle', label)
  }, [trackingCategory])

  const handleEnteredAndExited = useCallback(() => {
    setDockContent((dockContentDense == null || showDock) ? dockContentFull : dockContentDense)
    handleTrackEvent(showDock ? 'Shown' : 'Hide')
    dispatchEvent()
  }, [setDockContent, showDock, dockContentFull, dockContentDense, dispatchEvent])

  useEffect(() => {
    dispatchEvent()

    return () => dispatchEvent()
  }, [])

  return (
    <CSSTransition
      in={showDock}
      appear
      classNames={dockContentDense != null ? 'react-dock-dense' : 'react-dock-full'}
      timeout={slideDurationInMS + 100}
      onEntered={handleEnteredAndExited}
      onExited={handleEnteredAndExited}
    >
      {state => (
        <div className={dockContainerClasses} {...otherAttributes}>
          <div className='d-flex flex-column justify-content-center align-items-center position-relative'>
            {showToggleButton && onTabClicked != null && (
              <div className={dockTabClasses} onClick={() => onTabClicked()}>
                <span>{tabText} <FontAwesomeIcon className='ml-2 c-primary-lt-1' icon={faChevronUp} size='lg' style={iconStyle} /></span>
              </div>
            )}
            <div className={dockBodyClasses} style={dockBodyStyle}>
              <div className='mx-auto u-max-site-width h-100'>
                <div className='d-flex align-items-center h-100 u-flex-grid-row px-3 py-4'>
                  <div className='u-flex-grid-col-12'>
                    {dockContent}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </CSSTransition>
  )
}

export { Dock }
