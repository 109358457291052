import { ReactElement, useCallback, useRef } from 'react'
import { EngravingInfo } from './types'
import '@web/styles/ConfigItemSummaryTemplate.less'
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from '@web/shared-ui-components'
interface EngravingPreviewProps {
  previewInfo: EngravingInfo['EngravingTexts']
  id: string
}

/**
 * Engraving Preview + Button
 */
function EngravingPreview ({
  previewInfo, id
}: EngravingPreviewProps): ReactElement | null {
  const documentBody = useRef(document.body)
  const handleSubmit = useCallback(() => {
    $(`#${id}`).modal('toggle')
  }, [id])

  if (previewInfo == null || previewInfo.length === 0) return null

  return (
    <>
      {previewInfo.map((engravingText, i) =>
        engravingText.Text.length > 15 && (
          <div className='d-flex flex-column h-100' key={i}>
            <div className='d-flex align-center mb-2 my-auto'>
              <Button tag='a' className='sbtn-rounded t-5 u-padding-vertical-5' color='primary' style={{ minWidth: '1px' }} onClick={handleSubmit}>Preview</Button>
              <Modal portalRef={documentBody} id={id}>
                <ModalHeader title='Engraving Font Preview' onToggle={handleSubmit} />
                <ModalBody>
                  <img className='img-responsive' src={engravingText.RenderedFullMessageUrl} />
                </ModalBody>
                <ModalFooter>
                  <Button color='primary' onClick={handleSubmit}>Close</Button>
                </ModalFooter>
              </Modal>
            </div>
          </div>
        )
      )}
    </>
  )
}

export { EngravingPreview }
