import { ReactElement, MouseEventHandler } from 'react'
import { faChevronRight } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

interface TableRowButtonLinkProps {
  /*
   * URL to direct to
   */
  href: string
  /*
   * URL to direct to
   */
  openInNewWindow?: boolean
  /**
   * On Click Handler
   */
  handleClick?: MouseEventHandler
}

function TableRowButtonLink ({ href, openInNewWindow = false, handleClick, ...otherAttributes }: TableRowButtonLinkProps): ReactElement {
  return (
    <td className='stbl-row-toggle noPrint'>
      <a
        onClick={handleClick}
        className='d-flex h-100 w-100 justify-content-center align-items-center'
        href={href}
        target={openInNewWindow ? '_blank' : '_self'}
        rel='noreferrer'
        {...otherAttributes}
      >
        <FontAwesomeIcon icon={faChevronRight} size='lg' />
      </a>
    </td>
  )
}

export { TableRowButtonLink }
