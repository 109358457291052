import { useEffect, useRef } from 'react'

/**
 * Allows viewing previous state of some react state (initial value will be undefined)
 */
function usePrevious<T> (value: T): T {
  const ref: any = useRef<T>()

  // Store current value in ref and assign on change
  useEffect(() => {
    ref.current = value
  }, [value])

  return ref.current
}

export {
  usePrevious
}
