import {
  MouseEventHandler,
  ReactElement
} from 'react'
import { Button } from '@web/shared-ui-components'
import { DeletePaymentMethod } from '@web/payment-methods'

interface DeletePaymentProps {
  /**
    * Display name for payment methods
    */
  displayName?: string

  /**
    * ID for modal to confirm payment method deletion
    */
  deleteModalId: string

  /**
    * Event for removing payment methods
    */
  removePaymentMethod: MouseEventHandler

  /**
    * Event for removing payment methods
    */
  editPaymentMethod: MouseEventHandler

  showEdit: boolean
  showRemove: boolean
}

/**
 * Payment method actions component (edit and delete button)
 */
function PaymentMethodActions ({ removePaymentMethod, editPaymentMethod, displayName, deleteModalId, showEdit, showRemove }: DeletePaymentProps): ReactElement | null {
  if (!showEdit && !showRemove) {
    return null
  }

  return (
    <div className='d-flex justify-content-end u-flex-grid-col-6 u-flex-grid-col-sm-3 align-self-end align-self-sm-center ml-auto mb-4 pb-2 pr-2 mr-4 mb-md-0 pb-md-0'>
      {showEdit &&
        <div className='pr-3'>
          <Button color='secondary-inverse' className='f6' style={{ minWidth: '90px', maxHeight: '30px' }} onClick={editPaymentMethod} data-test='edit-payment-method'>Edit</Button>
        </div>}
      {showRemove &&
        <DeletePaymentMethod deleteModalId={deleteModalId} removeHandler={removePaymentMethod} displayName={displayName} />}
    </div>
  )
}

export {
  PaymentMethodActions
}
