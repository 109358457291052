import type { FilesTypesAccepted, ViewType } from '@web/shared-ui-components'
import { ReactElement, useCallback } from 'react'
import { FileUploadButton } from '@web/shared-ui-components'
import { FileRejection, useDropzone } from 'react-dropzone'
import clsx from 'clsx'

interface FileUploadDropzoneProps {
  files: File[]
  rejectedFiles: FileRejection[]
  fileLimit: number
  fileTypesAccepted: FilesTypesAccepted
  minFileSizeInBytes: number
  maxFileSizeInBytes: number
  viewType?: ViewType
  onFilesChange: (files: File[], rejectedFiles: FileRejection[]) => void
}

function FileUploadDropzone ({ files, rejectedFiles, fileLimit, fileTypesAccepted, minFileSizeInBytes, maxFileSizeInBytes, viewType = 'DEFAULT', onFilesChange, ...otherAttributes }: FileUploadDropzoneProps): ReactElement {
  const handleDrop = useCallback(<T extends File>(acceptedFiles: T[], rejectedFiles: FileRejection[]) => {
    const newFiles = [...files]

    for (const file of acceptedFiles) {
      newFiles.push(file)

      if (newFiles.length >= fileLimit) {
        break
      }
    }

    onFilesChange(newFiles, rejectedFiles)
  }, [files])

  const { getRootProps, getInputProps } = useDropzone({
    accept: fileTypesAccepted.fileTypes,
    maxFiles: fileLimit,
    minSize: minFileSizeInBytes,
    maxSize: maxFileSizeInBytes,
    onDrop: handleDrop
  })

  return (
    <>
      {/* X-Small */}
      <div className={clsx(viewType !== 'SINGLE_COMPACT' ? 'd-block d-sm-none' : 'u-width-100-xsmall')} {...getRootProps()}>
        <FileUploadButton
          className={clsx('w-100', viewType !== 'SINGLE_COMPACT' && 'mt-2')}
          files={files}
          fileLimit={fileLimit}
          data-test='file-upload-button'
          {...otherAttributes}
        />
        <input className='h-100 w-100' {...getInputProps()} />
      </div>
      {/* Small & Up */}
      {viewType !== 'SINGLE_COMPACT' &&
        <div className='d-none d-sm-block'>
          <div
            className={clsx('file-uploader-target p-5 text-center', rejectedFiles.length > 0 && 'file-uploader-target-error')}
            {...getRootProps()}
          >
            <input className='h-100 w-100' {...getInputProps()} />
            <div>Drag {fileLimit > 1 ? 'files' : 'a file'} here or choose files from your computer</div>
            <FileUploadButton
              className='mt-2'
              files={files}
              fileLimit={fileLimit}
              data-test='file-upload-button'
              {...otherAttributes}
            />
          </div>
        </div>}
    </>
  )
}

export { FileUploadDropzone }
