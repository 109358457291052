import { useLocation } from 'react-router-dom'
import { useMemo } from 'react'

interface UseQueryParams {
  /*
  * URLSearchParams Object
  */
  urlSearchParams: URLSearchParams

  /*
  * Query object returned from params
  */
  query: Record<string, string>
}

function useQueryParams (): UseQueryParams {
  const { search } = useLocation()

  const urlSearchParams = useMemo(() => new URLSearchParams(search), [search])

  const query = useMemo<Record<string, string>>(() => {
    const query = {}
    for (const [key, value] of urlSearchParams.entries()) {
      query[key] = value
    }
    return query
  }, [urlSearchParams])

  return { urlSearchParams, query }
}

export { useQueryParams }
