import { ReactElement, ReactNode, useMemo, useEffect, useCallback, MutableRefObject } from 'react'
import clsx from 'clsx'
import '@web/styles/Modal.less'
import { faTimes } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { TrackEvent } from '@web/shared-util-google-analytics'
import { createPortal } from 'react-dom'

/*
 * Event handler for onClose event
 */
type ModalToggleEventHandler = () => void

interface ModalProps {
  /*
   * Id of element
   */
  id: string
  /*
   * Child components
   */
  children?: ReactNode
  /*
   * Additional class name(s) to give to the containing element
   */
  className?: string
  /*
   * CSS defined size
   */
  size?: 'default' | 'large' | 'xlarge'
  /*
   * transparent element
   */
  transparent?: boolean
  /**
     * Handler called when modal is opening
     */
  onShow?: (event: Event) => void
  /**
    * Handler called once the modal is shown
    */
  onShown?: (event: Event) => void
  /**
    * Handler called when the modal is closing
    */
  onHide?: (event: Event) => void
  /**
    * Handler called once the modal is hidden
    */
  onHidden?: (event: Event) => void
  /**
   * Handler called when `static` backdrop and the modal has refused to close
   */
  onHidePrevented?: (event: Event) => void
  /**
   * Pass this category if you want to track it in GA
   */
  tracking?: { category?: string, action: string, label?: string }
  /**
   * Ref to render the modal to
   */
  portalRef?: MutableRefObject<any>
  /**
   * Prevents clicking outside of modal to close
   */
  staticBackdrop?: boolean

  /**
   * Prevents clicking outside of modal to close
   */
  fullscreen?: boolean
}

interface ModalHeaderProps {
  /*
   * Additional class name(s) to give to the containing element
   */
  className?: string
  /*
   * Title for the modal header
   */
  title?: ReactNode
  /*
   * Event handler for modal toggle
   */
  onToggle: ModalToggleEventHandler
  /*
   * Child components
   */
  children?: ReactNode
}

interface ModalBodyProps {
  /*
   * Child components
   */
  children: ReactNode
  /*
   * Additional class name(s) to give to the containing element
   */
  className?: string
}

interface ModalFooterProps {
  /*
   * Child components
   */
  children?: ReactNode
  /*
   * Additional class name(s) to give to the containing element
   */
  className?: string
  /*
   * Event handler for modal toggle
   */
  onToggle?: ModalToggleEventHandler
}

/*
 * Modal Header Component
 */
function ModalHeader ({ className, title, onToggle, children }: ModalHeaderProps): ReactElement {
  const classes = clsx('modal-header',
    (title != null || children != null) && 'u-padding-bottom-20',
    className)

  return (
    <div className={classes}>
      <button type='button' className='close' aria-label='Close' onClick={onToggle}>
        <FontAwesomeIcon icon={faTimes} />
      </button>
      {title != null && (
        <div className='t-h1'>
          {title}
        </div>
      )}
      {children}
    </div>
  )
}

/*
 * Modal Body Component
 */
function ModalBody ({ children, className }: ModalBodyProps): ReactElement {
  return (
    <div data-test='modal-body' className={clsx('modal-body', className)}>
      {children}
    </div>
  )
}

/*
 * Modal Footer Component
 */
function ModalFooter ({ children, className, onToggle }: ModalFooterProps): ReactElement {
  return (
    <div className={clsx('modal-footer', className)}>
      {children ??
        <div className='u-inline-block'>
          <button className='sbtn sbtn-primary' onClick={onToggle}>Close</button>
        </div>}
    </div>
  )
}

/*
 * Modal Component
 */
function Modal ({
  id,
  children,
  className,
  size = 'default',
  transparent = false,
  onShow = () => {},
  onShown = () => {},
  onHide = () => {},
  onHidden = () => {},
  onHidePrevented = () => {},
  tracking,
  portalRef,
  staticBackdrop = false,
  fullscreen = false
}: ModalProps): ReactElement | null {
  const classNames = useMemo(() => clsx(
    'modal-dialog',
    size === 'default' && 'm-standard',
    size === 'large' && 'm-large',
    size === 'xlarge' && 'm-x-large',
    transparent && 'm-transparent',
    fullscreen && 'full-screen',
    className
  ), [className, size, transparent])

  const staticBackgropAttributes = staticBackdrop
    ? {
        'data-backdrop': 'static',
        'data-keyboard': 'false'
      }
    : {}

  const handleShow = useCallback((event: Event) => {
    onShow(event)
    if (tracking == null || tracking.category == null) return
    TrackEvent(tracking.category, tracking.action, tracking.label ?? 'Show')
  }, [onShow])

  useEffect(() => {
    $(`#${id}`).off('show.bs.modal')
    $(`#${id}`).off('shown.bs.modal')
    $(`#${id}`).off('hide.bs.modal')
    $(`#${id}`).off('hidden.bs.modal')
    $(`#${id}`).off('hidePrevented.bs.modal')

    $(`#${id}`).on('show.bs.modal', handleShow)
    $(`#${id}`).on('shown.bs.modal', onShown)
    $(`#${id}`).on('hide.bs.modal', onHide)
    $(`#${id}`).on('hidden.bs.modal', onHidden)
    $(`#${id}`).on('hidePrevented.bs.modal', onHidePrevented)
  }, [onShow, onShown, onHide, onHidden, onHidePrevented])

  // The modal to be rendered (in portal or not)
  const reactModal = (
    <div id={id} className='modal react-modal' tabIndex={-1} role='dialog' {...staticBackgropAttributes}>
      <div className={classNames}>
        <div className='modal-content'>
          {children}
        </div>
      </div>
    </div>
  )

  if (portalRef != null) {
    if (portalRef.current != null) {
      return createPortal(reactModal, portalRef.current)
    } else {
      return null
    }
  }

  return reactModal
}

export {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalToggleEventHandler
}
