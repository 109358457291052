import React, { ReactElement, useMemo, useCallback, ReactNode } from 'react'
import { NavigationItemRouterLink, NavigationItemRowData } from '@web/product-navigation-feature'
import { NavigationItem, NavigationSection } from '@web/shared-data-access-queries'
import { FancySelect } from '@web/shared-ui-components'
import { components, ValueContainerProps, StylesConfig, GroupBase } from 'react-select'
import { sort } from '@web/shared-util-core'
import { NavigationSectionDropdownWrapper } from './NavigationSectionDropdownWrapper'

import '@web/styles/NavigationSectionDropdown.less'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown, faChevronUp } from '@fortawesome/pro-solid-svg-icons'

interface NavigationSectionDropdownProps{
/*
 * The section whose options being rendered within the dropdown
 */
  section: NavigationSection
}

interface NavigationSectionDropdownOption{
  /*
  * This is the value of the selected dropdown option (may not be needed)
  */
  value?: string | null

  /*
  * The component being rendered inside of the FancySelect
  */
  label: JSX.Element

  /*
   * The actual posted value of the NavigationItem only there to be able to find the changed NavigationItem in the dropdown
   */
  navItemValue: string
}

/**
 * function to build the override for the "selected dropdown display" on the FancySelect Component
 */
function getValueContainer (section: NavigationSection) {
  return ({
    children,
    ...props
  }: ValueContainerProps<NavigationSectionDropdownOption>): JSX.Element => {
    const selected = props.getValue()
    const latestSelectedItem: NavigationItem | null | undefined = selected.length > 0 ? section.NavigationItems.find(item => item.Value === selected[selected.length - 1].navItemValue) : null
    const content = ((length: number) => {
      switch (length) {
        case 0:
          return 'Select'
        case 1:
          return latestSelectedItem != null && <NavigationItemRowData navigationItem={latestSelectedItem} section={section} showCheckbox={false} layout={section.Layout} />
        default:
          return 'Multiple Selected'
      }
    })(selected.length)

    return (
      <components.ValueContainer {...props} className='pr-0'>
        {content}
        {/* this is a hack because the react-select will not open on "menu click" when you override the ValueContainer */}
        <span className='fancy-select-value-container-hack'>
          {React.Children.map<ReactNode, any>(children, (child) => (child != null && components.SingleValue !== child.type) ? child : null)}
        </span>
      </components.ValueContainer>
    )
  }
}

/**
 * function to compute the styles configuration of the FancySelect Component
 */
function getFancySelectStyles (): StylesConfig<NavigationSectionDropdownOption, boolean, GroupBase<NavigationSectionDropdownOption>> {
  const colorStyles: StylesConfig<NavigationSectionDropdownOption> = {
    control: (styles) => ({ ...styles, backgroundColor: 'white' }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => ({
      ...styles,
      backgroundColor: 'white',
      fontWeight: isSelected || isFocused ? 500 : 400,
      padding: 0,
      overflowX: 'hidden'
    })
  }
  return colorStyles
}

function NavigationSectionDropdown ({ section }: NavigationSectionDropdownProps): ReactElement {
  const navigationItems = useMemo(() => section.NavigationItems, [section.NavigationItems])

  const filteredOptions: NavigationItem[] | undefined = useMemo(() => {
    let filteredItems: any[] = []
    switch (section.Layout) {
      case 'text_and_image_sm':
      case 'text_and_image_md':
      case 'text_and_image_lg':
      case 'image_grid':
        for (const item of navigationItems) {
          if (item.ImageAsset?.source != null) {
            filteredItems.push(item)
          }
        }
        break
      default:
        filteredItems = [...navigationItems]
        break
    }
    return filteredItems.sort((a: NavigationItem, b: NavigationItem) => sort(a, b, 'IsSelected'))
  }, [section.Layout, navigationItems])

  const createUiOptions = useCallback((navigationItems: NavigationItem[]): NavigationSectionDropdownOption[] =>
    navigationItems.map(navigationItem => ({
      label:
  <NavigationSectionDropdownWrapper trackingName={navigationItem.TrackingName} selected={navigationItem.IsSelected} title={section.Title}>
    <NavigationItemRouterLink navigationItem={navigationItem} section={section} className='navigation-item-display'>
      <NavigationItemRowData navigationItem={navigationItem} section={section} showCheckbox={section.IsMultiSelect} />
    </NavigationItemRouterLink>
  </NavigationSectionDropdownWrapper>,
      value: navigationItem.LinkUrl,
      navItemValue: navigationItem.Value
    }))
  , [section.Layout, section])

  const optionsWithUi: NavigationSectionDropdownOption[] | undefined = useMemo(() =>
    createUiOptions(filteredOptions ?? [])
  , [filteredOptions])

  const selectedDropdownItems = useMemo(() =>
    createUiOptions(section?.NavigationItems.filter(option => option.IsSelected) ?? [])
  , [section?.NavigationItems])

  const ValueContainer = getValueContainer(section)
  const styles = getFancySelectStyles()

  return (
    <div className='simple-nav-dropdown react-standard-dropdown'>
      <FancySelect
        options={optionsWithUi}
        value={selectedDropdownItems}
        isClearable={false}
        isMulti={section.IsMultiSelect}
        hideSelectedOptions={false}
        isSearchable={false}
        components={{
          ValueContainer,
          IndicatorSeparator: () => null,
          DropdownIndicator: (props) => (
            <components.DropdownIndicator {...props}>
              <FontAwesomeIcon size='lg' icon={props.selectProps.menuIsOpen ? faChevronUp : faChevronDown} className='c-primary' />
            </components.DropdownIndicator>
          )
        }}
        openMenuOnClick
        styles={styles}
        classNamePrefix='react-fancy-select'
      />
    </div>
  )
}

export {
  NavigationSectionDropdown
}
