import {
  ReactElement,
  ReactNode,
  useEffect,
  useRef,
  useState
} from 'react'
import clsx from 'clsx'
import '@web/styles/ScrollableContainer.less'
import { ScrollOrientation } from '@web/shared-ui-components'
import { useDrag } from '@use-gesture/react'
import { useEventListener } from '@web/shared-util-hooks'

interface ScrollableContainerProps {
  className?: string
  containerClass?: string
  gap?: string | number
  children: ReactNode
  useShadows?: boolean
  useClickAndDrag?: boolean
  orientation: ScrollOrientation
  containerLength?: string | number
  scrollPadding?: string | number
  centered?: boolean
  innerClass?: string
  startShadowOffset?: number
  endShadowOffset?: number
}

function ScrollableContainer ({
  className,
  containerClass,
  gap,
  children,
  useShadows = false,
  useClickAndDrag = false,
  orientation,
  containerLength,
  scrollPadding,
  centered = false,
  innerClass,
  startShadowOffset = 0,
  endShadowOffset = 0
}: ScrollableContainerProps): ReactElement {
  // Shadow scroll logic
  const [atStartOfContainer, setAtStartOfContainer] = useState(true)
  const [atEndOfContainer, setAtEndOfContainer] = useState(true)
  const [hasOverflow, setHasOverflow] = useState(true)
  const scrollContainerRef = useRef<HTMLDivElement>(null)

  function resizeHandler (): void {
    setShadowState()
  }

  // listener for when the window is resized
  useEventListener('resize', resizeHandler)

  // determines when there is more content to load before and/or after the edges of the scrollable container
  function onScroll (): void {
    setShadowState()
  }

  function setShadowState (): void {
    if (scrollContainerRef.current != null) {
      if (orientation === 'horizontal') {
        const scrollLeft = scrollContainerRef.current.scrollLeft
        const scrollWidth = scrollContainerRef.current.scrollWidth - scrollContainerRef.current.clientWidth
        setAtStartOfContainer(scrollLeft === 0)
        setAtEndOfContainer(scrollLeft >= scrollWidth)
        setHasOverflow(scrollContainerRef.current.scrollWidth > scrollContainerRef.current.clientWidth)
      }
      if (orientation === 'vertical') {
        const scrollTop = scrollContainerRef.current.scrollTop
        const scrollHeight = scrollContainerRef.current.scrollHeight - scrollContainerRef.current.clientHeight
        setAtStartOfContainer(scrollTop === 0)
        setAtEndOfContainer(scrollTop >= scrollHeight)
        setHasOverflow(scrollContainerRef.current.scrollHeight > scrollContainerRef.current.clientHeight)
      }
    }
  }

  const centerContent = !hasOverflow && centered
  const orientationClass = orientation === 'horizontal' ? 'horizontal-scroll' : 'vertical-scroll'

  useEffect(() => {
    setShadowState()
  }, [scrollContainerRef, orientation])

  const scrollShadowClasses = clsx(className, orientationClass, useShadows && 'scroll-shadow-container', atStartOfContainer && 'start-of-container', atEndOfContainer && 'end-of-container')
  const scrollContainerClasses = clsx(containerClass, orientationClass, hasOverflow && 'has-overflow', 'scroll-container')
  const scrollContainerInnerClasses = clsx(innerClass, orientationClass, 'scroll-container-inner d-flex', centerContent ? 'justify-content-center scroll-container-inner-no-overflow' : 'scroll-container-inner-overflow', hasOverflow && 'has-overflow')

  // Click + drag logic
  if (useClickAndDrag) {
    useDrag(({ delta: [x, y], direction: [dx, dy] }) => {
      if (orientation === 'horizontal' && dx != null && dx !== 0) {
        scrollContainerRef?.current?.scrollBy({ left: -x })
      } else if (orientation === 'vertical' && dy != null && dy !== 0) {
        scrollContainerRef?.current?.scrollBy({ top: -y })
      }
    }, { target: scrollContainerRef, filterTaps: true, preventDefault: true, eventOptions: { capture: true, passive: false } })
  }

  // Container length logic
  let maxHeight: string | number = ''
  let maxWidth: string | number = ''
  if (containerLength != null) {
    maxHeight = orientation === 'vertical' ? containerLength : ''
    maxWidth = orientation === 'horizontal' ? containerLength : ''
  }

  const shadowRef = useRef<HTMLDivElement>(null)
  shadowRef?.current?.style.setProperty('--start-shadow-offset', `${startShadowOffset}px`)
  shadowRef?.current?.style.setProperty('--end-shadow-offset', `${endShadowOffset}px`)

  return (
    <div className={scrollShadowClasses} ref={shadowRef} style={{ maxWidth }}>
      <div className={scrollContainerClasses} onScroll={onScroll} ref={scrollContainerRef} style={{ maxHeight, scrollPadding }}>
        <div className={scrollContainerInnerClasses} style={{ gap }}>
          {children}
        </div>
      </div>
    </div>
  )
}

export { ScrollableContainer }
