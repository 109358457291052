import {
  ReactElement, ReactNode, useCallback, useMemo, useEffect
} from 'react'
import { AddToCartModalTrigger, AddToCartData, Button, Modal, ModalBody, ModalFooter, ModalHeader, Alert } from '@web/shared-ui-components'
import uuid from 'react-uuid'
import { useChildrenProps } from '@web/shared-util-hooks'
import { useGetHasMissingLogoOnConfigLazyQuery } from '@web/shared-data-access-queries'

interface OrderAgainModalTriggerProps {
  addToCartUrl: string
  addToCartData: AddToCartData[]
  ignoreWebEnabledFlag?: boolean
  autoReorder?: boolean
  /*
   * Children of element
   */
  children?: ReactNode
  productUrl: string
  configIsValid?: boolean
  configId: number | null
}

/*
 * Component that handles the fork of adding to cart or re-configuring an item
 */
function OrderAgainModalTrigger ({ addToCartData, productUrl, addToCartUrl, configId, ignoreWebEnabledFlag = false, autoReorder = false, children, configIsValid = false }: OrderAgainModalTriggerProps): ReactElement {
  const uniqueId: string = useMemo(() => uuid(), [])
  const id = `${uniqueId}-order-again`
  const handleModalToggle = useCallback((id: string) => {
    $(`#${id}`).modal('toggle')
  }, [])
  const mappedChildren = useChildrenProps(children, { onClick: () => handleModalToggle(id) })

  const [getLogoOnConfig, { data }] = useGetHasMissingLogoOnConfigLazyQuery({ fetchPolicy: 'network-only' })
  const hasMissingLogo = useMemo(() => data?.getHasMissingLogoOnConfig?.hasMissingLogo ?? null, [data])
  useEffect(() => {
    if (configId != null) {
      void getLogoOnConfig({
        variables: {
          params: {
            configId
          }
        }
      })
    }
  }, [configId, getLogoOnConfig])

  return (
    <>
      {mappedChildren}
      <Modal id={id} tracking={{ category: 'OrderDetails', action: 'OrderAgain' }} className='text-left'>
        <ModalHeader title='Order Again' onToggle={() => handleModalToggle(id)} />
        <ModalBody className='d-flex flex-column'>
          {configIsValid
            ? (
              <span>Add to cart to order this exact item again or customize it.</span>
              )
            : (
              <>
                <div>One or more parts of this customization are out of stock, no longer available, or have had their specifications changed or updated since your last order. </div>
                <div className='font-weight-bold'>You’ll need to customize this item again to reorder.</div>
              </>
              )}
          {hasMissingLogo != null && hasMissingLogo &&
            <Alert alertType='warning-low' className='mt-3'>
              The logo file for this custom-imprinted item is no longer available. You'll need to customize this item again and upload a new logo.
            </Alert>}
        </ModalBody>
        <ModalFooter>
          <div className='d-flex flex-row flex-row-reverse gap-3 align-items-center'>
            <Button color='primary' tag='a' href={productUrl}>Customize</Button>
            {configIsValid
              ? (
                <div onClick={() => handleModalToggle(id)}>
                  <AddToCartModalTrigger
                    addToCartUrl={addToCartUrl}
                    addToCartData={addToCartData}
                    ignoreWebEnabledFlag={ignoreWebEnabledFlag}
                    autoReorder={autoReorder}
                  >
                    <Button color='atc' className='mr-3' disabled={hasMissingLogo ?? false}>Add to Cart</Button>
                  </AddToCartModalTrigger>
                </div>
                )
              : (
                <div className='dark-link mr-3' onClick={() => handleModalToggle(id)}>Cancel</div>
                )}
          </div>
        </ModalFooter>
      </Modal>
    </>
  )
}

export { OrderAgainModalTrigger }
