import { ReactElement, ReactNode, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { TrackCurrentPageView } from '@web/shared-util-google-analytics'
import { useUserContext } from '@web/shared-data-access-context'

interface TrackComponentLoadProps {
  children: ReactNode
  skipTracking?: boolean
  trackInitial?: boolean
}

/**
 * Wrapper for all components to track when a page is rendered
 */
function TrackComponentLoad ({ children, skipTracking = false, trackInitial = false }: TrackComponentLoadProps): ReactElement {
  const location = useLocation()
  const [tmpLocation, setTmpLocation] = useState<any>(trackInitial ? location : null)
  const { context } = useUserContext()

  useEffect(() => {
    if (context == null || location === tmpLocation || skipTracking) {
      return
    }
    setTmpLocation(location)
    if (tmpLocation != null) {
      TrackCurrentPageView({ pathName: `${location.pathname}${location.search}`, context })
    }
  }, [location, context])

  return <> {children} </>
}

export { TrackComponentLoad }
