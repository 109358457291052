export function serializeQuery (obj: any): string {
  if (obj == null) {
    return ''
  }
  const qs = serializeQueryRecursive(obj, undefined, undefined)
  return (qs.substring(1, qs.length))
}

function serializeQueryRecursive (obj: any, path: string = '', parentIsArray: boolean = false): string {
  let qs = ''
  for (const prop in obj) {
    if (typeof obj[prop] === 'function') {
      continue
    }
    let val = obj[prop]
    if (typeof val === 'undefined') {
      val = ''
    }
    let pathAndProp = path
    if (parentIsArray) {
      pathAndProp += '['
    }
    pathAndProp += prop
    if (parentIsArray) {
      pathAndProp += ']'
    }
    if (typeof val === 'object') {
      if (Array.isArray(val)) {
        qs += serializeQueryRecursive(val, pathAndProp, true)
      } else {
        qs += serializeQueryRecursive(val, pathAndProp + '.')
      }
    } else {
      qs += '&' + encodeURIComponent(pathAndProp) + '=' + encodeURIComponent(val)
    }
  }
  return qs
}

export function deserializeQuery<T> (qs: string, hoistSinglePropObj: boolean = true, reviver?: (obj: any) => T): T {
  if (qs.substring(0, 1) === '?') {
    qs = qs.substring(1, qs.length)
  }
  const segments = qs.split('&')
  let _root = {}
  for (const segment of segments) {
    let _skip = false
    const keyAndValue = segment.split('=')
    const value = decodeURIComponent(keyAndValue[1])
    const potentialNodes = decodeURIComponent(keyAndValue[0]).split('.')
    let _path: {} | null = null
    for (let i = 0; i < potentialNodes.length; i++) {
      const { skip, root, path } = processNode(potentialNodes, i, _root, _path, value)
      _skip = skip
      _root = root
      _path = path
    }
    if (_skip) {
      continue
    }
  }
  if (hoistSinglePropObj) {
    const keys = Object.keys(_root)
    if (keys.length === 1) {
      _root = _root[keys[0]]
    }
  }
  if (reviver != null) {
    return reviver(_root)
  }
  return _root as T
}

function processNode (potentialNodes: string[], i: number, root: {}, path: {}|null, value: string): {root: {}, path: {}|null, skip: boolean} {
  const firstBracket = potentialNodes[i].indexOf('[')
  const lastBracket = potentialNodes[i].indexOf(']')

  if (firstBracket >= 0 && firstBracket >= lastBracket) {
    // go no further with this node list since it is malformed
    return { skip: true, root, path }
  }
  const node = potentialNodes[i].substring(0, (firstBracket < 0) ? potentialNodes[i].length : firstBracket)
  const isRootNodeUndefined = typeof root[node] === 'undefined'
  if (path == null) {
    if (isRootNodeUndefined) {
      root[node] = (firstBracket >= 0) ? [] : {}
    }
    path = root
  }
  const isPathNodeUndefined = typeof path[node] === 'undefined'
  if (firstBracket >= 0) { // We have an array indexer
    const index = parseInt(potentialNodes[i].substring(firstBracket + 1, lastBracket)) ?? 0
    if (isPathNodeUndefined) {
      path[node] = []
    }
    if (typeof path[node] !== 'object' || !Array.isArray(path[node])) {
      // go no further because we have already defined this node as something other than an array (malformed)
      return { skip: true, root, path }
    }
    for (let previousIndex = 0; previousIndex < index; previousIndex++) {
      if (typeof path[node][previousIndex] === 'undefined') {
        path[node][previousIndex] = null
      }
    }
    if (i === potentialNodes.length - 1) { // Last in the path
      path[node][index] = value
    } else if (typeof path[node][index] === 'undefined') {
      path[node][index] = {}
    }
    path = path[node][index] // One level deeper
  } else { // Normal path
    if (i === potentialNodes.length - 1) { // Last in the path
      path[node] = value
    } else if (typeof path[node] === 'undefined') {
      path[node] = {}
    }
    path = path[node] // One level deeper
  }
  return { root, path, skip: false }
}
