import { ReactElement, useEffect, useState } from 'react'

interface TemplateLoaderParams{
  /*
   * the id of the element rendered to hold the template
   */
  id?: string | undefined

  /*
   * the url to the content that will be loaded into the template
   */
  url: string

  /*
   * a callback for when the url is finished loading and
   */
  onLoaded: (url: string) => void
}

function TemplateLoader ({ id = '', url, onLoaded }: TemplateLoaderParams): ReactElement | null {
  const [renderHtml, setHtml] = useState('')

  useEffect(() => {
    fetch(url).then((response) => {
      if (response.ok) return response.text()
    }).then((html) => {
      if (html != null && html !== undefined) {
        setHtml(html)
        onLoaded(url)
      } else {
        setHtml('')
      }
    }).catch(() => {})
  }
  , [])

  return (
    <div id={id} dangerouslySetInnerHTML={{ __html: renderHtml }} />
  )
}

export {
  TemplateLoader
}
