import {
  LoadingIndicator,
  Modal,
  ModalBody,
  ModalHeader,
  CmsContent
} from '@web/shared-ui-components'
import '@web/styles/StullerPay.less'
import { ReactElement, useRef } from 'react'
import { TermOption } from '@web/shared-data-access-queries'
import { StullerPayFinancingModalInner } from '@web/payment-methods'
import { AddToCartDto } from '../../../quote/get-a-quote/feature/types'

interface StullerPayFinancingModalProps {
  onToggle: () => void
  stullerPayEligible: boolean
  stullerPayLoading: boolean
  productItemNumber: string
  productImageUrl: string
  productTitle: string
  termOptions: TermOption[]
  addToCartData: AddToCartDto
}

/**
 * Modal component for StullerPay financing options
 */
function StullerPayFinancingModal ({
  onToggle,
  stullerPayEligible,
  stullerPayLoading,
  productItemNumber,
  productImageUrl,
  productTitle,
  addToCartData,
  termOptions
}: StullerPayFinancingModalProps): ReactElement {
  const documentBody = useRef(document.body)

  return (
    <Modal portalRef={documentBody} id='stullerPayFinancingModal'>
      <ModalHeader onToggle={onToggle} />
      <LoadingIndicator loading={stullerPayLoading}>
        <ModalBody className='text-left'>
          {stullerPayLoading && <div style={{ height: '231px' }} />}
          {!stullerPayLoading &&
            <>
              {stullerPayEligible
                ? (
                  <StullerPayFinancingModalInner
                    termsOptions={termOptions}
                    productItemNumber={productItemNumber}
                    productImageUrl={productImageUrl}
                    productTitle={productTitle}
                    addToCartData={addToCartData}
                  />
                  )
                : (
                  <CmsContent contentContainerName='stullerpay-contact-form-content' />
                  )}
            </>}
        </ModalBody>
      </LoadingIndicator>
    </Modal>
  )
}

export { StullerPayFinancingModal }
