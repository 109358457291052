import { useGetCmsContentByNameQuery, useGetCmsContentByIdQuery } from '@web/shared-data-access-queries'
import { ReactElement, useRef, useEffect } from 'react'
import { useCmsContentContext } from '@web/shared-ui-components'
import { LoadingIndicator } from '../LoadingIndicator'

interface CmsContentProps {
  contentContainerId?: number
  contentContainerName?: string
  sectionOptions?: {
    categoryId?: number | null
    groupId?: number | null
    productId?: number | null
    url?: string | null
    searchTerm?: string | null
  }
  params?: object
  onLoaded?: () => void
  /**
   * DANGER:  This allows you to modify the HTML before it is appended to the DOM.
   */
  onPostProcess?: (html: string) => string
}

interface UseCmsQueryErrorResult {
  error: Error
  loading: false
  previousData?: undefined
  data?: undefined
}

/*
* Calls the correct cms content query hook based on the props passed in and narrows down the result.
*/
function useCmsQuery ({ contentContainerId, contentContainerName, sectionOptions = {}, params = {} }: CmsContentProps): UseCmsQueryErrorResult | ReturnType<typeof useGetCmsContentByIdQuery> | ReturnType<typeof useGetCmsContentByNameQuery> {
  const allParams = { ...params }
  for (const [key, value] of Object.entries(sectionOptions)) {
    if (value != null) {
      allParams[`sectionOptions.${key}`] = value
    }
  }
  const cmsContentContext = useCmsContentContext()
  for (const [key, value] of Object.entries(cmsContentContext)) {
    if (allParams[`sectionOptions.${key}`] == null && value != null) {
      allParams[`sectionOptions.${key}`] = value
    }
  }
  const skipCmsContentQuery = contentContainerId == null
  const cmsContentQueryResult = useGetCmsContentByIdQuery({
    variables: { contentContainerId: contentContainerId ?? 0, params: allParams },
    skip: skipCmsContentQuery
  })

  const skipCmsContentByNameQuery = contentContainerName == null
  const cmsContentByNameQueryResult = useGetCmsContentByNameQuery({
    variables: { contentContainerName: contentContainerName ?? '', params: allParams },
    skip: skipCmsContentByNameQuery
  })

  if (!skipCmsContentQuery) {
    return cmsContentQueryResult
  }
  if (!skipCmsContentByNameQuery) {
    return cmsContentByNameQueryResult
  }

  const result: UseCmsQueryErrorResult = {
    loading: false,
    error: new Error('contentContainerId or contentContainerName must be specified.')
  }

  return result
}

/*
 * Gets and shows CMS content
 */
function CmsContent ({ contentContainerId, contentContainerName, sectionOptions, params, onLoaded, onPostProcess }: CmsContentProps): ReactElement | null {
  const { loading, error, previousData, data = previousData } = useCmsQuery({ contentContainerId, contentContainerName, sectionOptions, params })

  const innerRef = useRef<HTMLDivElement>(null)

  // Attach HTML from CMS using a Range and appending to the div
  useEffect(() => {
    if (data == null || innerRef.current == null) {
      return
    }

    let cmsContent = data?.cmsContent ?? ''
    cmsContent = onPostProcess?.(cmsContent) ?? cmsContent

    const html = document.createRange().createContextualFragment(cmsContent)

    innerRef.current.innerHTML = ''
    innerRef.current.appendChild(html)

    const contentDiv = innerRef.current.children[0]
    if (contentDiv?.classList?.contains('cms_editable')) {
      window.InitializeCmsQtipOnElement(contentDiv)
    }
  }, [data, innerRef])

  useEffect(() => {
    if (!loading && data != null && onLoaded != null) {
      onLoaded()
    }
  }, [loading, data])

  if (error != null) {
    console.log(error)
    return null
  }
  return (
    <LoadingIndicator loading={loading} position='center'>
      <div ref={innerRef} />
    </LoadingIndicator>
  )
}

export {
  CmsContent
}
