import { ReactElement, useCallback, ReactNode } from 'react'
import { useNavigationItemTracking } from '@web/product-navigation-hooks'
import { SearchPresentationContext } from './types'

interface NavigationSectionDropdownWrapperProps {
  trackingName: string
  title: string
  selected: boolean
  children: ReactNode
}

function NavigationSectionDropdownWrapper ({ trackingName, title, selected, children }: NavigationSectionDropdownWrapperProps): ReactElement {
  const { trackClickEvent } = useNavigationItemTracking()

  const handleClick = useCallback(() => {
    trackClickEvent('filter', title, 'checkbox_dropdown', trackingName, !selected)
  }, [trackClickEvent, SearchPresentationContext, title, trackingName, selected])

  return (
    <div onClick={handleClick}>
      {children}
    </div>
  )
}

export {
  NavigationSectionDropdownWrapper
}
