import {
  ReactElement,
  useMemo,
  useCallback,
  useContext
} from 'react'
import { PaymentMethod } from '@web/shared-data-access-queries'
import { useLock } from '@web/shared-util-hooks'
import { AddCard, PaymentMethodSelectorContext } from '@web/payment-methods'
import { PaymentMethodSelectionBoxOption } from './types'
import { newCardAllowedMethods } from './helpers'
import { PaymentMethodSelectionBoxesGroup } from './PaymentMethodSelectionBoxesGroup'

/*
 * PaymentMethodSelectionBoxes Props
 */
interface CardSelectionBoxesProps {
  cardPaymentMethods: PaymentMethod[] | null | undefined
  /*
  * Currently selected value
  */
  selectedValue?: PaymentMethod | null
  mapOptions: (paymentMethods: PaymentMethod[] | null | undefined, selectedValue?: PaymentMethod | null) => PaymentMethodSelectionBoxOption[] | undefined
  deleteCard: any
}

/*
 * Payment Method Selector Selection Boxes component
 */
function CardSelectionBoxes ({ cardPaymentMethods, selectedValue, mapOptions, deleteCard }: CardSelectionBoxesProps): ReactElement {
  const {
    userCanUseCreditCards,
    userCanUseDebitCards,
    allowAdding,
    refetchPaymentMethods
  } = useContext(PaymentMethodSelectorContext)
  const paymentMethodSelectionBoxOptions = mapOptions(cardPaymentMethods, selectedValue)
  const canSelectCard = useMemo(() => userCanUseCreditCards || userCanUseDebitCards, [userCanUseCreditCards, userCanUseDebitCards])
  const selectedPaymentMethodSelectionBoxOption = useMemo(() => paymentMethodSelectionBoxOptions?.filter(x => x.paymentMethod.SelectableValue === selectedValue?.SelectableValue)[0], [paymentMethodSelectionBoxOptions])

  const [handleRemoveCard] = useLock(useCallback(async (creditCardId: string) => {
    $(`#delete-payment-${creditCardId}`).modal('toggle')
    if (creditCardId != null) {
      await deleteCard({
        variables: {
          input: {
            creditCardId
          }
        }
      }).then(() => {
        void refetchPaymentMethods()
      })
    }
  }, [paymentMethodSelectionBoxOptions]))

  const label = useMemo(() => {
    let cardTypePrefix = ''

    if (userCanUseCreditCards && userCanUseDebitCards) {
      cardTypePrefix = 'Credit/Debit'
    } else if (userCanUseCreditCards) {
      cardTypePrefix = 'Credit'
    } else if (userCanUseDebitCards) {
      cardTypePrefix = 'Debit'
    }

    return `${cardTypePrefix} Card`
  }, [userCanUseCreditCards, userCanUseDebitCards])

  return (
    <>
      {canSelectCard &&
        <>
          <PaymentMethodSelectionBoxesGroup
            paymentMethods={paymentMethodSelectionBoxOptions}
            handleRemoveCard={handleRemoveCard}
            methodLabel={label}
            selectedValue={selectedPaymentMethodSelectionBoxOption}
            allowAdding={allowAdding}
          />
          {allowAdding &&
            <AddCard newCardAllowedMethods={newCardAllowedMethods} label={label} />}
        </>}
    </>
  )
}

export {
  CardSelectionBoxes
}
