import { ReactElement } from 'react'
import { Alert } from '@web/shared-ui-components'
import { faWrench } from '@fortawesome/pro-solid-svg-icons'

interface OracleDownAlertProps {
/*
 * Additional classes
 */
  className?: string
}

/*
 * Oracle Down Alert component
 */
function OracleDownAlert ({ className }: OracleDownAlertProps): ReactElement | null {
  return (
    <Alert alertType='warning-high' icon={faWrench} showBackground className={className}>
      <div className='u-bold'>Our ordering system is currently down for maintenance.</div>
      <div>Some of your information will be unavailable. Please check back later.</div>
    </Alert>
  )
}

export { OracleDownAlert }
