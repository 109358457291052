import {
  ReactElement,
  ReactNode
} from 'react'
import { Transition } from 'react-transition-group'
import clsx from 'clsx'
import '@web/styles/Transition.less'

interface SlideDownTransitionProps {
  isOpen?: boolean
  className?: string
  allowScroll: boolean
  /*
   * Message to display for empty results
   */
  children: ReactNode
}

/*
 * SlideDownTransition component
 */
function SlideDownTransition ({
  isOpen = false,
  className,
  allowScroll,
  children
}: SlideDownTransitionProps): ReactElement {
  return (
    <Transition in={isOpen} timeout={100}>
      {state => (
        <div className={clsx(className, allowScroll && 'react-slide-down-scroll', `react-slide-down-${state}`)}>
          {children}
        </div>
      )}
    </Transition>
  )
}

export {
  SlideDownTransition
}
