import { ReactNode } from 'react'

interface ItemStructure {
  /**
   * label of item
   */
  label: string
  /**
   * value of item
   */
  value: ReactNode
  /**
   * priority of item
   */
  priority?: number | null
}

interface PairedItemsResult {
  /**
   * Array of paired items with possibility of last pair having only one item.
   */
  pairedItems: Array<[ItemStructure, ItemStructure?]>
}

/**
 * Returns an array of paired items with possibility of last pair having
 * only one item.
 */
function usePairedItems (items: ItemStructure[]): PairedItemsResult {
  const pairedItems: Array<[ItemStructure, ItemStructure?]> = []

  for (let i = 0; i < items.length; i += 2) {
    pairedItems.push([
      items[i],
      items[i + 1] ?? { label: '', value: undefined }
    ])
  }

  return { pairedItems }
}

export { usePairedItems, ItemStructure }
