import {
  ChangeEvent,
  ReactElement, useCallback, useEffect, useMemo, useState
} from 'react'
import uuid from 'react-uuid'
import { faCircleQuestion } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Alert, Button, CmsContent, FormError, Input, LoadingIndicator, Modal, ModalBody, ModalFooter, ModalHeader } from '@web/shared-ui-components'
import qs from 'qs'
import { useLock } from '@web/shared-util-hooks'
import { useTokenizeCardLazyQuery, BankAccountInput, useSaveNewBankAccountMutation, SaveNewBankAccountMutation, useGetContextMinimalQuery } from '@web/shared-data-access-queries'
import { AddBankAccountAlert } from '@web/payment-methods'
import { z } from 'zod'

const newBankAccountInput: BankAccountInput = {
  BankName: '',
  AccountNumber: '',
  AccountNumberConfirmation: '',
  RoutingNumber: '',
  AccountNumberUntokenized: '',
  RoutingNumberUntokenized: '',
  TokenType: ''
}

interface AddBankAccountProps {
  /**
   * Is save type for bank account new or edit
   */
  saveType: 'new' | 'edit'
  /**
   * Allowed payment methods from parent to determine if it's possible to add a new bank account
   */
  allowedMethods: string[]
  /**
 * Is bank account selected as default payment method
 */
  isDefault: boolean
  /**
   * Bank account params for form
   */
  bankAccountParams: BankAccountInput
  /**
   * Handle what happens after saving the bank account
   */
  afterSave: (saving: boolean, data?: SaveNewBankAccountMutation | null | undefined) => void
  /**
   * Handle the toggle of the edit bank account
   */
  onToggleCollapse: () => void
  containerClass?: string
}

interface RoutingAndAccountNumberModalProps {
  /*
   * Method for showing or hiding modal
   */
  handleModalToggle: (id: string) => void
  id: string
}

/**
 * Modal element that shows image of location of account/routing numbers on a check
 */
function RoutingAndAccountNumberModal ({ handleModalToggle, id }: RoutingAndAccountNumberModalProps): ReactElement {
  const handleToggle = useCallback(() => {
    return handleModalToggle(id)
  }, [id])

  return (
    <Modal id={id} size='default' tracking={{ category: 'OrderDetails', action: 'AddOrderComments' }}>
      <ModalHeader title='Routing and Account Number' onToggle={handleToggle} />
      <ModalBody className='d-flex flex-column'>
        <CmsContent contentContainerName='routing-account-numbers-info' />
      </ModalBody>
      <ModalFooter>
        <div className='float-right d-flex flex-row align-items-center'>
          <Button color='secondary' onClick={handleToggle}>Close</Button>
        </div>
      </ModalFooter>
    </Modal>
  )
}

// ZOD bank account form schema
const bankAccountFormSchema = z.object({
  BankName: z.string().min(1, { message: 'This is a required field' }).trim(),
  RoutingNumber: z.string().min(9, { message: 'Must be 9 digits' }).max(9, { message: 'Must be 9 digits' }).regex(/^[0-9]+$/),
  AccountNumber: z.string().min(4, { message: 'Must be between 4 and 17 digits' }).max(17, { message: 'Must be between 4 and 17 digits' }).regex(/^[0-9]+$/),
  AccountNumberConfirmation: z.string().min(4, { message: 'Must be between 4 and 17 digits' }).max(17, { message: 'Must be between 4 and 17 digits' }).regex(/^[0-9]+$/)
}).refine(data => data.AccountNumber === data.AccountNumberConfirmation, {
  message: 'Account number must match',
  path: ['AccountNumberConfirmation']
})

/**
   * BankAccountForm component
   */
function BankAccountForm ({
  saveType,
  allowedMethods,
  isDefault,
  bankAccountParams,
  afterSave,
  onToggleCollapse,
  containerClass = 'u-flex-grid-col-md-8 offset-md-2'
}: AddBankAccountProps): ReactElement | null {
  // Form inputs for new bank accounts
  const [formInput, setFormInput] = useState<BankAccountInput>(bankAccountParams)

  // Errors from API call
  const [errors, setErrors] = useState<string[]>([])

  // Errors from ZOD Schema validation
  const [formErrors, setFormErrors] = useState({
    BankName: undefined as string | undefined,
    RoutingNumber: undefined as string | undefined,
    AccountNumber: undefined as string | undefined,
    AccountNumberConfirmation: undefined as string | undefined
  })

  const [tokenizeError, setTokenizeError] = useState(false)
  const [saveNewBankAccount, { loading: saving, data }] = useSaveNewBankAccountMutation()
  const [attemptedSave, setAttemptedSave] = useState(false)
  const uniqueId: string = useMemo(() => uuid(), [])
  const [tokenizing, setTokenizing] = useState<boolean>(false)
  const contextQuery = useGetContextMinimalQuery()
  const useClientACHTokenization = useMemo(() => contextQuery.data?.context.UseClientACHTokenization, [contextQuery.data])
  const tokenizeEndpoint = useMemo(() => (useClientACHTokenization == null || useClientACHTokenization) ? 'cardconnect' : 'stullercom', [useClientACHTokenization])
  const tokenType = useMemo(() => (useClientACHTokenization == null || useClientACHTokenization) ? 'CardConnect' : 'SnapPay', [useClientACHTokenization])
  const tokenizePath = useMemo(() => (useClientACHTokenization == null || useClientACHTokenization) ? 'cardsecure/cs' : 'paymentmethods/tokenizebankaccount', [useClientACHTokenization])
  const tokenizeData = useMemo(() => (
    (useClientACHTokenization == null || useClientACHTokenization)
      ? qs.stringify({ action: 'CE', data: `${formInput.RoutingNumber}/${formInput.AccountNumber}` })
      : qs.stringify({ panPad: false, value: `${formInput.RoutingNumber}/${formInput.AccountNumber}` })
  ), [formInput.RoutingNumber, formInput.AccountNumber])
  const [tokenizeAccount] = useTokenizeCardLazyQuery(
    {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only',
      onCompleted: (responseVal) => {
        setTokenizing(false)
        const parsedResponse = qs.parse(responseVal.tokenizeCard)
        const tokenizedAccountNumber = (useClientACHTokenization == null || useClientACHTokenization)
          ? (parsedResponse.action !== 'ER' ? parsedResponse.data : '')
          : responseVal.tokenizeCard
        void handleSave(tokenizedAccountNumber)
      },
      onError: () => {
        setTokenizing(false)
        setTokenizeError(true)
      }
    }
  )

  /**
   * On submit form function that will tokenize the account number and then save the new account.
   */
  const [handleAccountTokenizeAndSave, locked] = useLock(useCallback(async (event: React.SyntheticEvent<HTMLFormElement>) => {
    event.preventDefault()
    setAttemptedSave(false)
    setTokenizeError(false)

    // Validate zod bank account schema
    const validationResult = bankAccountFormSchema.safeParse(formInput)

    if (!validationResult.success) {
      setFormErrors({
        BankName: validationResult.error.errors.find(x => x.path[0] === 'BankName')?.message ?? undefined,
        RoutingNumber: validationResult.error.errors.find(x => x.path[0] === 'RoutingNumber')?.message ?? undefined,
        AccountNumber: validationResult.error.errors.find(x => x.path[0] === 'AccountNumber')?.message ?? undefined,
        AccountNumberConfirmation: validationResult.error.errors.find(x => x.path[0] === 'AccountNumberConfirmation')?.message ?? undefined
      })
    } else {
      setFormErrors({
        BankName: undefined,
        RoutingNumber: undefined,
        AccountNumber: undefined,
        AccountNumberConfirmation: undefined
      })
      setTokenizing(true)
      void tokenizeAccount({
        variables: {
          endpoint: tokenizeEndpoint,
          overridePath: `${tokenizePath}?${tokenizeData as string}`
        }
      })

      try {
        if (data?.saveNewBankAccount?.success === true && (errors == null || errors.length <= 0)) {
          setErrors([])
        }
      } catch (_) {
        setErrors(['Something went wrong'])
      }
    }
  }, [formInput, formErrors, setFormErrors, tokenizeEndpoint, tokenizePath, tokenizeData]))

  const submitIsDisabled = useMemo(() => {
    return locked // || validationFailed
  }, [locked])

  /**
   * Handle Modal Toggle
   */
  const handleModalToggle = useCallback((id: string) => {
    $(`#${id}`).modal('toggle')
  }, [])

  /**
   * Handle setting of data on form input changes
   */
  const handleInputTextChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setFormInput(initialBankAccountData => ({
      ...initialBankAccountData,
      [event.target.name]: event.target.value
    }))
  }, [])

  /**
   * if save new bank account was successful, collapse the form and call the after save callback
   */
  useEffect(() => {
    if (data?.saveNewBankAccount != null && data.saveNewBankAccount.success) {
      onToggleCollapse()
      if (afterSave != null) {
        afterSave(saving, data)
      }
      setFormInput(newBankAccountInput)
    }
  }, [data?.saveNewBankAccount])

  /**
   * handle saving of bank account
   */
  const handleSave = useCallback(async (accountToken: string) => {
    await saveNewBankAccount({
      variables: {
        input: {
          bankAccount: {
            ...formInput,
            AccountNumber: accountToken,
            AccountNumberConfirmation: accountToken,
            AccountNumberUntokenized: formInput.AccountNumber,
            RoutingNumberUntokenized: formInput.RoutingNumber,
            Tokenized: true,
            TokenType: tokenType
          }
        }
      }
    })
    setAttemptedSave(true)
  }, [data, formInput, tokenType])

  return (
    <LoadingIndicator loading={saving || locked || tokenizing}>
      <RoutingAndAccountNumberModal handleModalToggle={handleModalToggle} id={`routingAndAccountLocation-${uniqueId}`} />
      <div className={containerClass}>
        <div className='form-input-group mb-0 pb-sm-3 u-flex-grid-row'>
          <div className='u-flex-grid-col-12 u-flex-grid-col-sm-6 mb-4'>
            <label className='pb-2'>Financial Institution Name (US Only)</label>
            <Input id='BankName' data-test='bank-name' name='BankName' onChange={handleInputTextChange} value={formInput.BankName} invalid={formErrors.BankName != null} />
            <FormError message={formErrors.BankName} />
          </div>
          <div className='u-flex-grid-col-12 u-flex-grid-col-sm-6 mb-4'>
            <label className='pb-2'>Routing Number</label>
            <Input name='RoutingNumber' data-test='routing-number' maxLength={9} onChange={handleInputTextChange} invalid={formErrors.RoutingNumber != null} value={formInput.RoutingNumber} inputMode='numeric' />
            <FormError message={formErrors.RoutingNumber} />
          </div>
        </div>
        <div className='form-input-group u-flex-grid-row'>
          <div className='u-flex-grid-col-12 u-flex-grid-col-sm-6 mb-4 mb-sm-2'>
            <label className='pb-2'>Account Number</label>
            <Input name='AccountNumber' data-test='account-number' maxLength={17} onChange={handleInputTextChange} invalid={formErrors.AccountNumber != null} value={formInput.AccountNumber} inputMode='numeric' />
            <FormError message={formErrors.AccountNumber} />
          </div>
          <div className='u-flex-grid-col-12 u-flex-grid-col-sm-6 mb-0'>
            <label className='pb-2'>Confirm Account Number</label>
            <Input name='AccountNumberConfirmation' data-test='account-number-confirmation' maxLength={17} onChange={handleInputTextChange} invalid={formErrors.AccountNumberConfirmation != null} value={formInput.AccountNumberConfirmation} inputMode='numeric' />
            <FormError message={formErrors.AccountNumberConfirmation} />
          </div>
        </div>
        <div className='u-flex-grid-row flex-wrap align-items-center'>
          <div className='u-flex-grid-col-12 u-flex-grid-col-sm-6 pb-3 pb-sm-0 c-primary-dk-1 font-weight-bold mb-auto'>
            <span onClick={() => handleModalToggle(`routingAndAccountLocation-${uniqueId}`)} role='button'>
              <FontAwesomeIcon icon={faCircleQuestion} className='mr-2 c-primary' size='lg' />
              <span className='primary-link'>
                Routing & account number location
              </span>
            </span>
          </div>
          <div className='u-flex-grid-col-12 u-flex-grid-col-sm-6 mt-5 mb-2'>
            <div className='d-flex justify-content-end align-items-center'>
              <Button tag='a' color='link' className='mr-4' onClick={onToggleCollapse}>Cancel</Button>
              <Button color='primary' type='submit' data-test='save-bank-details-button' onClick={handleAccountTokenizeAndSave} disabled={submitIsDisabled} loading={saving || locked || tokenizing}>Save</Button>
            </div>
          </div>
        </div>
        {data?.saveNewBankAccount != null && !data.saveNewBankAccount.success && attemptedSave &&
          <div className='u-flex-grid-row flex-wrap align-items-center mt-3'>
            <div className='u-flex-grid-col-12'>
              <AddBankAccountAlert saving={saving} saveBankAccountResponse={data?.saveNewBankAccount} />
            </div>
          </div>}
        {tokenizeError &&
          <div className='u-flex-grid-row flex-wrap align-items-center mt-3'>
            <div className='u-flex-grid-col-12'>
              <Alert alertType='error' className='my-4'>The account you are attempting to save is invalid.</Alert>
            </div>
          </div>}
      </div>
    </LoadingIndicator>
  )
}

export {
  BankAccountForm
}
