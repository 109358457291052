import { useEffect, useMemo } from 'react'

export interface BrowserRedirectProps {
  /**
   * Url for the redirect
   */
  url: string
  /**
   * do a location.replace on the url prop instead of just setting location.href
   */
  replace?: boolean
  /**
   * add the hack querystring prop to fix the browser's back button
   */
  addQuerystringHack?: boolean
}

function BrowserRedirect ({ url, replace = false, addQuerystringHack = false }: BrowserRedirectProps): null {
  const fullUrl = useMemo(() => {
    if (!addQuerystringHack) return url

    const urlHasQuerystring = url.includes('?')
    const urlPlusQsSymbol = urlHasQuerystring ? `${url}&` : `${url}?`
    return `${urlPlusQsSymbol}r`
  }, [url, addQuerystringHack])

  useEffect(() => {
    if (replace) {
      window.location.replace(fullUrl)
    } else {
      window.location.href = fullUrl
    }
  }, [fullUrl])

  return null
}

export { BrowserRedirect }
