import { ReactElement } from 'react'
import { LaserEngravedModalValue } from '@web/shared-ui-components'

interface LaserEngravingPreviewProps {
  input: LaserEngravedModalValue
}

function LaserEngravingPreview ({ input }: LaserEngravingPreviewProps): ReactElement {
  const previewImage: string = getPreviewImage(input)

  return (
    <div className='text-center'>
      <img
        className='w-100 h-100'
        src={previewImage}
        alt='Preview'
        style={{ maxWidth: '350px', objectFit: 'contain' }}
        data-test='laser-engraving-preview'
      />

      <div>This image is a preview of the engraving.</div>
      <div>The band seen here is for reference only.</div>
    </div>
  )
}

function getPreviewImage (value: LaserEngravedModalValue): string {
  const font = value.FontFaceOptions.MeteorFont
  const color = `%23${value.EngravingColor.fillColor}`
  const text = encodeURIComponent(value.EngravingText).replace(/\(/g, '%28').replace(/\)/g, '%29') // Encode For Meteor

  return 'https://meteor.stullercloud.com' +
          '?size=350&fmt=smart-alpha&src=(das/68860013?fmt=smart-alpha&flip&size=350&fmt=png)' +
          '&src=(?src=(das/76652856?fmt=png&extendN=0,0,0,0.9280000000000002&size=350)' +
          `&src=(?fmt=png&text=${text}&text.size=21.28&text.font=${font}&text.color=${color}` +
          '&text.insetshadow=2&text.wrap=false&layerblend=in&text.offsety=20&warp.angle=2.2&' +
          'warp.tilt=-60&warp.diameter=290&warp.perspective=20&size=350)&fmt=png)'
}

export { LaserEngravingPreview }
