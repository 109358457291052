import type { ContactInformationFormErrors, ContactInformationFormInput } from '@web/shared-ui-components'
import { ReactElement, useCallback, useMemo } from 'react'
import { Input, FormError } from '@web/shared-ui-components'
import clsx from 'clsx'

type ContactInformationInputId = 'FirstName' | 'LastName' | 'BusinessPhone' | 'MobilePhone' | 'EmailAddress'

interface ContactInformationInputProps {
  label: string
  id: ContactInformationInputId
  formInput: ContactInformationFormInput
  formErrors: ContactInformationFormErrors
  onInputChange?: (formInput: ContactInformationFormInput) => void
  readOnly?: boolean
}

function ContactInformationInput ({ label, id, formInput, formErrors, onInputChange, readOnly = false, ...otherAttributes }: ContactInformationInputProps): ReactElement {
  const handleOnChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    onInputChange?.({ ...formInput, [id]: e.target.value })
  }, [formInput, id, onInputChange])

  const formError = useMemo(() => formErrors[id], [formErrors, id])
  const inputValue = formInput[id] ?? ''

  return (
    <div className='form-input-group'>
      <label className={clsx(readOnly && 'mb-2')}>{label}</label>
      {readOnly
        ? <div className='mb-3'>{inputValue}</div>
        : <Input
            id={id}
            name={id}
            type='text'
            value={inputValue}
            onChange={handleOnChange}
            {...otherAttributes}
          />}
      {formError != null && <FormError message={formError} />}
    </div>
  )
}
export { ContactInformationInput, ContactInformationInputId }
