import { ValidateFunction } from 'ajv'

export interface ValidateData {
  [key: string]: string | number | boolean | string[] | null
}

export interface ValidateErrors {
  [key: string]: string
}

export interface ValidateInfo {
  value: ValidateData
  errors: ValidateErrors | null
}

/*
 * Validates a form object given ajv validate function and data (flat object)
 * Returns new value and errors if any
 */
function validate (validateFunction: ValidateFunction, data: ValidateData): ValidateInfo {
  const value = { ...data }
  const valid = validateFunction(value)
  let errors: ValidateInfo['errors'] = null

  if (!valid) {
    errors = {}
    for (const error of validateFunction.errors ?? []) {
      errors[error.instancePath.slice(1).replace(/\/\d$/, '')] = error.message ?? 'must be valid'
    }
  }

  return { value, errors }
}

export {
  validate
}
