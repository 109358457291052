import { ReactElement, useMemo } from 'react'
import { Currency as CurrencyClass } from '@web/shared-util-currency'
import { useGetContextMinimalQuery } from '@web/shared-data-access-queries'

interface CurrencyProps {
  /*
   * Amount of currency
   */
  amount: number
  currencyCode?: string
  showAsCredit?: boolean
  className?: string
  showLocaleAndCurrency?: boolean
}

/**
 * Formatting currency based on locale data
 */
function Currency ({ amount, currencyCode, showAsCredit = false, className, showLocaleAndCurrency = false, ...otherAttributes }: CurrencyProps): ReactElement | null {
  const { data } = useGetContextMinimalQuery()

  const currencyClass = useMemo(() => new CurrencyClass({
    locale: data?.context?.CurrentCultureName,
    currency: currencyCode ?? data?.context?.Currency?.Currency ?? 'USD'
  })
  , [data, currencyCode])

  if (amount < 0 || showAsCredit) {
    return (
      <>
        <span className={className}>
          ({currencyClass.formatMoney(Math.abs(amount))})
        </span>
        {showLocaleAndCurrency && data?.context.IsDev &&
          <div className='t-6'>Locale: {currencyClass.locale}, Currency: {currencyClass.currency}</div>}
      </>
    )
  }

  return (
    <>
      <span className={className} {...otherAttributes}>
        {currencyClass.formatMoney(amount)}
      </span>
      {showLocaleAndCurrency && data?.context.IsDev &&
        <div className='t-6'>Locale: {currencyClass.locale}, Currency: {currencyClass.currency}</div>}
    </>
  )
}

export { Currency }
