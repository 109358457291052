import { faUniversity } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { CSSProperties, ReactElement, useMemo } from 'react'
import { CardIcon } from './CardIcon'
import clsx from 'clsx'

type PaymentMethodTemplateType = 'normal-inline' | 'condensed-inline'
type PaymentMethodMerchantDisplayType = 'icon' | 'text' | 'none'

interface CardMerchantIconOrTextProps {
  displayCardType?: string | null
  paymentMethodMerchantDisplayType: PaymentMethodMerchantDisplayType
  iconStyle?: CSSProperties
  iconClassname?: string
}

function CardMerchantIconOrText ({ displayCardType, paymentMethodMerchantDisplayType, iconStyle, iconClassname }: CardMerchantIconOrTextProps): ReactElement | null {
  if (displayCardType == null || paymentMethodMerchantDisplayType === 'none') {
    return null
  }

  return (
    <>
      {paymentMethodMerchantDisplayType === 'text'
        ? (<>{displayCardType}&nbsp;</>)
        : (
          <CardIcon
            displayCardType={displayCardType}
            className={iconClassname}
            style={{ paddingRight: '8px', ...iconStyle }}
          />
          )}
    </>
  )
}

interface CardTypeProps {
  template: PaymentMethodTemplateType
  isDebit?: boolean | null
  capitalize: boolean
}

function CardType ({ template, isDebit, capitalize }: CardTypeProps): ReactElement {
  let cardType = ''

  if (isDebit === true) {
    cardType = 'debit'
  } else if (isDebit === false) {
    cardType = 'credit'
  }

  if (template !== 'condensed-inline' || isDebit == null) {
    cardType = clsx(cardType, 'card')
  }

  function capitalizeFirstLetter (string: string): string {
    return string.charAt(0).toUpperCase() + string.slice(1)
  }

  return (
    <>
      {capitalize ? capitalizeFirstLetter(cardType) : cardType}&nbsp;
    </>
  )
}

interface CardSummaryProps {
  creditCardLastFour?: string
  displayCardType?: string | null
  isDebit?: boolean | null
  paymentMethodMerchantDisplayType: PaymentMethodMerchantDisplayType
  iconStyle?: CSSProperties
  iconClassname?: string
  template: PaymentMethodTemplateType
  separatorText: string
}

function CardSummary ({ creditCardLastFour, displayCardType, isDebit, paymentMethodMerchantDisplayType, iconStyle, iconClassname, template, separatorText }: CardSummaryProps): ReactElement {
  return (
    <>
      <CardMerchantIconOrText
        displayCardType={displayCardType}
        paymentMethodMerchantDisplayType={paymentMethodMerchantDisplayType}
        iconStyle={iconStyle}
        iconClassname={iconClassname}
      />
      <CardType
        template={template}
        isDebit={isDebit}
        capitalize={paymentMethodMerchantDisplayType === 'icon' || paymentMethodMerchantDisplayType === 'none'}
      />
      {separatorText} {creditCardLastFour}
    </>
  )
}

interface BankIconOrTextProps {
  template: PaymentMethodTemplateType
  paymentMethodMerchantDisplayType: PaymentMethodMerchantDisplayType
  iconStyle?: CSSProperties
  iconClassname?: string
}

function BankIconOrText ({ template, paymentMethodMerchantDisplayType, iconStyle, iconClassname }: BankIconOrTextProps): ReactElement | null {
  if (paymentMethodMerchantDisplayType === 'icon') {
    return (
      <>
        <FontAwesomeIcon
          icon={faUniversity}
          style={{ paddingRight: '8px', ...iconStyle }}
          className={iconClassname}
          size='lg'
        />
        {template === 'condensed-inline' ? 'Bank' : 'Account'}
      </>
    )
  }

  return (
    <>
      {template === 'condensed-inline' ? 'Bank' : 'Bank account'}
    </>
  )
}

interface BankSummaryProps {
  bankAccountLastFour?: string
  paymentMethodMerchantDisplayType: PaymentMethodMerchantDisplayType
  iconStyle?: CSSProperties
  iconClassname?: string
  template: PaymentMethodTemplateType
  separatorText: string
}

function BankSummary ({ bankAccountLastFour, paymentMethodMerchantDisplayType, iconStyle, iconClassname = 'c-gray-dk-1', template, separatorText }: BankSummaryProps): ReactElement {
  return (
    <>
      <BankIconOrText
        template={template}
        paymentMethodMerchantDisplayType={paymentMethodMerchantDisplayType}
        iconStyle={iconStyle}
        iconClassname={iconClassname}
      />&nbsp;
      {separatorText} {bankAccountLastFour}
    </>
  )
}

interface PaymentMethodSummaryProps {
  creditCardLastFour?: string | null
  displayCardType?: string | null
  isDebit?: boolean | null
  bankAccountLastFour?: string | null
  paymentMethodMerchantDisplayType?: PaymentMethodMerchantDisplayType
  iconStyle?: CSSProperties
  iconClassname?: string
  template?: PaymentMethodTemplateType
}

function PaymentMethodSummary ({
  creditCardLastFour,
  displayCardType,
  isDebit,
  bankAccountLastFour,
  paymentMethodMerchantDisplayType = 'icon',
  iconStyle,
  iconClassname,
  template = 'normal-inline'
}: PaymentMethodSummaryProps): ReactElement | null {
  const separatorText = useMemo(() => template === 'condensed-inline' ? '|' : 'ending in', [template])

  if (creditCardLastFour != null) {
    return (
      <CardSummary
        creditCardLastFour={creditCardLastFour}
        displayCardType={displayCardType}
        isDebit={isDebit}
        paymentMethodMerchantDisplayType={paymentMethodMerchantDisplayType}
        iconStyle={iconStyle}
        iconClassname={iconClassname}
        template={template}
        separatorText={separatorText}
      />
    )
  }

  if (bankAccountLastFour != null) {
    return (
      <BankSummary
        bankAccountLastFour={bankAccountLastFour}
        paymentMethodMerchantDisplayType={paymentMethodMerchantDisplayType}
        iconStyle={iconStyle}
        iconClassname={iconClassname}
        template={template}
        separatorText={separatorText}
      />
    )
  }

  return null
}

export { PaymentMethodSummary, PaymentMethodTemplateType }
