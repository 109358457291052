import { ReactElement, useMemo } from 'react'
import clsx from 'clsx'
import { CardProps, EngravingInfo } from './types'
import { toLower } from 'lodash'
import { faCheck } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { EngravingPreview } from './EngravingPreview'
import uuid from 'react-uuid'
import '@web/styles/ConfigItemSummaryTemplate.less'

interface RenderContentAreaProps {
  engravingInfo: EngravingInfo
}

/**
 * Content area of engraving card
 */
function RenderContentArea ({
  engravingInfo
}: RenderContentAreaProps): ReactElement {
  if (engravingInfo.Type === 'Handwritten Message') {
    return (
      <span className='ml-2 c-primary-dk-1 font-weight-bold'>
        <FontAwesomeIcon className='c-primary-lt-1' size='lg' icon={faCheck} /> File uploaded
      </span>
    )
  }

  if (engravingInfo.Type === 'Pattern Engraving') {
    return (
      <div>
        {engravingInfo.EngravingTexts?.map((engravingText, i) => <div className='engravingPattern' key={i}> <img src={engravingText.RenderedMessageUrl} /> </div>)}
      </div>
    )
  }

  return (
    <div className='d-flex flex-column h-100'>
      {engravingInfo.EngravingTexts?.map((engravingText, i) =>
        <div className='d-flex flex-column mb-2 my-auto' key={i}>
          {engravingText.Location != null && <span className='t-ui-label'> {engravingText.Location} </span>}
          {engravingInfo.EngravingLines > 1 && engravingText.Location == null && <span className='t-ui-label'> Line {i + 1} </span>}
          {engravingText.RenderedMessageUrl != null && engravingText.Text.length < 15 ? <img className='img-responsive' src={engravingText.RenderedMessageUrl} /> : <span> {engravingText.Text} </span>}
        </div>
      )}
    </div>
  )
}

/**
 * Template for the engravings
 */
function EngravingCard ({
  id,
  className,
  config
}: CardProps): ReactElement | null {
  const engravingInfo: EngravingInfo = useMemo(() => config.TemplateData[0], [config])
  const uniqueId = useMemo(() => uuid(), [])
  const classNames = useMemo(() => clsx(
    className,
    'engravingTemplate c-bg-white mb-2 u-padding-all-15 d-flex flex-column'
  ),
  [className])
  // Utilizing classes that are used on the 3C Builder
  const engravingFillColorClassNames = useMemo(() => clsx(
    'engravingFillColor',
    toLower(engravingInfo.Color)
  ), [engravingInfo.Color])

  return (
    <div
      id={id}
      className={classNames}
    >
      <div className='mb-1 d-flex flex-wrap justify-content-between'>
        <span className='templateTitle'>{config.Title}</span>
        {engravingInfo.EngravingLines <= 1 && (
          <EngravingPreview previewInfo={engravingInfo.EngravingTexts} id={uniqueId} />
        )}
        <hr className='c-bg-gray-lt-1 w-100' />
      </div>
      <div className='u-flex-grid-row pr-4 t-leading-tight'>
        <div className='u-flex-grid-col-6 pr-0'>
          <RenderContentArea engravingInfo={engravingInfo} />
        </div>
        <div className='u-flex-grid-col-6 font-weight-light text-right px-0'>
          <div className='mb-2'>{engravingInfo.Type}</div>
          {engravingInfo.EngravingLines > 1 && engravingInfo.EngravingTexts != null && (
            <div className='text-nowrap mb-2'>
              {config.Title !== 'Monogram' && <span>{engravingInfo.EngravingTexts.length} of {engravingInfo.EngravingLines} Lines Engraved</span>}
              {config.Title === 'Monogram' && <span>{engravingInfo.EngravingTexts.length} of {engravingInfo.EngravingLines} Letters Chosen</span>}
            </div>
          )}
          {engravingInfo.Font != null && <div className='mb-2'>{engravingInfo.Font}</div>}
          {engravingInfo.Color != null && (
            <div className='d-flex flex-row justify-content-end align-items-center'>
              <div>
                <div className={engravingFillColorClassNames} />
              </div>
              <div className='mb-n1'>
                {engravingInfo.Color}
              </div>
            </div>
          )}
          {engravingInfo.Color == null && (
            <div className='d-flex flex-row justify-content-end align-items-center'>
              No Color Fill
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export { EngravingCard }
