import { ChangeEventHandler, createContext } from 'react'

interface SelectionBoxContextProps {
  /*
  * Currently selected value for options
  */
  selectedValue?: string | null
  /*
  * Display format
  */
  format?: 'simple' | 'largeContent'
  value: string
  /*
   * Name to group options
   */
  name: string

  /**
   * Is the dropdown open or not
   */
  isOpen?: boolean

  disabled?: boolean

  /*
   * OnChange handler
   */
  onChange: ChangeEventHandler
}

const SelectionBoxContext = createContext<SelectionBoxContextProps>(
  {
    selectedValue: null,
    format: 'simple',
    value: '',
    name: '',
    isOpen: false,
    disabled: false,
    onChange: () => {}
  }
)

export { SelectionBoxContext, SelectionBoxContextProps }
