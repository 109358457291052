import {
  GetProductNavigationParams, GetSearchNavigationQuery, GetArticleResultsQuery,
  NavigationItem,
  SearchResult
} from '@web/shared-data-access-queries'

export type SearchFacetValue = NonNullable<GetProductNavigationParams['facetValues']>[number]
export type SideNavigationSection = NonNullable<GetSearchNavigationQuery['getSearchNavigation']['NavigationSections']>[number]
export type SideNavigationItem = NonNullable<SideNavigationSection>['NavigationItems'][number]
export type CenterStoneColors = GetSearchNavigationQuery['getSearchNavigation']['CenterStoneColors']

export type SelectedFacets = GetSearchNavigationQuery['getSearchNavigation']['SelectedFacets']
export type SelectedFacet = NonNullable<SelectedFacets>[number]

export type BottomNavigationSectionContentId = GetSearchNavigationQuery['getSearchNavigation']['BottomNavigationSectionContentId']
export type UnderCategorySectionContentId = GetSearchNavigationQuery['getSearchNavigation']['UnderCategorySectionContentId']
export type ShowCenterStoneColors = GetSearchNavigationQuery['getSearchNavigation']['ShowCenterStoneColors']
export type ClearAllUrl = GetSearchNavigationQuery['getSearchNavigation']['ClearAllUrl']
export type HideSideNav = GetSearchNavigationQuery['getSearchNavigation']['HideSideNav']

export type SortByFilterOptions = GetSearchNavigationQuery['getSearchNavigation']['SortByFilter']
export type PageSizeFilterOptions = GetSearchNavigationQuery['getSearchNavigation']['PageSizeFilter']

export type Pager = GetSearchNavigationQuery['getSearchNavigation']['Pager']

export type ImageAsset = NonNullable<SideNavigationSection['NavigationItems']>[number]['ImageAsset']

export type Article = NonNullable<GetArticleResultsQuery['getArticleResults']['SearchResults']>[number]

export enum ROUTES {
  Articles = '/search/results/articles',
  Search = '/search/results',
  Browse = '/browse',
  DiscountedProductsInGroup = '/deals/discountedproductsingroup'
}

export enum ContentBlockLocation {
  Top = 0,
  DealsTop = 1,
  Bottom = 2,
  TopRowInResults = 3
}

export type NavigationItemSelectedEventHandler = (item: NavigationItem) => void
export type SearchResultSelectedEventHandler = (item: SearchResult) => void
export enum SearchPresentationContext {
  Search,
  Browse,
  Deals,
  SalesEvent,
  StullerFirstSale
}
