import {
  ReactElement,
  ReactNode,
  useMemo,
  useState
} from 'react'
import clsx from 'clsx'
import { faCheckCircle } from '@fortawesome/pro-solid-svg-icons'
import { faCircle as falCircle } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconDefinition } from '@fortawesome/fontawesome-common-types'
import '@web/styles/Tracker.less'
import { Popover } from './Popover'

type TrackerOptionState = 'past' | 'current' | 'future' | 'complete'

/*
 * Object for Tracker properties
 */
interface TrackerOption {
  /*
  * Text
  */
  text: string | ReactElement

  /*
  * Subtext
  */
  subtext?: string | ReactElement | null

  /*
  * Display state of the current option
  *
  * past: finished
  * complete: successfully finished
  * current: in process
  * future: has not started yet
  * none: does not involve a state.  this is for showing info in place of a step
  */
  state: TrackerOptionState

  showBackground?: boolean

  /*
  * FontAwesome icon
  */
  icon?: IconDefinition

  /*
  * Icon classes
  */
  iconClassName?: string

  /*
  * Classes
  */
  className?: string

  /*
  * Override border style to state
  */
  borderStyle?: 'solid' | 'dashed'

  /*
  * Flex number to adjust sizing of steps
  */
  flexGrow?: number

  tooltipContent?: string | ReactNode
}

/*
 * TrackerStepIcon Props
 */
interface TrackerStepIconProps {
  /*
  * Tracker step state
  */
  state: TrackerOptionState

  /*
  * FontAwesome Icon
  */
  icon?: IconDefinition

  /*
  * Classes
  */
  className?: string
}

/*
 * Tracker Step Icon component
 */
function TrackerStepIcon ({ state, icon, className }: TrackerStepIconProps): ReactElement {
  const faIcon = useMemo(() => icon != null ? icon : (state === 'future' ? falCircle : faCheckCircle), [state])
  const pulsingIconClasses = useMemo(() => state === 'current' && icon == null && clsx('tracker-step-icon tracker-step-icon-pulsing', className), [className])
  const classes = useMemo(() => clsx('tracker-step-icon', className), [className])

  return (
    pulsingIconClasses !== false
      ? <div className={pulsingIconClasses} />
      : <FontAwesomeIcon icon={faIcon} className={classes} />
  )
}

/*
 * TrackerStep Props
 */
interface TrackerStepProps {
  /*
  * Option
  */
  option: TrackerOption

  /**
   * Default: 'horizontal'
   */
  direction?: 'horizontal' | 'vertical'
}

/*
 * Tracker Step component
 */
function TrackerStep ({ option, direction, ...otherAttributes }: TrackerStepProps): ReactElement {
  const [reference, setReference] = useState<HTMLElement | null>(null)
  const [popoverOpen, setPopoverOpen] = useState(false)

  return (
    <>
      <div ref={setReference} className='tracker-step' data-test='tracker-step'>
        <div className='tracker-step-bubble'>
          <div className='d-flex align-items-center'>
            <TrackerStepIcon state={option.state} icon={option.icon} className={option.iconClassName} />
            {option.text}
          </div>
          {direction === 'vertical' && option.subtext != null && (
            <div className='tracker-step-subtext'>{option.subtext}</div>
          )}
        </div>
        {direction === 'horizontal' && option.subtext != null && (
          <div className='tracker-step-subtext' {...otherAttributes}>
            {option.subtext}
          </div>
        )}
      </div>
      {option.tooltipContent != null &&
        <Popover
          placement='top'
          style={{ maxWidth: '300px' }}
          hover
          hoverProps={{ delay: { open: 300 } }}
          offset={10}
          open={popoverOpen}
          onOpenChange={setPopoverOpen}
          reference={reference}
          arrow
        >
          {option.tooltipContent}
        </Popover>}
    </>
  )
}

/*
 * TrackerStepContainer Props
 */
interface TrackerStepContainerProps {
  /*
  * Option
  */
  option: TrackerOption

  /**
   * Default: 'horizontal'
   */
  direction?: 'horizontal' | 'vertical'
}

/*
 * Tracker Step component
 */
function TrackerStepContainer ({ option, direction, ...otherAttributes }: TrackerStepContainerProps): ReactElement {
  const showBackground = useMemo(() => option.showBackground == null || option.showBackground, [option.showBackground])
  const classes = useMemo(() => clsx(
    'tracker-step-container',
    option.className,
    `tracker-step-state-${option.state}`,
    !showBackground && 'tracker-step-state-no-bg',
    `tracker-step-container-border-${option.borderStyle != null ? option.borderStyle : (option.state === 'future' ? 'dashed' : 'solid')}`
  ), [option])
  const flexGrow = useMemo(() => option.flexGrow != null ? { flexGrow: option.flexGrow } : {}, [option])

  return (
    <div className={classes} style={flexGrow}>
      <TrackerStep
        option={option}
        direction={direction}
        {...otherAttributes}
      />
    </div>
  )
}

/*
 * Tracker Props
 */
interface TrackerProps {
  /*
  * Options
  */
  options: TrackerOption[]

  /*
  * Classes
  */
  className?: string
  /**
   * Default: 'horizontal'
   */
  direction?: 'horizontal' | 'vertical'
}

/*
 * Tracker component
 */
function Tracker ({ options, className, direction = 'horizontal', ...otherAttributes }: TrackerProps): ReactElement {
  return (
    <div className={clsx('react-tracker', direction === 'vertical' && 'react-tracker-vertical', className)} {...otherAttributes}>
      {options.map((o, i) => (
        <TrackerStepContainer key={i} option={o} direction={direction} data-test='tracker-step-substep' />
      ))}
    </div>
  )
}

export { TrackerOption, Tracker, TrackerOptionState }
