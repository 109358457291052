import {
  ReactElement,
  ChangeEventHandler,
  forwardRef,
  ForwardedRef
} from 'react'
import clsx from 'clsx'

interface TextareaProps {
  /**
   * Id of the textarea
   */
  id?: string
  /**
   * Name of the textarea
   */
  name?: string
  /**
   * Value of the textarea
   */
  value?: any
  /**
   * Default value of the textarea
   */
  defaultValue?: any
  /**
   * Indicates the textarea is invalid to show fail style
   */
  invalid?: boolean | string
  /**
   * Indicates the textarea is disabled
   */
  disabled?: boolean
  /**
   * Function to call on textarea change
   */
  onChange?: ChangeEventHandler
  /**
   * Class overrides
   */
  className?: string
  /**
   * The visible width of the text control, in average character widths
   */
  cols?: number
  /**
   * The number of visible text lines for the control
   */
  rows?: number
  /**
   * Hint for form autofill feature (see https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/autocomplete#values)
   */
  autoComplete?: string
  /**
   * Automatically focus the form control when the page is loaded
   */
  autoFocus?: boolean
  /**
   * Maximum length (number of characters) of value
   */
  maxLength?: number
  /**
   * Minimum length (number of characters) of value
   */
  minLength?: number
  /**
   * Placeholder text for hint
   */
  placeholder?: string
  /**
   * Indicates textarea is readonly
   */
  readOnly?: boolean
  /**
   * Indicates the textarea is required
   */
  required?: boolean
  /**
   * Title of the textarea as tooltip
   */
  title?: string
  /**
   * Specifies the wrap type for the textarea
   */
  wrap?: 'hard' | 'soft'
  /**
   * All other attributes
   */
  [key: string]: any
}

/*
 * Textarea input component
 */
function Textarea ({
  id,
  name,
  value,
  defaultValue,
  invalid,
  disabled,
  onChange,
  className,
  cols,
  rows,
  autoComplete,
  autoFocus,
  maxLength,
  minLength,
  placeholder,
  readOnly,
  required,
  title,
  wrap,
  ...otherAttributes
}: TextareaProps, ref: ForwardedRef<HTMLTextAreaElement>): ReactElement {
  const classNames = clsx(
    invalid != null && invalid !== false && invalid !== '' && 'validation-input-error',
    className
  )

  return (
    <textarea
      id={id}
      name={name}
      value={value}
      defaultValue={defaultValue}
      disabled={disabled}
      onChange={onChange}
      className={classNames}
      cols={cols}
      rows={rows}
      autoComplete={autoComplete}
      autoFocus={autoFocus}
      maxLength={maxLength}
      minLength={minLength}
      placeholder={placeholder}
      readOnly={readOnly}
      required={required}
      title={title}
      wrap={wrap}
      ref={ref}
      {...otherAttributes}
    />
  )
}

/*
 * Textarea with forwardRef
 */
const TextareaWrapped = forwardRef(Textarea)

export {
  TextareaWrapped as Textarea
}
