import {
  ReactElement,
  useContext,
  useMemo,
  useCallback,
  ChangeEvent
} from 'react'
import { PaymentMethod, PaymentMethodType } from '@web/shared-data-access-queries'
import {
  CmsContent,
  SelectionBox,
  SelectionBoxBodyContent,
  SelectionBoxDropDownContent
} from '@web/shared-ui-components'
import clsx from 'clsx'
import { faCheck } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { EditCard, PaymentMethodActions, PaymentMethodSelectorContext } from '@web/payment-methods'
import { PaymentMethodSelectionBoxOption } from './types'
import { isCardPaymentType, isBankOrCardPaymentType } from './helpers'
import { PaymentMethodSelectionBoxDisplay } from './PaymentMethodSelectionBoxDisplay'

/**
 * PaymentMethodSelectionBox Props
 */
interface PaymentMethodSelectionBoxProps {
  /**
   * Payment method option
   */
  paymentMethodOption: PaymentMethodSelectionBoxOption
  /**
   * Currently selected payment method option
   */
  selectedValue: PaymentMethodSelectionBoxOption | undefined
  /**
   * Remove card handler
   */
  handleRemoveCard?: any
  /**
   * Remove bank handler
   */
  handleRemoveBank?: any

  handleEdit: () => void
  /**
   * Is form currently shown for editing
   */
  isActive: boolean
}

function PaymentMethodSelectionBox ({ paymentMethodOption, handleEdit, isActive, selectedValue, handleRemoveBank, handleRemoveCard }: PaymentMethodSelectionBoxProps): ReactElement {
  const { allowEditing, allowDeleting, paymentMethods, handleSelectedPaymentMethod } = useContext(PaymentMethodSelectorContext)
  const canEdit = useMemo(() => isCardPaymentType(paymentMethodOption.paymentMethod.Method) && allowEditing, [paymentMethodOption, allowEditing])
  const canRemove = useMemo(() => isBankOrCardPaymentType(paymentMethodOption.paymentMethod.Method) && allowDeleting, [paymentMethodOption, allowDeleting])

  const handleInputRadioChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    const paymentMethod = paymentMethods?.filter(x => x.SelectableValue === event.target.value)[0]
    handleSelectedPaymentMethod(paymentMethod)
  }, [paymentMethods, handleSelectedPaymentMethod])

  const removePaymentMethodCallback = useCallback((paymentMethod: PaymentMethod) => {
    if (paymentMethod.Method === PaymentMethodType.Ach) {
      handleRemoveBank(paymentMethod.SelectableValue)
    } else if (paymentMethod.Method === PaymentMethodType.Debitcard || paymentMethod.Method === PaymentMethodType.Creditcard) {
      handleRemoveCard(paymentMethod.SelectableValue)
    }
  }, [])

  return (
    <SelectionBox
      className={clsx((paymentMethodOption.paymentMethod.CreditCard?.IsExpired === true || paymentMethodOption.paymentMethod.OnlyShowInConsultantMode) && 'c-bg-red-lt-2 c-border-red-lt-1 c-red-dk-1',
        paymentMethodOption.paymentMethod.OnlyShowInConsultantMode && 'woe-only')}
      name='paymentMethodChoices'
      selectedValue={selectedValue?.value}
      value={paymentMethodOption.value}
      data-test='payment-method-choice'
      onChange={handleInputRadioChange}
      disabled={paymentMethodOption.disabled}
      isOpen={isActive}
    >
      <SelectionBoxBodyContent>
        <div className='py-4 my-2 d-flex flex-wrap flex-sm-nowrap u-flex-grid-col-12 u-flex-grid-col-md-8 pl-0 align-items-center align-items-sm-end u-regular-font-size'>
          <PaymentMethodSelectionBoxDisplay isSelected={paymentMethodOption.isSelected} paymentMethod={paymentMethodOption.paymentMethod} />
          {paymentMethodOption.paymentMethod.IsSelected &&
            <>
              <span className='d-none d-sm-inline'>|</span>
              <span data-test='default-text-on-saved-card' className={clsx(isBankOrCardPaymentType(paymentMethodOption.paymentMethod.Method) ? 'mt-4' : '', 'd-sm-inline d-none mx-sm-2 font-weight-bold c-primary-dk-2 ml-2')}>
                <FontAwesomeIcon icon={faCheck} className='mr-2 c-primary' />Default
              </span>
            </>}
        </div>
        {paymentMethodOption.paymentMethod.IsSelected &&
          <span className='d-flex u-flex-grid-col-4  u-flex-grid-col-sm-2 pl-0 pt-3 pt-md-0 pb-3 mb-1 u-regular-font-size d-sm-none mx-sm-2 font-weight-bold c-primary-dk-2'>
            <FontAwesomeIcon icon={faCheck} className='mr-2 c-primary' />Default
          </span>}
        <PaymentMethodActions
          removePaymentMethod={() => removePaymentMethodCallback(paymentMethodOption.paymentMethod)}
          editPaymentMethod={handleEdit}
          deleteModalId={paymentMethodOption.paymentMethod.SelectableValue}
          displayName={paymentMethodOption.paymentMethod.DisplayName}
          showEdit={canEdit}
          showRemove={canRemove}
        />
      </SelectionBoxBodyContent>
      {paymentMethodOption.paymentMethod.Method === PaymentMethodType.Cod &&
        <CmsContent contentContainerName='cod-alert-checkout-payment' />}
      {canEdit && paymentMethodOption.paymentMethod.CreditCard != null && isActive &&
        <SelectionBoxDropDownContent>
          <div className='py-4 mt-2 px-2'>
            <EditCard
              currentCard={paymentMethodOption.paymentMethod.CreditCard}
              isDefault={paymentMethodOption.paymentMethod.IsSelected}
              onToggleCollapse={handleEdit}
            />
          </div>
        </SelectionBoxDropDownContent>}
    </SelectionBox>
  )
}

export { PaymentMethodSelectionBox }
