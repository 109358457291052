import { IImageUrls } from "./api/models/image-urls";

export class ClientSideImageUrls {
    Image: IImageUrls;
    SanitizedRawImageUrl: string;
    static SizesAndRecipes: Record<string, string> = {
        RawUrl: "",
        FullSizeUrl: "$standard$",
        Thumb75Url: "$thumb$",
        Thumb125Url: "$125$",
        Thumb150Url: "$geminfo$",
        Thumb165Url: "$list$",
        Thumb100Url: "$100$",
        Thumb40Url: "$tiny$",
        Size640Url: "$xlarge$",
        Size430Url: "$430$",
        Size350Url: "$350$",
        Size250Url: "$250$",
        Size190Url: "$spotlight$",
        ZoomUrl: "$zoom$"
    };
    
    constructor(rawImageUrl: string) {
        this.SanitizedRawImageUrl = rawImageUrl;
        
        this.Image = {
            RawUrl: "",
            FullSizeUrl: "",
            Thumb75Url: "",
            Thumb125Url: "",
            Thumb150Url: "",
            Thumb165Url: "",
            Thumb100Url: "",
            Thumb40Url: "",
            Size640Url: "",
            Size430Url: "",
            Size350Url: "",
            Size250Url: "",
            Size190Url: "",
            ZoomUrl: "",
        }
    
        this.BuildUrl();
    }

    private BuildUrl = () => {
        Object.keys(this.Image).forEach(key => {
            this.Image[key] = this.SanitizedRawImageUrl;
            let querystringSeparator = this.Image[key].indexOf('?') != -1 ? "&" : "?";
            if(ClientSideImageUrls.SizesAndRecipes[key])
                this.Image[key] += `${querystringSeparator}${ClientSideImageUrls.SizesAndRecipes[key]}`;
        });
    }
}