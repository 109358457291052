import {
  ReactElement,
  useEffect,
  useState,
  useMemo,
  ForwardedRef,
  forwardRef,
  useImperativeHandle
} from 'react'
import { GetPaymentMethodsAndOptionsQuery, PaymentMethod, PaymentMethodType, useGetPaymentMethodsAndOptionsLazyQuery } from '@web/shared-data-access-queries'
import {
  LoadingIndicator
} from '@web/shared-ui-components'
import { PaymentMethodSelectionBoxes } from './PaymentMethodSelectionBoxes'
import '@web/styles/CreditCardIcons.less'
import qs from 'qs'
import { PaymentMethodSelectorContext, PaymentMethodSelectorContextProps } from './PaymentMethodSelectorContext'
import { PaymentMethodSource } from './types'
import { PaymentMethodSelectorDropDown } from './PaymentMethodSelectorDropDown'
import { mapPaymentMethod } from './helpers'

interface PaymentMethodSelectorProps {
  /*
   * Display format
   */
  format?: 'selection-boxes' | 'drop-down'

  /*
   * PaymentMethodTypes to display
   */
  allowedMethods: PaymentMethodType[]

  /*
   * Show expired cards or not
   */
  limitExpiredCards?: boolean

  /**
   * Override to not allow add new payment methods
   */
  allowAdding?: boolean

  /**
   * Override to not allow editing of payment methods
   */
  allowEditing?: boolean

  /**
   * Override to not allow deleting of payment methods
   */
  allowDeleting?: boolean

  /**
   * Override to allow setting default while adding/editing
   */
  allowSettingDefault?: boolean

  /*
   * OnChange handler
   */
  onChange: (paymentMethod?: PaymentMethod | null) => void

  onLoggedOutSubmit?: (any) => void

  onPaymentMethodsLoadedAndSelected?: () => void

  /**
   * selected payment override
   */
  selectedPayment?: string | null

  source?: PaymentMethodSource | null

  setAllowedMethods?: (allowedMethods: PaymentMethodType[], allowedMethodsForConsultantOnly: PaymentMethodType[]) => void

  collapseAddCard?: boolean

  collapseAddBankAccount?: boolean
}

/*
 * PaymentMethodSelector component
 */
function PaymentMethodSelector ({
  format = 'selection-boxes',
  allowedMethods,
  limitExpiredCards = true,
  allowAdding = true,
  allowEditing = true,
  allowDeleting = true,
  allowSettingDefault = true,
  onChange,
  onLoggedOutSubmit,
  onPaymentMethodsLoadedAndSelected,
  selectedPayment = null,
  source = null,
  setAllowedMethods,
  collapseAddCard = true,
  collapseAddBankAccount = true
}: PaymentMethodSelectorProps, ref: ForwardedRef<any>
): ReactElement {
  const [paymentMethods, setPaymentMethods] = useState<PaymentMethod[] | null>(null)
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<PaymentMethod | null | undefined>(null)
  const [paymentMethodsLoadedAndSelected, setPaymentMethodsLoadedAndSelected] = useState<boolean>(false)
  const overridePath = useMemo(() => `paymentmethods/getpaymentmethods?${qs.stringify({ desiredMethods: allowedMethods, limitExpiredCards, source }) as string}`, [allowedMethods, limitExpiredCards, source])
  const [fetchPaymentMethodsAndOptions, { loading, data, refetch }] = useGetPaymentMethodsAndOptionsLazyQuery({
    variables: {
      overridePath
    }
  })

  const setupPaymentMethodsAndSelect = (data?: GetPaymentMethodsAndOptionsQuery): void => {
    if (data?.getPaymentMethodsAndOptions != null) {
      const mappedPaymentMethods: PaymentMethod[] = data.getPaymentMethodsAndOptions.PaymentMethods.map(d => mapPaymentMethod(d))

      setPaymentMethods(mappedPaymentMethods)
      if (selectedPayment == null) {
        setSelectedPaymentMethod(mappedPaymentMethods.filter(x => x.IsSelected)[0])
      } else if (selectedPayment != null) {
        const paymentMethod = mappedPaymentMethods?.filter(x => x.SelectableValue === selectedPayment)[0]
        setSelectedPaymentMethod(paymentMethod)
        onChange(paymentMethod)
      }

      setPaymentMethodsLoadedAndSelected(true)

      if (setAllowedMethods != null) {
        setAllowedMethods(data.getPaymentMethodsAndOptions.AllowedMethods, data.getPaymentMethodsAndOptions.AllowedMethodsForConsultantOnly)
      }
    }
  }

  // This useEffect is to help with the timing of the checkout step validation in knockout
  useEffect(() => {
    if (onPaymentMethodsLoadedAndSelected != null && paymentMethodsLoadedAndSelected) {
      onPaymentMethodsLoadedAndSelected()
    }
  }, [paymentMethodsLoadedAndSelected, onPaymentMethodsLoadedAndSelected])

  const refetchPaymentMethodsAndOptions = async (): Promise<GetPaymentMethodsAndOptionsQuery> => {
    return await refetch({
      overridePath
    }).then((res) => {
      const { data } = res

      setupPaymentMethodsAndSelect(data)

      return data
    })
  }

  const paymentMethodSelectorContext: PaymentMethodSelectorContextProps = useMemo(() => (
    {
      allowedMethods: data?.getPaymentMethodsAndOptions?.AllowedMethods ?? [],
      allowedMethodsForConsultantOnly: data?.getPaymentMethodsAndOptions?.AllowedMethodsForConsultantOnly ?? [],
      allowedCreditCardTypes: data?.getPaymentMethodsAndOptions?.AllowedCreditCardTypes ?? [],
      paymentMethods,
      userCanUseTerms: data?.getPaymentMethodsAndOptions?.UserCanUseTerms ?? false,
      userCanUseCOD: data?.getPaymentMethodsAndOptions?.UserCanUseCOD ?? false,
      userCanUseCreditCards: data?.getPaymentMethodsAndOptions?.UserCanUseCreditCards ?? false,
      userCanUseDebitCards: data?.getPaymentMethodsAndOptions?.UserCanUseDebitCards ?? false,
      userCanUseACH: data?.getPaymentMethodsAndOptions?.UserCanUseACH ?? false,
      isLoggedIn: data?.getPaymentMethodsAndOptions?.IsLoggedIn ?? false,
      allowAdding,
      allowEditing,
      allowDeleting,
      allowSettingDefault,
      handleSelectedPaymentMethod: setSelectedPaymentMethod,
      refetchPaymentMethods: async () => {
        const data = await refetchPaymentMethodsAndOptions()

        return data?.getPaymentMethodsAndOptions?.PaymentMethods ?? []
      },
      onLoggedOutSubmit,
      collapseAddCard,
      collapseAddBankAccount
    }), [paymentMethods, data?.getPaymentMethodsAndOptions, collapseAddCard, collapseAddBankAccount])

  useEffect(() => {
    onChange(selectedPaymentMethod)
  }, [selectedPaymentMethod])

  useEffect(() => {
    void fetchPaymentMethodsAndOptions().then((res) => {
      const { data } = res

      setupPaymentMethodsAndSelect(data)
    })
  }, [])

  useImperativeHandle(ref, () => ({
    refetchPaymentMethods () {
      void setupPaymentMethodsAndSelect()
    }
  }))

  return (
    <LoadingIndicator loading={loading}>
      <PaymentMethodSelectorContext.Provider value={paymentMethodSelectorContext}>
        <div className='react-credit-cards' data-test='all-payment-methods-container'>
          {paymentMethods != null && (
            <>
              {format === 'selection-boxes'
                ? (
                  <PaymentMethodSelectionBoxes
                    selectedValue={selectedPaymentMethod}
                  />
                  )
                : (
                  <PaymentMethodSelectorDropDown
                    paymentMethods={paymentMethods}
                    selectedPaymentMethod={selectedPaymentMethod}
                    handleSelectedPaymentMethod={setSelectedPaymentMethod}
                  />
                  )}
            </>
          )}
        </div>
      </PaymentMethodSelectorContext.Provider>
    </LoadingIndicator>
  )
}

const PaymentMethodSelectorWrapped = forwardRef(PaymentMethodSelector)
export {
  PaymentMethodSelectorWrapped as PaymentMethodSelector
}
