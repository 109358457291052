import { faCaretDown, faCaretUp } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ReactElement, ReactNode, useMemo } from 'react'

interface SortableProps<TSortProperty> {
  property: TSortProperty
  selectedProperty: TSortProperty
  sortDescending?: boolean
  handleSort: (sortProperty: TSortProperty, sortDescending: boolean) => void
  children: ReactNode
}

function Sortable<TSortProperty> ({ property, selectedProperty, sortDescending = false, handleSort, children }: SortableProps<TSortProperty>): ReactElement {
  const icon = useMemo(() => sortDescending ? faCaretDown : faCaretUp, [sortDescending])
  const isSelected = useMemo(() => property === selectedProperty, [property, selectedProperty])

  return (
    <span className='u-cursor-pointer d-inline-flex align-items-center' onClick={() => handleSort(property, !sortDescending)}>
      <span className='u-underline'>
        {children}
      </span>
      {isSelected &&
        <FontAwesomeIcon className='ml-2' icon={icon} />}
    </span>
  )
}

export { Sortable }
