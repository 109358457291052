import { ReactElement, ReactNode, useState } from 'react'
import { Button, Popover } from '@web/shared-ui-components'

interface CopyToClipboardProps {
  children: ReactNode
  copyText: string
  tooltipDuration?: number
  className?: string
  onCopy?: () => void
  onTooltipHide?: () => void
}

function CopyToClipboard ({ children, copyText, tooltipDuration = 2000, onCopy, onTooltipHide, className, ...otherAttributes }: CopyToClipboardProps): ReactElement {
  const [showTooltip, setShowTooltip] = useState(false)
  const [referenceElement, setReferenceElement] = useState<HTMLElement | null>(null)

  async function handleCopy (): Promise<void> {
    await navigator.clipboard.writeText(copyText).then(() => {
      onCopy?.()
      setShowTooltip(true)
      setTimeout(() => {
        setShowTooltip(false)
        onTooltipHide?.()
      }, tooltipDuration)
    })
  }

  return (
    <>
      <Button tag='a' color='link' ref={setReferenceElement} onClick={handleCopy} className={className} {...otherAttributes}>
        {children}
      </Button>
      <Popover
        className='px-4 py-3'
        textWrap={false}
        placement='top'
        offset={5}
        open={showTooltip}
        reference={referenceElement}
      >
        Copied to clipboard
      </Popover>
    </>
  )
}

export { CopyToClipboard }
