import { ReactElement, useCallback } from 'react'
import { useReactiveVar } from '@apollo/client'
import { svgsVar, useSvg } from '@web/shared-util-hooks'
import { TemplateLoader } from '@web/shared-ui-components'

function EmbeddedSvgLoader (): ReactElement | null {
  const [, updateSvg] = useSvg()
  const svgs = useReactiveVar(svgsVar)

  const onLoaded = useCallback((url: string): void => {
    updateSvg(url, true)
  }, [])

  return (
    <>
      {svgs.map((svg, index) => (
        <TemplateLoader
          key={svg.url}
          id={`svg-template-${index}`}
          url={svg.url}
          onLoaded={onLoaded}
        />))}
    </>
  )
}

export {
  EmbeddedSvgLoader
}
