import { useCallback, useMemo, useState, useEffect } from 'react'
import { useCreateEvent } from './useEvent'

interface ViewPort {
  /*
   * Current window width
   */
  windowSize: number
  isViewportXSmall: boolean
  isViewportSmallAndDown: boolean
  isViewportSmall: boolean
  isViewportSmallAndUp: boolean
  isViewportMediumAndDown: boolean
  isViewportMedium: boolean
  isViewportMediumAndUp: boolean
  isViewportLargeAndDown: boolean
  isViewportLarge: boolean
  isViewportLargeAndUp: boolean
  isViewportXLarge: boolean
}

function useViewport (): ViewPort {
  const [windowSize, setWindowSize] = useState(window.innerWidth)
  const { addEventListener, removeEventListener } = useCreateEvent('resize')

  const handleSetWindowSize = useCallback(() => setWindowSize(window.innerWidth), [window.innerWidth])

  useEffect(() => {
    addEventListener(handleSetWindowSize)
    return () => removeEventListener(handleSetWindowSize)
  }, [])

  const isViewportXSmall = useMemo(() => windowSize < 576, [windowSize])

  const isViewportSmallAndDown = useMemo(() => windowSize < 768, [windowSize])
  const isViewportSmall = useMemo(() => windowSize >= 576 && windowSize < 768, [windowSize])
  const isViewportSmallAndUp = useMemo(() => windowSize >= 576, [windowSize])

  const isViewportMediumAndDown = useMemo(() => windowSize < 992, [windowSize])
  const isViewportMedium = useMemo(() => windowSize >= 768 && windowSize < 992, [windowSize])
  const isViewportMediumAndUp = useMemo(() => windowSize >= 768, [windowSize])

  const isViewportLargeAndDown = useMemo(() => windowSize < 1200, [windowSize])
  const isViewportLarge = useMemo(() => windowSize >= 992 && windowSize < 1200, [windowSize])
  const isViewportLargeAndUp = useMemo(() => windowSize >= 992, [windowSize])

  const isViewportXLarge = useMemo(() => windowSize >= 1200, [windowSize])

  /*   const isViewportLarge = useMemo(() => windowSize >= 1200, [windowSize])
  const isViewportMedium = useMemo(() => windowSize <= 1199 && windowSize >= 992, [windowSize])
  const isViewportSmall = useMemo(() => windowSize <= 991 && windowSize >= 768, [windowSize])
  const isViewportSmallAndDown = useMemo(() => windowSize <= 991, [windowSize])
  const isViewportXSmall = useMemo(() => windowSize <= 767, [windowSize]) */

  return {
    windowSize,
    isViewportXSmall,
    isViewportSmallAndDown,
    isViewportSmall,
    isViewportSmallAndUp,
    isViewportMediumAndDown,
    isViewportMedium,
    isViewportMediumAndUp,
    isViewportLargeAndDown,
    isViewportLarge,
    isViewportLargeAndUp,
    isViewportXLarge
  }
}

export { useViewport }
