import { useState } from 'react'

// Using type because you can't make tuple from interface
type DownloadFileResponse = [
  (url: string) => void,
  { loading: boolean
    error: string }
]

/**
 * Hook providing a callback function to download a file from a given url
 */
function useDownloadFile (): DownloadFileResponse {
  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<string>('')

  const handleDownloadFile = (url: string): void => {
    let filename = ''
    setLoading(true)
    fetch(url)
      .then(async (res) => {
        // Get the filename from the result header
        if (!res.ok) {
          setError(`Failed to download file from url: ${url}`)
          return
        }
        filename = res.headers.get('Content-Disposition')?.split('filename=')[1] ?? 'download'
        return await res.blob()
      })
      .then((blob) => {
        if (blob == null) {
          return
        }

        // Create a temporary link and click it to download the file
        setLoading(false)
        const href = window.URL.createObjectURL(blob)
        const link = document.createElement('a')
        link.href = href
        link.setAttribute('download', filename)
        document.body.appendChild(link)
        link.click()

        // Cleanup created objects
        document.body.removeChild(link)
        window.URL.revokeObjectURL(href)
      })
      .catch((err) => {
        setLoading(false)
        setError(err)
      })
  }

  return [handleDownloadFile, { loading, error }]
}

export { useDownloadFile }
