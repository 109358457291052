import {
  ReactElement, useEffect
} from 'react'
import { SaveNewBankAccountResponse } from '@web/shared-data-access-queries'
import { Alert } from '@web/shared-ui-components'
import { faCheckCircle } from '@fortawesome/pro-solid-svg-icons'
import { useToggle } from '@web/shared-util-hooks'

interface AddBankAccountAlertProps {
  /**
   * Is a new bank account being saved
   */
  saving: boolean
  /**
   * Response from adding a new Bank Account
   */
  saveBankAccountResponse?: SaveNewBankAccountResponse | null
}

/**
 * AddBankAccountAlert component
 */
function AddBankAccountAlert ({ saving, saveBankAccountResponse }: AddBankAccountAlertProps): ReactElement | null {
  const [show, toggleShow, setShow] = useToggle(false)

  useEffect(() => {
    setShow(!saving && saveBankAccountResponse != null)
  }, [saving, saveBankAccountResponse])

  if (saving || saveBankAccountResponse == null) {
    return null
  }

  if (!saveBankAccountResponse.success && saveBankAccountResponse.errors != null) {
    return (
      <Alert show={show} onToggle={toggleShow} alertType='error' className='my-4'>{saveBankAccountResponse.errors}</Alert>
    )
  }

  return (
    <Alert show={show} onToggle={toggleShow} alertType='success' icon={faCheckCircle} className='my-4' data-test='bank-details-saved-successful-alert'>Your bank account has been saved!</Alert>
  )
}

export {
  AddBankAccountAlert
}
