import { ReactElement, useMemo, useCallback } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleXmark } from '@fortawesome/pro-solid-svg-icons'
import { FileUploadPreviewCardImage } from './FileUploadPreviewCardImage'
import clsx from 'clsx'
import { ViewType } from '.'

interface FileUploadPreviewCardProps {
  file: File
  viewType?: ViewType
  onRemove?: (file: File) => void
}

function FileUploadPreviewCard ({ file, viewType = 'DEFAULT', onRemove }: FileUploadPreviewCardProps): ReactElement {
  const fileNameWithoutExtension = useMemo(() => file.name.split('.').shift() ?? '', [file.name])
  const fileType = useMemo(() => file.name.split('.').pop()?.toLocaleUpperCase() ?? '', [file.name])

  const fileSize = useMemo(() => {
    const kiloBytes = file.size / 1024

    if (kiloBytes < 1024) {
      return kiloBytes.toFixed(1) + ' KB'
    }

    const megaBytes = kiloBytes / 1024

    return megaBytes.toFixed(1) + ' MB'
  }, [file.size])

  const handleRemove = useCallback(() => {
    if (onRemove != null) {
      onRemove(file)
    }
  }, [onRemove])

  return (
    <>
      {/* X-Small */}
      <div className={clsx(viewType !== 'SINGLE_COMPACT' && 'd-block d-sm-none')} data-test='xsmall-screen-preview'>
        <div className={clsx(viewType !== 'SINGLE_COMPACT' && 'mb-2', 'c-bg-white d-flex  overflow-hidden u-border-all u-border-radius-medium')} style={{ minHeight: '65px' }}>
          <div className='align-items-center d-flex flex-fill' style={{ minWidth: 0 }}>
            <div>
              <div className='mx-3 align-items-center d-flex justify-content-center' style={{ height: '45px', width: '45px' }}>
                <FileUploadPreviewCardImage file={file} />
              </div>
            </div>

            <div className='align-self-center text-truncate'>
              <div className='f-roboto-light text-truncate'>{fileNameWithoutExtension}</div>
              <div className='t-5' data-test='file-type'>{fileType} • {fileSize}</div>
            </div>
          </div>

          <div className='align-items-center c-bg-gray-lt-3 d-flex justify-content-center ml-5 p-3'>
            <FontAwesomeIcon
              icon={faCircleXmark}
              className='c-gray u-cursor-pointer'
              style={{ fontSize: '20px' }}
              onClick={handleRemove}
            />
          </div>
        </div>
      </div>

      {/* Small & Up */}
      {viewType !== 'SINGLE_COMPACT' &&
        <div className='d-none d-sm-block' style={{ height: '248px' }} data-test='small-and-up-screen-preview'>
          <div className='mb-2 overflow-hidden u-border-all u-border-radius-medium' style={{ height: '248px' }}>
            <div className='c-bg-white d-flex align-items-center justify-content-center' style={{ height: '190px' }}>
              <FileUploadPreviewCardImage file={file} />
            </div>

            <div className='c-bg-gray-lt-3' style={{ height: '58px' }}>
              <div className='d-flex align-items-center p-3'>
                <div className='flex-fill text-truncate'>
                  <div className='f-roboto-light text-truncate'>{fileNameWithoutExtension}</div>
                  <div className='t-5' data-test='file-type'>{fileType} • {fileSize}</div>
                </div>

                <div className='pl-4'>
                  <FontAwesomeIcon
                    icon={faCircleXmark}
                    className='c-gray u-cursor-pointer'
                    style={{ fontSize: '20px' }}
                    onClick={handleRemove}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>}
    </>
  )
}

export { FileUploadPreviewCard }
