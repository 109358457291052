import { ReactElement } from 'react'
import { CenterStoneColor } from '@web/shared-data-access-queries'
import { Link } from 'react-router-dom'
import clsx from 'clsx'
import '@web/styles/CenterStoneColorOptions.less'

export interface CenterStoneColorWithUrl extends CenterStoneColor {
  url: string
}
interface CenterStoneColorOptionsProps {
  centerStoneColors: CenterStoneColorWithUrl[]
}

interface ColorOptionProps extends CenterStoneColor {
  onClick?: (name: string) => void
  isNoneSelected: boolean
}

interface LinkColorOptionProps extends ColorOptionProps {
  url: string
}

function ColorOption ({ onClick, isNoneSelected, HexCode, IsSelected, Name }: ColorOptionProps): ReactElement {
  const isSelected = IsSelected || (isNoneSelected && !hasValue(HexCode))
  const style = {
    backgroundColor: `#${!hasValue(HexCode) || HexCode === 'diamond' ? 'FFFFFF' : HexCode}`
  }

  return (
    <div
      className={clsx('d-flex align-items-center justify-content-center colorChoiceBox', isSelected && 'selectedOutline')}
      style={style}
      onClick={(onClick != null) ? () => onClick(Name) : undefined}
    >
      {!hasValue(HexCode) ? <div className='color-option-none'>None</div> : null}
    </div>
  )
}

function LinkColorOption ({ url, ...props }: LinkColorOptionProps): ReactElement {
  return (
    <Link to={url}>
      <ColorOption {...props} />
    </Link>
  )
}

function CenterStoneColorOptions ({ centerStoneColors }: CenterStoneColorOptionsProps): ReactElement {
  const isNoneSelected = !centerStoneColors.some(x => x.IsSelected)
  const options = centerStoneColors.map((x, index) => (<LinkColorOption key={index} isNoneSelected={isNoneSelected} {...x} />))

  return (
    <div className='react-center-stone-color-options border paddingLarge containerFix topMarginLarge'>
      <div className='bold bottomMargin'>
        Center Stone Preview:
      </div>
      <div className='d-block d-lg-flex'>
        <div className='d-flex flex-wrap flex-lg-nowrap' data-test='center-stone-preview-container'>
          {options}
        </div>
        <div className='colorNote note'>
          Please note that colors are for visual purposes only and are not indicative of the actual gems available to fit.
        </div>
      </div>
    </div>
  )
}

function hasValue (stringVal?: string | null): stringVal is string {
  return stringVal !== undefined && stringVal !== null && stringVal !== ''
}

export { CenterStoneColorOptions }
