import { MouseEventHandler, ReactElement, ReactNode, useMemo } from 'react'
import { ShowMore } from './ShowMore'

interface ItemRowDetailProps {
  /*
   * Id of element
   */
  id?: string
  /*
   * Serial Number of element
   */
  serialNumber?: number | null
  /*
   * Children of element
   */
  children?: ReactNode
  /*
   * Sku for item
   */
  sku: string
  /*
   * Is item a configuration
   */
  isConfig?: boolean
  /*
   * Description for item
   */
  description: string
  /*
   * Image source url for item
   */
  imageSrc: string
  /*
   * Item url
   */
  url: string
  /*
   * Additional class name(s) to give to the containing element
   */
  className?: string
  /*
   * Additional class name(s) to give to the detail container element
   */
  detailContainerClassName?: string
  /**
   * Handler for image click
   */
  handleImageClick?: MouseEventHandler
  /**
   * Handler for item click
   */
  handleItemClick?: MouseEventHandler
}

/*
 * Component to display a item with extra details in a row style
 */
function ItemRowDetail ({
  id,
  serialNumber,
  children,
  sku,
  isConfig = false,
  description,
  imageSrc,
  url,
  className,
  detailContainerClassName = 'u-flex-grid-col-12 u-flex-grid-col-sm-7 u-flex-grid-col-md-5 mb-2',
  handleImageClick,
  handleItemClick
}: ItemRowDetailProps): ReactElement {
  const itemLink = useMemo(() => serialNumber != null ? `Serial # ${serialNumber}` : `${sku}${isConfig ? ' (Customized)' : ''}`, [serialNumber, sku, isConfig])

  return (
    <div id={id} className={className}>
      <div className='u-flex-grid-row justify-content-between'>
        <div className={detailContainerClassName}>
          <div className='u-flex-grid-row no-gutters'>
            <>
              <a onClick={handleImageClick} className='u-flex-grid-col-4' href={url} target='_blank' rel='noreferrer'>
                <img className='p-1 c-bg-white' style={{ width: '60px', height: '60px' }} src={imageSrc} alt={itemLink} />
              </a>
              <div className='u-flex-grid-col-8'>
                <a onClick={handleItemClick} className='primary-link' href={url} target='_blank' rel='noreferrer'>{itemLink}</a>
                <ShowMore displayText={description} />
              </div>
            </>
          </div>
        </div>
        {children}
      </div>
    </div>
  )
}

export { ItemRowDetail }
