import { trackEcommerceEvent } from '@web/shared-util-google-analytics'
import { ReactElement, useEffect } from 'react'
import { Link } from 'react-router-dom'

interface NoProductResultsProps {
  query: string
  correctedQuery: string
  articlesCount: number
  isBrowseish: boolean
}

/**
 * This function will return a react element containing the 'No Results' card to be shown when no products are found
 */
function NoProductResults ({ query, correctedQuery, articlesCount, isBrowseish }: NoProductResultsProps): ReactElement {
  // track zero results
  useEffect(() => {
    trackEcommerceEvent('view_item_list', [])
  }, [query])

  return (
    <div>
      {isBrowseish
        ? (
          <>
            <div>No results were found for your filter selections </div>
            <div className='pt-3 mb-5'>
              <div className='f4 f700 mb-2 c-gray-dk-4'>Suggestions</div>
              <ul>
                <li className='mb-1'>Remove some filters to broaden your search.</li>
                <li className='mb-1'>Make additional product filter selections.</li>
                <li className='mb-1'>Clear all filters to view every available product.</li>
              </ul>
            </div>
          </>
          )
        : (
          <>
            <div className='mb-5'>
              <div className='f4 f700 mb-2 c-gray-dk-4'>Search Tips</div>
              <ul className='mb-3'>
                {correctedQuery !== '' &&
                  <li className='mb-1'>Try searching for <a href={`/search/results/?query=${correctedQuery}`}>{correctedQuery}</a> instead.</li>}
                {articlesCount > 0 &&
                  <li className='hideInShowCase mb-1'><Link to={`/search/results/articles?query=${query}`}>Search for <span className='bold'>"{query}"</span> in <span className='bold'>Articles</span> instead.</Link></li>}
                <li className='mb-1'>Make sure all words are spelled correctly.</li>
                <li className='mb-1'>Broaden your search by using more general terms.</li>
                <li className='mb-1'>Simplify your search by using fewer keywords.</li>
              </ul>
              <div>Need help finding something? Call <a className='primary-link' href='tel:+18008777777'>800-877-7777</a>.</div>
            </div>
          </>
          )}
    </div>
  )
}

export { NoProductResults }
