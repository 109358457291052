import { ReactElement, ReactNode, useState } from 'react'
import { faTriangleExclamation } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import clsx from 'clsx'
import { Popover } from '@web/shared-ui-components'

interface ProgressBarBlockProps {
  errorCardIndices: number[]
  completedCount: number
  errorCount: number
  errorToolTipContent: ReactNode
  index: number
}

function ProgressBarBlock ({
  errorCardIndices,
  completedCount,
  errorCount,
  errorToolTipContent,
  index
}: ProgressBarBlockProps): ReactElement {
  const [errorCardPopoverRef, setErrorCardPopoverRef] = useState<HTMLElement | null>(null)
  const [errorCardPopoverOpen, setErrorCardPopoverOpen] = useState<boolean>(false)
  const isErrorCard = errorCardIndices.includes(index)

  return (
    <div
      ref={isErrorCard ? setErrorCardPopoverRef : null}
      className={clsx(
        'u-border-radius-medium flex-grow-1 u-padding-vertical-20',
        index < completedCount && 'c-bg-stullerpay-primary',
        isErrorCard && 'c-bg-red',
        !isErrorCard && index >= completedCount + errorCount && 'c-bg-gray-lt-2'
      )}
    >
      {isErrorCard && errorToolTipContent !== undefined &&
        <Popover
          className='border c-border-gray-lt-1 c-bg-white u-border-radius-medium p-4'
          placement='top'
          hover
          open={errorCardPopoverOpen}
          onOpenChange={setErrorCardPopoverOpen}
          reference={errorCardPopoverRef}
        >
          {errorToolTipContent}
        </Popover>}
    </div>
  )
}

interface ProgressBarProps {
  totalCount: number
  completedCount: number
  errorCount: number
  label: string
  errorToolTipContent?: ReactNode
}

function ProgressBar ({
  totalCount,
  completedCount,
  errorCount,
  label,
  errorToolTipContent
}: ProgressBarProps): ReactElement {
  const incompleteCount = totalCount - completedCount

  const displayBlocks = (): ReactElement => {
    const errorCardIndices: number[] = []

    // Get which indices to display error cards
    if (errorCount > 0) {
      for (let i = completedCount; i < completedCount + errorCount; i++) {
        errorCardIndices.push(i)
      }
    }
    return (
      <>{Array(totalCount).fill(null).map((block, i) =>
        <ProgressBarBlock
          completedCount={completedCount}
          errorCount={errorCount}
          errorToolTipContent={errorToolTipContent}
          errorCardIndices={errorCardIndices}
          index={i}
          key={i}
        />)}
      </>
    )
  }

  return (
    <div className='p-3 font-weight-bold c-bg-gray-lt-3 u-border-radius-medium'>
      <div className='d-flex justify-content-between'>
        <div>{completedCount} {label}{completedCount !== 1 && 's'} Made</div>
        <div>{incompleteCount} {label}{incompleteCount !== 1 && 's'} Left</div>
      </div>
      <div style={{ gap: '3px' }} className='d-flex mt-2'>
        {displayBlocks()}
      </div>
      {errorCount !== 0 &&
        <div className='mt-3 d-flex'>
          <FontAwesomeIcon icon={faTriangleExclamation} className='c-red' size='lg' />
          <div className='c-red-dk-2 ml-1'>{errorCount} Past Due {label}{errorCount !== 1 && 's'}</div>
        </div>}
    </div>
  )
}

export { ProgressBar }
