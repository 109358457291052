import {
  CSSProperties,
  ReactElement,
  ReactNode,
  useMemo
} from 'react'
import clsx from 'clsx'

interface RadioGroupProps {
  /*
   * Options for the select as children
   */
  children: ReactNode
  /*
   * Class overrides
   */
  className?: string
  /*
   * List layout
   */
  layout?: 'vertical' | 'horizontal' | undefined
  /*
   * Class override for container
   */
  containerClassName?: string
  style?: CSSProperties
}

/*
 * Group for the radio component
 */
function RadioGroup ({
  className,
  containerClassName,
  layout,
  children,
  style
}: RadioGroupProps): ReactElement {
  const containerClassNames = clsx(
    containerClassName,
    'form-input-group'
  )

  const listClassNames = useMemo(() => clsx(
    className,
    (layout != null) ? `u-${layout}-list` : '',
    'u-list-style-none'
  ), [layout])

  return (
    <div className={containerClassNames}>
      <ul className={listClassNames} style={style}>
        {children}
      </ul>
    </div>
  )
}

export { RadioGroup as RadioGroupOld }
