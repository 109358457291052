import { ProductSearchAttribute } from '@web/shared-data-access-queries'
import { ReactElement, useMemo, useState } from 'react'
import { usePopper } from 'react-popper'
import { SameInteractionEventHandler, useSameInteractionEvents } from '@web/shared-util-hooks'
import { SearchImageAsset } from '@web/search-feature'
import { merge } from 'lodash'

type PopperOptions = Parameters<typeof usePopper>['2']

export interface SearchAttributeIconProps extends ProductSearchAttribute {
  onSelect?: SameInteractionEventHandler
  onUnselect?: SameInteractionEventHandler
  onSearchAttrSelected?: () => void
  className?: string
  tooltipOptions?: PopperOptions
}

function SearchAttributeIcon ({
  onSelect, onUnselect, onSearchAttrSelected, AttributeImageAsset, AttributeValue, ItemDetailsUrl, className,
  tooltipOptions
}: SearchAttributeIconProps): ReactElement {
  const defaultPopperOptions: PopperOptions = {
    placement: 'top',
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, 6]
        }
      }
    ]
  }
  const popperOptions = useMemo<PopperOptions>(() => merge(defaultPopperOptions, tooltipOptions), [tooltipOptions])
  const [referenceElement, setReferenceElement] = useState<HTMLAnchorElement|null>(null)
  const [popperElement, setPopperElement] = useState<HTMLDivElement|null>(null)
  const { styles, attributes } = usePopper(referenceElement, popperElement, popperOptions)
  const [showTooltip, setShowTooltip] = useState(false)

  const handleSelect = useMemo<SameInteractionEventHandler>(() => (e) => {
    setShowTooltip(true)
    onSelect?.(e)
  }, [onSelect])
  const handleUnselect = useMemo<SameInteractionEventHandler>(() => (e) => {
    setShowTooltip(false)
    onUnselect?.(e)
  }, [onUnselect])
  const selectEvents = useSameInteractionEvents(handleSelect, handleUnselect)

  return (
    <div className={className}>
      <a
        href={ItemDetailsUrl}
        key={AttributeValue}
        ref={setReferenceElement}
        className='h-100 d-inline-block'
        onClick={onSearchAttrSelected}
        {...selectEvents}
        aria-label={AttributeValue}
      >
        {AttributeImageAsset != null && <SearchImageAsset AttributeValue={AttributeValue} {...AttributeImageAsset} className='h-100 w-100' />}
        {AttributeImageAsset == null && AttributeValue}
      </a>

      {showTooltip &&
        <div
          className='search-attribute-icon-tooltip c-bg-white border c-border-gray-lt-1 u-border-radius-medium px-3 py-2'
          ref={setPopperElement} style={styles.popper} {...attributes.popper}
        >
          {AttributeValue}
          <div id='arrow' data-popper-arrow style={styles.arrow} {...attributes.arrow} />
        </div>}
    </div>
  )
}

export { SearchAttributeIcon }
