import { ReactElement, useMemo, useEffect, useCallback } from 'react'
import { SideNavigationSection, SectionHeaderContent } from '@web/product-navigation-feature'
import { LoadingIndicator } from '../../shared/ui/components/LoadingIndicator'
import { NavigationSection, NavigationItem } from '@web/shared-data-access-queries'
import {
  SideNavigationSection as SideNavigationSectionType,
  BottomNavigationSectionContentId,
  UnderCategorySectionContentId,
  ShowCenterStoneColors,
  CenterStoneColors,
  ClearAllUrl,
  HideSideNav
} from '@web/product-navigation-types'
import '@web/styles/SideNav.less'
import { Collapse } from '@web/shared-ui-components'
import { useSvg, useToggle, useViewport } from '@web/shared-util-hooks'
import { useUserContext } from '@web/shared-data-access-context'

export interface SideNavProps{
  navigationSections?: SideNavigationSectionType[] | null
  bottomNavigationSectionContentId: BottomNavigationSectionContentId
  underCategorySectionContentId?: UnderCategorySectionContentId
  showCenterStoneColors: ShowCenterStoneColors
  centerStoneColors: CenterStoneColors
  clearAllUrl: ClearAllUrl
  hideSideNav: HideSideNav
  sideNavIsDisabled?: boolean
}

function SideNav ({
  navigationSections,
  bottomNavigationSectionContentId,
  underCategorySectionContentId,
  showCenterStoneColors,
  centerStoneColors,
  clearAllUrl,
  hideSideNav,
  sideNavIsDisabled = false
}: SideNavProps): ReactElement {
  // This will be where we loop through the facet sections and pull out all the "embedded svgs" to add to the svgsVar via the useSvg hook
  const { context } = useUserContext()
  const isAuthenticated: boolean = context?.LoginId != null
  const isInRetailMode: boolean = context?.IsInShowcase ?? false
  const isSterling: boolean = context?.IsSterling ?? false
  const [addSvg] = useSvg()
  const [dealsSectionCollapsed, onDealsSectionCollapseToggle] = useToggle(false)

  const dealSection: NavigationSection | null = useMemo(() => {
    return navigationSections?.find(navSection => navSection.TopLabel != null && navSection.TopLabel?.toLowerCase() === 'stuller deals') ?? null
  }, [navigationSections])

  const showDealsSection = useMemo(() => (dealSection != null && (!dealSection.LoggedInOnly || (isAuthenticated && !isInRetailMode)) && !(dealSection.HideForSterling && isSterling)), [dealSection, isAuthenticated, isInRetailMode])
  const handleDealsClick = useCallback((item: NavigationItem) => {
    TrackNavigationClick('SideBar-Deals', item.TrackingName.replace("'", "\\'") + ' - Deals')
  }, [])

  const { isViewportSmallAndDown } = useViewport()

  useEffect(() => {
    const svgMap = new Map()
    navigationSections?.forEach(section => {
      section.NavigationItems.forEach(item => {
        const imageAsset = item.ImageAsset
        if (imageAsset != null && imageAsset.type === 'Embedded SVG') {
          if (!svgMap.has(imageAsset.source)) {
            svgMap.set(imageAsset.source, true)
            addSvg(imageAsset.source)
          }
        }
      })
    })
  }, [navigationSections])

  return (
    <>
      {sideNavIsDisabled && <div className='disabled-overlay h-100 w-100 c-bg-white position-absolute' />}
      <div className='react-navigation-section'>
        <LoadingIndicator loading={context == null}>
          {context != null && navigationSections != null &&
            <div className='mb-3 sideNavigation' data-test='side-navigation'>
              {navigationSections.map((section, index) => (
                <SideNavigationSection
                  key={section.Title + section.SearchField}
                  section={section} position={index}
                  underCategorySectionContentId={underCategorySectionContentId}
                  isSmallAndDownViewport={isViewportSmallAndDown}
                />
              ))}
              {showDealsSection &&
                <div className='menuSection deals'>
                  <SectionHeaderContent
                    sectionTitle={dealSection?.TopLabel ?? ''}
                    sectionTitleCount={dealSection?.NavigationItems.length ?? 0}
                    collapsed={dealsSectionCollapsed}
                    onCollapseToggle={onDealsSectionCollapseToggle}
                  />
                  <Collapse isCollapsed={dealsSectionCollapsed}>
                    <ul className='sectionLinks'>
                      {dealSection?.NavigationItems.map((item, index) => (
                        <li key={index}>
                          <a
                            className='noSub navigation-item-display' href={item.LinkUrl}
                            onClick={e => handleDealsClick(item)}
                          >
                            {item.LinkText}
                          </a>
                        </li>
                      ))}
                    </ul>
                  </Collapse>
                </div>}
            </div>}
        </LoadingIndicator>
      </div>
    </>
  )
}

export { SideNav }
