import React, { ReactNode } from 'react'

function useChildrenProps (children: ReactNode, props: any): Array<React.ReactElement<any, string | React.JSXElementConstructor<any>>> | null | undefined {
  return React.Children.map(children, child => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, {
        ...child.props,
        ...props
      })
    }
  })
}

export {
  useChildrenProps
}
