import {
  forwardRef,
  ReactElement,
  useContext,
  useState
} from 'react'
import clsx from 'clsx'
import { CarouselContext, CarouselContextType } from './CarouselContext'
import { Button } from '../Button'

export interface CarouselIndicatorsProps extends Omit<CarouselContextType, 'sliding'> {
  /**
     * Id of element
     */
  id?: string
  /**
     * Additional class name(s) to give to the containing element
     */
  className?: string
  /**
     * Additional class name(s) to give to the individual indicators
     */
  indicatorClassName?: string
  /**
     * Indicates to use the dark variant
     */
  dark?: boolean
}

export interface CarouselIndicatorProps extends Pick<CarouselContextType, 'index' | 'onChange'> {
  /**
     * Additional class name(s) to give to the containing element
     */
  className?: string
  /**
     * Index of the carousel item
     */
  itemIndex: number
}

/**
   * A single carousel indicator button
   */
function CarouselIndicator ({
  className,
  itemIndex,
  index,
  onChange
}: CarouselIndicatorProps): ReactElement {
  const { sliding } = useContext(CarouselContext)
  const activeInit = useState(itemIndex === index && 'active')
  const classNames = clsx(activeInit, className)

  // Handle clicking the button to update the current index
  function handleClick (): void {
    if (!sliding) {
      onChange?.(itemIndex)
    }
  }

  return (
    <Button
      type='button'
      className={classNames}
      data-bs-target=''
      data-bs-slide-to={itemIndex}
      onClick={handleClick}
    />
  )
}

/**
   * Carousel indicators component
   */
const CarouselIndicators = forwardRef<HTMLDivElement, CarouselIndicatorsProps>(({
  className,
  index: indexIn,
  length: lengthIn,
  onChange: onChangeIn,
  indicatorClassName,
  dark = false,
  ...otherAttributes
}, ref) => {
  const { index: indexContext, length: lengthContext, onChange: onChangeContext } = useContext(CarouselContext)
  const index = indexIn ?? indexContext
  const length = lengthIn ?? lengthContext
  const onChange = onChangeIn ?? onChangeContext
  const classNames = clsx(
    'carousel-indicators',
    dark && 'carousel-dark',
    className
  )
  const buttonArray = Array.from(Array(length))

  // Do not render if length or index is null to prevent errors
  if (length == null || length < 1 || index == null) {
    return null
  }

  return (
    <div
      className={classNames}
      ref={ref}
      {...otherAttributes}
    >
      {buttonArray.map((_, i) => (
        <CarouselIndicator
          key={i}
          className={indicatorClassName}
          itemIndex={i}
          index={index}
          onChange={onChange}
        />))}
    </div>
  )
})

export {
  CarouselIndicators
}
