import { ReactElement, useCallback } from 'react'
import { FileUploadPreviewCard, ViewType } from '@web/shared-ui-components'
import clsx from 'clsx'

interface FileUploadPreviewProps {
  files: File[]
  previewColClasses: string
  viewType?: ViewType
  onRemoveFile: (file: File) => void
}

function FileUploadPreview ({ files, previewColClasses, viewType = 'DEFAULT', onRemoveFile }: FileUploadPreviewProps): ReactElement {
  const handleRemoveFile = useCallback((file: File) => {
    onRemoveFile(file)
  }, [onRemoveFile])

  return (
    <div className={clsx(viewType !== 'SINGLE_COMPACT' && 'mb-3')}>
      {/* X-Small */}
      <div className={clsx(viewType !== 'SINGLE_COMPACT' && 'd-block d-sm-none')}>
        {files.map((file, index) => <FileUploadPreviewCard key={index} file={file} onRemove={handleRemoveFile} viewType={viewType} />)}
      </div>

      {/* Small & Up */}
      {viewType !== 'SINGLE_COMPACT' &&
        <div className='d-none d-sm-flex u-flex-grid-row px-3'>
          {files.map((file, index) => (
            <div
              key={index}
              className={clsx(previewColClasses, 'mb-3 px-2')}
            >
              <FileUploadPreviewCard file={file} onRemove={handleRemoveFile} />
            </div>
          ))}
        </div>}
    </div>
  )
}

export { FileUploadPreview }
