import '@web/styles/StoneShapeFilterOptions.less'
import { ReactElement, useMemo, useCallback } from 'react'
import { LoadingIndicator, FancySelect, FancySelectOption } from '@web/shared-ui-components'
import { useGetLookupsByTypeQuery } from '@web/shared-data-access-queries'
import clsx from 'clsx'

interface StoneShapeFilterOptionsProps {
  /**
   * list of shape options
   */
  options: Array<string | null>
  /**
   * selected shape value
   */
  selectedValue: string | null
  /**
   * handler to select shape
   */
  onSelect: (value: string | null) => void
  /**
   * flag to show full list of shapes
   */
  fullList?: boolean
  /**
   * flag to show if the select is invalid
   */
  invalid?: boolean
}

/**
 * Stone shape filter options component to show shape options in dropdown with icons and labels
 */
function StoneShapeFilterOptions ({ options: shapeOptions, selectedValue: selectedShape, onSelect, fullList = false, invalid = false }: StoneShapeFilterOptionsProps): ReactElement | null {
  const { loading, data } = useGetLookupsByTypeQuery({
    variables: {
      type: 'Stone-Shape-Icons'
    }
  })

  const handleSelect = useCallback((newValue: FancySelectOption | null, actionMeta) => {
    if (onSelect != null && newValue != null && actionMeta.action === 'select-option') {
      onSelect(newValue.value)
    }
  }, [onSelect])

  const options = useMemo<FancySelectOption[]>(() => {
    const dropdownOptions: FancySelectOption[] = []
    const stoneShapeLookups = data?.getLookupsByType ?? []
    stoneShapeLookups.forEach((lookup) => {
      const isSelected = lookup.Value === selectedShape

      if ((!fullList && shapeOptions.includes(lookup.Value) && lookup.Value2?.toLocaleLowerCase() === 'true') || (fullList && shapeOptions.includes(lookup.Code))) {
        dropdownOptions.push(
          {
            value: lookup.Value,
            label: (
              <div className='d-flex align-items-center'>
                <div className='stone-shape-icon'>
                  <svg
                    className={clsx('embedded-svg mr-2', isSelected && 'c-fill-primary-subdued-lt-1 c-primary-dk-1')}
                    style={{ height: '30px', width: '30px' }}
                  >
                    <use xlinkHref={`#${lookup.Code}`} />
                  </svg>
                </div>
                <div>{lookup.Value4 ?? lookup.Value}</div>
              </div>
            )
          })
      }
    })
    return dropdownOptions
  }, [shapeOptions, data])

  const value = useMemo(() => {
    return options.filter(x => x.value === selectedShape)?.[0] ?? null
  }, [options, selectedShape])

  return (
    <div>
      <LoadingIndicator loading={loading}>
        <div className='stone-shape-icon-container' data-test='stone-shape-icon-container'>
          <FancySelect
            options={options}
            value={value}
            isSearchable={false}
            color='secondary'
            onChange={handleSelect}
            menuPortalTarget={null}
            optionBorders
            invalid={invalid}
            styles={({ menuPortal: base => ({ ...base, zIndex: 9999 }) })}
          />
        </div>
      </LoadingIndicator>
    </div>
  )
}

export {
  StoneShapeFilterOptions
}
