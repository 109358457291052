import {
  ReactElement,
  ReactNode
} from 'react'

interface EmptyResultProps {
  /*
   * Message to display for empty results
   */
  children?: ReactNode
}

/*
 * EmptyResult component
 */
function EmptyResult ({
  children, ...otherAttributes
}: EmptyResultProps): ReactElement {
  return (
    <div className='u-empty-results' {...otherAttributes}>
      {children}
    </div>
  )
}

export {
  EmptyResult
}
