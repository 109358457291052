import { ReactElement, useMemo } from 'react'
import clsx from 'clsx'
import { ImageAsset } from '@web/product-navigation-types'
import '@web/styles/NavigationItemIcon.less'

interface NavigationItemIconProps {
  /*
  * The icon object to be displayed next to a facet value
  */
  imageAsset?: ImageAsset | null

  /*
  * The flag for whether or not the facet value has already been selected by the user
  */
  isSelected: boolean | undefined

  /*
  * The layout governing the display of all the facet value options
  */
  layout?: String | null
}

function NavigationItemIcon ({
  imageAsset = null,
  isSelected = false,
  layout = null
}: NavigationItemIconProps): ReactElement | null {
  const showIcon = useMemo(() => imageAsset !== null && layout !== 'text_only', [imageAsset, layout])

  const isSrcImageType = useMemo(() => imageAsset?.type === 'URL' || imageAsset?.type === 'DAS', [imageAsset?.type])

  const iconCssClass = useMemo(() => {
    switch (layout) {
      case 'text_and_image_sm':
        return 'small'
      case 'text_and_image_md':
        return 'medium'
      case 'text_and_image_lg':
        return 'large'
      case 'image_grid':
        return 'grid'
      default:
        return 'small'
    }
  }, [layout])

  const isSourceSvgLoaded = true

  if (!showIcon) {
    return null
  }

  return (
    <div className={clsx('navigation-item-icon-container', isSelected && 'selected')}>
      <span className={clsx('navigation-item-icon', iconCssClass)}>
        {isSrcImageType && (
          <img src={imageAsset?.source} />
        )}
        {imageAsset?.type === 'Embedded SVG' && isSourceSvgLoaded && (
          <svg className='embedded-svg'>
            <use xlinkHref={`#${imageAsset.id}`} />
          </svg>
        )}
      </span>
    </div>
  )
}

export { NavigationItemIcon }
