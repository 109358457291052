import { ReactNode, forwardRef } from 'react'
import clsx from 'clsx'

export interface CarouselInnerProps {
  /**
   * Id of element
   */
  id?: string
  /**
   * Additional class name(s) to give to the containing element
   */
  className?: string
  /**
   * Children of element
   */
  children?: ReactNode
}

/**
 * Carousel inner component to hold the carousel items
 */
const CarouselInner = forwardRef<HTMLDivElement, CarouselInnerProps>(({
  className,
  children,
  ...otherAttributes
}, ref) => {
  const classNames = clsx('carousel-inner', className)

  return (
    <div
      className={classNames}
      ref={ref}
      {...otherAttributes}
    >
      {children}
    </div>
  )
})

export {
  CarouselInner
}
