import { ReactElement, ComponentType, ReactNode } from 'react'
import { render } from 'react-dom'
import { client, ApolloProvider } from '@web/shared-data-access-apollo'
import { BrowserRouter } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import { TrackComponentLoad } from './TrackComponentLoad'
import 'react-toastify/dist/ReactToastify.css'
import '@web/styles/App.less'
import { CmsContentProvider } from './cms-content'
import { UserContextProvider } from './context/UserContextProvider'
interface AppProps {
  /**
   * Children of element
   */
  children: ReactNode
  /**
   * Please do not track component load af
   */
  skipTracking?: boolean
}

/*
 * Main React app entry component
 */
function App ({ children, skipTracking = false }: AppProps): ReactElement {
  return (
    <BrowserRouter>
      <ApolloProvider client={client}>
        <UserContextProvider>
          <CmsContentProvider>
            <TrackComponentLoad skipTracking={skipTracking}>
              <ToastContainer
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                icon={false}
              />
              {children}
            </TrackComponentLoad>
          </CmsContentProvider>
        </UserContextProvider>
      </ApolloProvider>
    </BrowserRouter>
  )
}

/*
 * Starts a react app with Stuller.com defaults
 */
function reactApp (component: ComponentType, querySelector: string, appProps: Omit<AppProps, 'children'> = {}): void {
  const Component = component

  document.addEventListener('DOMContentLoaded', () => {
    const node = document.querySelector(querySelector)

    render(<App {...appProps}><Component /></App>, node)
  })
}

export {
  App,
  reactApp
}
