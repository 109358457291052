import {
  ReactElement, useCallback, useContext, useMemo, useState
} from 'react'
import { PaymentMethodType, SaveNewBankAccountResponse, SaveNewBankAccountMutation, BankAccountInput } from '@web/shared-data-access-queries'
import { useToggle } from '@web/shared-util-hooks'
import { AddPaymentMethodWrapper, AddPaymentMethodHeader, BankAccountForm, AddBankAccountAlert, PaymentMethodSelectorContext } from '@web/payment-methods'
import { AddPaymentMethodBody } from '../AddPaymentMethodBody'
import { faUniversity } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

interface AddBankAccountProps {
  /**
   * Allowed payment methods for when adding a new bank account
   */
  newBankAccountAllowedMethods: PaymentMethodType[]
}

const newBankAccountParams: BankAccountInput = {
  AccountNumber: '',
  AccountNumberConfirmation: '',
  BankName: '',
  RoutingNumber: '',
  AccountNumberUntokenized: '',
  RoutingNumberUntokenized: '',
  TokenType: ''
}

/**
 * AddBankAccount component
 */
function AddBankAccount ({ newBankAccountAllowedMethods }: AddBankAccountProps): ReactElement | null {
  const { allowedMethods, refetchPaymentMethods, collapseAddBankAccount } = useContext(PaymentMethodSelectorContext)
  const [isCollapsed, onToggleCollapse] = useToggle(collapseAddBankAccount)
  const canAddNewBankAccount = useMemo(() => allowedMethods.some(x => newBankAccountAllowedMethods.map(y => y.toString()).includes(x)), [allowedMethods, newBankAccountAllowedMethods])
  const [isSaving, setIsSaving] = useState<boolean>(false)
  const [saveBankAccountResponse, setSaveBankAccountResponse] = useState<SaveNewBankAccountResponse | null | undefined>(null)

  const afterSave = useCallback((saving: boolean, data?: SaveNewBankAccountMutation | null | undefined) => {
    setIsSaving(saving)
    setSaveBankAccountResponse(data?.saveNewBankAccount)
    if (data?.saveNewBankAccount != null && data.saveNewBankAccount.success) {
      void refetchPaymentMethods()
    }
  }, [refetchPaymentMethods])

  if (!canAddNewBankAccount) {
    return null
  }

  return (
    <>
      <AddPaymentMethodWrapper isCollapsed={isCollapsed} onToggleCollapse={onToggleCollapse} allowedMethods={newBankAccountAllowedMethods}>
        <AddPaymentMethodHeader>
          <div data-test='add-a-new-bank-account-button'>
            Add New Bank Account
          </div>
          <div>
            <FontAwesomeIcon icon={faUniversity} className='mr-2 c-gray-dk-1 mr-3 ml-2 pl-4' size='2x' style={{ maxHeight: '25px' }} />
          </div>
        </AddPaymentMethodHeader>

        <AddPaymentMethodBody>
          <BankAccountForm saveType='new' bankAccountParams={newBankAccountParams} isDefault={false} allowedMethods={newBankAccountAllowedMethods} onToggleCollapse={onToggleCollapse} afterSave={afterSave} />
        </AddPaymentMethodBody>
      </AddPaymentMethodWrapper>
      <AddBankAccountAlert saving={isSaving} saveBankAccountResponse={saveBankAccountResponse} />
    </>
  )
}

export {
  AddBankAccount
}
