import { ReactElement, HTMLAttributes } from 'react'
import { Badge, IndividualBadgeProps } from '@web/shared-ui-components'

interface NewBadgeProps extends HTMLAttributes<HTMLElement>, IndividualBadgeProps {}

function NewBadge ({ ...otherAttributes }: NewBadgeProps): ReactElement {
  return (
    <Badge
      pill
      badgeColor='new'
      {...otherAttributes}
    >
      New
    </Badge>
  )
}

export { NewBadge }
