import { GetContextMinimalQuery } from '@web/shared-data-access-queries'

interface TrackCurrentPageViewProps {
  /**
   * Current path that we are viewing
   */
  pathName: string
  /**
   * The context object used to pass information to GA
   */
  context: GetContextMinimalQuery['context']
}

/**
 * Track Page view on load for GA
 */
function TrackCurrentPageView ({ pathName, context }: TrackCurrentPageViewProps): void {
  if (pathName == null || window.__gaTracker == null) {
    console.error('Error: TrackCurrentPageView')
    return
  }

  window.__gaTracker('set', 'page', pathName)

  const pageView = {
    page: pathName,
    dimension1: context.LoginId != null ? 'Yes' : 'No',
    dimension2: context.IsInShowcase ? 'Yes' : 'No',
    dimension3: context.UserMemberId ?? 'None',
    dimension4: context.SessionId ?? 'None',
    dimension7: context.SearchEngine ?? undefined
  }

  if (pageView.dimension7 == null) delete pageView.dimension7

  window.__gaTracker('send', 'pageview', pageView)
}

export { TrackCurrentPageView }
