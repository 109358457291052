import { cardTypesAndIcons } from 'libs/paymentmethods/util/constants'
import { CSSProperties, ReactElement, useMemo } from 'react'

interface CardIconProps {
  displayCardType: string | undefined | null
  width?: number
  className?: string
  style?: CSSProperties
}

function CardIcon ({ displayCardType, width = 30, className, style }: CardIconProps): ReactElement | null {
  const matchingCardType = useMemo(() => cardTypesAndIcons.filter(cardType => cardType.type.toLowerCase() === displayCardType?.toLowerCase()), [displayCardType])
  const iconStyles = useMemo(() => {
    return { width: `${width}px` }
  }, [width])

  return (
    <span className={className} style={style}>
      {displayCardType == null || matchingCardType == null || matchingCardType.length === 0
        ? (
          <img src='https://assets.stullercloud.com/das/107933808.svg' alt='N/A' style={iconStyles} />
          )
        : (
          <img src={matchingCardType[0].icon} alt={matchingCardType[0].type} style={iconStyles} />
          )}
    </span>
  )
}

export { CardIcon }
