import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { CategoryOrSearchViewModel } from '@web/shared-data-access-queries'
import { faMagnifyingGlass } from '@fortawesome/pro-solid-svg-icons'
import { Button } from '@web/shared-ui-components'

import { ReactElement } from 'react'

interface SuggestedSearchesProps {
  terms: CategoryOrSearchViewModel['RelatedSearchTerms']
}

function SuggestedSearches ({ terms }: SuggestedSearchesProps): ReactElement|null {
  return (
    <div className='mb-5'>
      <h2>Suggested Searches</h2>
      <div className='d-flex flex-row flex-wrap' style={{ gap: '1.25rem' }}>
        {terms?.map((term, index) => (
          <Button
            key={index}
            tag='a'
            color='secondary'
            rounded
            className='text-capitalize'
            href={`/search/results/?suggested=True&query=${term?.Suggestion ?? ''}&suggestionRank=${term?.Rank ?? ''}`}
          >
            <FontAwesomeIcon className='mr-2 t-4 align-middle' icon={faMagnifyingGlass} />
            {term?.Suggestion ?? ''}
          </Button>
        ))}
      </div>
    </div>
  )
}

export { SuggestedSearches }
