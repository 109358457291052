import { useContext } from 'react'
import { CmsContentContext } from './CmsContentContext'
import { ICmsContentContext } from './types'

/**
 * Retrieves current CMS content context variables for use when asynchronously loading CMS content (via child `CmsContent` components)
 */
function useCmsContentContext (): ICmsContentContext {
  return useContext(CmsContentContext)
}

export { useCmsContentContext }
