import { createContext } from 'react'

interface AddPaymentMethodContextProps {
  /**
   * Is the dropdown collapsed or not
   */
  isCollapsed: boolean
  /**
    * Callback to call when the dropdown is toggled
    */
  onToggleCollapse?: () => void
}

const AddPaymentMethodContext = createContext<AddPaymentMethodContextProps>({ isCollapsed: true })

export { AddPaymentMethodContext, AddPaymentMethodContextProps }
