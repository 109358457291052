import { ApolloClient, ApolloProvider } from '@apollo/client'
import { RestLink } from 'apollo-link-rest'
import { cache } from './cache'
import { env } from '@web/env'

enum CustomApolloErrorNames {
  JSON_RESPONSE_TYPE_ERR = 'JSON_RESPONSE_TYPE_ERR'
}

async function responseTransformer (response: Response, typeName: string): Promise<any> {
  if (typeName === 'String' || typeName === 'String!') {
    return response?.text != null ? (await response.text()) : null
  } else if (typeName === 'Int' || typeName === 'Int!') {
    return response?.text != null ? +(await response.text()) : null
  } else {
    try {
      return await response?.json()
    } catch (err) {
      const jsonError = new Error('Expected json but response could not be read')
      jsonError.name = CustomApolloErrorNames.JSON_RESPONSE_TYPE_ERR

      throw jsonError
    }
  }
}

const baseHeaders = {
  Accept: 'application/json, text/plain, */*',
  'Content-Type': 'application/json'
}

// Setup rest endpoints
const restLink = new RestLink({
  endpoints: {
    stullercom: {
      uri: '/',
      responseTransformer
    },
    cardconnect: {
      uri: env.CARD_CONNECT_URL,
      responseTransformer
    }
  },
  customFetch: async (uri, options) => {
    const url = typeof uri === 'string' ? uri : uri.url
    if (url.startsWith(env.CARD_CONNECT_URL)) {
      options.credentials = 'same-origin'
      options.headers = { ...baseHeaders }
    } else {
      options.credentials = 'include'
      options.headers = {
        ...baseHeaders,
        'X-Requested-With': 'XMLHttpRequest'
      }
    }

    return await fetch(uri, options)
  }
})

// Setup Apollo client
const client = new ApolloClient({
  cache,
  link: restLink,
  connectToDevTools: process.env.NODE_ENV === 'development'
})

export {
  client,
  ApolloProvider,
  CustomApolloErrorNames
}
