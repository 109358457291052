import { ReactElement } from 'react'
import { ErrorPage, ErrorPageProps } from './ErrorPage'

export interface NotFoundPageProps extends Partial<Omit<ErrorPageProps, 'header' | 'sideImage' | 'isFormAllowed' >> { }

function NotFoundPage (props: NotFoundPageProps): ReactElement {
  return (
    <ErrorPage
      {...props}
      isFormAllowed={false}
      header='Whoops! (404 Error)'
      sideImage={{
        src: 'https://assets.stullercloud.com/web/static/i/server/404.png',
        alt: 'Page Not Found'
      }}
    >
      <p>We're Sorry...</p>
      <p>The page you are looking for doesn't seem to exist.</p>
    </ErrorPage>
  )
}

export { NotFoundPage }
