import { ReactElement, useCallback, Dispatch, SetStateAction, RefObject, useRef, MouseEventHandler, MouseEvent } from 'react'
import { NavigationItem, NavigationSection, SearchPresentationContext } from '@web/shared-data-access-queries'
import { NavigationItemRouterLink, CategoryPopoutModel, NavigationItemRowData } from '@web/product-navigation-feature'
import { useNavigationItemTracking } from '@web/product-navigation-hooks'
import clsx from 'clsx'

interface NavigationItemRowWrapperProps {
  /*
   * The NavigationSection that houses this component
   */
  section: NavigationSection
  /*
   * Array of NavigationItems to be looped over and displayed
   */
  navigationItem: NavigationItem

  /*
   * setter for the props of the CategoryPopout
   */
  toggleCatPopoutProps: (value: CategoryPopoutModel | null) => void

  /*
  * Delegate-ception
  */
  setRef: Dispatch<SetStateAction<RefObject<HTMLLIElement> | null>> | null

  /*
  * Holds the value of the active nav item row
  */
  activeItemRowTrackingName: string | null

  onClick?: MouseEventHandler

  hasCategoryPopout: boolean
}

function largeItemClass (section: NavigationSection, item: NavigationItem, activeItemRowTrackingName: string): string {
  const qTipMegaMenu = item.Children !== null &&
  item.Children != null && item.Children.length > 0 &&
  section.TopLabel != null &&
  section.TopLabel !== '' &&
  item.LinkText.toLowerCase() !== 'show all'

  const showAll = item.LinkText.toLowerCase().startsWith('show all')

  const activeCatLink = activeItemRowTrackingName === item.TrackingName

  return clsx('visible-md visible-lg', qTipMegaMenu && 'qTipMegaMenu', showAll && 'showAll', activeCatLink && 'activeCatLink')
}

function NavigationItemRowWrapper ({
  section,
  navigationItem,
  toggleCatPopoutProps,
  setRef,
  activeItemRowTrackingName,
  onClick,
  hasCategoryPopout
}: NavigationItemRowWrapperProps): ReactElement {
  const rowWrapperRef = useRef<HTMLLIElement>(null)

  const { trackClickEvent } = useNavigationItemTracking()

  const isCategorySectionTitle = section.Title.toLocaleLowerCase() === 'category'

  const trackingInputType = isCategorySectionTitle
    ? 'link'
    : section.IsMultiSelect ? 'checkbox' : 'toggle_link'

  const handleClick = useCallback((e: MouseEvent) => {
    trackClickEvent('filter',
      section.Title,
      trackingInputType,
      navigationItem.TrackingName,
      isCategorySectionTitle ? undefined : !navigationItem.IsSelected
    )
    onClick?.(e)
  }, [trackClickEvent, navigationItem.IsSelected, SearchPresentationContext, section, trackingInputType, navigationItem.TrackingName])

  const handleMouseEnter = useCallback(() => {
    if (setRef != null) {
      setRef(rowWrapperRef)
    }
    let catPopoutProps = null as CategoryPopoutModel | null
    if (navigationItem.Children != null && navigationItem.Children.length > 0) {
      catPopoutProps = {
        section: section,
        navigationItem: navigationItem,
        grandchildCatMax: navigationItem.Children.length <= 15 ? 5 : 3,
        childrenCats: navigationItem.Children
      }
    }
    toggleCatPopoutProps(catPopoutProps)
  }, [navigationItem, section])

  const isWindowWidthAtMax991 = (): boolean => {
    return window.matchMedia('(max-width: 991px)').matches
  }

  return (
    <>
      {isWindowWidthAtMax991()
        ? (
          <li className={clsx('visible-xs visible-sm hideForNonResponsive', activeItemRowTrackingName === navigationItem.TrackingName && 'activeCatLink')}>
            <NavigationItemRouterLink navigationItem={navigationItem} section={section} onClick={handleClick} className='navigation-item-display'>
              <NavigationItemRowData navigationItem={navigationItem} section={section} showCheckbox={section.IsMultiSelect} />
            </NavigationItemRouterLink>
          </li>)
        : (
          <li className={largeItemClass(section, navigationItem, activeItemRowTrackingName ?? '')} onMouseEnter={handleMouseEnter} ref={rowWrapperRef} data-test={navigationItem.LinkText}>
            <NavigationItemRouterLink navigationItem={navigationItem} section={section} onClick={handleClick} showPopoutArrow={hasCategoryPopout} className='navigation-item-display'>
              <NavigationItemRowData navigationItem={navigationItem} section={section} showCheckbox={section.IsMultiSelect} />
            </NavigationItemRouterLink>
          </li>)}
    </>
  )
}

export { NavigationItemRowWrapper }
