import { Pager } from '@web/shared-ui-components'
import '@web/styles/AddAHead.less'
import { ReactElement, ReactNode, useMemo } from 'react'
import { Sort } from '@web/products'

interface AddAHeadSortingAndPagingProps {
  children?: ReactNode
  pageSize: number
  currentSort: Sort
  currentPage: number
  sortOptions: Sort[]
  totalRecords: number
  onSortChange: (sort: Sort) => void
  onPageChange: (page: number) => void
}

/*
  Displays sorting and paging header/foot around child nodes (paging header collapses/hides at small)
 */
function AddAHeadSortingAndPaging ({
  children,
  pageSize = 12,
  currentSort,
  currentPage,
  sortOptions,
  totalRecords,
  onSortChange,
  onPageChange
}: AddAHeadSortingAndPagingProps): ReactElement | null {
  const singlePage = useMemo(() => totalRecords <= pageSize, [totalRecords, pageSize])

  const pagingDisplaySmall = useMemo(() => {
    const suffix = totalRecords === 1 ? ' Result' : ' Results'
    return `${totalRecords}${suffix}`
  }, [totalRecords])

  const pagingDisplay = useMemo(() => {
    const lower = (currentPage - 1) * pageSize + 1
    const upper = Math.min(currentPage * pageSize, totalRecords)
    const prefix = singlePage ? '' : `${lower}—${upper} of `
    const suffix = totalRecords === 1 ? ' result' : ' results'
    return `${prefix}${totalRecords}${suffix}`
  }, [currentPage, pageSize, totalRecords, singlePage])

  return (
    <div className='add-a-head-pager'>
      <div className='d-flex align-items-center flex-wrap mb-4'>
        <div className='d-flex align-content-between justify-content-center align-items-baseline'>
          <div className='text-nowrap d-block'>Sort by</div>
          <div className='d-flex c-modern-input-wrapper dark-arrow'>
            <select
              className='pr-5 p-2 pl-3 ml-2 c-border-gray-lt-1 c-gray-dk-3 font-weight-bold u-border-radius-small'
              data-test='sort-by-select'
              defaultValue={currentSort}
              onChange={evt => onSortChange(evt.target.value as Sort)}
            >
              {sortOptions.map(x => <option key={x}>{x}</option>)}
            </select>
          </div>
        </div>
        <div className='d-flex align-items-center ml-auto'>
          <span className='d-none d-md-flex mr-3'>{pagingDisplay}</span>
          <span className='d-flex d-md-none mr-3'>{pagingDisplaySmall}</span>
          {!singlePage &&
            <div className='d-none d-md-flex'>
              <Pager page={currentPage} perPage={pageSize} total={totalRecords} onChange={onPageChange} data-test='product-result-pagination' />
            </div>}
        </div>
      </div>
      <div className='w-100'>
        {children}
      </div>
      {!singlePage &&
        <div className='d-flex align-items-center justify-content-center justify-content-md-end mt-3'>
          <div>
            <Pager page={currentPage} perPage={pageSize} total={totalRecords} onChange={onPageChange} />
          </div>
        </div>}
    </div>
  )
}

export { AddAHeadSortingAndPaging }
