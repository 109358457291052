import { SearchImageAsset } from '@web/search-feature'
import { ProductSearchAttribute } from '@web/shared-data-access-queries'
import { SameInteractionEventHandler, useSameInteractionEvents } from '@web/shared-util-hooks'
import clsx from 'clsx'
import { ReactElement, useMemo, useState } from 'react'

interface SearchAttributeDropdownRowProps {
  onSelect?: SameInteractionEventHandler
  onUnselect?: SameInteractionEventHandler
  onSearchAttrSelected?: () => void
  attribute: ProductSearchAttribute
  className: string
}

function SearchAttributeDropdownRow ({ onSelect, onUnselect, onSearchAttrSelected, attribute, className }: SearchAttributeDropdownRowProps): ReactElement | null {
  const [isHovered, setIsHovered] = useState(false)
  const handleSelect = useMemo<SameInteractionEventHandler>(() => (e) => {
    setIsHovered(true)
    onSelect?.(e)
  }, [onSelect])
  const handleUnselect = useMemo<SameInteractionEventHandler>(() => (e) => {
    setIsHovered(false)
    onUnselect?.(e)
  }, [onUnselect])
  const selectEvents = useSameInteractionEvents(handleSelect, handleUnselect)

  return (
    <a className={clsx(className, isHovered ? 'selectedRow' : 'defaultRow', 'text-decoration-none d-flex p-2 featuredAttrDropdownRow')} {...selectEvents} href={attribute.ItemDetailsUrl} onClick={onSearchAttrSelected}>
      <div className='featured-attribute-icon-height featured-attribute-icon-width mr-2'>
        {attribute.AttributeImageAsset != null &&
          <SearchImageAsset
            AttributeValue={attribute.AttributeValue}
            {...attribute.AttributeImageAsset}
            className='h-100'
          />}
      </div>
      <span className={clsx(isHovered && 'bold')}>{attribute.AttributeValue}</span>
    </a>
  )
}

export { SearchAttributeDropdownRow }
