import {
  ReactElement, useCallback, useRef, useState
} from 'react'
import { PaymentMethodSelector } from '../payment-method-selector/PaymentMethodSelector'
import { PaymentMethod, PaymentMethodType, SaveDefaultPaymentMethodResponse, useSaveDefaultPaymentMethodMutation } from '@web/shared-data-access-queries'
import { Alert, Button } from '@web/shared-ui-components'
import { useLock } from '@web/shared-util-hooks'
import { faCheckCircle } from '@fortawesome/pro-solid-svg-icons'

const defaultAllowedMethods = [
  PaymentMethodType.Terms,
  PaymentMethodType.Debitcard,
  PaymentMethodType.Creditcard,
  PaymentMethodType.Cod
]

interface DefaultPaymentMethodAlertProps {
  saving: boolean
  saveDefaultPaymentMethodResponse?: SaveDefaultPaymentMethodResponse | null
}

/**
 * DefaultPaymentMethodAlert component
 */
function DefaultPaymentMethodAlert ({ saving, saveDefaultPaymentMethodResponse }: DefaultPaymentMethodAlertProps): ReactElement | null {
  if (saving || saveDefaultPaymentMethodResponse == null) {
    return null
  }

  if (!saveDefaultPaymentMethodResponse.success && saveDefaultPaymentMethodResponse.errors != null) {
    return (
      <Alert alertType='error' className='my-4'>{saveDefaultPaymentMethodResponse.errors}</Alert>
    )
  }

  return (
    <Alert alertType='success' icon={faCheckCircle} className='my-4'>Your default payment method has been saved!</Alert>
  )
}

/**
 * DefaultPaymentMethod component
 */
function DefaultPaymentMethod (): ReactElement {
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<PaymentMethod | null | undefined>(null)
  const [saveDefaultPaymentMethod, { loading: saving, data }] = useSaveDefaultPaymentMethodMutation()
  const paymentMethodSelectorRef = useRef<any>()

  const [handleSave] = useLock(useCallback(async (event: React.SyntheticEvent<HTMLFormElement>) => {
    event.preventDefault()

    if (selectedPaymentMethod == null) return

    await saveDefaultPaymentMethod({
      variables: {
        input: {
          paymentMethod: selectedPaymentMethod.Method,
          paymentMethodId: selectedPaymentMethod.SelectableValue
        }
      }
    })

    paymentMethodSelectorRef?.current?.refetchPaymentMethods()
  }, [selectedPaymentMethod]))

  return (
    <>
      <PaymentMethodSelector
        ref={paymentMethodSelectorRef}
        allowedMethods={defaultAllowedMethods}
        onChange={setSelectedPaymentMethod}
        allowSettingDefault
      />

      <DefaultPaymentMethodAlert saving={saving} saveDefaultPaymentMethodResponse={data?.saveDefaultPaymentMethod} />
      <Button color='primary' type='submit' onClick={handleSave}>Save</Button>
    </>
  )
}

export {
  DefaultPaymentMethod
}
