import { ReactElement, ReactNode, MouseEventHandler } from 'react'
import { TableRowButtonLink } from './index'

interface TableBodyRowWithButtonLinkProps {
  /*
   * Table body rows
   */
  children: ReactNode
  /*
   * Table row link
   */
  href: string
  /**
   * On Click Handler
   */
  handleClick?: MouseEventHandler
}

function TableBodyRowWithButtonLink ({ children, href, handleClick, ...otherAttributes }: TableBodyRowWithButtonLinkProps): ReactElement {
  return (
    <>
      {children}
      <TableRowButtonLink handleClick={handleClick} {...otherAttributes} href={href} openInNewWindow />
    </>
  )
}

export { TableBodyRowWithButtonLink }
