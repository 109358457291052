import { CreditCardParams, PaymentMethodType, SaveCardMutation, useSaveCardMutation, useTokenizeCardLazyQuery, useGetContextMinimalQuery } from '@web/shared-data-access-queries'
import { Button, CheckboxOld, Input, ExpirationDateShorthandInput, CreditCardInput, LoadingIndicator, CvvInput, PanPadInput, FormError, Popover } from '@web/shared-ui-components'
import { useImportantStyle, useLock } from '@web/shared-util-hooks'
import { CreditCardType } from 'credit-card-type/dist/types'
import { ChangeEvent, ReactElement, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import qs from 'qs'
import Verification, { creditCardType } from 'card-validator'
import uuid from 'react-uuid'
import { faCheck, faExclamationTriangle } from '@fortawesome/pro-solid-svg-icons'
import clsx from 'clsx'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { PaymentMethodSelectorContext } from '@web/payment-methods'
import S from 'fluent-json-schema'
import { validate, ValidateErrors, ajv } from '@web/shared-util-validation'

interface CardFormProps {
  /**
     * Is save type for card new or edit
     */
  saveType: 'new' | 'edit'
  /**
     * Iniitial Credit card params for form
     */
  initialCreditCardParams: CreditCardParams
  /**
   * Is credit card selected as default payment method
   */
  isDefault: boolean
  /**
   * Handle what happens after saving the card
   */
  afterSave: (saving: boolean, data?: SaveCardMutation | null | undefined) => void
  /**
     * Allowed payment methods for when adding a new card
     */
  newCardAllowedMethods: PaymentMethodType[]
  /**
   * Handle the toggle of the edit card
   */
  onToggleCollapse: () => void
  containerClass?: string
  /**
   * Override isLoggedIn from context
   */
  isLoggedIn?: boolean
}

// Validation schema for add/edit card form
const formAddSchema = S.object()
  .raw({
    errorMessage: {
      properties: {
        CardHolderName: 'Required',
        CardNumber: 'Required',
        ExpirationDate: 'Required',
        Cvv: 'Required',
        Zip: 'Required'
      }
    }
  })
  .prop('CardHolderName', S.string().required().minLength(1))
  .prop('CardNumber', S.string().required().minLength(1))
  .prop('ExpirationDate', S.string().required().minLength(1))
  .prop('Cvv', S.string().required().minLength(1))
  .prop('Zip', S.string().required().minLength(1))
const formEditSchema = S.object()
  .raw({
    errorMessage: {
      properties: {
        CardHolderName: 'Required',
        ExpirationDate: 'Required'
      }
    }
  })
  .prop('CardHolderName', S.string().required().minLength(1))
  .prop('ExpirationDate', S.string().required().minLength(1))

const validateAddForm = ajv.compile(formAddSchema.valueOf())
const validateEditForm = ajv.compile(formEditSchema.valueOf())

/**
   * CardForm component
   */
function CardForm ({
  saveType,
  initialCreditCardParams,
  isDefault,
  afterSave,
  newCardAllowedMethods,
  onToggleCollapse,
  containerClass = 'u-flex-grid-col-md-8 offset-md-2',
  isLoggedIn
}: CardFormProps): ReactElement | null {
  const { allowSettingDefault, isLoggedIn: contextIsLoggedIn, onLoggedOutSubmit } = useContext(PaymentMethodSelectorContext)
  isLoggedIn = isLoggedIn ?? contextIsLoggedIn
  const setDefaultId: string = useMemo(() => `set-default-${uuid() as string}`, [])
  const handleExpirationDateSet = useCallback((month?: number | null, year?: number | null) => month != null && year != null ? `${month?.toString().padStart(2, '0')}/${year?.toString().substring(2, 4)}` : '', [])
  const [cardHolderName, setCardHolderName] = useState<string>(initialCreditCardParams.CardHolderName)
  useEffect(() => setCardHolderName(initialCreditCardParams.CardHolderName), [initialCreditCardParams.CardHolderName])
  const [cardNumber, setCardNumber] = useState<string | null | undefined>(initialCreditCardParams.CardNumber)
  const [cardTypeString, setCardTypeString] = useState<string>(initialCreditCardParams.CardType)
  const cardType = useMemo(() => creditCardType.getTypeInfo(cardTypeString), [creditCardType, cardTypeString])
  const [cardExpiration, setCardExpiration] = useState<string>(handleExpirationDateSet(initialCreditCardParams.ExpirationMonth, initialCreditCardParams.ExpirationYear))
  const [cardZip, setCardZip] = useState<string>(initialCreditCardParams.Zip)
  const [cardCvv, setCardCvv] = useState<string | null | undefined>(initialCreditCardParams.Cvv)
  const [cardIsDefault, setCardIsDefault] = useState<boolean>(false)
  const [panpadSuccessStatus, setPanpadSuccessStatus] = useState<boolean>(false)
  const creditCardLabelClass = useMemo(() => clsx(panpadSuccessStatus ? 'c-green' : '', 'mb-2'), [panpadSuccessStatus])
  const contextQuery = useGetContextMinimalQuery()
  const [panPadData, setPanPadData] = useState<string>('')
  const usePanPad = useMemo(() => contextQuery.data?.context.RequiresPanPad, [contextQuery.data])
  const [tokenizedCardNumber, setTokenizedCardNumber] = useState<string>('')
  const [tokenizeCard] = useTokenizeCardLazyQuery(
    {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only',
      onCompleted: (responseVal) => {
        const parsedResponse = qs.parse(responseVal.tokenizeCard)
        setTokenizedCardNumber(parsedResponse.action !== 'ER' ? parsedResponse.data : '')
      }
    }
  )
  const [saveCard, { loading: saving, data }] = useSaveCardMutation()
  const [errors, setErrors] = useState<ValidateErrors | null>(null)
  const [reference, setReference] = useState<HTMLElement | null>(null)
  const [open, setOpen] = useState(false)
  const [lastFour, setLastFour] = useState<string>('')

  const handleNameChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setCardHolderName(event.target.value)
  }, [setCardHolderName])

  const handleCardInputChange = useCallback((formattedCreditCardNumber: string, cardType: CreditCardType | null) => {
    setCardNumber(formattedCreditCardNumber)
    setCardTypeString(cardType?.type ?? '')

    if (Verification.number(formattedCreditCardNumber).isPotentiallyValid) {
      setLastFour(formattedCreditCardNumber.slice(-4))
      void tokenizeCard({
        variables: {
          overridePath: `cardsecure/cs?${qs.stringify({ action: 'CE', data: formattedCreditCardNumber }) as string}`
        }
      })
    } else if (usePanPad === true) {
      void tokenizeCard({
        variables: {
          overridePath: `cardsecure/cs?${qs.stringify({ action: 'CZ', data: panPadData }) as string}`
        }
      })
    }
  }, [panPadData])

  const [handleSave, locked] = useLock(useCallback(async (event: React.SyntheticEvent<HTMLFormElement>) => {
    event.preventDefault()

    let { errors: newErrors } = validate(saveType === 'new' ? validateAddForm : validateEditForm, {
      CardHolderName: cardHolderName,
      CardNumber: cardNumber ?? '',
      ExpirationDate: cardExpiration,
      Cvv: cardCvv ?? '',
      Zip: cardZip
    })

    const parsedExpiration = cardExpiration.split('/')
    const month = parsedExpiration.length <= 0 || parsedExpiration[0] === undefined || parsedExpiration[0].trim().length === 0 ? '' : parsedExpiration[0]
    const year = parsedExpiration.length <= 1 || parsedExpiration[1] === undefined || parsedExpiration[1].trim().length === 0 ? '' : parsedExpiration[1]
    const expDateIsExpired = new Date(2000 + parseInt(year), parseInt(month)) <= new Date()
    const expDateNotComplete = cardExpiration.length < 5

    if (expDateIsExpired || expDateNotComplete) {
      if (newErrors == null) {
        newErrors = {}
      }
      newErrors.ExpirationDate = expDateNotComplete ? 'Required' : 'Expired'
    }

    setErrors(newErrors)

    const creditCardParams: CreditCardParams = {
      CardHolderName: cardHolderName,
      CardNumber: tokenizedCardNumber,
      LastFour: lastFour,
      ExpirationMonth: Number(month),
      ExpirationYear: 2000 + Number(year),
      CardType: saveType === 'new' && cardType != null ? cardType?.niceType.replace(/\s+/g, '') : cardTypeString,
      Cvv: cardCvv,
      Zip: cardZip
    }

    if (newErrors == null) {
      if (isLoggedIn !== true && onLoggedOutSubmit != null) {
        onLoggedOutSubmit({
          Method: PaymentMethodType.Creditcard,
          CreditCard: creditCardParams
        })
      } else {
        await saveCard({
          variables: {
            input: {
              creditCard: {
                ...creditCardParams,
                Id: initialCreditCardParams.Id,
                IsDebit: initialCreditCardParams.IsDebit
              },
              allowedMethods: newCardAllowedMethods,
              setAsDefault: allowSettingDefault && cardIsDefault
            }
          }
        }).catch(() => {
          afterSave(saving, data)
        })
      }
    }
  }, [
    newCardAllowedMethods,
    initialCreditCardParams.Id,
    initialCreditCardParams.CardType,
    cardTypeString,
    cardHolderName,
    tokenizedCardNumber,
    cardExpiration,
    cardCvv,
    cardZip,
    cardIsDefault,
    panPadData
  ]))

  const handleZipChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setCardZip(event.target.value)
  }, [setCardZip])

  const handleSetDefaultChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setCardIsDefault(event.target.checked)
  }, [])

  useEffect(() => {
    if (data?.saveCard != null && data.saveCard.success) {
      afterSave(saving, data)
      onToggleCollapse()
      setCardHolderName(initialCreditCardParams.CardHolderName)
      setCardExpiration(handleExpirationDateSet(initialCreditCardParams.ExpirationMonth, initialCreditCardParams.ExpirationYear))
      setCardNumber(initialCreditCardParams.CardNumber)
      setCardCvv(initialCreditCardParams.Cvv)
      setCardZip(initialCreditCardParams.Zip)
      setPanpadSuccessStatus(false)
      setCardIsDefault(false)
    } else if (data?.saveCard != null && !data.saveCard.success) {
      afterSave(saving, data)
    }
  }, [data, initialCreditCardParams])

  return (
    <LoadingIndicator loading={saving || locked}>
      <div className={containerClass}>
        {saveType === 'new' &&
          <>
            <div className='form-input-group mb-0 pb-4'>
              <label className='pb-2'>Name on Card</label>
              <Input
                name='CardHolderName'
                placeholder='Full Name'
                onChange={handleNameChange}
                value={cardHolderName}
                autoComplete='cc-name'
                invalid={errors?.CardHolderName}
                data-test='name-on-card'
              />
              <FormError message={errors?.CardHolderName} />
            </div>
            <PanPadInput
              usePanPad={usePanPad}
              savePanPadData={setPanPadData}
              saveNewCardCvv={setCardCvv}
              saveNewCardNumber={setCardNumber}
              saveNewCardExpiration={setCardExpiration}
              saveNewCardZip={setCardZip}
              savePanpadSuccessStatus={setPanpadSuccessStatus}
              panpadSuccessStatus={panpadSuccessStatus}
            />
            <div className='form-input-group u-flex-grid-row u-small-gutters'>
              <div className='u-flex-grid-col-12 u-flex-grid-col-md-5 mb-5 mb-md-0'>
                <label className={creditCardLabelClass}>
                  Card Number
                  {panpadSuccessStatus && <FontAwesomeIcon icon={faCheck} className='ml-2 c-green-lt-1' size='lg' />}
                </label>
                <CreditCardInput
                  name='CardNumber'
                  usePanPad={usePanPad}
                  onChange={handleCardInputChange}
                  value={cardNumber}
                  invalid={errors?.CardNumber}
                />
                <FormError message={errors?.CardNumber} />
              </div>
              <div className='u-flex-grid-col-4 u-flex-grid-col-sm-2'>
                <label className={creditCardLabelClass}>
                  Exp. Date
                  {panpadSuccessStatus && <FontAwesomeIcon icon={faCheck} className='ml-2 c-green-lt-1' size='lg' />}
                </label>
                <ExpirationDateShorthandInput
                  name='ExpirationDate'
                  value={cardExpiration}
                  onChange={setCardExpiration}
                  invalid={errors?.ExpirationDate}
                />
                <FormError message={errors?.ExpirationDate} />
              </div>
              <div className='u-flex-grid-col-3 u-flex-grid-col-sm-2'>
                <label className='mb-2'>
                  CVV {usePanPad === true &&
                    <>
                      <span ref={setReference}>
                        <FontAwesomeIcon icon={faExclamationTriangle} className='c-red' />
                      </span>
                      <Popover
                        placement='top'
                        style={{ width: '200px' }}
                        hover
                        hoverProps={{ delay: { open: 300 } }}
                        offset={5}
                        open={open}
                        onOpenChange={setOpen}
                        reference={reference}
                      >
                        The PanPad does not capture the card CVV. You'll need to enter the CVV manually.
                      </Popover>
                    </>}
                </label>
                <CvvInput
                  name='Cvv'
                  value={cardCvv}
                  cardType={cardType}
                  required
                  onChange={setCardCvv}
                  invalid={errors?.Cvv}
                />
                <FormError message={errors?.Cvv} />
              </div>
              <div className='u-flex-grid-col-4 u-flex-grid-col-sm-3'>
                <label className={creditCardLabelClass}>
                  ZIP/Postal Code
                  {panpadSuccessStatus && <FontAwesomeIcon icon={faCheck} className='ml-2 c-green-lt-1' size='lg' />}
                </label>
                <Input
                  name='Zip'
                  placeholder='70508'
                  inputMode='numeric'
                  onChange={handleZipChange}
                  value={cardZip}
                  invalid={errors?.Zip}
                  data-test='zip-postal-code'
                />
                <FormError message={errors?.Zip} />
              </div>
            </div>
            {allowSettingDefault && isLoggedIn &&
              <div className='form-input-group my-2 u-flex-grid-row u-small-gutters'>
                <div className='u-flex-grid-col-12 d-flex justify-content-end my-2'>
                  <CheckboxOld name='IsDefault' id={setDefaultId} type='checkbox' className='my-0' checked={cardIsDefault} onChange={handleSetDefaultChange} data-test='set-as-default-checkbox-container'>
                    <label htmlFor={setDefaultId} className='pl-5 mb-0 pb-0' ref={(sd) => useImportantStyle(sd, 'margin-right', '0px')}>Set as default payment method</label>
                  </CheckboxOld>
                </div>
              </div>}
          </>}
        {saveType === 'edit' &&
          <>
            <div className='form-input-group u-flex-grid-row u-small-gutters' data-test='edit-card-form'>
              <div className='u-flex-grid-col-9'>
                <label>Name on Card</label>
                <Input
                  name='CardHolderName'
                  placeholder='Full Name'
                  onChange={handleNameChange}
                  value={cardHolderName}
                  autoComplete='cc-name'
                  invalid={errors?.CardHolderName}
                  data-test='name-on-card'
                />
                <FormError message={errors?.CardHolderName} />
              </div>
              <div className='u-flex-grid-col-3'>
                <label>Exp. Date</label>
                <ExpirationDateShorthandInput
                  name='ExpirationDate'
                  value={cardExpiration}
                  onChange={setCardExpiration}
                  invalid={errors?.ExpirationDate}
                />
                <FormError message={errors?.ExpirationDate} />
              </div>
            </div>
            {!isDefault && allowSettingDefault && isLoggedIn &&
              <div className='u-flex-grid-col-12 pr-0 d-flex justify-content-end mt-2'>
                <CheckboxOld name='IsDefault' id={setDefaultId} type='checkbox' checked={cardIsDefault} onChange={handleSetDefaultChange} data-test='set-as-default-checkbox-container'>
                  <label className='pl-5' htmlFor={setDefaultId} ref={(sd) => useImportantStyle(sd, 'margin-right', '0px')}>Set as default payment method</label>
                </CheckboxOld>
              </div>}
          </>}
        <div className={clsx('d-flex mb-2 justify-content-end align-items-center mt-2', isDefault && 'mt-4 pt-2')}>
          <Button tag='a' color='link' className='mr-4' onClick={onToggleCollapse} data-test='cancel-card-button'>Cancel</Button>
          <Button color='primary' type='button' onClick={handleSave} data-test='save-card-button'>Save</Button>
        </div>
      </div>
    </LoadingIndicator>
  )
}

export {
  CardForm
}
