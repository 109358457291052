import {
  ReactElement, useEffect
} from 'react'
import { SaveCardResponse } from '@web/shared-data-access-queries'
import { Alert } from '@web/shared-ui-components'
import { useToggle } from '@web/shared-util-hooks'

interface AddCardErrorAlertProps {
  /**
   * Is a new card being saved
   */
  saving: boolean
  /**
   * Response from adding a new card
   */
  saveCardResponse?: SaveCardResponse | null
}

function AddCardErrorAlert ({ saving, saveCardResponse }: AddCardErrorAlertProps): ReactElement | null {
  const [show, toggleShow, setShow] = useToggle(false)

  useEffect(() => {
    setShow(!saving && saveCardResponse != null)
  }, [saving, saveCardResponse])

  if (saving || saveCardResponse == null || saveCardResponse.success || saveCardResponse.errors == null) {
    return null
  }

  return (
    <Alert show={show} onToggle={toggleShow} alertType='error' className='my-4' data-test='save-card-error'>{saveCardResponse.errors}</Alert>
  )
}

export {
  AddCardErrorAlert
}
