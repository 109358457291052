import { toast } from 'react-toastify'
import { Alert } from '@web/shared-ui-components'
import { faCheck, faWarning } from '@fortawesome/pro-solid-svg-icons'

interface UseToastParams {
  status?: 'success' | 'error'
  message?: string
}
/**
 * Uses a status and message to add "toasts" to the page (with `react-toastify`)
 */
function useToast (hookParams: UseToastParams): Function {
  function handleToast (handleParams: UseToastParams): void {
    const { message, status } = { ...hookParams, ...handleParams }
    if (status == null || message == null) {
      return
    }
    const toastStatus = status === 'success' ? toast.success : toast.error
    toastStatus(
      <>
        <Alert
          data-test='alert'
          alertType={status}
          showBackground={false}
          showBorder={false}
          icon={status === 'success' ? faCheck : faWarning}
        >
          {message}
        </Alert>
      </>,
      { position: 'top-right' }
    )
  }
  return handleToast
}

export { useToast }
