
function useImportantStyle (element: HTMLElement | null, styleProperty: string, styleValue: string): void {
  if (element != null) {
    element.style.setProperty(styleProperty, styleValue, 'important')
  }
}

export {
  useImportantStyle
}
