import { ReactElement } from 'react'
import { ModalFooter, Button } from '@web/shared-ui-components'

interface ModalWizardFooterProps {
  /**
   * Text for prev button
   */
  prevButtonText?: string
  /**
   * Function to handle prev button click
   */
  onClickPrevButton?: () => void
  /**
   * Text for next button
   */
  nextButtonText?: string
  /**
   * Function to handle next button click
   */
  onClickNextButton?: () => void
  /**
   * Boolean to disable next button
   */
  disableNext?: boolean
}

/**
 * Footer for use inside of modals with wizard-like functionality
 */
function ModalWizardFooter ({
  prevButtonText,
  onClickPrevButton,
  nextButtonText = 'Next',
  onClickNextButton,
  disableNext = false
}: ModalWizardFooterProps): ReactElement {
  const canShowPreviousButton: boolean = onClickPrevButton != null && prevButtonText != null
  const canShowNextButton: boolean = onClickNextButton != null && nextButtonText != null

  return (
    <ModalFooter>
      <div className='d-flex align-items-center justify-content-between'>
        {canShowPreviousButton
          ? (
            <Button color='secondary' onClick={onClickPrevButton} style={{ minWidth: '90px' }}>
              {prevButtonText}
            </Button>
            )
          : (
            <div />
            )}
        {canShowNextButton
          ? (
            <Button color='primary' onClick={onClickNextButton} style={{ minWidth: '90px' }} disabled={disableNext} data-test={nextButtonText}>
              {nextButtonText}
            </Button>
            )
          : (
            <div />
            )}
      </div>
    </ModalFooter>
  )
}

export { ModalWizardFooter }
