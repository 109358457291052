import { ReactElement, useMemo } from 'react'
import { useToggle } from '@web/shared-util-hooks'
interface ShowMoreProps {
  /*
   * Id of element
   */
  id?: string
  /*
   * Additional class name(s) to give to the containing element
   */
  className?: string
  /*
   * Max length of text to show
   */
  maxTextLength?: number
  /*
   * Text to display
   */
  displayText: string
  /**
   * Show THE show text or not
   */
  displayShowText?: boolean
}

/*
 * Show or hide component for text
 */
function ShowMore ({
  id,
  className,
  maxTextLength = 50,
  displayText,
  displayShowText = true
}: ShowMoreProps): ReactElement {
  const [showMore, toggleShowMore] = useToggle(false)

  const extraTextLength = useMemo(() => displayShowText ? 13 : 8, [displayShowText]) // Account for the length of the text that will show
  const isTextTruncated = useMemo(() => displayText.length > (maxTextLength + extraTextLength), [displayText.length, maxTextLength, extraTextLength])

  const truncatedText = useMemo(() => (!showMore && isTextTruncated)
    ? displayText.substring(0, maxTextLength) + '...'
    : displayText, [showMore, maxTextLength, displayText, isTextTruncated])

  return (
    <div id={id} className={className}>
      <span className='text-break'>{truncatedText}</span>
      {isTextTruncated && (
        <span className='dark-link font-weight-bold ml-2' onClick={toggleShowMore}>
          {displayShowText && 'Show '}{!showMore ? 'More' : 'Less'}
        </span>
      )}
    </div>
  )
}

export { ShowMore }
