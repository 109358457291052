import { ReactElement, useEffect } from 'react'
import { Alert } from '@web/shared-ui-components'
import { DeleteBankAccountResponse } from '@web/shared-data-access-queries'
import { faCheckCircle } from '@fortawesome/pro-solid-svg-icons'
import { useToggle } from '@web/shared-util-hooks'

interface DeleteBankAlertProps {
  /**
    * Is bank being deleted
    */
  deletingBank: boolean

  /**
    * Response from deleting a bank account
    */
  deleteBankResponse?: DeleteBankAccountResponse | null
}

/**
 * deleting bank alert component
 */
function DeleteBankAlert ({ deletingBank, deleteBankResponse }: DeleteBankAlertProps): ReactElement | null {
  const [show, toggleShow, setShow] = useToggle(false)

  useEffect(() => {
    setShow(!deletingBank && deleteBankResponse != null)
  }, [deletingBank, deleteBankResponse])

  if (deletingBank || deleteBankResponse == null) {
    return null
  }

  if (!deleteBankResponse.success) {
    return (
      <Alert show={show} onToggle={toggleShow} alertType='error' className='my-4'>Could not delete bank account. </Alert>
    )
  }

  return (
    <Alert show={show} onToggle={toggleShow} alertType='success' icon={faCheckCircle} className='my-4'>Your bank account has been deleted!</Alert>
  )
}

export {
  DeleteBankAlert
}
