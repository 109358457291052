import {
  ReactElement,
  ReactNode,
  useContext,
  useMemo
} from 'react'
import clsx from 'clsx'
import uuid from 'react-uuid'
import { Input, SelectionBoxContext } from '@web/shared-ui-components'
import { useImportantStyle } from '@web/shared-util-hooks'

/*
 * SelectionBoxBodyContent Props
 */
interface SelectionBoxBodyContentProps {
  /*
   * Children
   */
  children: ReactNode
}

/*
 * SelectionBoxBodyContent component
 */
function SelectionBoxBodyContent ({
  children,
  ...otherAttributes
}: SelectionBoxBodyContentProps): ReactElement | null {
  const { format, value, name, selectedValue, onChange, disabled } = useContext(SelectionBoxContext)

  const uniqueId = useMemo(() => uuid(), [])

  const styleClasses = useMemo(() => clsx(
    format === 'largeContent' && 'c-bg-gray-lt-1 col u-flex-grid-col-md-1'
  ), [format, disabled])

  const labelContainerClasses = useMemo(() => clsx(
    disabled != null && disabled ? 'u-cursor-not-allowed' : 'u-cursor-pointer'
  ), [format, disabled])

  return (
    <label className={clsx('d-flex w-100', labelContainerClasses)}>
      {/* Middle aligning the radio button in the content box because trying to align it to the top doesn't always look right */}
      <div className={clsx('p-0 d-flex justify-content-center align-items-center u-border-radius-medium', styleClasses)}>
        <Input id={uniqueId} type='radio' disabled={disabled} value={value} data-test={value} checked={value === selectedValue} name={name} onChange={onChange} {...otherAttributes} />
        <label htmlFor={uniqueId} style={{ marginLeft: '21px' }} ref={(x) => useImportantStyle(x, 'margin-right', '0px')} />
      </div>
      <div className='d-flex flex-wrap flex-fill'>
        {children}
      </div>
    </label>
  )
}

export { SelectionBoxBodyContent }
