import { ReactElement } from 'react'
import '@web/styles/AddProductToProductFeedModal.less'

interface FeedProductCardProps {
  productImage: string
  title: string
  sku: string
  url: string
}

function FeedProductCard ({ productImage, title, sku, url }: FeedProductCardProps): ReactElement {
  return (
    <div className='product-summary-card d-flex d-flex flex-row flex-wrap flex-sm-nowrap u-border-radius-medium border c-bg-white c-border-gray-lt-1'>
      <div className='d-flex justify-content-center order-1'>
        <img
          src={productImage}
          alt={title}
          className='product-image u-border-radius-medium m-4'
        />
      </div>
      <div className='mx-4 mt-0 mb-4 my-sm-4 ml-sm-0 mr-sm-4 d-block order-xs-3 order-4 order-sm-2'>
        <div className='u-bold mb-1'>{title}</div>
        <a href={url} target='blank' className='primary-link'>Item #{sku}</a>
      </div>
      <div className='w-100 d-block d-sm-none order-3' />
    </div>
  )
}

export { FeedProductCard }
