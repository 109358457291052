import {
  ReactElement,
  ChangeEventHandler,
  useMemo,
  useCallback,
  KeyboardEvent,
  ChangeEvent
} from 'react'
import { Button, Input } from '@web/shared-ui-components'
import clsx from 'clsx'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/pro-solid-svg-icons'
import '@web/styles/InputWithSearchButton.less'

interface InputWithSearchButtonProps {
  /**
   * Class overrides
   */
  className?: string
  value?: string
  placeholder: string
  /**
   * Handle change of search value
   */
  onChange?: ChangeEventHandler
  /**
   * Is Value input invalid
   */
  isInvalid?: boolean
  /**
   * Is Loading (Disables input & button. Replaces button icon/text with spinner)
   */
  loading?: boolean
  /**
   * Disable button
   */
  buttonDisabled?: boolean
  onSubmit: (value?: string) => void
  /*
   * All other attributes
   */
  [key: string]: any
  /**
   * Class overrides for input
   */
  inputClassName?: string
}

/**
 * The search by number component
 */
function InputWithSearchButton ({
  className,
  value,
  placeholder,
  onChange,
  isInvalid = false,
  loading = false,
  buttonDisabled = false,
  onSubmit,
  inputClassName,
  ...otherAttributes
}: InputWithSearchButtonProps): ReactElement {
  const containerClass = useMemo(() => clsx(className, 'react-input-with-search-button form-input-group d-flex flex-column flex-sm-row align-items-end noPrint'), [className])
  const valueValidationClass = useMemo(() => clsx(isInvalid && 'validation-input-error mb-0', 'u-border-radius-right-0', inputClassName), [isInvalid])

  const handleKeyDown = useCallback((event: KeyboardEvent) => {
    if (event.key === 'Enter') {
      onSubmit(value)
    }
  }, [onSubmit])

  const handleSubmit = useCallback(() => {
    onSubmit(value)
  }, [onSubmit, value])

  const handleChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    value = event.target.value
    if (onChange !== undefined) {
      onChange(event)
    }
  }, [onChange])

  return (
    <div className={containerClass}>
      <Input
        onKeyPress={handleKeyDown}
        placeholder={placeholder}
        value={value}
        onChange={handleChange}
        className={valueValidationClass}
        disabled={loading}
        {...otherAttributes}
      />
      <Button
        className='search-by-button mt-3 mt-sm-0 mt-md-0 mt-lg-0'
        data-test='search-by-number-button'
        color='primary'
        disabled={buttonDisabled || loading}
        onClick={handleSubmit}
      >
        {loading && <div className='spinner-border' />}

        {!loading &&
          <>
            <FontAwesomeIcon icon={faSearch} className='d-none d-md-block' />
            <span className='d-block d-md-none'>Search</span>
          </>}
      </Button>
    </div>
  )
}

export {
  InputWithSearchButton
}
