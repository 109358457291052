/**
 * Calls a method from our common JS, that will sends events to both old school google analytics and universal Analytics
 */
declare function StullerTrackEvent (
  category: string,
  action: string,
  label: string,
  value?: number,
  nonInteraction?: boolean
): void

const TrackEvent: typeof StullerTrackEvent = (...args) => StullerTrackEvent(...args)

export { TrackEvent }
