import { ReactElement } from 'react'
import { useGetContextMinimalQuery, GetSearchNavigationQuery } from '@web/shared-data-access-queries'
import { CmsContent } from '@web/shared-ui-components'

export interface ContentOnlyViewProps {
  contentId?: GetSearchNavigationQuery['getSearchNavigation']['ContentId']
}

function ContentOnlyView ({
  contentId
}: ContentOnlyViewProps): ReactElement | null {
  const { data } = useGetContextMinimalQuery()
  const context = data?.context

  return (
    <div id='content'>
      {contentId != null &&
        <div className='top_content'>
          <CmsContent contentContainerId={contentId} />
        </div>}
      {contentId == null && context != null && !context.IsInShowcase &&
        <CmsContent contentContainerId={59989} />}
    </div>
  )
}

export { ContentOnlyView }
