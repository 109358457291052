import { ProductNavigationContext } from '@web/product-navigation-feature'
import { SearchPresentationContext } from '@web/product-navigation-types'
import { dispatchStullerEvent } from 'libs/shared/util/stuller-events/dispatchStullerEvent'
import { useContext } from 'react'

function sanitize (input: string): string {
  return input.replace("'", "\\'")
}

function useNavigationItemTracking (): { trackClickEvent: (event: string, inputName: string, inputType: string, inputValue: string, inputChecked?: boolean) => void } {
  const { PresentationContext } = useContext(ProductNavigationContext)

  const trackClickEvent = (event: string, inputName: string, inputType: string, inputValue: string, inputChecked?: boolean): void => {
    const section = PresentationContext === SearchPresentationContext.Search ? 'site_search' : 'category_browse'
    event = sanitize(event)
    inputName = sanitize(inputName)
    inputType = sanitize(inputType)
    inputValue = sanitize(inputValue)

    const details = {
      section,
      input_name: inputName,
      input_type: inputType,
      input_value: inputValue,
      ...(inputChecked != null && { input_checked: inputChecked })
    }

    dispatchStullerEvent('track-event', { event, details })
  }

  return { trackClickEvent }
}

export {
  useNavigationItemTracking
}
