import { LoadingIndicator } from '@web/shared-ui-components'
import { useImagePreloader } from '@web/shared-util-hooks'
import { CSSTransition } from 'react-transition-group'
import { memo, useMemo, useState } from 'react'
import '@web/styles/ProductResultImage.less'

interface ProductCardImageProps {
  imageAlt: string
  placeholderImage?: string | null
  mainImage: string
  sideImage?: string | null
}

const ProductCardImage = memo(({ mainImage, placeholderImage, sideImage, imageAlt }: ProductCardImageProps) => {
  const hasSide: boolean = sideImage != null
  const images = useMemo(() => {
    const imgs = [mainImage ?? '', sideImage ?? '']
    return imgs.filter(img => img !== '')
  }, [mainImage, sideImage])
  const { imagesPreloaded: mainImagesPreloaded } = useImagePreloader(images)
  const [showSideImage, changeShowSideImage] = useState(false)

  return (
    <LoadingIndicator loading={!mainImagesPreloaded} position='center'>
      {!mainImagesPreloaded &&
        <div className='d-flex justify-content-center productResultImage'>
          <img
            className='image-responsive'
            src={placeholderImage ?? ''}
            alt={imageAlt}
          />
        </div>}
      {mainImagesPreloaded &&
        <div className='d-flex justify-content-center productResultImage'>
          {!hasSide &&
            <div className='h-100 position-relative'>
              <img
                className='image-responsive'
                src={mainImage}
                alt={imageAlt}
              />
            </div>}
          {hasSide &&
            <div
              className='h-100 position-relative'
              onMouseEnter={() => {
                changeShowSideImage(true)
              }}
              onMouseLeave={() => {
                changeShowSideImage(false)
              }}
              data-test='product-image'
            >
              <CSSTransition in={!showSideImage} timeout={300} classNames='result-image-hover'>
                <img
                  className='image-responsive'
                  src={mainImage}
                  alt={imageAlt}
                />
              </CSSTransition>
              <CSSTransition in={showSideImage} timeout={300} classNames='result-image-hover'>
                <img
                  className='image-responsive result-side-image'
                  src={sideImage ?? ''}
                  alt={imageAlt ?? ''}
                />
              </CSSTransition>
            </div>}
        </div>}
    </LoadingIndicator>
  )
})

export { ProductCardImage }
