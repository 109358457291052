import { ReactElement, useMemo, RefObject, useEffect } from 'react'
import { NavigationItem, NavigationSection, Maybe } from '@web/shared-data-access-queries'
import { useNavigationItemTracking } from '@web/product-navigation-hooks'
import '@web/styles/CategoryPopout.less'
import clsx from 'clsx'

export interface CategoryPopoutModel {
  childrenCats: Array<Maybe<NavigationItem>>
  grandchildCatMax: number
  section: NavigationSection
  navigationItem: NavigationItem
}

export interface CategoryPopoutProps {
  props: CategoryPopoutModel
  toggleCatPopoutProps: (value: CategoryPopoutModel | null) => void
  popoutRef: RefObject<HTMLDivElement> | null
  topPosition: number
  onChange: () => void
}

function firstLevelClass (child: NavigationItem): string {
  return clsx('firstLevelChildren', child.Children != null && child.Children.length > 0 && 'hasChildren')
}

function CategoryPopout ({
  props,
  toggleCatPopoutProps,
  popoutRef,
  topPosition,
  onChange
}: CategoryPopoutProps): ReactElement | null {
  const columns = useMemo(() => {
    const columns: NavigationItem[][] = []
    let columnCount = 0
    let rowCounter = 0
    if (props?.childrenCats == null) {
      return null
    }
    for (const child of props.childrenCats) {
      if (rowCounter === 0) {
        columns[columnCount] = []
      }

      if (child != null) {
        columns[columnCount].push(child)
        rowCounter += 2
      }

      if (child?.Children != null && child.Children.length > 0) {
        let grandchildCount = 0
        for (let i = 0; i < child.Children.length; i++) {
          rowCounter++
          grandchildCount++

          if (child.Children.length > props.grandchildCatMax && grandchildCount >= props.grandchildCatMax) {
            rowCounter++
            break
          }
        }
      }

      if (rowCounter >= 25) {
        rowCounter = 0
        columnCount++
      }
    }

    return columns
  }, [props])

  const { trackClickEvent } = useNavigationItemTracking()

  useEffect(() => {
    onChange()
  }, [props])

  return (
    <div className='categoryPopoutNew' ref={popoutRef} style={{ top: `${topPosition}px` }} onMouseEnter={() => toggleCatPopoutProps(props)} onMouseLeave={() => toggleCatPopoutProps(null)}>
      <div className='d-flex flex-row align-items-start'>
        {columns?.map((column, colIndex) => (
          <ul key={colIndex} className='catColumn'>
            {column.map((child, childIndex) => (
              <li key={childIndex} className={firstLevelClass(child)}>
                <a href={child.LinkUrl} onClick={() => trackClickEvent('filter', props.navigationItem.TrackingName, 'link', child.TrackingName)}>
                  <span dangerouslySetInnerHTML={{ __html: child.LinkText }} />
                </a>
                {child.Children != null && child.Children.length > 0 && (
                  <ul className='secondLevelChildren'>
                    {child.Children.map((grandChild, grandChildIndex) => (
                      <li key={grandChildIndex}>
                        {props?.grandchildCatMax != null && grandChildIndex + 1 <= props.grandchildCatMax && grandChild != null && (
                          <a href={grandChild.LinkUrl} onClick={() => trackClickEvent('filter', child.TrackingName, 'link', grandChild.TrackingName)}>
                            <span dangerouslySetInnerHTML={{ __html: grandChild.LinkText }} />
                          </a>
                        )}
                        {props?.grandchildCatMax != null && grandChildIndex + 1 === props.grandchildCatMax && child.Children != null && child.Children.length > props.grandchildCatMax && (
                          <a className='categoryShowAll' href={child.LinkUrl}>Show All</a>
                        )}
                      </li>
                    ))}
                  </ul>
                )}
                <ul />
              </li>
            ))}
          </ul>
        ))}
      </div>

    </div>
  )
}

export { CategoryPopout }
