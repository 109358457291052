import {
  ForwardedRef,
  forwardRef,
  ReactElement,
  ReactNode,
  useMemo,
  MouseEventHandler
} from 'react'
import clsx from 'clsx'
import '@web/styles/Dropdown.less'
import { DropdownContext, DropdownContextType } from './DropdownContext'

interface DropdownProps extends DropdownContextType {
  /**
   * Id of element
   */
  id?: string
  /**
    * Additional class name(s) to give to the containing element
    */
  className?: string
  /**
    * Handle click event
    */
  onClick?: MouseEventHandler
  /**
    * Children of element
    */
  children?: ReactNode
}

/**
 * Dropdown component for collapsible content overlays
 */
function Dropdown ({
  id,
  className,
  isCollapsed,
  onToggle,
  onClick,
  children
}: DropdownProps, ref: ForwardedRef<any>): ReactElement {
  const context: DropdownContextType = useMemo(() => ({ isCollapsed, onToggle }), [isCollapsed, onToggle])
  const classNames = useMemo(() => clsx(
    'react-dropdown-container',
    className
  ), [className])

  return (
    <DropdownContext.Provider value={context}>
      <div
        id={id}
        className={classNames}
        ref={ref}
        onClick={onClick}
      >
        {children}
      </div>
    </DropdownContext.Provider>
  )
}

/**
 * Dropdown with forwardRef
 */
const DropdownWrapped = forwardRef(Dropdown)

export { DropdownWrapped as Dropdown }
