import clsx from 'clsx'
import { ReactElement } from 'react'

interface FormErrorProps {
  /*
   * Error message to show
   */
  message?: string
  /*
    * Class names to append to default class names
  */
  className?: string
}

/*
 * Error component for validation errors
 */
function FormError ({ message, className, ...otherAttributes }: FormErrorProps): ReactElement | null {
  if (message == null) {
    return null
  }

  return (
    <div className={clsx(className, 'validation-message-error')} {...otherAttributes}>{message}</div>
  )
}

export {
  FormError
}
