import {
  ReactElement
} from 'react'
import { PaymentMethodSelectionBoxOption } from './types'
import { PaymentMethodSelectionBox } from './PaymentMethodSelectionBox'
import { useToggleKey } from '@web/shared-util-hooks'

interface PaymentMethodSelectionBoxesGroupProps {
  /**
   * Payment method options
   */
  paymentMethods: PaymentMethodSelectionBoxOption[] | undefined
  methodLabel: string
  /**
   * Currently selected payment method option
   */
  selectedValue: PaymentMethodSelectionBoxOption | undefined
  /**
   * Remove card handler
   */
  handleRemoveCard?: any
  /**
    * Remove bank handler
    */
  handleRemoveBank?: any
  allowAdding: boolean
}

/*
 * Payment Method Selector Selection Boxes component
 */
function PaymentMethodSelectionBoxesGroup ({ paymentMethods, methodLabel, handleRemoveBank, handleRemoveCard, selectedValue, allowAdding }: PaymentMethodSelectionBoxesGroupProps): ReactElement | null {
  const [open, handleToggle] = useToggleKey()
  if ((paymentMethods == null || paymentMethods.length === 0) && !allowAdding) {
    return null
  }

  return (
    <div>
      <div className='c-gray-dk-2 t-ui-label'>{methodLabel.toUpperCase()}</div>
      {paymentMethods?.map((pmsbo, i) =>
        <PaymentMethodSelectionBox
          key={`payment-method-choice-${i}`}
          paymentMethodOption={pmsbo}
          selectedValue={selectedValue}
          handleRemoveBank={handleRemoveBank}
          handleRemoveCard={handleRemoveCard}
          isActive={open === pmsbo.paymentMethod.SelectableValue}
          handleEdit={() => handleToggle(pmsbo.paymentMethod.SelectableValue)}
        />
      )}
    </div>
  )
}

export { PaymentMethodSelectionBoxesGroup }
