import { ReactElement } from 'react'
import { Badge, IndividualBadgeProps } from '@web/shared-ui-components'

function LimitedTime ({ popoverZIndex, ...otherAttributes }: IndividualBadgeProps): ReactElement {
  // const [reference, setReference] = useState<HTMLElement | null>(null)
  // const [open, setOpen] = useState(false)

  return (
    <>
      <Badge
        // ref={setReference}
        pill
        badgeColor='deal'
        {...otherAttributes}
      >
        Limited Time Only
      </Badge>
      {/* <Popover
        placement='top'
        style={{ maxWidth: '300px', zIndex: popoverZIndex }}
        hover
        hoverProps={{ delay: { open: 300 } }}
        offset={5}
        open={open}
        onOpenChange={setOpen}
        reference={reference}
      >
        <span className='font-weight-bold'>Limited Time Only: </span>Products that are here for a limited time.
      </Popover> */}
    </>
  )
}

export {
  LimitedTime
}
