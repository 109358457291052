import { ReactElement, useMemo } from 'react'
import '@web/styles/Breadcrumbs.less'
import clsx from 'clsx'
import { useUserContext } from '@web/shared-data-access-context'

export interface BreadcrumbViewModel{
  /**
   * Display Text of the Breadcrumb
   */
  EncodedText: string

  /**
   * Url for the Link of the Breadcrumb to go to
   */
  Url?: string | null
}

export interface BreadcrumbsProps<T> {
  crumbs: T[]
  allowHtml?: boolean
}

function Breadcrumbs<T extends BreadcrumbViewModel> ({ crumbs, allowHtml = false }: BreadcrumbsProps<T>): ReactElement {
  const { context } = useUserContext()
  const breadcrumbsWrapperClasses = useMemo(() => context?.HideBreadcrumbs === true ? 'mt-4' : '', [context])
  const breadcrumbsClasses = useMemo(() => clsx('react-breadcrumbs', context?.IsSterling === true && 'p-0 mb-3 sterling-viewing-box', context?.HideBreadcrumbs === true && 'd-none'), [context])

  return (
    <div className={breadcrumbsWrapperClasses}>
      <ul className={breadcrumbsClasses} data-test='viewing-bar'>
        <li>
          <a href='/'>Home</a>
        </li>
        {crumbs.map((crumb, index) =>
          <Breadcrumb key={index} crumb={crumb} allowHtml={allowHtml} lastCrumb={index === crumbs.length - 1} />
        )}
      </ul>
    </div>
  )
}

interface BreadcrumbProps<T>{
  crumb: T
  allowHtml?: boolean
  lastCrumb: boolean
}

function Breadcrumb<T extends BreadcrumbViewModel> ({ crumb, allowHtml = false, lastCrumb = false }: BreadcrumbProps<T>): ReactElement {
  return (
    <li>
      {crumb.Url != null &&
      !lastCrumb &&
        <a href={crumb.Url}>
          <BreadcrumbTextOrHtml text={crumb.EncodedText} allowHtml={allowHtml} />
        </a>}
      {(crumb.Url == null ||
      lastCrumb) &&
        <BreadcrumbTextOrHtml text={crumb.EncodedText} allowHtml={allowHtml} />}
    </li>
  )
}

interface BreadcrumbTextOrHtmlProps{
  text: string
  allowHtml?: boolean
}

function BreadcrumbTextOrHtml ({ text, allowHtml = false }: BreadcrumbTextOrHtmlProps): ReactElement {
  return (
    <>
      {allowHtml && <span dangerouslySetInnerHTML={{ __html: text }} />}
      {!allowHtml && <span>{text}</span>}
    </>
  )
}

export { Breadcrumbs }
