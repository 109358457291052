import {
  ReactElement,
  ReactNode,
  ChangeEventHandler,
  forwardRef,
  ForwardedRef
} from 'react'
import clsx from 'clsx'
import '@web/styles/Select.less'

interface SelectProps {
  /*
   * Id of the select
   */
  id?: string
  /*
   * Name of the select
   */
  name?: string
  /*
   * Value of the select
   */
  value?: any
  /*
   * Default value of the select
   */
  defaultValue?: any
  /*
   * Options for the select as children
   */
  children?: ReactNode
  /*
   * Indicates the select is invalid to show fail style
   */
  invalid?: boolean | string
  /*
   * Indicates the select is disabled
   */
  disabled?: boolean
  /*
   * Function to call on select change
   */
  onChange?: ChangeEventHandler
  /**
   * Container class overrride
   */
  containerClassName?: string
  /*
   * Class overrides
   */
  className?: string
  /*
   * Hint for form autofill feature (see https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/autocomplete#values)
   */
  autoComplete?: string
  /*
   * Automatically focus the form control when the page is loaded
   */
  autoFocus?: boolean
  /*
   * Indicates that multiple options can be selected in the list
   */
  multiple?: boolean
  /*
   * Indicates the select is required
   */
  required?: boolean
  /*
   * Title of the select as tooltip
   */
  title?: string
  /*
   * The number of rows in the list that should be visible at one time when using multiple
   */
  size?: number
  /*
   * All other attributes
   */
  [key: string]: any
}

/*
 * Select (dropdown) input component
 */
function Select ({
  id,
  name,
  value,
  defaultValue,
  children,
  invalid,
  disabled,
  onChange,
  containerClassName = 'react-select-container',
  className,
  autoComplete,
  autoFocus,
  multiple,
  required,
  title,
  size,
  ...otherAttributes
}: SelectProps, ref: ForwardedRef<HTMLSelectElement>): ReactElement {
  const classNames = clsx(
    invalid != null && invalid !== false && invalid !== '' && 'validation-input-error',
    className
  )

  return (
    <div className={containerClassName}>
      <select
        id={id}
        name={name}
        value={value}
        defaultValue={defaultValue}
        disabled={disabled}
        onChange={onChange}
        className={classNames}
        autoComplete={autoComplete}
        autoFocus={autoFocus}
        multiple={multiple}
        required={required}
        title={title}
        size={size}
        ref={ref}
        {...otherAttributes}
      >
        {children}
      </select>
    </div>
  )
}

/*
 * Select with forwardRef
 */
const SelectWrapped = forwardRef(Select)

export {
  SelectWrapped as Select
}
