import { ReactElement, useEffect } from 'react'
import { Button, Currency, CmsContent } from '@web/shared-ui-components'
import { useStullerPayLazyQuery } from '@web/shared-data-access-queries'
import { StullerPayFinancingModal } from './StullerPayFinancingModal'
import { StullerPayOracleDownModal, StullerPayIneligibleModal } from '@web/payment-methods'
import { dispatchStullerEvent } from '@web/shared-util-stuller-events'
import { AddToCartDto } from '../../../quote/get-a-quote/feature/types'
import { eraseCookie, readCookie } from '@web/shared-util-cookies'
import { sortStullerPayTermByMonthlyPrice } from 'libs/paymentmethods/util/stuller-pay'

interface StullerPayPdpCalloutProps {
  addToCartData: AddToCartDto
  isLeasingAvailable: boolean
  isInShowcase: boolean
  isSterling: boolean
  isAddToCartModal: boolean
  productItemNumber: string
  productImageUrl: string
  productTitle: string
}

/**
 * StullerPay component for PDPs
 */

function StullerPayPdpCallout ({ addToCartData, isLeasingAvailable, isAddToCartModal, isInShowcase, isSterling, productItemNumber, productImageUrl, productTitle }: StullerPayPdpCalloutProps): ReactElement {
  const showStullerPay = !isInShowcase && !isSterling && !isAddToCartModal && isLeasingAvailable

  // If stullerpay can't be shown, stop render logic here
  if (!showStullerPay) {
    return <></>
  }

  const [getStullerPay, { loading: stullerPayLoading, data: stullerPayData }] = useStullerPayLazyQuery({ fetchPolicy: 'network-only' })
  const productId = addToCartData.InventoryItemId
  const quantity = addToCartData.Quantity
  const termOptions = stullerPayData?.stullerPay?.TermOptions ?? []
  const stullerPayEligible = (stullerPayData?.stullerPay?.Eligible ?? false) && (termOptions.length > 0)
  const lowestMontlyPayment = [...termOptions].sort(sortStullerPayTermByMonthlyPrice).find(term => term.Price >= term.MinimumPrice)?.MonthlyPrice ?? 0
  const showLowestPrice = !stullerPayLoading && stullerPayEligible && lowestMontlyPayment > 0

  function handleCloseModal (): void {
    dispatchStullerEvent('track-universal-event', {
      category: 'ProductPage',
      action: 'StullerPayButton',
      label: 'Closed'
    })
    $('#stullerPayFinancingModal').modal('hide')
  }
  function handleOpenModal (): void {
    if (stullerPayEligible) {
      dispatchStullerEvent('track-event', {
        event: 'hotjar',
        details: { hotjar_events: 'stuller-pay-recording' }
      })
    }
    dispatchStullerEvent('track-universal-event', {
      category: 'ProductPage',
      action: 'StullerPayButton',
      label: 'Opened'
    })
    $('#stullerPayFinancingModal').modal('show')
  }

  useEffect(() => {
    if (quantity > 0) {
      void getStullerPay({
        variables: {
          params: {
            productId,
            quantity
          }
        }
      })
    }
  }, [productId, quantity])

  useEffect(() => {
    dispatchStullerEvent('track-universal-event', {
      category: 'ProductPage',
      action: 'StullerPayButton',
      label: 'Shown'
    })
  }, [])

  // Check if redirected from stullerpay checkout and show corresponding modal
  useEffect(() => {
    const redirectCookie = readCookie('AlertModal')
    if (redirectCookie === 'stullerpay down') {
      eraseCookie('AlertModal')
      $('#stuller-pay-oracle-down-notification').modal('toggle')
    }
    if (redirectCookie === 'stullerpay ineligible') {
      eraseCookie('AlertModal')
      $('#stuller-pay-not-eligible-notification').modal('toggle')
    }
  }, [])

  return (
    <div className='d-flex flex-wrap border justify-content-start u-border-radius-medium u-padding-all-20 my-3'>
      <img alt='Stuller Pay' src='https://assets.stullercloud.com/das/116145457.svg' className='w-100' style={{ maxWidth: '180px' }} />
      <div className='text-left pt-3'>
        <CmsContent contentContainerName='stullerpay-cart-partial' />
      </div>
      <Button onClick={handleOpenModal} color='stullerpay' className='w-100' data-test='see-finance-options-button' style={{ minWidth: 'auto', paddingTop: stullerPayEligible ? 6 : 10 }} disabled={addToCartData?.Quantity < 1}>
        <div>
          <div className='t-leading-loose f700'>See Finance Options</div>
          {showLowestPrice && (
            <div className='t-leading-tight f400 f6'>from <Currency data-test='from-pricing' amount={lowestMontlyPayment} />/mo</div>
          )}
        </div>
      </Button>
      {addToCartData.Quantity < 1 && (
        <div className='w-100 text-center pt-3'>
          <span className='c-red'>Quantity must be greater than 0</span>
        </div>
      )}
      <StullerPayFinancingModal
        onToggle={handleCloseModal}
        stullerPayEligible={stullerPayEligible}
        stullerPayLoading={stullerPayLoading}
        productItemNumber={productItemNumber}
        productImageUrl={productImageUrl}
        productTitle={productTitle}
        addToCartData={addToCartData}
        termOptions={termOptions}
      />
      <StullerPayIneligibleModal />
      <StullerPayOracleDownModal />
    </div>
  )
}

export { StullerPayPdpCallout }
