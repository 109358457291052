import { CategoryOrSearchViewModel, SearchPresentationContext } from '@web/shared-data-access-queries'
import { CmsContent } from '@web/shared-ui-components'
import { ReactElement, useMemo } from 'react'
import { useUserContext } from '@web/shared-data-access-context'

interface ProductResultsCMSSectionProps{
  topRowInResultsContentId: CategoryOrSearchViewModel['TopRowInResultsContentID']
  category: CategoryOrSearchViewModel['Category']
  categoryId: CategoryOrSearchViewModel['CategoryId']
  presentationContext: SearchPresentationContext
}

/*
 This is a component for the CMS Section at the top of the product results
 If there is no content to show and the user is in CMS mode, a form will be displayed to add new content
*/
function ProductResultsCMSSection ({ topRowInResultsContentId, category, categoryId, presentationContext }: ProductResultsCMSSectionProps): ReactElement {
  const { context } = useUserContext()
  const IsInCMSMode = useMemo(() => context?.IsInCmsMode, [context?.IsInCmsMode])
  const isDealsOrSalesEvent = useMemo(() => [
    SearchPresentationContext.Deals,
    SearchPresentationContext.SalesEvent,
    SearchPresentationContext.StullerFirstSale
  ].includes(presentationContext), [presentationContext])
  const showContent = useMemo(() => {
    return topRowInResultsContentId != null && context?.IsSterling === false && !isDealsOrSalesEvent
  }, [topRowInResultsContentId, context?.IsSterling, isDealsOrSalesEvent])
  const showForm = useMemo(() => {
    return !showContent &&
      IsInCMSMode &&
      context?.IsSterling === false &&
      categoryId != null
  }, [showContent, IsInCMSMode, context?.IsSterling, categoryId])

  return (
    <>
      {(showContent &&
        <div className='u-flex-grid-row'>
          <div className='px-0 px-sm-4'>
            <CmsContent contentContainerId={topRowInResultsContentId ?? 0} />
          </div>
        </div>
      )}
      {((showForm === true) &&
        <div className='addCMSBlock'>
          <form action='/categories/CreateNewContentBlock/' method='post'>
            <input id='blockName' name='blockName' type='hidden' value={`${category ?? ''} (${categoryId ?? 0}) - Featured Item List in Results`} />
            <input id='categoryId' name='categoryId' type='hidden' value={categoryId ?? ''} />
            <input id='contentBlockLocation' name='contentBlockLocation' type='hidden' value='TopRowInResults' />
            <input id='contentType' name='contentType' type='hidden' value='FeaturedItem' />
            <button className='greenButtonLink' type='submit'>Create a featured list here</button>
          </form>
        </div>)}
    </>
  )
}

export { ProductResultsCMSSection }
