import { ReactElement } from 'react'
import { Currency } from '@web/shared-ui-components'
import { TermOption } from '@web/shared-data-access-queries'
import { StullerPayTermsOptions } from '@web/payment-methods'

interface StullerPayFinancingTermsProps {
  termsOptions: TermOption[]
  productItemNumber: string
  productImageUrl: string
  productTitle: string
  selectedTermId: string
  onChange: (termId: string) => void
}

function StullerPayFinancingTerms ({ termsOptions, productItemNumber, productImageUrl, productTitle, selectedTermId, onChange }: StullerPayFinancingTermsProps): ReactElement {
  const productPrice = termsOptions[0].Price
  const selectedTerm = termsOptions.find(x => x.Option === selectedTermId)
  return (
    <>
      <div className='d-none d-md-flex flex-row align-items-center mb-4'>
        <img className='pr-3' src={productImageUrl} alt={productTitle} />
        <div className='flex-grow-1'>
          <div className='u-bold'>Viewing Terms for</div>
          <div className='my-1'>{productTitle}</div>
          <div>Item # {productItemNumber}</div>
        </div>
        <div className='pl-3'>
          <h3 className='pb-0 mb-0'>
            <Currency amount={productPrice} data-test='item-amount' />
          </h3>
        </div>
      </div>

      <StullerPayTermsOptions termsOptions={termsOptions} selectedTermId={selectedTermId} onChange={onChange} />
      <StullerPayPriceBreakdown selectedTerm={selectedTerm} />
    </>
  )
}

interface StullerPayPriceBreakdownProps {
  selectedTerm: TermOption | undefined
}

function StullerPayPriceBreakdown ({ selectedTerm }: StullerPayPriceBreakdownProps): ReactElement | null {
  if (selectedTerm == null) {
    return null
  }

  const totalPrice = selectedTerm.Price + selectedTerm.FeeAmount + (selectedTerm.ShippingCost ?? 0)
  return (
    <div className='mb-4'>
      <div className='d-flex justify-content-between mb-2'>
        <div>Item Price</div>
        <Currency amount={selectedTerm.Price} data-test='item-price' />
      </div>
      <div className='d-flex justify-content-between mb-2'>
        <div>Finance Fee</div>
        <Currency amount={selectedTerm.FeeAmount} data-test='finance-fee' />
      </div>
      <div className='d-flex justify-content-between mb-2'>
        <div>Shipping</div>
        {
          selectedTerm.ShippingCost != null
            ? <Currency amount={selectedTerm.ShippingCost} />
            : <span>N/A</span>
        }
      </div>
      <div className='d-flex justify-content-between mb-2'>
        <div>Total</div>
        <Currency amount={totalPrice} data-test='total-stullerpay-price' />
      </div>
      <div className='d-flex justify-content-between mb-3'>
        <div>Finance Term</div>
        <div data-test='selected-term'>{selectedTerm.TermValue} Months</div>
      </div>
      <hr className='mb-4' />
      <div className='d-flex justify-content-between align-items-center'>
        <div>Estimated Monthly Payment</div>
        <h1 className='mb-0 pb-0'><Currency amount={selectedTerm.MonthlyPrice} data-test='estimated-monthly-payment' /></h1>
      </div>
    </div>
  )
}

export { StullerPayFinancingTerms }
