import clsx from 'clsx'
import {
  ReactElement,
  useCallback,
  useMemo,
  useRef
} from 'react'
import { ModalHeader, Modal, ModalBody } from '.'
import '@web/styles/ImageModalThumbnail.less'

interface ImageModalThumbnailProps {
  /**
   * Id of element
   */
  id: string
  /**
    * Additional class name(s) to give to the containing element
    */
  className?: string
  /**
   * The default thumbnail image
   */
  thumbnailImageUrl: string
  /**
   * The zoomed in image to show in the modal
   */
  modalImageUrl: string
  /**
   * Modal description for the header
   */
  description?: string
}

/**
 * Clickable thumbnail that opens a modal with a full sized image
 */
function ImageModalThumbnail ({
  id,
  className,
  thumbnailImageUrl,
  modalImageUrl,
  description
}: ImageModalThumbnailProps): ReactElement {
  const documentBody = useRef(document.body)
  const classNames = useMemo(() => clsx(
    'position-relative overflow-hidden d-inline-block u-cursor-pointer',
    className
  ), [className])

  const handleModalToggle = useCallback(() => $(`#${id}`).modal('toggle'), [id])

  return (
    <>
      <Modal portalRef={documentBody} id={id} size='default'>
        <ModalHeader title={description} onToggle={handleModalToggle} />
        <ModalBody>
          <div className='d-flex justify-content-center u-padding-top-20'>
            <img src={modalImageUrl} className='img-responsive' />
          </div>
        </ModalBody>
      </Modal>
      <div
        id='thumbnail-image-container'
        className={classNames}
        onClick={handleModalToggle}
      >
        <img
          className='img-responsive'
          src={thumbnailImageUrl}
        />
        <div className='dark-zoom-icon' />
      </div>
    </>
  )
}

export { ImageModalThumbnail }
