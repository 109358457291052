import { ReactElement, useMemo } from 'react'
import { Alert, FilesTypesAccepted } from '@web/shared-ui-components'
import { FileError, FileRejection } from 'react-dropzone'

interface FileUploadErrorProps {
  rejectedFiles: FileRejection[]
  fileTypesAccepted: FilesTypesAccepted
}

function FileUploadError ({ rejectedFiles, fileTypesAccepted }: FileUploadErrorProps): ReactElement {
  const errors = useMemo<ReactElement[]>(() => {
    const errorMessages: Map<string, string[]> = new Map()

    rejectedFiles.forEach(fileRejection => {
      fileRejection.errors.forEach((fileError: FileError) => {
        let errorMessage: string
        let existingErrors: string[] | null

        switch (fileError.code) {
          case 'file-invalid-type': {
            errorMessage = `The following files cannot be uploaded because of an unsupported file type.
             Only the following extensions are allowed: ${fileTypesAccepted.label ?? ''}.`
            existingErrors = errorMessages.get(errorMessage) ?? []
            break
          }
          case 'too-many-files': {
            errorMessage = `You reached the maximum number of files allowed for upload.
              Please remove some files and try again.`
            existingErrors = null
            break
          }
          case 'file-too-large': {
            errorMessage = `The following files cannot be uploaded because the file sizes are too large.
             Please reduce the size of the files and try again.`
            existingErrors = errorMessages.get(errorMessage) ?? []
            break
          }
          case 'file-too-small': {
            errorMessage = `The following files cannot be uploaded because the file sizes are too small.
             Please select a larger file and try again`
            existingErrors = errorMessages.get(errorMessage) ?? []
            break
          }
          default: {
            errorMessage = 'Something went wrong uploading the following files.'
            existingErrors = errorMessages.get(errorMessage) ?? []
            break
          }
        }

        existingErrors = existingErrors == null ? [] : [...errorMessages.get(errorMessage) ?? [], fileRejection.file.name]
        errorMessages.set(errorMessage, existingErrors)
      })
    })

    const errorElements: ReactElement[] = []

    errorMessages.forEach((fileNames, errorMessage) => {
      if (fileNames.length === 0) {
        errorElements.push(<>{errorMessage}</>)
      } else {
        errorElements.push(
          <>
            {errorMessage}
            <ul style={{ listStyle: 'disc' }}>
              {fileNames.map(fileName => <li key={fileName} className='ml-2'>{fileName}</li>)}
            </ul>
          </>
        )
      }
    })

    return errorElements
  }, [rejectedFiles, fileTypesAccepted])

  return (
    <>
      {errors.length > 0 &&
        <Alert alertType='error' data-test='file-upload-error'>
          <ul className='u-no-list-style'>
            {errors.map((error, index) => <li key={index} className='ml-2'>{error}</li>)}
          </ul>
        </Alert>}
    </>
  )
}

export { FileUploadError }
