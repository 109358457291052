import {
  ReactElement,
  ReactNode,
  KeyboardEventHandler
} from 'react'
import { DateSelector } from '@web/shared-ui-components'
import '@web/styles/DateRangeSelector.less'

interface MinMaxDateRange {
  minDate: Date
  maxDate: Date
}

interface DateRangeSimple {
  startDate: Date
  endDate: Date
}

interface DateRangeProps {
  /*
   * Start Date Object
   */
  startDate: Date
  onStartDateChange: (startDate: Date) => void
  /*
   * End Date Object
   */
  endDate: Date
  onEndDateChange: (endDate: Date) => void
  /*
   * Submit element
   */
  children?: ReactNode
  /*
   * Max Date Object
   */
  maxDate?: Date
  /*
   * Max Date Object
   */
  minDate?: Date
  /**
   * When pressing enter this action will trigger
   */
  onKeyDown?: KeyboardEventHandler
}

/*
 * Combines two date selectors to select a date range
 */
function DateRangeSelector ({
  startDate,
  onStartDateChange,
  endDate,
  onEndDateChange,
  maxDate,
  minDate,
  onKeyDown,
  children
}: DateRangeProps): ReactElement {
  return (
    <div className='date-range-container'>
      <div className='d-flex date-section-container no-gutters'>
        <div className='d-flex date-section'>
          <span className='t-tiny-upper mb-1 px-0'>Start Date</span>
          <div className='date-selector-container'>
            <DateSelector
              onKeyDown={onKeyDown}
              selectedDate={startDate}
              onChange={onStartDateChange}
              inputClassName='date-selector-start-input u-border-radius-0'
              selectsstart='true'
              startdate={startDate}
              enddate={endDate}
              maxDate={endDate}
              minDate={minDate}
              data-test='date-range-selector-start-date'
            />
          </div>
        </div>
        <div className='d-flex date-section'>
          <span className='t-tiny-upper mb-1 px-0'>End Date</span>
          <div className='date-selector-container'>
            <DateSelector
              onKeyDown={onKeyDown}
              selectedDate={endDate}
              onChange={onEndDateChange}
              selectsend='true'
              inputClassName='date-selector-end-input u-border-radius-0'
              startdate={startDate}
              enddate={endDate}
              maxDate={maxDate}
              minDate={startDate}
              data-test='date-range-selector-end-date'
            />
          </div>
        </div>
        <div className='align-self-end submit-section'>
          {children}
        </div>
      </div>
    </div>
  )
}

export { DateRangeSelector, MinMaxDateRange, DateRangeSimple }
