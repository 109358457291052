import { InMemoryCache } from '@apollo/client'
import { darkModeVar } from './var'

// List of configuration options available
// https://www.apollographql.com/docs/react/caching/cache-configuration/#configuration-options
export const cache: InMemoryCache = new InMemoryCache({
  typePolicies: { // Type policy map
    Query: {
      fields: {
        darkMode: {
          read () {
            return darkModeVar()
          }
        }
      }
    }
  }
})
