import { ReactElement, useState, useEffect } from 'react'
import { LaserEngravedModalValue, ButtonToggleGroup, ButtonToggleOptions } from '@web/shared-ui-components'

interface EngravingColorOption {
  label: string
  fillColor: string
  imageUrl?: string
  value: string
}

const engravingColors: EngravingColorOption[] = [
  { label: 'None', fillColor: 'bfbab8', value: 'NO COLOR' },
  { label: 'Black', fillColor: '000000', imageUrl: 'https://assets.stullercloud.com/das/84852076.svg', value: 'BLACK' },
  { label: 'Blue', fillColor: '0000FE', imageUrl: 'https://assets.stullercloud.com/das/84852077.svg', value: 'BLUE' },
  { label: 'Brown', fillColor: '553800', imageUrl: 'https://assets.stullercloud.com/das/84852078.svg', value: 'BROWN' },
  { label: 'Green', fillColor: '009900', imageUrl: 'https://assets.stullercloud.com/das/84852080.svg', value: 'GREEN' },
  { label: 'Pink', fillColor: 'FEBCBD', imageUrl: 'https://assets.stullercloud.com/das/84983654.svg', value: 'PINK' },
  { label: 'Red', fillColor: 'FE0000', imageUrl: 'https://assets.stullercloud.com/das/84852084.svg', value: 'RED' }
]

interface LaserEngravingColorsProps {
  input: LaserEngravedModalValue
  onInputChange?: (key: 'EngravingColor', value: EngravingColorOption) => void
}

function LaserEngravingColor ({ input, onInputChange }: LaserEngravingColorsProps): ReactElement {
  const [selectedValue, setSelectedValue] = useState<string>(engravingColors.find(x => x.fillColor === input.EngravingColor.fillColor)?.fillColor ?? 'NO COLOR')

  useEffect(() => {
    const selectedEngravingColor = engravingColors.find(x => x.fillColor === selectedValue) ?? engravingColors[0]
    onInputChange?.('EngravingColor', selectedEngravingColor)
  }, [selectedValue])

  const toggleColorOptions: ButtonToggleOptions[] = engravingColors.map((colorOption: EngravingColorOption) => (
    {
      label: (
        <>
          <div style={{ height: '20px', width: '20px' }}>
            {colorOption.imageUrl != null &&
              <img
                src={colorOption.imageUrl}
                alt={colorOption.label}
              />}
          </div>

          <div className='ml-2 f-roboto-light'>{colorOption.label}</div>
        </>
      ),
      value: colorOption.fillColor
    }
  ))

  return (
    <>
      <div className='t-tiny-upper'>Color</div>

      <ButtonToggleGroup
        options={toggleColorOptions}
        currentValue={selectedValue}
        setCurrentValue={setSelectedValue}
        buttonClassName='justify-content-start'
      />
    </>
  )
}

export { LaserEngravingColor, EngravingColorOption, engravingColors }
