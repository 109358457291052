import { ReactElement, useEffect, useState } from 'react'
import { LaserEngravedModalValue, ButtonToggleGroup, ButtonToggleOptions } from '@web/shared-ui-components'

interface EngravingTypeOption {
  label: string
  value: string
  icon: JSX.Element
}

const engravingTypes: EngravingTypeOption[] = [
  {
    label: 'Text',
    value: 'Laser Engraving',
    icon: (
      <svg viewBox='0 0 39.29 42.75' style={{ width: '27.5px', height: '20px' }}>
        <path d='M10,29,5.7,41.6h5.65v1.15H0V41.6H4.1L18.65,0h1.73l14,41.6h4.87v1.15H21.86V41.6H28L23.65,29ZM16.86,9,10.45,27.88H23.2Z' />
      </svg>
    )
  }
]

interface LaserEngravingTypesProps {
  input: LaserEngravedModalValue
  onInputChange?: (key: 'EngravingType', value: EngravingTypeOption) => void
}

function LaserEngravingType ({ input, onInputChange }: LaserEngravingTypesProps): ReactElement {
  const [selectedEngravingType, setSelectedEngravingType] = useState<string>(engravingTypes.find(x => x.value === input.EngravingType.value)?.value ?? engravingTypes[0].value)

  useEffect(() => {
    onInputChange?.('EngravingType', engravingTypes.find(x => x.value === selectedEngravingType) ?? engravingTypes[0])
  }, [selectedEngravingType])

  const toggleTypeOptions: ButtonToggleOptions[] = engravingTypes.map((type: EngravingTypeOption) => (
    {
      label: (
        <>
          <div className='d-flex align-items-center justify-content-center pr-2'>
            <div className='engraving-type-icon'>{type.icon}</div>
            <div className='ml-2 t-tiny-upper'>{type.label}</div>
          </div>
        </>),
      value: type.value
    }
  ))

  return (
    <div className='react-laser-engraving-type'>
      <div className='t-tiny-upper'>Engraving Type</div>

      <ButtonToggleGroup
        options={toggleTypeOptions}
        currentValue={selectedEngravingType}
        setCurrentValue={setSelectedEngravingType}
        buttonClassName='justify-content-start'
        gridTemplateColumns='repeat(auto-fit, minmax(80px, 100px))'
      />
    </div>
  )
}

export { LaserEngravingType, EngravingTypeOption, engravingTypes }
