import {
  ReactElement, useMemo
} from 'react'
import clsx from 'clsx'
import { CardProps, Color, ImprintingInfo } from './types'

interface ImprintingTemplateData {
  templateData: ImprintingInfo
}

function ConfigurationImage ({
  templateData
}: ImprintingTemplateData): ReactElement | null {
  if (templateData.ImagePath == null) {
    return null
  }
  return <img className='img-responsive' src={templateData?.ImagePath} alt={`${templateData.Location} Logo`} />
}

function ImprintingColor ({ DisplayValue, HexValue }: Color): ReactElement {
  const imprintingFillColorClassNames = useMemo(() => clsx(
    'c-imprinting-color-swatch c-imprinting-icon-small',
    DisplayValue.toUpperCase().includes('WHITE') && 'border',
    DisplayValue.toUpperCase().includes('METALLIC') && 'metallic-color'
  ), [DisplayValue])

  return (
    <div className='d-flex flex-row justify-content-start align-items-center pt-1'>
      {HexValue != null &&
        <div className={imprintingFillColorClassNames} style={{ backgroundColor: HexValue }} />}
      <div className='mb-n1 font-weight-light'>
        {DisplayValue}
      </div>
    </div>
  )
}

function ImprintingColors ({ templateData }: ImprintingTemplateData): ReactElement {
  return (
    <div>
      {templateData.Colors?.map((color, index) =>
        <div className='d-flex flex-column pt-2 justify-content-center' key={index}>
          <span className='t-ui-label'>color #{Number(index) + 1}</span>
          <ImprintingColor DisplayValue={color.DisplayValue} HexValue={color.HexValue} Instructions={color.Instructions} />
          {color.Instructions !== '' && color.Instructions != null &&
            <div className='d-flex flex-column mb-2 pt-1'>
              <span className='t-ui-label'>Notes for Color #{Number(index) + 1}</span>
              <span className='font-weight-light'>{color.Instructions}</span>
            </div>}
        </div>
      )}
    </div>
  )
}

/**
 * Card used for imprinting configurations
 */
function ImprintingCard ({
  id,
  className,
  config
}: CardProps): ReactElement {
  const imprintingInfo: ImprintingInfo[] = useMemo(() => config.TemplateData, [config])
  const classNames = useMemo(() => clsx(
    'configurationCard c-bg-white mb-2 u-padding-all-15 d-flex flex-column',
    className
  ),
  [className])
  const lineDrawing = useMemo(() => imprintingInfo?.[0]?.LineDrawingViewModel.ImageUrl, [imprintingInfo?.[0]])

  return (
    <div id={id} className={classNames}>
      <div className='u-flex-grid-row align-items-center py-2 no-gutters'>
        <div className='u-flex-grid-col-2'>
          <img className='img-responsive' src={lineDrawing} alt={`${imprintingInfo?.[0].Location} Line Drawing`} />
        </div>
        <div className='u-flex-grid-col-9 text-left'>
          <span className='font-weight-bold px-4'>{config.Title}</span>
        </div>
        <div className='u-flex-grid-col-12'>
          <hr className='c-bg-gray-lt-1' />
        </div>
      </div>
      {imprintingInfo.map((item, index) =>
        <div key={index}>
          <div className='u-flex-grid-row mx-0'>
            <div className='u-flex-grid-col-2 px-0'>
              <ConfigurationImage templateData={item} />
            </div>
            <div className='u-flex-grid-col-10 pr-0'>
              <ImprintingColors templateData={item} />
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
export { ImprintingCard }
