import {
  ReactElement,
  ReactNode,
  forwardRef,
  ForwardedRef,
  useEffect,
  useRef,
  useImperativeHandle
} from 'react'
import {
  Input,
  InputProps
} from '@web/shared-ui-components'
import clsx from 'clsx'
import { useImportantStyle } from '@web/shared-util-hooks'

interface CheckboxProps extends InputProps {
  /*
   * Indicates the input should be rendered as a switch (for checkbox, radio)
   */
  isSwitch?: boolean
  /*
   * Options for the select as children
   */
  children?: ReactNode
  /*
   * Options for the select as children
   */
  indeterminate?: boolean
}

/*
 * Creates a checkbox out of the Input wrapper component
 */
function Checkbox ({
  id,
  name,
  value,
  checked,
  className,
  onChange,
  children,
  indeterminate = false,
  isSwitch = false,
  noWrapperMargin = false,
  ...otherAttributes
}: CheckboxProps, ref: ForwardedRef<HTMLInputElement>): ReactElement {
  const classNames = clsx(className, isSwitch && 'switch', indeterminate && 'indeterminate')

  const innerRef = useRef<HTMLInputElement>(null)
  useImperativeHandle(ref, () => innerRef.current as HTMLInputElement)

  useEffect(() => {
    if (innerRef.current == null || indeterminate == null) {
      return
    }

    innerRef.current.indeterminate = indeterminate
  }, [indeterminate, innerRef.current])

  return (
    <div className='form-input-group' ref={(cb) => noWrapperMargin && useImportantStyle(cb, 'margin', '0px')}>
      <Input
        type='checkbox'
        id={id}
        name={name}
        value={value}
        checked={checked}
        className={classNames}
        onChange={onChange}
        ref={innerRef}
        {...otherAttributes}
      />
      {children}
    </div>
  )
}

/* Radio with forwardRef */
const CheckboxWrapped = forwardRef(Checkbox)

export { CheckboxWrapped as CheckboxOld }
