import {
  ReactElement,
  useMemo
} from 'react'
import clsx from 'clsx'
import { DefaultInfo, CardProps } from './types'
import { ImageModalThumbnail } from '../ImageModalThumbnail'
import uuid from 'react-uuid'

interface DefaultTemplateData {
  templateData?: DefaultInfo
}

function ConfigurationImage ({
  templateData
}: DefaultTemplateData): ReactElement | null {
  if (templateData?.Images == null || templateData?.Images.length === 0) {
    return null
  }
  if (templateData?.Title === 'I will send my stone') {
    return (
      <img src={templateData.Images[0].Thumb75Url} className='img-responsive' />
    )
  }
  const uniqueId = useMemo(() => uuid(), [])
  return (
    <ImageModalThumbnail
      id={uniqueId}
      thumbnailImageUrl={templateData.Images[0].Thumb75Url}
      modalImageUrl={templateData.Images[0].Size640Url}
      description={templateData.Title}
    />
  )
}
function ConfigurationDescription ({ templateData }: DefaultTemplateData): ReactElement | null {
  if (templateData?.Description == null) return null

  return (
    <div>{templateData?.Description}</div>
  )
}

function ConfigurationTitle ({ templateData }: DefaultTemplateData): ReactElement {
  const isSkuClickable = templateData?.ProductUrl != null && templateData?.IsActive === true

  return (
    <div>
      <div className='font-weight-bold text-wrap'>{templateData?.Title}</div>
      {isSkuClickable
        ? (
          <a
            className='font-weight-bold primary-link c-primary'
            target='_blank'
            rel='noreferrer'
            href={templateData?.ProductUrl}
          >
            {templateData?.ItemNumber}
          </a>
          )
        : (
          <div
            className='font-weight-bold'
          >
            {templateData?.ItemNumber}
          </div>)}
    </div>
  )
}

function ConfigurationQuantity ({ templateData }: DefaultTemplateData): ReactElement | null {
  if (templateData?.Quantity == null) {
    return null
  }

  return (
    <span className='text-nowrap'>Qty: {templateData.Quantity}</span>
  )
}

function ConfigurationLocation ({ templateData }: DefaultTemplateData): ReactElement | null {
  if (templateData?.Location == null) {
    return null
  }

  return (
    <div className='c-gray-dk-1 font-weight-bold'>{templateData.Location}</div>
  )
}

/**
 * Card used for configurations labeled as default (aka basic)
 */
function DefaultCard ({
  id,
  className,
  config
}: CardProps): ReactElement {
  const defaultInfo: DefaultInfo[] = useMemo(() => config.TemplateData, [config])
  const classNames = useMemo(() => clsx(
    className,
    'configurationCard c-bg-white mb-2 u-padding-all-15 d-flex flex-column'
  ),
  [className])

  return (
    <div id={id} className={classNames}>
      <div className='mb-1'>
        <div className='templateTitle'>{config.Title}</div>
        <div className='mt-n1'>{config.Description}</div>
      </div>
      {defaultInfo !== null &&
        <div className='u-flex-grid-row'>
          <div className='u-flex-grid-col-12'>
            <hr className='c-bg-gray-lt-1' />
          </div>
        </div>}
      {defaultInfo?.map((item, index) =>
        item.Title != null &&
          <div className='u-flex-grid-row pt-3 mx-0 u-justify-content-center t-leading-tight align-items-center' key={index}>
            <div className='u-flex-grid-col-2 u-flex-grid-col-md-1 u-flex-grid-col-lg-2 px-0'>
              <ConfigurationImage templateData={item} />
            </div>
            <div className='u-flex-grid-col-8 u-flex-grid-col-md-9 u-flex-grid-col-lg-8 pr-0'>
              <ConfigurationTitle templateData={item} />
              <ConfigurationDescription templateData={item} />
              <ConfigurationLocation templateData={item} />
            </div>
            <div className='u-flex-grid-col-2 px-0 text-right'>
              <ConfigurationQuantity templateData={item} />
            </div>
          </div>
      )}
    </div>
  )
}
export { DefaultCard }
