import {
  HTMLAttributes,
  ReactElement,
  ReactNode
} from 'react'
import clsx from 'clsx'

interface CardProps extends HTMLAttributes<HTMLElement> {
  /*
   * Class overrides
   */
  className?: string
  /*
   * Render content here
   */
  children?: ReactNode
}

/*
 * Card component
 */
function Card ({ className, children, ...otherAttributes }: CardProps): ReactElement {
  return (
    <div className={clsx(className, 'card')} {...otherAttributes}>
      {children}
    </div>
  )
}

export { Card }
