import { ReactElement, useEffect, useState } from 'react'
import { FontViewModel } from '@web/shared-data-access-queries'
import { LaserEngravedModalValue, ButtonToggleGroup, ButtonToggleOptions } from '@web/shared-ui-components'

interface EngravingFontOption {
  MeteorFont: string
  Description: string
}

interface FontImagesProps {
  description: string
  meteorScalingFactor: string
  meteorFont: string
}

function FontImages ({
  description,
  meteorScalingFactor,
  meteorFont
}: FontImagesProps): ReactElement {
  let scalingFactor: number = parseFloat(meteorScalingFactor)
  scalingFactor = isNaN(scalingFactor) ? 1 : (scalingFactor > 1 ? 1 : scalingFactor)
  const fontSize: number = description === 'Ornate' ? 20 : 20 * scalingFactor

  function imageString (color: string): string {
    return `https://meteor.stullercloud.com/?text=Aa&size=30,20&text.color=${color}&text.font=${meteorFont}&text.size=${fontSize}&fmt=smart-alpha`
  }

  return (
    <>
      <img className='font-image-light' src={imageString('white')} />
      <img className='font-image-dark' src={imageString('%23777777')} />
    </>
  )
}

interface LaserEngravingFontsProps {
  fonts: FontViewModel[]
  input: LaserEngravedModalValue
  onInputChange?: (key: 'FontFaceOptions', value: EngravingFontOption) => void
}

function LaserEngravingFont ({ fonts, input, onInputChange }: LaserEngravingFontsProps): ReactElement {
  const [selectedMeteorFontValue, setSelectedMeteorFontValue] = useState<string>(fonts.find(x => x.MeteorFont === input.FontFaceOptions.MeteorFont)?.MeteorFont ?? fonts[0].MeteorFont)

  const toggleFontOptions: ButtonToggleOptions[] = []
  fonts.forEach((font: FontViewModel) => {
    toggleFontOptions.push(
      {
        label: (
          <>
            <FontImages description={font.Description} meteorScalingFactor={font.MeteorFontSizeScalingFactor} meteorFont={font.MeteorFont} />

            <div className='ml-3 text-truncate text-left'>
              {font.Description}
            </div>
          </>),
        value: font.MeteorFont
      }
    )
  })

  useEffect(() => {
    onInputChange?.('FontFaceOptions', fonts.find(font => font.MeteorFont === selectedMeteorFontValue) ?? fonts[0])
  }, [selectedMeteorFontValue])

  return (
    <div className='react-laser-engraving-font'>
      <ButtonToggleGroup
        options={toggleFontOptions}
        currentValue={selectedMeteorFontValue}
        setCurrentValue={setSelectedMeteorFontValue}
        gridTemplateColumns='repeat(auto-fit, minMax(170px, 1fr))'
        buttonClassName='justify-content-start'
      />
    </div>
  )
}

export { LaserEngravingFont, EngravingFontOption }
