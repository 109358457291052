import { PluginFunc } from 'dayjs'

/**
 * Overrides locale properties like `formats`
 */
const plugin: PluginFunc = (option, Dayjs, dayjs) => {
  // English overrides
  dayjs.Ls.en.formats = {
    ...dayjs.Ls.en.formats,
    LT: 'h:mm a',
    LTS: 'h:mm:ss a',
    LLL: 'MMMM D, YYYY h:mm a',
    LLLL: 'dddd, MMMM D, YYYY h:mm a'
  }
}

export default plugin
