import { useImagePreloader } from '@web/shared-util-hooks'

interface ProductResultCardImageLoaderProps{
  urls: string[]
}

function ProductResultCardImageLoader ({ urls }: ProductResultCardImageLoaderProps): null {
  useImagePreloader(urls)
  return null
}

export { ProductResultCardImageLoader }
