import {
  ReactElement,
  ReactNode,
  useContext
} from 'react'
import { AddPaymentMethodContext } from '@web/payment-methods'
import { SlideDownTransition } from '@web/shared-ui-components'

interface AddPaymentMethodBodyProps {
  children: ReactNode
}

/**
 * AddPaymentMethodBody component
 */
function AddPaymentMethodBody ({ children }: AddPaymentMethodBodyProps): ReactElement {
  const { isCollapsed } = useContext(AddPaymentMethodContext)

  return (
    <SlideDownTransition allowScroll={false} isOpen={!isCollapsed}>
      <div className='p-4'>
        {children}
      </div>
    </SlideDownTransition>
  )
}

export {
  AddPaymentMethodBody
}
