import { ReactElement, useState } from 'react'
import { Badge, IndividualBadgeProps, Popover } from '@web/shared-ui-components'

function EverAndEver ({ popoverZIndex, ...otherAttributes }: IndividualBadgeProps): ReactElement {
  const [reference, setReference] = useState<HTMLElement | null>(null)
  const [open, setOpen] = useState(false)

  return (
    <>
      <Badge
        ref={setReference}
        pill
        badgeColor='ever-and-ever'
        {...otherAttributes}
      >
        ever&ever<sup>&reg;</sup>
      </Badge>
      <Popover
        placement='top'
        style={{ maxWidth: '300px', zIndex: popoverZIndex }}
        hover
        hoverProps={{ delay: { open: 300 } }}
        offset={5}
        open={open}
        onOpenChange={setOpen}
        reference={reference}
      >
        <span className='font-weight-bold'>ever&ever<sup>&reg;</sup>: </span>
        Stuller’s complete bridal program featuring high-quality customizable engagement rings, marketing support, and in-store prototype selling kits. <a href='/everandever' className='primary-link'>Learn More</a>
      </Popover>
    </>
  )
}

export {
  EverAndEver
}
