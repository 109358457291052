import { ReactElement, ReactNode, useMemo } from 'react'
import clsx from 'clsx'
import '@web/styles/Table.less'

interface TableProps {
  /*
   * classes that will be applied to the table
   */
  className?: string
  /*
   * Children will consist of the table header and table body
   */
  children?: ReactNode
  /*
   * Designate what table style do you want
   */
  tableStyle?: 'gray' | 'white' | 'striped'
  tableSize?: 'normal' | 'small'
}

function Table ({ className, children, tableStyle, tableSize = 'normal', ...otherAttributes }: TableProps): ReactElement {
  const classNames = useMemo(() => clsx(
    'react-table',
    tableStyle === 'gray' && 'react-table-gray',
    tableStyle === 'white' && 'react-table-white',
    tableStyle === 'striped' && 'react-table-striped',
    tableSize === 'small' && 'react-table-small',
    className
  ), [tableStyle, tableSize])

  return (
    <table className={classNames} {...otherAttributes}>
      {children}
    </table>
  )
}

export { Table }
