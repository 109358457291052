import {
  ReactElement,
  useCallback,
  ChangeEvent,
  useState,
  useMemo,
  useEffect
} from 'react'
import { Input, FormError } from '@web/shared-ui-components'
import { CreditCardType } from 'credit-card-type/dist/types'
import { useImportantStyle } from '@web/shared-util-hooks'
import creditCardType from 'credit-card-type'
import InputMask from 'react-input-mask'
import Verification from 'card-validator'
import uuid from 'react-uuid'
import { CardIcon } from '@web/payment-methods'

function getCardType (cardNumber?: string): CreditCardType | null {
  const matchingCardTypes = cardNumber == null || cardNumber.trim() === '' ? null : creditCardType(cardNumber)
  return matchingCardTypes == null || matchingCardTypes.length === 0 ? null : matchingCardTypes[0]
}

interface CreditCardInputProps{
  /**
   * Input name field
   */
  name: string
  /**
   * Input value field
   */
  value?: string | null
  /**
   * Input required field
   */
  required?: boolean
  /**
   * Input onChange event
   */
  onChange: (formattedCreditCardNumber: string, cardType: CreditCardType | null) => void
  /**
   * Is panpad being used
   */
  usePanPad: boolean | undefined
  /**
   * Invalid styling
   */
  invalid?: boolean | string
}

function CreditCardInput ({ name, value, required, onChange, usePanPad = false, invalid }: CreditCardInputProps): ReactElement {
  const uniqueId = useMemo(() => `card-number-${uuid() as string}`, [])
  const [cardType, setCardType] = useState<CreditCardType | null>(null)
  const [cardIsValid, setCardIsValid] = useState<boolean>(true)
  const cardTypeMaxLength = useMemo(() => cardType != null ? Math.max(...cardType.lengths) : 16, [cardType])
  const cardMask = useMemo(() => {
    const mask: Array<string | RegExp> = []
    for (let i = 0; i < cardTypeMaxLength; i++) {
      if (cardType != null && cardType.gaps.filter(x => x === i).length > 0) {
        mask.push(' ')
      }
      if (!usePanPad) {
        mask.push(/[0-9]/)
      } else {
        mask.push(/.*/)
      }
    }
    return mask
  }, [cardType, cardTypeMaxLength])

  const handleCardInputChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    const cardInputValue = event.target.value
    const matchingCardType = getCardType(cardInputValue)
    setCardType(matchingCardType)
    setCardIsValid(Verification.number(cardInputValue).isPotentiallyValid)
    onChange(cardInputValue, matchingCardType)
  }, [onChange])

  useEffect(() => {
    if (value === '' || value == null) {
      setCardType(null)
    } else if (usePanPad) {
      const cardInputValue = value
      const matchingCardType = getCardType(cardInputValue)
      setCardType(matchingCardType)
      setCardIsValid(true)
      onChange(cardInputValue, matchingCardType)
    }
  }, [value])

  const beforeMaskedStateChange = useCallback(({ currentState, nextState }) => {
    if (currentState?.value != null && currentState.value !== '') {
      const trimmedValue = currentState.value.replaceAll(' ', '')
      const maxLength = trimmedValue.length < cardTypeMaxLength ? trimmedValue.length : cardTypeMaxLength

      let newMaskedValue: string = ''
      let gapsAdded = 0
      for (let i = 0; i < maxLength; i++) {
        if (cardType != null && cardType.gaps.filter(x => x === i).length > 0) {
          newMaskedValue += ` ${trimmedValue[i] as string}`
          gapsAdded++
        } else {
          newMaskedValue += trimmedValue[i] as string
        }
      }

      return {
        value: newMaskedValue,
        selection: {
          start: newMaskedValue.length,
          end: newMaskedValue.length + gapsAdded
        }
      }
    }
    return nextState
  }, [cardType, cardTypeMaxLength])

  return (
    <div className='position-relative'>
      <CardIcon
        displayCardType={cardType?.niceType}
        style={{ top: '11px', left: '10px' }}
        className='position-absolute'
      />
      <InputMask mask={cardMask} maskPlaceholder={null} disabled={usePanPad} onChange={handleCardInputChange} value={value} beforeMaskedStateChange={beforeMaskedStateChange}>
        <Input
          id={uniqueId}
          name={name}
          inputMode='numeric'
          ref={(cn) => useImportantStyle(cn, 'padding-left', '50px')}
          placeholder='0000 0000 0000 0000'
          invalid={!cardIsValid || invalid}
          required={required}
          autoComplete='cc-number'
          autoCorrect='off'
          spellCheck='false'
          className='c-gray-dk-3'
          data-test='card-number'
        />
      </InputMask>
      {!cardIsValid && <FormError message='Invalid' />}
    </div>
  )
}

export { CreditCardInput }
