import { ReactElement, useCallback, useEffect, useMemo, useState } from 'react'
import { ButtonToggleGroup, ButtonToggleOptions } from '@web/shared-ui-components'
import clsx from 'clsx'
import dayjs from 'dayjs'
import { DateRangeSimple } from './DateRangeSelector'
import { TrackEvent } from '@web/shared-util-google-analytics'

interface ButtonToggleOptionsWithDateRange {
  buttonOption: ButtonToggleOptions
  dateRange: DateRangeSimple
}

interface QuickSearchByDateRangeProps {
  /**
   * Class overrides
   */
  className?: string
  /*
   * Handle change of searchByLastDaysValue
   */
  onChange: (dateRange: DateRangeSimple, lastDays?: number) => void
  /*
   * Array of days
   */
  dayButtonOptions: ButtonToggleOptions[]
  selectedDateRange: DateRangeSimple
  trackingCategory?: string
  trackingEvent?: string
}

/*
 * The quick search by date component
 */
function QuickSearchByDateRange ({
  className,
  onChange,
  dayButtonOptions,
  selectedDateRange,
  trackingCategory,
  trackingEvent
}: QuickSearchByDateRangeProps): ReactElement {
  const [lastDays, setLastDays] = useState<string>()

  const buttonOptionsWithDateRanges: ButtonToggleOptionsWithDateRange[] = useMemo(() => {
    return dayButtonOptions.map(dbo => ({
      buttonOption: dbo,
      dateRange: { startDate: dayjs().add(parseInt(dbo.value), 'd').toDate(), endDate: new Date() }
    })
    )
  }, [dayButtonOptions])

  useEffect(() => {
    const match = buttonOptionsWithDateRanges.filter(x => dayjs(x.dateRange.startDate).format('L') === dayjs(selectedDateRange.startDate).format('L') &&
      dayjs(x.dateRange.endDate).format('L') === dayjs(selectedDateRange.endDate).format('L'))[0]
    setLastDays(match?.buttonOption?.value)
    if (match != null) {
      onChange(match.dateRange, lastDays != null ? parseInt(lastDays) : undefined)
    }
  }, [buttonOptionsWithDateRanges, selectedDateRange, setLastDays])

  useEffect(() => {
    const match = buttonOptionsWithDateRanges.filter(x => x.buttonOption.value === lastDays)[0]
    if (match != null) {
      onChange(match.dateRange)
    }
  }, [buttonOptionsWithDateRanges, lastDays])

  const handleQuickSearchDays = useCallback((val: string) => {
    setLastDays(val)
    if (trackingCategory != null && trackingEvent != null) {
      TrackEvent(trackingCategory, trackingEvent, `QuickSearch${val}days`)
    }
  }, [setLastDays, trackingCategory, trackingEvent, TrackEvent])

  return (
    <div className={clsx(className, 'd-flex flex-column justify-content-between noPrint')}>
      <span className='t-tiny-upper mb-1'>Quick Search</span>
      <div className='w-100 d-flex pr-md-3'>
        <ButtonToggleGroup
          options={dayButtonOptions}
          currentValue={lastDays}
          setCurrentValue={handleQuickSearchDays}
          data-test='quick-search-by-days-buttons'
        />
      </div>
    </div>
  )
}

export { QuickSearchByDateRange }
