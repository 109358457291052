import { ISearchQuery } from '@web/search-types'
import { useGetAddAHeadFacetsAndResultsQuery } from '@web/shared-data-access-queries'

interface AddAHeadQuery {
  atoModelNumber: string
  pageSize: number
  page: number
  productId?: string | null
  iterationId?: string | null
  sort?: Sort | null
  metalQuality?: string | null
  stoneShape?: string | null
  stoneSize?: string | null
  productState?: string | null
  prongCount?: string | null
  series?: string | null
  autocomplete?: boolean
  excludedIterationIds?: string[] | null
  shouldFilterOnIteration?: boolean | null
  useIterationsSystemSetting?: boolean | null
}

function useAddAHeadQuery (query: AddAHeadQuery, skip?: boolean): ReturnType<typeof useGetAddAHeadFacetsAndResultsQuery> {
  const {
    atoModelNumber,
    pageSize,
    page,
    productId,
    iterationId,
    sort,
    metalQuality,
    stoneShape,
    stoneSize,
    productState,
    prongCount,
    series,
    autocomplete,
    shouldFilterOnIteration,
    useIterationsSystemSetting
  } = query

  // Filtering
  const currentProductFilter = getCurrentProductFilter(atoModelNumber, productId ?? null, iterationId ?? null, shouldFilterOnIteration)
  const excludedIterationIdsFilter = useIterationsSystemSetting === true ? getExcludedIterationIdsQuery(iterationId) : null
  const seriesFilter = getFacetFilter('series', series, autocomplete)
  const metalQualityFilter = getFacetFilter('attr_string_Quality', metalQuality)
  const stoneShapeFilter = getFacetFilter('attr_string_Stone_Shape', stoneShape)
  const stoneSizeFilter = getFacetFilter('attr_string_Stone_Size', stoneSize)
  const productStateFilter = getFacetFilter('attr_string_Product_State', productState)
  const prongCountFilter = getFacetFilter('attr_string_Prong_Count', prongCount)

  // Construct initial query with filter on current product
  const paging: ISearchQuery.IBasicPagination = {
    Page: page,
    Size: pageSize
  }
  const searchQuery: ISearchQuery = {
    Filters: [currentProductFilter],
    Faceting: {
      Fields: [] as IFacetField[]
    },
    Paging: paging,
    Sorts: [getSort(sort ?? null)]
  }
  if (searchQuery.Filters != null) {
    if (seriesFilter != null) {
      searchQuery.Filters.push(seriesFilter)
    }
    if (metalQualityFilter != null) {
      searchQuery.Filters.push(metalQualityFilter)
    }
    if (stoneShapeFilter != null) {
      searchQuery.Filters.push(stoneShapeFilter)
    }
    if (stoneSizeFilter != null) {
      searchQuery.Filters.push(stoneSizeFilter)
    }
    if (productStateFilter != null) {
      searchQuery.Filters.push(productStateFilter)
    }
    if (prongCountFilter != null) {
      searchQuery.Filters.push(prongCountFilter)
    }
    if (excludedIterationIdsFilter != null) {
      searchQuery.Filters.push(excludedIterationIdsFilter)
    }
  }

  // Faceting
  if (searchQuery.Faceting != null) {
    searchQuery.Faceting.Fields = [{ Name: 'series' }]
    if (autocomplete !== true) {
      searchQuery.Faceting.Fields = [
        { Name: 'attr_string_Quality', ExcludeFilters: ['attr_string_Quality'] },
        { Name: 'attr_string_Stone_Shape', ExcludeFilters: ['attr_string_Stone_Shape'] },
        { Name: 'attr_string_Stone_Size', ExcludeFilters: ['attr_string_Stone_Size'] },
        { Name: 'attr_string_Product_State', ExcludeFilters: ['attr_string_Product_State'] },
        { Name: 'attr_string_Prong_Count', ExcludeFilters: ['attr_string_Prong_Count'] }
      ]
    }
  }

  // base apollo hook
  return useGetAddAHeadFacetsAndResultsQuery({
    fetchPolicy: 'no-cache',
    skip,
    variables: {
      query: JSON.stringify(searchQuery)
    }
  })
}

function getCurrentProductFilter (atoModelNumber: string, productId: string | null, iterationId: string | null, shouldFilterOnIteration): ISearchQuery.IAttributeFilter {
  let currentProductFilter: any = {
    AttributeName: 'attrs_string_Parent_ATO_Model_Number',
    Values: [atoModelNumber]
  }

  if (iterationId !== null && shouldFilterOnIteration === true) {
    currentProductFilter = {
      Children: [
        currentProductFilter,
        {
          AttributeName: 'attrs_string_Related_Shank_Product_Id_WebProduct_ProductIterationRelation',
          Values: [iterationId]
        }
      ]
    }
  } else if (productId !== null) {
    currentProductFilter = {
      Children: [
        currentProductFilter,
        {
          AttributeName: 'attrs_string_Related_Shank_Product_Id',
          Values: [productId]
        }
      ]
    }
  }
  return currentProductFilter
}

function getExcludedIterationIdsQuery (iterationId: string | null | undefined): ISearchQuery.IAttributeFilter | null {
  return iterationId != null
    ? {
        AttributeName: 'attrs_string_Related_Shank_Product_Id_Exclusions',
        Values: [iterationId],
        IsExcluded: true
      }
    : null
}

interface IFacetField {
  Name: string
  ExcludeFilters?: string[]
}

type IFacetFilter = ISearchQuery.IAttributeFilter & ISearchQuery.INamedFilter

function getFacetFilter (attrName: string, attrValue: string | null | undefined, isStartsWith = false): IFacetFilter | null {
  const filter: IFacetFilter | null = attrValue != null
    ? {
        Name: attrName,
        AttributeName: attrName,
        Values: [attrValue]
      }
    : null
  if (filter != null && isStartsWith) {
    filter.MatchMode = 'startswith'
  }
  return filter
}

function getSort (sort: Sort | null): ISearchQuery.ISort {
  // Sorting
  let querySort = {
    Field: 'price',
    IsDescending: true
  }
  if (sort === Sort.PriceAsc) {
    querySort = {
      Field: 'price',
      IsDescending: false
    }
  }
  if (sort === Sort.Newest) {
    querySort = {
      Field: 'product_creation_date',
      IsDescending: true
    }
  }
  if (sort === Sort.Name) {
    querySort = {
      Field: 'attr_string_Title',
      IsDescending: false
    }
  }
  return querySort
}

enum Sort {
  PriceAsc = 'Price: Low to High',
  PriceDesc = 'Price: High to Low',
  Newest = 'Newest',
  Name = 'Name'
}

export { useAddAHeadQuery, AddAHeadQuery, Sort }
