import { ReactElement, ReactNode } from 'react'
import { useLocation } from 'react-router'
import { CmsContentContext } from './CmsContentContext'
import { useCmsContentContext } from './useCmsContentContext'
import { ICmsContentContext } from './types'

interface CmsContentProviderProps extends Omit<ICmsContentContext, 'url'> {
  children?: ReactNode
}

/**
 * Provides a way to set/pass context variables used when asynchronously loading CMS content (via child `CmsContent` components)
 * This provider may be used recursively to set context at different levels in the component hierarchy, with the "lowest" instance taking precedence
 */
function CmsContentProvider ({ children, ...details }: CmsContentProviderProps): ReactElement {
  const { pathname, search } = useLocation()
  const url = `${pathname}${search}`
  const outerContext = useCmsContentContext()

  return (
    <CmsContentContext.Provider value={{ ...outerContext, url, ...details }}>
      {children}
    </CmsContentContext.Provider>
  )
}

export { CmsContentProvider }
