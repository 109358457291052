import { ReactElement, ReactNode, useCallback, useMemo } from 'react'
import clsx from 'clsx'
import '@web/styles/Pager.less'
import { faCaretLeft, faCaretRight } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { TrackEvent } from '@web/shared-util-google-analytics'

interface PagerObject {
  /*
   * the current page
   */
  page: number
  /*
   * the number of records per page
   */
  perPage: number
  /*
   * the total number of records
   */
  total: number
}

interface PagerProps {
  /*
   * the current page
   */
  page: number
  /*
   * the number of records per page
   */
  perPage: number
  /*
   * the total number of records
   */
  total: number
  /*
   * event handler for when the page is changing
   */
  onChange: (page: number, nextOrPrev?: string) => void
  /*
   * the number of "button" style pages to show
   * @default 4
   */
  pagesToShow?: number
  /*
   * the className to give to the containing element
   */
  className?: string
  /**
   * Pass this category if you want to track it in GA
   */
  trackingCategory?: string
}

interface PagerLinkProps extends Pick<PagerProps, 'trackingCategory'> {
  children: ReactNode
  className?: string
  page: number
  onClick: (page: number, nextorPrev?: string) => void
  direction: 'Left' | 'Right'
}

interface PagerButtonProps extends Pick<PagerProps, 'trackingCategory'> {
  children: ReactNode
  isCurrentPage?: boolean
  page: number
  onClick?: (page: number, nextorPrev?: string) => void
}

interface PagerItemProps {
  children: ReactNode
  className?: string
}

/*
 * Link component for pager
 */
function PagerLink ({ children, onClick, page, className, trackingCategory, direction }: PagerLinkProps): ReactElement {
  const clickHandler = useCallback(() => {
    onClick(page, direction === 'Left' ? 'prev' : 'next')
    if (trackingCategory == null) return
    TrackEvent(trackingCategory, 'Page', `Page${direction}`)
  }, [onClick, page])

  const buttonLabel = direction === 'Left' ? 'Previous Page' : 'Next Page'

  return (
    <button
      className={clsx('page-link link mr-2 u-border-radius-small text-center c-gray-dk-3 c-bg-gray-lt-2 h-100', className)}
      onClick={clickHandler}
      aria-label={buttonLabel}
      type='button'
    >
      <span aria-hidden='true'>{children}</span>
    </button>
  )
}

/*
 * Button link component for pager
 */
function PagerButtonLink ({ page, children, isCurrentPage = false, onClick, trackingCategory }: PagerButtonProps): ReactElement {
  const clickHandler = useCallback(() => {
    if (onClick != null) {
      onClick(page)
      if (trackingCategory == null) return
      TrackEvent(trackingCategory, 'Page', `PageSelect-${page}`)
    }
  }, [onClick, page])
  const baseCssClasses = 'page-link mr-2 u-border-radius-small text-center'
  const buttonScreenReaderLabel = `Page ${page}`

  if (isCurrentPage) {
    return (
      <span className={clsx('c-white c-bg-primary-dk-1', baseCssClasses)}>{children}</span>
    )
  }
  return (
    <button
      className={clsx('button-link c-gray-dk-3 c-bg-gray-lt-2', baseCssClasses)}
      onClick={clickHandler}
      type='button'
      aria-label={buttonScreenReaderLabel}
    >
      {children}
    </button>
  )
}

/*
 * Ellipses component for pager
 */
function PagerEllipses (): ReactElement {
  return (
    <span className='ellipses px-2 border-0 c-gray-lt-1 fa fa-ellipsis-h' />
  )
}

/*
 * Item component for pager
 */
function PagerItem ({ className, children }: PagerItemProps): ReactElement {
  return (
    <li className={clsx('page-item', className)}>{children}</li>
  )
}

/*
 * Returns the total number of pages based on the inputs
 */
function getTotalPages (total: number, perPage: number): number {
  return Math.ceil(total / perPage)
}
/*
 * Returns the first page that should be displayed based on the inputs
 */
function getFirstPageToDisplay (lastPageToDisplay: number, pagesToShow: number): number {
  return Math.floor(Math.max(lastPageToDisplay - pagesToShow, 1))
}

/*
 * Returns the last page that should be displayed based on the inputs
 */
function getLastPageToDisplay (currentPage: number, pagesToShow: number, totalPages: number): number {
  const page = Math.min(currentPage + (pagesToShow / 2), totalPages)
  const minLastDisplayPage = Math.min(totalPages, pagesToShow)
  if (page < minLastDisplayPage) {
    return minLastDisplayPage
  }
  return Math.floor(page)
}

/*
 * Returns an array populated with the page numbers that should be displayed
 */
function getPagesToDisplay (firstPageToDisplay: number, lastPageToDisplay: number): number[] {
  const pages: number[] = []
  for (let i = firstPageToDisplay; i <= lastPageToDisplay; i++) {
    pages.push(i)
  }
  return pages
}

/*
 * Shows a user friendly pager for navigating between pages
 */
function Pager ({ className, onChange, page, pagesToShow = 4, perPage, total, trackingCategory, ...otherAttributes }: PagerProps): ReactElement | null {
  const totalPages = useMemo(() => getTotalPages(total, perPage), [total, perPage])
  const lastPageToDisplay = useMemo(() => getLastPageToDisplay(page, pagesToShow, totalPages), [page, pagesToShow, totalPages])
  const firstPageToDisplay = useMemo(() => getFirstPageToDisplay(lastPageToDisplay, pagesToShow), [lastPageToDisplay, pagesToShow])
  const pagesToDisplay = useMemo(() => getPagesToDisplay(firstPageToDisplay, lastPageToDisplay), [firstPageToDisplay, lastPageToDisplay])

  if (totalPages <= 1) {
    return null
  }

  return (
    <nav aria-label='pager' className={clsx(className, 'react-pager')}>
      <ul className='pagination d-flex flex-row align-items-center' {...otherAttributes}>
        <PagerItem>
          <PagerLink
            className={clsx(firstPageToDisplay === page && 'u-disable', 'mr-2')}
            page={page - 1}
            direction='Left'
            onClick={onChange}
            trackingCategory={trackingCategory}
          >
            <FontAwesomeIcon icon={faCaretLeft} size='lg' />
          </PagerLink>
        </PagerItem>
        {firstPageToDisplay > 1 && (
          <>
            <PagerItem>
              <PagerButtonLink page={1} onClick={onChange}>1</PagerButtonLink>
            </PagerItem>
            {lastPageToDisplay >= pagesToShow &&
              <PagerItem>
                <PagerEllipses />
              </PagerItem>}
          </>
        )}

        {pagesToDisplay.map(dp =>
          <PagerItem key={dp}>
            <PagerButtonLink
              page={dp}
              isCurrentPage={dp === page}
              onClick={onChange}
              trackingCategory={trackingCategory}
            >
              {dp}
            </PagerButtonLink>
          </PagerItem>
        )}

        {lastPageToDisplay < totalPages && (
          <>
            {lastPageToDisplay < (totalPages - 1) &&
              <PagerItem>
                <PagerEllipses />
              </PagerItem>}
            <PagerItem>
              <PagerButtonLink
                page={totalPages}
                onClick={onChange}
                trackingCategory={trackingCategory}
              >
                {totalPages}
              </PagerButtonLink>
            </PagerItem>
          </>
        )}

        {lastPageToDisplay !== page && (
          <PagerItem>
            <PagerLink
              page={page + 1}
              direction='Right'
              className='lr-2' onClick={onChange}
              trackingCategory={trackingCategory}
            >
              <FontAwesomeIcon icon={faCaretRight} size='lg' />
            </PagerLink>
          </PagerItem>
        )}
      </ul>
    </nav>
  )
}

export {
  Pager,
  PagerObject,
  PagerProps
}
