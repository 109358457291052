import {
  ReactElement, useCallback, useContext, useMemo
} from 'react'
import { SaveCardMutation, CreditCard } from '@web/shared-data-access-queries'
import { CardForm, PaymentMethodSelectorContext } from '@web/payment-methods'

interface EditCardProps {
  /**
   * Current credit card values
   */
  currentCard: CreditCard
  /**
   * Is credit card selected as default payment method
   */
  isDefault: boolean
  /**
   * Handle the toggle of the edit card
   */
  onToggleCollapse: () => void
}

/**
 * EditCard component
 */
function EditCard ({ currentCard, isDefault, onToggleCollapse }: EditCardProps): ReactElement | null {
  const { refetchPaymentMethods } = useContext(PaymentMethodSelectorContext)

  const creditCardParams = useMemo(() => {
    return {
      Id: currentCard.Id,
      CardType: currentCard.CardType,
      CardNumber: null,
      CardHolderName: currentCard.CardHolderName,
      ExpirationMonth: currentCard.ExpirationMonth,
      ExpirationYear: currentCard.ExpirationYear,
      Zip: '',
      Cvv: '',
      IsDebit: currentCard.IsDebit
    }
  }, [currentCard])

  const afterSave = useCallback((saving: boolean, data?: SaveCardMutation | null | undefined) => {
    if (data?.saveCard != null && data.saveCard.success) {
      void refetchPaymentMethods()
    }
  }, [])

  return (
    <CardForm
      saveType='edit'
      initialCreditCardParams={creditCardParams}
      isDefault={isDefault}
      newCardAllowedMethods={[]}
      onToggleCollapse={onToggleCollapse}
      afterSave={afterSave}
    />
  )
}

export {
  EditCard
}
