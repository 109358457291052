export interface ProductTracking {
  item_id: string
  item_variant: string
  item_name: string
  item_brand?: string
  item_category?: string
  item_category2?: string
  item_category3?: string
  item_category4?: string
  item_category5?: string
  currency?: string
  discount?: number
  price?: number
  quantity: number
  config_id?: number
  serial_number?: number
  coupon?: string
  index?: number
  item_list_id?: string
  item_list_name?: string
}

function trackEcommerceEvent (event: string, items: ProductTracking[]): void {
  window.dataLayer = window.dataLayer ?? []
  window.dataLayer.push({ details: null })
  window.dataLayer.push({
    event,
    details: {
      items
    }
  })
}

export { trackEcommerceEvent }
