import { ReactElement, useCallback, useMemo } from 'react'
import { PaymentMethod } from '@web/shared-data-access-queries'
import { FancySelect, FancySelectOption } from '@web/shared-ui-components'
import { PaymentMethodSummary } from '../summaries'

interface PaymentMethodSelectorDropDownProps {
  paymentMethods: PaymentMethod[] | null
  selectedPaymentMethod: PaymentMethod | undefined | null
  handleSelectedPaymentMethod: (paymentMethod: PaymentMethod | undefined | null) => void
}

function PaymentMethodSelectorDropDown ({
  paymentMethods,
  selectedPaymentMethod,
  handleSelectedPaymentMethod
}: PaymentMethodSelectorDropDownProps
): ReactElement {
  const handleSelectChange = useCallback((option: FancySelectOption) => {
    const paymentMethod = paymentMethods?.filter(x => x.SelectableValue === option.value)[0]

    handleSelectedPaymentMethod(paymentMethod)
  }, [paymentMethods, handleSelectedPaymentMethod])

  const createUiOptions = useCallback((paymentMethods: PaymentMethod[]): FancySelectOption[] =>
    paymentMethods.map(paymentMethod => ({
      label: (
        <div>
          <PaymentMethodSummary
            creditCardLastFour={paymentMethod.CreditCard?.CardNumber}
            displayCardType={paymentMethod.DisplayCardType}
            isDebit={paymentMethod.CreditCard?.IsDebit}
            bankAccountLastFour={paymentMethod.BankAccount?.AccountNumber}
          />
        </div>
      ),
      value: paymentMethod.SelectableValue
    }))
  , [])

  const optionsWithUi: FancySelectOption[] | undefined = useMemo(() =>
    createUiOptions(paymentMethods ?? [])
  , [paymentMethods])

  const selectedFancySelectOptionValue = useMemo(() => {
    const selectedpm = optionsWithUi.filter(x => x.value === selectedPaymentMethod?.SelectableValue)
    return (selectedpm == null || selectedpm.length === 0) ? null : selectedpm[0]
  }, [optionsWithUi, selectedPaymentMethod])

  return (
    <FancySelect
      options={optionsWithUi}
      value={selectedFancySelectOptionValue}
      isSearchable={false}
      color='secondary'
      onChange={handleSelectChange}
      styles={({ menuPortal: base => ({ ...base, zIndex: 9999 }) })}
    />
  )
}

export { PaymentMethodSelectorDropDown }
