import { faTimesCircle } from '@fortawesome/pro-solid-svg-icons'
import { faCircle } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ReactElement } from 'react'
import { ErrorPage, ErrorPageProps } from './ErrorPage'

export interface GenericErrorPageProps extends Partial<Omit<ErrorPageProps, 'icon' | 'header'>> { }

function GenericErrorPage (props: GenericErrorPageProps): ReactElement {
  return (
    <ErrorPage
      {...props}
      header='Something Went Wrong! (500 Error)'
      icon={
        <span className='fa-layers'>
          <FontAwesomeIcon icon={faCircle} />
          <FontAwesomeIcon icon={faTimesCircle} color='red' transform='shrink-1' />
        </span>
      }
    >
      <p>It looks as though something in our system has broken.<br />
        Don't panic - an email has been sent to the web development team that something went wrong.
      </p>
    </ErrorPage>
  )
}

export { GenericErrorPage }
