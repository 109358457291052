import { useEventListener } from '@web/shared-util-hooks'
import { useEffect } from 'react'
import { STULLER_EVENT_PREFIX } from './constants'
import { StullerEvents } from './types'

/**
 * Wrapper around `useEventListener` to listen for events from `dispatchStullerEvent`
 */
function useStullerEventListener<T extends keyof StullerEvents> (eventName: T, handler: (event: CustomEvent<StullerEvents[T] | null>) => void): void {
  useEventListener(`${STULLER_EVENT_PREFIX}${eventName}`, handler as (event: Event) => void)

  useEffect(() => {
    if (window.stullerEvents[eventName] === false) {
      window.stullerEvents[eventName] = true
      if (window.stullerEventsQueue[eventName].length > 0) {
        window.stullerEventsQueue[eventName].forEach(event => window.dispatchEvent(event))
        window.stullerEventsQueue[eventName] = []
      }
    }
  }, [])
}

export {
  useStullerEventListener
}
