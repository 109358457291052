/**
 * Sets the default cookie view and hides setDefaultView content
 */
function setDefaultViewCookie (defaultView): void {
  setCookie('defaultProductView', defaultView, Infinity, '/products', null, null)
  const setDefaultView = document.getElementById('setDefaultView')
  if (setDefaultView != null) {
    setDefaultView.style.display = 'none'
  }
}

/**
 * Sets a cookies properties based on passed in parameters
 */
function setCookie (sKey: string, sValue: string, vEnd: string | number | object, sPath: string, sDomain: string | null, bSecure: string | null): void {
  if (sKey.length === 0 || /^(?:expires|max-age|path|domain|secure)$/.test(sKey)) {
    return
  }

  let sExpires = ''
  if (vEnd !== null) {
    switch (typeof vEnd) {
      case 'number':
        sExpires = `; max-age='${vEnd}`
        break
      case 'string':
        sExpires = `; expires='${vEnd}`
        break
      case 'object':
        if (Object.prototype.hasOwnProperty.call(vEnd, 'toGMTString')) {
          sExpires = `; expires='${(vEnd as Date).toUTCString()}`
        }
        break
    }
  }
  document.cookie = `${encodeURI(sKey)}=${encodeURI(sValue)}${sExpires}${(sDomain !== null && sDomain !== undefined) ? `; domain=${sDomain}` : ''}${(sPath !== null && sPath !== undefined) ? `; path=${sPath}` : ''}${(bSecure !== null && bSecure !== undefined) ? ';secure' : ''}`
}

/**
 * Creates a cookie in the browser based on the passed in parameters
 */
function createCookie (name: string, value, days: number): void {
  let expires = ''
  if (!isNaN(days)) {
    const date = new Date()
    date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000))
    expires = '; expires=' + date.toUTCString()
  } else expires = ''
  document.cookie = encodeURI(name) + '=' + encodeURI(value) + expires + '; path=/'
}

function readCookie (name: string): string | null {
  const nameEQ = encodeURI(name) + '='
  const ca = document.cookie.split(';')
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i]
    while (c.charAt(0) === ' ') c = c.substring(1, c.length)
    if (c.indexOf(nameEQ) === 0) return decodeURI(c.substring(nameEQ.length, c.length))
  }
  return null
}

/**
 * Erases a cookie by the passed in name
 */
function eraseCookie (name: string): void {
  createCookie(name, '', -1)
}

export {
  setDefaultViewCookie,
  setCookie,
  createCookie,
  readCookie,
  eraseCookie
}
