import { SearchResult } from '@web/shared-data-access-queries'
import { ReactElement, useMemo } from 'react'
import { MeteorBadge } from '@web/product-navigation-feature'
import { useUserContext } from '@web/shared-data-access-context'

interface TopBadgeProps{
  productResult: SearchResult
  searchSourceIsDeals: boolean
}

/*
  Returns a top badge based off of the product result
*/
function TopBadge ({ productResult, searchSourceIsDeals }: TopBadgeProps): ReactElement | null {
  const { context } = useUserContext()
  const canShowDealBadge = productResult.IsDiscounted
  const canShow3CBadge = productResult.IsValidFor3CBadge
  const canShowNewBadge = productResult.IsValidForNewItemBadge
  const canShowNewDealsBadge = productResult.IsValidForNewDealBadge
  const canShowBestSellerBadge = productResult.IsValidForBestSellerBadge
  const canShowLimitedTimeOnlyBadge = productResult.IsValidForLimitedTimeOnlyBadge

  const isDealsController: boolean = useMemo<boolean>(() => context?.ControllerName?.toLocaleLowerCase() === 'deals', [context?.ControllerName])
  const isDealsIndex: boolean = useMemo<boolean>(() => context?.ActionName?.toLocaleLowerCase() === 'products', [context?.ActionName])

  let imgSrcId = ''
  let imgTitle = ''

  if (canShow3CBadge) {
    imgSrcId = '14948259'
    imgTitle = '3C'
  } else if (canShowNewBadge && !searchSourceIsDeals) {
    imgSrcId = '24762476'
    imgTitle = 'New Item'
  } else if (canShowBestSellerBadge && !searchSourceIsDeals) {
    imgSrcId = '26189941'
    imgTitle = 'Best Seller'
  } else if (canShowLimitedTimeOnlyBadge) {
    imgSrcId = '43601380'
    imgTitle = 'Limited Time Only'
  } else if (canShowDealBadge) {
    if (!isDealsController) {
      imgSrcId = '16419937'
      imgTitle = 'Deal'
    } else if (isDealsController && isDealsIndex && canShowNewDealsBadge) {
      imgSrcId = '26640545'
      imgTitle = 'New Deal'
    }
  }

  if (imgSrcId === '' || imgTitle === '') {
    return null
  }
  return (
    <MeteorBadge
      source={`//meteor.stullercloud.com/das/${imgSrcId}`}
      title={imgTitle}
      width='40'
      xsWidth='35'
      classes='stuller-badge-not-xs'
      xsClasses='stuller-badge-xs'
    />
  )
}

export { TopBadge }
