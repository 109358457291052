import {
  ReactElement,
  ReactNode,
  MouseEventHandler,
  useMemo,
  forwardRef,
  ForwardedRef,
  CSSProperties,
  ComponentPropsWithoutRef
} from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import clsx from 'clsx'
import { faSpinnerThird } from '@fortawesome/pro-solid-svg-icons'

type ButtonTagProps = ComponentPropsWithoutRef<'button'> & ComponentPropsWithoutRef<'a'>

interface ButtonProps extends ButtonTagProps {
  /*
   * Id of element
   */
  id?: string
  /*
   * Children of element
   */
  children?: ReactNode
  /*
   * Additional class name(s) to give to the containing element
   */
  className?: string
  /*
   * Additional style(s) to give to the containing element
   */
  style?: CSSProperties
  /*
   * CSS defined color
   * Default: 'secondary'
   */
  color?: 'primary' | 'primary-inverse' | 'secondary' | 'secondary-inverse' | 'negative' | 'dark' | 'atc' | 'link' | 'stullerpay' | 'digital-goldsmith' | 'amazon'
  /*
   * Disable element
   */
  disabled?: boolean
  /*
   * CSS defined size
   */
  size?: 'micro'
  /*
   * Defined element type
   * Default: 'button'
   */
  tag?: 'a' | 'button'
  /*
   * Event handler for element clicked
   */
  onClick?: MouseEventHandler
  /*
   * Href for if the button is a link
   */
  href?: string
  /*
   * Target for if the button is a link
   */
  target?: '_blank' | '_self' | ' _parent' | '_top'
  /*
   * Type for the button
   * Default: 'submit'
   */
  type?: 'button' | 'submit' | 'reset'
  /**
   * Mimic the styling of a button being active
   */
  isSelected?: boolean
  /**
   * Applies class for round button
   */
  rounded?: boolean
  /**
   * If there should be a loading spinner
   */
  loading?: boolean
}

/*
 * Button component
 */
function Button ({
  id,
  children,
  className,
  style,
  color = 'secondary',
  disabled,
  size,
  tag: Tag = 'button',
  onClick,
  href,
  target,
  isSelected = false,
  rounded = false,
  loading = false,
  ...otherAttributes
}: ButtonProps, ref: ForwardedRef<any>): ReactElement {
  const classNames = useMemo(() => clsx(
    color !== 'link' && 'sbtn',
    `sbtn-${color}`,
    size != null && `sbtn-${size}`,
    isSelected && 'is-selected',
    rounded && 'u-border-radius-xlarge',
    disabled != null && disabled && 'sbtn-disabled',
    loading && 'sbtn-loading',
    className
  ), [color, size, className, isSelected, disabled, loading])

  const typeOrRole = useMemo(() => ({
    [Tag === 'button' ? 'type' : 'role']: 'button'
  }), [Tag])

  return (
    <Tag
      id={id}
      {...typeOrRole}
      disabled={disabled}
      onClick={onClick}
      href={href}
      className={classNames}
      style={style}
      ref={ref}
      target={target}
      {...otherAttributes}
    >
      {loading && <FontAwesomeIcon icon={faSpinnerThird} className='fa-spin-faster' />}
      {children}
    </Tag>
  )
}

/*
 * Button with forwardRef
 */
const ButtonWrapped = forwardRef(Button)

export {
  ButtonWrapped as Button
}
