interface RedirectProps {
  to: string
}

function Redirect ({ to }: RedirectProps): any {
  window.location.replace(to)
  return null
}

export { Redirect }
