import { ReactElement } from 'react'
import { FilesTypesAccepted } from '@web/shared-ui-components'

interface FileUploadInformationProps {
  files: File[]
  fileLimit: number
  fileTypesAccepted: FilesTypesAccepted
}

function FileUploadInformation ({ files, fileLimit, fileTypesAccepted }: FileUploadInformationProps): ReactElement {
  return (
    <div className='text-center'>
      <div className='f-roboto-bold' data-test='file-limit-message'>{files.length} of {fileLimit} File{fileLimit > 1 && 's'} Uploaded</div>
      {fileTypesAccepted.label != null && <div className='f-robot-light'>File types accepted: {fileTypesAccepted.label}</div>}
    </div>
  )
}

export { FileUploadInformation }
