import { FetchResult, ServerError, ApolloError } from '@apollo/client'
import { NetworkError } from '@apollo/client/errors'
import { GraphQLError } from 'graphql'
import { Maybe } from 'graphql/jsutils/Maybe'

class ApolloRestGraphQLError extends GraphQLError {
  constructor (public statusCode: number, errorMessage: string) {
    super(errorMessage)
  }
}

type ServerErrorWithResult = Omit<ServerError, 'result'> & {
  result: { errors: string[] } | string
}

function isServerErrorWithResult (networkErr: NetworkError | null | undefined): networkErr is ServerErrorWithResult {
  return networkErr != null && 'result' in networkErr
}

/**
 * Converts one or more `ApolloError` objects into an array of `ApolloRestGraphQLError` by parsing the network error response body
 * @param apolloErrors One or more `ApolloError` objects
 * @returns {Array<ApolloRestGraphQLError> | null} Returns `Array<ApolloRestGraphQLError>` whenever at least one error message is found within network error response, or
 * `null` if no error messages were found
 * @example
 * const { data, loading, error } = useGetSomeDataQuery()
 * const errorsGettingSomeData = getApolloRestErrors(error)
 */
function getApolloRestErrors (...apolloErrors: Array<Maybe<ApolloError>>): ApolloRestGraphQLError[] | null {
  const errors: ApolloRestGraphQLError[] = []
  for (const err of apolloErrors) {
    if (err == null || !isServerErrorWithResult(err.networkError)) {
      continue
    }
    const statusCode = err.networkError?.statusCode
    if (statusCode === 500) {
      errors.push(new ApolloRestGraphQLError(statusCode, 'An unknown error occurred.'))
    } else if (typeof err.networkError.result === 'string') {
      errors.push(new ApolloRestGraphQLError(statusCode, err.networkError.result))
    } else if (Array.isArray(err.networkError.result?.errors)) {
      errors.push(...err.networkError.result.errors.map(e => new ApolloRestGraphQLError(statusCode, e)))
    } else {
      errors.push(new ApolloRestGraphQLError(statusCode, err.message))
    }
  }
  return errors.length >= 1 ? errors : null
}

/**
 * Handler intended to be used within the `.catch` callback of a `Promise<FetchResult<T>>` promise chain and prevent throwing certain `ApolloError` network errors
 *
 * The `apollo-rest-link` library throws `ApolloError` any time an REST call returns non-success HTTP status code.  However, this is usually undesirable behavior because the error messages never appear on the apollo result in the same way they would with normal GraphQL calls
 *
 * This method will catch thrown `ApolloError` errors, read error messages from the network response body, then add them to the `errors` collection of `GraphQLError` objects returned on `FetchResult<T>` allowing code execution to proceed
 *
 * If the `ApolloError` does not contain error messages on the network response, the original error will still be thrown
 * @param err an `ApolloError` object potentially containing network errors within response
 * @example
 * ```typescript
 * try {
 *    const result = await createPayment({ variables: { input: { val: "test" }}})
 *      .catch(handleApolloRestErrors<CreatePaymentMutation>)
 *
 *    // Now we can read errors on `result`
 *    if (result.errors != null) {
 *      return
 *    }
 *
 *    console.log(result.data) // do something with result data
 *
 * } catch (e) {
 *    console.log("something went way wrong", e) // if original error was not handled
 * }
 * ```
 */
function handleApolloRestErrors<T> (err: ApolloError): FetchResult<T> | PromiseLike<FetchResult<T>> {
  const restErrors = getApolloRestErrors(err)
  if (restErrors == null || restErrors.length < 1) {
    throw err
  }
  return { errors: restErrors }
}

export {
  getApolloRestErrors,
  handleApolloRestErrors,
  ApolloRestGraphQLError
}
