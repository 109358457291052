import {
  ReactElement,
  ReactNode
} from 'react'
import clsx from 'clsx'
import '@web/styles/RadioGroup.less'

interface RadioGroupProps {
  /*
   * Options for the select as children
   */
  children: ReactNode
  /*
   * Class overrides
   */
  className?: string
  /*
   * List layout
   */
  layout?: 'vertical' | 'horizontal'
}

/*
 * Group for the radio component
 */
function RadioGroup ({
  className,
  layout = 'vertical',
  children
}: RadioGroupProps): ReactElement {
  const classNames = clsx('react-radio-group', className, `${layout}-radio-group`)

  return (
    <div className={classNames}>
      {children}
    </div>
  )
}

export { RadioGroup }
