import { ReactElement, useState, useCallback, ChangeEvent, useMemo, useEffect, useReducer, ReactNode } from 'react'
import { Modal, ModalBody, ModalHeader, ModalFooter, FancySelect, Button, Input, LoadingIndicator, Alert, AddToFavoritesData } from '@web/shared-ui-components'
import { useGetFavoritesListLazyQuery, GetFavoritesListQuery, useAddToFavoritesMutation, AddToFavoritesParams } from '@web/shared-data-access-queries'
import { faTimesCircle, faPlus } from '@fortawesome/pro-solid-svg-icons'
import { faCheckCircle } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import '@web/styles/AddToFavorites.less'
import { ApolloError } from '@apollo/client'
import { ProductTracking } from '@web/shared-util-google-analytics'
import uuid from 'react-uuid'
import { useChildrenProps } from '@web/shared-util-hooks'

type ATFOptionsType = GetFavoritesListQuery['addToFavoritesOptions']
type ListsType = NonNullable<ATFOptionsType>['Lists']
type ListType = ListsType[number]

interface Option {
  /*
   * Value of option
   */
  value: number | undefined
  /*
   * Element to render
   */
  label: JSX.Element
}

interface FavoritesSelectionProps {
  /**
   * Selected value from the query, used to set the fancy select
   */
  selectedList: NonNullable<ATFOptionsType>['DefaultList']
  /**
   * Lists from the query, used here to render which type of selection
   */
  lists: ListsType
  /**
   * New list being created value, passed down to the input
   */
  newListName: string
  /**
   * Handler for creating a new list
   */
  handleNewListName: (event: ChangeEvent<HTMLInputElement>) => void
  /**
   * Used to determin if select or input are disabled
   */
  disabled: boolean
  /**
   * Handler passed down to update the list id to pass to the mutation
   */
  handleSelectedList: (list: ListType) => void
}

/**
 * A component for which type of selection are we doing, Input or FancySelect
 */
function FavoritesSelection ({ selectedList, lists, newListName, handleNewListName, disabled, handleSelectedList }: FavoritesSelectionProps): ReactElement | null {
  const [defaultValue, setDefaultValue] = useState<number | null>(null)
  const [optionsIndex, setOptionsIndex] = useState<number | null>(null)

  const optionsWithUi: Option[] = useMemo(() => {
    const defaultList: Option[] = [{ value: -1, label: <span><FontAwesomeIcon icon={faPlus} className='mr-2 c-gray' />Create New List</span> }]
    const optionsList = lists?.map((list) => ({
      value: list.value,
      label: <span>{list.name}</span>
    }))
    return defaultList.concat(optionsList)
  }, [lists])

  const handleSelectOnChange = useCallback((option: Option) => {
    const indexFound = optionsWithUi.findIndex(item => item.value === option.value)
    setOptionsIndex(indexFound)
    handleSelectedList(lists[indexFound - 1])
  }, [optionsWithUi, lists])

  useEffect(() => {
    /** Sets a default value and used to set the fancy selection */
    if (selectedList != null) {
      setDefaultValue(selectedList)
    }
  }, [selectedList])

  useEffect(() => {
    if (defaultValue === null || lists.length === 0) return
    /** When a default value has been set we are going to find the option and set it here */
    const defaultOption = optionsWithUi.find(item => item.value === defaultValue)
    if (defaultOption != null && lists.length > 0) {
      handleSelectOnChange(defaultOption)
    }
  }, [optionsWithUi, lists, defaultValue])

  if (defaultValue !== null && optionsIndex != null) {
    return (
      <>
        <label className='c-gray-dk-3 u-flex-grid-col-12 px-0'>Choose your list</label>
        <div className='u-flex-grid-col-12 px-0' data-test='choose-your-list-dropdown'>
          <FancySelect
            isSearchable={false}
            onChange={handleSelectOnChange}
            options={optionsWithUi}
            value={optionsWithUi[optionsIndex]}
            isDisabled={disabled}
            styles={({ menuPortal: base => ({ ...base, zIndex: 9999 }) })}
            menuPortalTarget={null}
          />
        </div>
        {optionsWithUi[optionsIndex].value === -1 && (
          <div className='u-flex-grid-col-12 mt-3 px-0'>
            <Input
              autoFocus={optionsWithUi[optionsIndex].value === -1}
              disabled={disabled}
              type='text'
              className='form-control new-list-input'
              value={newListName}
              onChange={handleNewListName}
              placeholder='New List...'
            />
          </div>
        )}
      </>
    )
  }

  return (
    <>
      <label className='c-gray-dk-3 u-flex-grid-col-12'>New List</label>
      <div className='u-flex-grid-col-12'>
        <Input
          disabled={disabled}
          type='text'
          className='form-control new-list-input'
          value={newListName}
          onChange={handleNewListName}
          placeholder='Create New List...'
        />
      </div>
    </>
  )
}

interface AddToFavoritesModalInnerProps {
  /**
   * Lists from the query, used here to render which type of selection
   */
  favoritesResult: NonNullable<ATFOptionsType>
  /**
   * Add to cart product data send to the add to favorites mutation
   */
  atcProduct: AddToFavoritesData
  /**
   * Toggling of the modal
   */
  handleAddToFavoritesToggle: () => void
  /**
   * Used to display the error message
   */
  error: ApolloError | undefined
}

function trackAddToFavorites (item: ProductTracking): void {
  window.dataLayer = window.dataLayer ?? []
  window.dataLayer.push({ details: null })
  window.dataLayer.push({
    event: 'add_to_wishlist',
    details: {
      value: item.price == null ? undefined : ((item.price - (item.discount ?? 0)) * item.quantity).toFixed(2),
      currency: 'USD',
      items: [item]
    }
  })
}

function AddToFavoritesModalInner ({ favoritesResult, atcProduct, handleAddToFavoritesToggle, error }: AddToFavoritesModalInnerProps): ReactElement {
  const [currentState, setCurrentState] = useReducer(addToFavoritesReducer, initialAddToFavoritesState)
  const [addToFavorites] = useAddToFavoritesMutation()

  const favoriteListLink = useMemo(() => currentState.successId != null ? `/favorite/?favoriteListId=${currentState.successId.toString()}` : '', [currentState.successId])
  const submitFailed = useMemo(() => (currentState.success != null && !currentState.success) ?? false, [currentState.success])
  const listNameAlreadyExists = useMemo(() => currentState.success != null && !currentState.success && currentState.listNameAlreadyExists, [currentState.success, currentState.listNameAlreadyExists])
  const inputDisabled = useMemo(() => (currentState.success ?? false) || currentState.submitting, [currentState.success, currentState.submitting])
  const selectedListName = useMemo(() => currentState.selectedList != null ? currentState.selectedList.name : currentState.newListName, [currentState.selectedList, currentState.newListName])
  const defaultListId = useMemo(() => favoritesResult?.DefaultList, [favoritesResult?.DefaultList])
  const favoritesResultLists = useMemo(() => ((favoritesResult?.Lists) != null) ? favoritesResult.Lists : [], [favoritesResult?.Lists])
  const favoritesErrorMessage = useMemo(() => {
    return listNameAlreadyExists
      ? 'A favorites list with this name already exists. Please choose another name.'
      : 'Could not add this item to favorites. Refresh the page before trying again.'
  }, [currentState.listNameAlreadyExists])

  const submitDisabled = useMemo(() => {
    if (inputDisabled) {
      return inputDisabled
    }
    return currentState.selectedList == null && currentState.newListName.trim() === ''
  }, [currentState.selectedList, currentState.newListName, inputDisabled])

  const atfParams: AddToFavoritesParams = useMemo(() => ({
    ...atcProduct,
    notes: currentState.notes,
    favoriteListId: currentState.selectedList?.value,
    favoriteListName: currentState.newListName
  }), [atcProduct, currentState.notes, currentState.selectedList, currentState.newListName])

  const fetchList = useCallback((listId: number) => favoritesResult?.Lists.find(list => list.value === listId), [favoritesResult])
  const handleTextAreaChange = useCallback((event: ChangeEvent<HTMLTextAreaElement>) => setCurrentState({ type: 'notes', value: event.target.value }), [])
  const handleNewListName = useCallback((event: ChangeEvent<HTMLInputElement>) => setCurrentState({ type: 'newListName', value: event.target.value }), [])
  const handleSelectedList = useCallback((list: ListType) => setCurrentState({ type: 'selectedList', value: list }), [])

  const handleATFSuccess = useCallback((listId: number) => {
    setCurrentState({ type: 'success', value: true })
    setCurrentState({ type: 'successId', value: listId })
    setCurrentState({ type: 'selectedList', value: fetchList(listId) })
    setCurrentState({ type: 'listNameAlreadyExists', value: false })
    trackAddToFavorites(atcProduct.productTracking)
  }, [atcProduct.productTracking])

  const handleAddToFavorites = useCallback(async () => {
    try {
      setCurrentState({ type: 'success', value: undefined })
      setCurrentState({ type: 'submitting', value: true })
      const result = await addToFavorites({ variables: { params: atfParams } })
      if (result.data == null) return
      const atf = result.data.addToFavorites
      if (atf != null && result.data.addToFavorites?.success === true) {
        handleATFSuccess(atf.favoriteListId)
      }
      if (atf != null && result.data.addToFavorites?.success === false && result.data.addToFavorites?.nameAlreadyExists === true) {
        setCurrentState({ type: 'success', value: false })
        setCurrentState({ type: 'listNameAlreadyExists', value: true })
        setCurrentState({ type: 'submitting', value: false })
      }
    } catch {
      setCurrentState({ type: 'success', value: false })
      setCurrentState({ type: 'submitting', value: false })
    }
  }, [atfParams])

  useEffect(() => {
    /** Sets the selected list used for the Fancy Select */
    if (defaultListId != null && !currentState.defaultListSet) {
      const defaultList = fetchList(defaultListId)
      setCurrentState({ type: 'selectedList', value: defaultList })
      setCurrentState({ type: 'defaultListSet', value: true })
    }
  }, [defaultListId, currentState.selectedList, currentState.defaultListSet])

  return (
    <>
      <ModalBody>
        {(currentState.success ?? false) && currentState.successId != null
          ? (
            <div className='my-3'>
              <div className='text-center'>
                <h2><FontAwesomeIcon icon={faCheckCircle} className='mr-2 c-green-lt-1' />Added to Favorites</h2>
              </div>
              <div className='text-center'>This item has been added to <a className='primary-link' target='_blank' href={favoriteListLink} rel='noreferrer'>{selectedListName}</a></div>
            </div>)
          : (
            <>
              <div className='u-flex-grid-row w-100 mx-0 text-left my-3'>
                {(error != null || submitFailed) && (
                  <div className='u-flex-grid-col-12 px-0'>
                    <Alert alertType='error' icon={faTimesCircle} className='mb-4'>
                      <div>{favoritesErrorMessage}</div>
                    </Alert>
                  </div>
                )}
                <div className='u-flex-grid-col-12 px-0'>
                  <h2>Add To Favorites</h2>
                </div>
                <FavoritesSelection
                  disabled={inputDisabled}
                  selectedList={currentState.selectedList?.value}
                  lists={favoritesResultLists}
                  newListName={currentState.newListName}
                  handleNewListName={handleNewListName}
                  handleSelectedList={handleSelectedList}
                />
              </div>
              <div className='u-flex-grid-row text-left'>
                <label className='font-weight-bold c-gray-dk-3 u-flex-grid-col-12'>Notes (optional)</label>
                <div className='u-flex-grid-col-12'>
                  <textarea disabled={inputDisabled} className='form-control rounded-sm' onChange={handleTextAreaChange} value={currentState.notes} />
                </div>
              </div>
            </>)}
      </ModalBody>
      <ModalFooter>
        {(currentState.success ?? false) && currentState.successId != null
          ? (
            <div className='d-flex justify-content-center'>
              <Button tag='a' color='primary' className='mt-4' target='_blank' href={favoriteListLink} data-test='view-list-button'>
                View List
              </Button>
            </div>
            )
          : (
            <div className='float-right d-flex flex-row align-items-center'>
              <a className='secondary-link mr-5 text-nowrap' onClick={handleAddToFavoritesToggle}>Cancel</a>
              <Button color='primary' disabled={submitDisabled} onClick={handleAddToFavorites} data-test='modal-add-to-favorites-button'>
                Add to Favorites
              </Button>
            </div>
            )}
      </ModalFooter>
    </>
  )
}

interface AddToFavoritesModalProps {
  /**
   * Unique identifier of the modal, used for toggling
   */
  id: string
  /**
   * Lists from the query, used here to render which type of selection
   */
  favoritesResult: ATFOptionsType | undefined
  /**
   * Used to render loading spinner if we are loading the favorites
   */
  loadingFavorites: boolean
  /**
   * Add to cart product data send to the add to favorites mutation
   */
  atcProduct: AddToFavoritesData
  /**
   * Toggling of the modal
   */
  handleAddToFavoritesToggle: () => void
  /**
   * Used to display the error message
   */
  error: ApolloError | undefined
}

/**
 * Add To Favorites Modal holds functionality for adding an item to your favorites
 */
function AddToFavoritesModal ({
  id,
  favoritesResult,
  loadingFavorites,
  atcProduct,
  handleAddToFavoritesToggle,
  error
}: AddToFavoritesModalProps): ReactElement | null {
  const [openedIndex, setOpenedIndex] = useState(0)

  const handleModalHide = useCallback(() => {
    setOpenedIndex(i => i + 1)
  }, [])

  return (
    <Modal id={id} size='default' onHide={handleModalHide}>
      <ModalHeader onToggle={handleAddToFavoritesToggle} />
      <LoadingIndicator containerClass='my-auto' loading={loadingFavorites}>
        {loadingFavorites && (
          <ModalBody>
            <div style={{ height: '231px' }} />
          </ModalBody>
        )}
        {!loadingFavorites && favoritesResult == null && (
          <Alert alertType='error' icon={faTimesCircle} className='mb-4'>
            <div>Could not load favorites. Refresh the page before trying again.</div>
          </Alert>
        )}
        {!loadingFavorites && favoritesResult != null && (
          <AddToFavoritesModalInner
            key={openedIndex}
            atcProduct={atcProduct}
            favoritesResult={favoritesResult}
            handleAddToFavoritesToggle={handleAddToFavoritesToggle}
            error={error}
          />
        )}
      </LoadingIndicator>
    </Modal>
  )
}

interface AddToFavoritesState {
  /**
   * Favorites list, used to select a list
   */
  selectedList: ListType | undefined
  /**
   * The current notes to save along side with adding this item to favorites
   */
  notes: string
  /**
   * When creating a new list what will that name be
   */
  newListName: string
  /**
   * Was adding to favorites did we succeed or fail
   */
  success: boolean | undefined
  /**
   * Has the ATF started submitting
   */
  submitting: boolean
  /**
   * Has the ATF started submitting
   */
  successId: number | undefined
  /**
   * Has the default list been set
   */
  defaultListSet: boolean
  /**
   * Does a list with that name already exist
   */
  listNameAlreadyExists: boolean
}

type AddToFavoritesActionType =
  | { type: 'selectedList', value: ListType | undefined }
  | { type: 'notes', value: string }
  | { type: 'newListName', value: string }
  | { type: 'success', value: boolean | undefined }
  | { type: 'submitting', value: boolean }
  | { type: 'successId', value: number | undefined }
  | { type: 'defaultListSet', value: boolean }
  | { type: 'listNameAlreadyExists', value: boolean }

const initialAddToFavoritesState: AddToFavoritesState = {
  selectedList: undefined,
  notes: '',
  newListName: '',
  success: undefined,
  submitting: false,
  successId: undefined,
  defaultListSet: false,
  listNameAlreadyExists: false
}

const addToFavoritesReducer = (state: AddToFavoritesState, action: AddToFavoritesActionType): AddToFavoritesState => {
  return { ...state, [action.type]: action.value }
}

interface AddToFavoritesModalTriggerProps {
  /**
   * Add to cart product data send to the add to favorites mutation
   */
  atcProduct: AddToFavoritesData
  children: ReactNode
}

/**
 * Add To Favorites top level component that fetches favorites list and renders button to toggle modal
 */
function AddToFavoritesModalTrigger ({
  atcProduct,
  children
}: AddToFavoritesModalTriggerProps): ReactElement {
  const uniqueId: string = useMemo(() => uuid(), [])
  const id: string = useMemo(() => `${uniqueId}-atf`, [])
  const [fetchFavoritesList, { loading, error, data }] = useGetFavoritesListLazyQuery({ fetchPolicy: 'cache-and-network' })
  const favoritesResult = useMemo(() => data?.addToFavoritesOptions, [data?.addToFavoritesOptions])
  const handleButtonClick = useCallback(() => {
    void fetchFavoritesList()
    handleAddToFavoritesToggle()
  }, [])
  const mappedChildren = useChildrenProps(children, { onClick: handleButtonClick })

  const handleAddToFavoritesToggle = useCallback(() => {
    $(`#${id}`).modal('toggle')
  }, [id])

  return (
    <>
      {mappedChildren}
      <AddToFavoritesModal
        id={id}
        handleAddToFavoritesToggle={handleAddToFavoritesToggle}
        favoritesResult={favoritesResult}
        loadingFavorites={loading}
        error={error}
        atcProduct={atcProduct}
      />
    </>
  )
}

export { AddToFavoritesModalTrigger }
