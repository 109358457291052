import { ReactElement, useMemo } from 'react'
import { SelectedFacet as SelectedFacetType } from '@web/product-navigation-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimesCircle } from '@fortawesome/pro-solid-svg-icons'
import '@web/styles/SelectedFacets.less'
import { Link } from 'react-router-dom'

interface ISelectedFacets {
  facets: SelectedFacetType[]
  clearAllUrl: string
}

function SelectedFacets ({ facets, clearAllUrl }: ISelectedFacets): ReactElement|null {
  const groupedFacets = useMemo(() => {
    const groups: {[key: string]: SelectedFacetType[]} = facets.reduce((acc, facet) => {
      if (facet?.FromSection != null) {
        if (acc[facet.FromSection] == null) {
          acc[facet.FromSection] = []
        }
        acc[facet.FromSection].push(facet)
      }
      return acc
    }, {})
    return groups
  }, [facets])

  const groupKeys = useMemo(() => Object.keys(groupedFacets), [groupedFacets])

  return (
    <div className='d-flex flex-wrap align-items-center react-selected-facets mb-3'>
      {groupKeys.map(groupKey =>
        <span className='react-selected-facet-container d-flex flex-wrap align-items-center p-2 c-bg-gray-lt-3 mb-2 mr-2' key={groupKey} data-test='selected-facet-name'>
          <span className='f600 pl-2'>{groupKey}:</span>
          {groupedFacets[groupKey].map(facet =>
            <SelectedFacet key={`${facet.SearchField}-${facet.Value}`} name={facet.Name} linkUrl={facet.Url} />
          )}
        </span>
      )}
      <Link to={clearAllUrl} className='p-2 mb-1 react-selected-facet text-decoration-none' data-test='clear-all-facets-link'>
        <span className='text-nowrap'>clear all<FontAwesomeIcon icon={faTimesCircle} className='ml-1' /></span>
      </Link>

    </div>
  )
}

interface ISelectedFacet{
  /**
   * text to display on the selected facet
   */
  name: string

  /**
   * the full url for removing the selected facet
   */
  linkUrl: string
}

function SelectedFacet ({ name, linkUrl }: ISelectedFacet): ReactElement {
  return (
    <Link to={linkUrl} className='react-selected-facet p-2 text-decoration-none' data-test='facet-clear-link'>
      <span>
        <span dangerouslySetInnerHTML={{ __html: name }} /><FontAwesomeIcon icon={faTimesCircle} className='ml-1' />
      </span>
    </Link>
  )
}

export { SelectedFacets }
