import {
  ReactElement,
  useMemo,
  useContext
} from 'react'
import { PaymentMethod } from '@web/shared-data-access-queries'
import { PaymentMethodSelectorContext } from '@web/payment-methods'
import { PaymentMethodSelectionBoxOption } from './types'
import { PaymentMethodSelectionBoxesGroup } from './PaymentMethodSelectionBoxesGroup'

/*
 * PaymentMethodSelectionBoxes Props
 */
interface TermsSelectionBoxesProps {
  termsPaymentMethods: PaymentMethod[] | null | undefined
  /*
  * Currently selected value
  */
  selectedValue?: PaymentMethod | null
  mapOptions: (paymentMethods: PaymentMethod[] | null | undefined, selectedValue?: PaymentMethod | null) => PaymentMethodSelectionBoxOption[] | undefined
}

/*
 * Payment Method Selector Selection Boxes component
 */
function TermsSelectionBoxes ({ termsPaymentMethods, selectedValue, mapOptions }: TermsSelectionBoxesProps): ReactElement {
  const {
    userCanUseTerms
  } = useContext(PaymentMethodSelectorContext)

  const paymentMethodSelectionBoxOptions = mapOptions(termsPaymentMethods, selectedValue)
  const selectedPaymentMethodSelectionBoxOption = useMemo(() => paymentMethodSelectionBoxOptions?.filter(x => x.paymentMethod.SelectableValue === selectedValue?.SelectableValue)[0], [paymentMethodSelectionBoxOptions])

  return (
    <>
      {userCanUseTerms &&
        <PaymentMethodSelectionBoxesGroup
          paymentMethods={paymentMethodSelectionBoxOptions}
          methodLabel='Stuller Line of Credit'
          selectedValue={selectedPaymentMethodSelectionBoxOption}
          allowAdding={false}
        />}
    </>
  )
}

export {
  TermsSelectionBoxes
}
