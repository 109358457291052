import { JsonPrice } from '@web/shared-data-access-queries'
import { CSSProperties, ReactElement } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle } from '@fortawesome/pro-solid-svg-icons'
import { ProductCardImage } from './ProductCardImage'
import { LabGrown } from '@web/products'
import clsx from 'clsx'
import '@web/styles/ProductCard.less'

interface ProductCardProps {
  image: string
  itemNumber: string
  description: string
  inventoryItemId?: number | null
  buttonText?: string | null
  price?: JsonPrice | null
  detailsUrl?: string | null
  isSelected?: boolean| null
  selectedButtonText?: string
  title?: string | null
  sideImage?: string | null
  placeholderImage: string | null
  onSelect?: ((inventoryItemId: number) => void) | null
  secondaryStoneType?: string | null
  secondaryStoneOrigin?: string | null
}

function ProductCard ({ image, itemNumber, detailsUrl, description, buttonText, price, isSelected, selectedButtonText, title, sideImage, placeholderImage, inventoryItemId, onSelect, secondaryStoneType, secondaryStoneOrigin }: ProductCardProps): ReactElement | null {
  const selectedIconStyle: CSSProperties = {
    right: '10px',
    top: '10px',
    zIndex: 1,
    pointerEvents: 'none'
  }

  const showButton = buttonText !== null
  const itemId = inventoryItemId ?? 0
  if (showButton) {
    selectedButtonText = selectedButtonText ?? 'Selected'
  }

  const detailsUrlWithRecSource = detailsUrl != null ? detailsUrl + '&recommendationSource=Add_A_Head_Modal' : ''

  return (
    <div className='product-card u-border-all overflow-hidden h-100 u-border-radius-medium position-relative'>
      {isSelected === true &&
        <FontAwesomeIcon icon={faCheckCircle} className='c-primary-dk-1 position-absolute f3-5' style={selectedIconStyle} />}
      <div className='d-flex flex-row flex-sm-column mx-0 h-100'>
        <div className='p-4 mx-auto'>
          {title != null &&
            <div className='text-center f6 py-2 c-bg-gray-lt-3'>{title}</div>}
          <div className='position-relative product-card-image-wrapper'>
            <ProductCardImage
              mainImage={image}
              placeholderImage={placeholderImage}
              sideImage={sideImage}
              imageAlt={description ?? 'product-image'}
            />
          </div>
        </div>
        <div className='p-4 pl-0 product-card-info-section d-flex flex-column justify-content-between h-100'>
          <div>
            {secondaryStoneOrigin?.toLocaleLowerCase() === 'lab-grown' && secondaryStoneType?.toLocaleLowerCase() === 'diamond' &&
              <LabGrown className='mb-3' popoverZIndex={1} />}
            <div className='d-flex flex-row justify-content-between pb-0 pb-sm-3'>
              <div className='u-bold' dangerouslySetInnerHTML={{ __html: price?.CurrentPrice?.HtmlFormattedPrice ?? '' }} />
              <a className='f6 u-bold d-none d-sm-inline primary-link' href={detailsUrlWithRecSource} target='_blank' rel='noreferrer'>{itemNumber}</a>
            </div>
            <div className='pb-0 pb-sm-5'>
              <a className='f5-5 product-card-desc position-relative' href={detailsUrlWithRecSource} target='_blank' rel='noreferrer'>{description}</a>
            </div>
          </div>
          <a className='f6 u-bold d-block d-sm-none my-2 my-sm-0' href={detailsUrlWithRecSource} target='_blank' rel='noreferrer'>{itemNumber}</a>
          {showButton &&
            <a data-test={buttonText} className={clsx('sbtn sbtn-rounded w-100 mt-auto', isSelected === true ? 'sbtn-negative-transparent' : 'sbtn-primary')} onClick={() => onSelect?.(itemId)}>{isSelected === true ? selectedButtonText : buttonText}</a>}
        </div>
      </div>
    </div>
  )
}

export { ProductCardProps, ProductCard }
