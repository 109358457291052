import { ChangeEvent, ReactElement, useEffect, useMemo, useState } from 'react'
import { Input, InputProps } from '@web/shared-ui-components'

/**
 * Wild math function that gets the precision of the number without converting to a string
 */
function getPrecision (a): number {
  if (!isFinite(a)) return 0
  let e = 1
  let p = 0
  while (Math.round(a * e) / e !== a) {
    e *= 10
    p++
  }
  return p
}

interface NumberInputProps extends Pick<InputProps, Exclude<keyof InputProps, 'value' | 'onChange'>> {
  /**
   * Value of number input from outside component
   */
  value: number | undefined
  /**
   * callback to change the value in the calling component
   */
  onChange: (value: number | undefined, name: string) => void
  /**
   * Allow/Disallow decimals
   */
  allowDecimal?: boolean
  /**
   * Precision of decimal
   */
  precision?: number
  /**
   * Allow/Disallow '0' input
   */
  allowZero?: boolean
  className?: string
  inputMode?: string
}

/**
 * Component that handles using a text input field for floats/ints and adding constraints on it:
 * - Allow only numbers
 * - Allow/Disallow decimals
 * - Allow/Disallow zero
 * - Set a precision and successfully keep that precision in the input field when hitting backspace
 */
function NumberInput ({
  value,
  onChange,
  allowDecimal = false,
  precision = 2,
  allowZero = false,
  className,
  inputMode = 'numeric',
  ...props
}: NumberInputProps): ReactElement {
  const [userEnteredZero, setUserEnteredZero] = useState(false)
  const [currentPrecision, setCurrentPrecision] = useState<number>(0)
  const [localValue, setLocalValue] = useState<string | undefined>(value?.toString())

  useEffect(() => {
    // to handle when edit is clicked in stone submission modals, find the precision from the value sent in, if it exists
    setLocalValue(value?.toFixed(currentPrecision === 0 ? getPrecision(value) : currentPrecision))
  }, [value])

  const inputValue = useMemo(() => {
    if (allowZero && userEnteredZero) {
      return localValue == null ? '' : localValue
    }

    return localValue === '0' || localValue == null ? '' : localValue
  }, [localValue, userEnteredZero])

  const handleChangeInput = (event: ChangeEvent<HTMLInputElement>): void => {
    const value = event.target.value
    setUserEnteredZero(value === '0')

    if (value === '') {
      setLocalValue('')
      onChange(undefined, event.target.name)
      return
    }

    if (allowDecimal) {
      // If the user enters more than one decimal, NaN, or precision is greater than max, ignore the input
      const numberOfDecimalsEntered = value.match(/\./g)?.length ?? 0
      const tempPrecision = value.split('.')[1]?.length ?? 0
      const isNotNumberInput = !/[\d.]/.test(value[value.length - 1])
      const invalidInput = numberOfDecimalsEntered > 1 || isNotNumberInput || tempPrecision > precision
      if (invalidInput) {
        return
      }

      const userEnteredDecimal = numberOfDecimalsEntered === 1

      setCurrentPrecision(tempPrecision)
      setLocalValue(value)

      if ((userEnteredDecimal && tempPrecision > 0 && tempPrecision <= precision) ||
        (!userEnteredDecimal)) {
        onChange((!isNaN(parseFloat(value)) ? parseFloat(value) : undefined), event.target.name)
      } else if (value === '') {
        onChange(undefined, event.target.name)
      }
      return
    }

    onChange((!isNaN(parseInt(value)) ? parseInt(value) : undefined), event.target.name)
  }

  return (
    <Input
      {...props}
      className={className}
      inputMode={inputMode}
      value={inputValue}
      onChange={handleChangeInput}
    />
  )
}

export { NumberInput }
