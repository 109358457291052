import {
  ReactNode,
  ReactElement,
  ForwardedRef,
  forwardRef,
  useContext,
  useMemo
} from 'react'
import { DropdownContext } from './DropdownContext'
import '@web/styles/Dropdown.less'
import clsx from 'clsx'
import { Collapse } from '../Collapse'

interface DropdownBodyProps {
  /**
   * Id of element
   */
  id?: string
  /**
    * Additional class name(s) to give to the containing element
    */
  className?: string
  /**
  * Additional class name(s) to give to the collapse element
  */
  collapseClassName?: string
  /**
   * Override for the content height
   */
  contentHeight?: number
  /**
    * Children of element
    */
  children?: ReactNode
}

/**
 * Dropdown body component that contains the content
 */
function DropdownBody ({
  id,
  className,
  collapseClassName,
  contentHeight,
  children
}: DropdownBodyProps, ref: ForwardedRef<any>): ReactElement {
  const { isCollapsed } = useContext(DropdownContext)
  const collapseClass = useMemo(() => clsx(
    'react-dropdown-collapse',
    collapseClassName
  ), [collapseClassName])
  const classNames = useMemo(() => clsx(
    'react-dropdown-body px-3 pb-2',
    className
  ), [className, isCollapsed])

  return (
    <Collapse
      id={id}
      isCollapsed={isCollapsed}
      className={collapseClass}
      contentHeight={contentHeight}
      scrollOnCollapse
    >
      <div
        className={classNames}
      >
        {children}
      </div>
    </Collapse>
  )
}

/**
 * DropdownBody with forwardRef
 */
const DropdownBodyWrapped = forwardRef(DropdownBody)

export { DropdownBodyWrapped as DropdownBody }
