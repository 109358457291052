import { ReactElement, ReactNode } from 'react'
import clsx from 'clsx'
import '@web/styles/CheckboxGroup.less'

interface CheckboxGroupProps {
  /*
   * Options for the select as children
   */
  children: ReactNode
  /*
   * Class overrides
   */
  className?: string
  /*
   * List layout
   */
  layout?: 'vertical' | 'horizontal'
}

/*
 * Group for the checkbox component
 */
function CheckboxGroup ({
  className,
  layout = 'vertical',
  children
}: CheckboxGroupProps): ReactElement {
  const classNames = clsx('react-checkbox-group', className, `${layout}-checkbox-group`)

  return (
    <div className={classNames}>
      {children}
    </div>
  )
}

export { CheckboxGroup }
