import React, {
  ChangeEvent,
  ReactElement, useCallback, useEffect, useState
} from 'react'
import { Input } from '@web/shared-ui-components'
import { useDebounce } from '@web/shared-util-hooks'

interface PanPadData {
  FirstName: string
  LastName: string
  CardNumber: string
  ExpirationMonth: string
  ExpirationYear: string
  Cvv: string
  Zip: string
  StreetNumber: string
}

interface PanPadFieldProps {
  usePanPad: boolean | undefined
  savePanPadData: React.Dispatch<React.SetStateAction<string>>
  saveNewCardExpiration: React.Dispatch<React.SetStateAction<string>>
  saveNewCardNumber: React.Dispatch<React.SetStateAction<string | null | undefined>>
  saveNewCardCvv: React.Dispatch<React.SetStateAction<string | null | undefined>>
  saveNewCardZip: React.Dispatch<React.SetStateAction<string>>
  savePanpadSuccessStatus: React.Dispatch<React.SetStateAction<boolean>>
  panpadSuccessStatus: boolean
}
/**
 * PanPadField component
 */
function PanPadInput ({ usePanPad = true, savePanPadData, saveNewCardExpiration, saveNewCardNumber, saveNewCardCvv, saveNewCardZip, savePanpadSuccessStatus, panpadSuccessStatus }: PanPadFieldProps): ReactElement | null {
  if (!usePanPad) return null
  const [panPadData, setPanPadData] = useState<string>('')
  const debouncedPanPadData = useDebounce<string>(panPadData, 500)
  const matchFieldValue = useCallback((value: string): string[] | null => {
    const regex = /^02[A-F0-9]{4}([A-F0-9]{2})[A-F0-9]{12}(?:%[A-Z*]([0-9*]{1,19})\^([^/]+)[/]?([^^]+)?\^([0-9]{2})([0-9]{2})[^?]+\?\*?)?(?:;([0-9*]{1,19})=([0-9]{2})([0-9]{2})(:[0-9*]{3})?[^?]*\?\*?)?(?:1([0-9]+)=)?(?:0([0-9]+)=)?.*03$/i
    return value.match(regex)
  }, [])

  const getDataFromMatches = useCallback((matches: string[]): PanPadData => {
    return {
      FirstName: matches[4],
      LastName: matches[3],
      CardNumber: matches[2] != null ? matches[2] : matches[7],
      ExpirationMonth: matches[6] != null ? matches[6] : matches[9],
      ExpirationYear: matches[5] != null ? matches[5] : matches[8],
      Cvv: matches[10],
      Zip: matches[12],
      StreetNumber: matches[11]
    }
  }, [debouncedPanPadData])

  useEffect(() => {
    const matches = matchFieldValue(debouncedPanPadData)
    if (matches != null && matches.length === 13 && matches[0] !== undefined) {
      const panpadData = getDataFromMatches(matches)
      savePanPadData(debouncedPanPadData)
      saveNewCardNumber(panpadData.CardNumber)
      saveNewCardZip(panpadData.Zip)
      saveNewCardExpiration(`${panpadData.ExpirationMonth}/${panpadData.ExpirationYear}`)
      savePanpadSuccessStatus(true)
    }
    setPanPadData('')
  }, [debouncedPanPadData])

  const handleFocus = useCallback(() => {
    savePanpadSuccessStatus(false)
  }, [])

  const handleInputTextChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setPanPadData(event.target.value)
  }, [])

  return (
    <div className='form-input-group'>
      <label>Card Info (from PanPad)</label>
      <Input name='CardInfo' value={panPadData} onChange={handleInputTextChange} onFocus={handleFocus} type='password' autoComplete='off' data-test='panpad-input' />
    </div>
  )
}

export { PanPadInput }
