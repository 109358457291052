import { ReactElement } from 'react'
import { PaymentMethod, PaymentMethodType } from '@web/shared-data-access-queries'
import clsx from 'clsx'
import { faUniversity } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { PaymentMethodSummary } from '../summaries/PaymentMethodSummary'

/*
 * Props for PaymentMethodSelectionBoxDisplay
 */
interface PaymentMethodSelectionBoxDisplayProps {
  /*
  * Payment method to show for display
  */
  paymentMethod: PaymentMethod
  /*
  * Is payment method selected
  */
  isSelected: boolean
}

/*
 * Display text for payment method selection box
 */
function PaymentMethodSelectionBoxDisplay ({ paymentMethod, isSelected }: PaymentMethodSelectionBoxDisplayProps): ReactElement {
  if ((paymentMethod.Method === PaymentMethodType.Creditcard || paymentMethod.Method === PaymentMethodType.Debitcard) && paymentMethod.CreditCard != null) {
    const expirationDate = `${paymentMethod.CreditCard.ExpirationMonth}/${String(paymentMethod.CreditCard.ExpirationYear).slice(-2)}`
    return (
      <>
        <div className={clsx(!paymentMethod?.CreditCard.IsExpired && !isSelected ? 'c-gray-dk-3' : isSelected && !paymentMethod?.CreditCard?.IsExpired ? 'c-primary-dk-3' : 'c-red-dk-1')} data-test='saved-card-details'>
          <span className={clsx('c-cc-image mr-2', paymentMethod.CardTypeIcon)} />
          <span className='mr-2' data-test='saved-card-holder-name'>
            {paymentMethod.CreditCard.CardHolderName}
          </span>
          <br />
          <span className='font-weight-normal'>
            <span className='mr-2' data-test='saved-card-number'>
              <PaymentMethodSummary
                creditCardLastFour={paymentMethod.CreditCard.CardNumber}
                displayCardType={paymentMethod.DisplayCardType}
                isDebit={paymentMethod.CreditCard.IsDebit}
                paymentMethodMerchantDisplayType='none'
              />
            </span>
            <span className='d-none d-sm-inline'>|</span>
            <span className='d-block d-sm-inline mx-0 mx-sm-2'>
              {paymentMethod.CreditCard.IsExpired
                ? (
                  <span className='c-red-dk-1 font-weight-bold'>
                    Expired {expirationDate}
                  </span>
                  )
                : (
                  <span>
                    Expires {expirationDate}
                  </span>
                  )}
            </span>

          </span>
        </div>
      </>
    )
  }

  if (paymentMethod.Method === PaymentMethodType.Ach) {
    return (
      <div className={clsx(isSelected ? 'c-primary-dk-3' : 'c-gray-dk-3')} data-test='saved-bank-details'>
        <FontAwesomeIcon icon={faUniversity} className={clsx('mr-2 ', isSelected ? 'c-primary-dk-1' : ' c-gray-dk-1')} size='lg' />
        <span className={clsx('font-weight-bold', isSelected ? 'c-primary-dk-3' : 'c-gray-dk-3')}>{paymentMethod.BankAccount?.BankName}</span>
        <br />
        <span className='font-weight-normal'>
          <span data-test='account-ending-in-text'>
            <PaymentMethodSummary
              bankAccountLastFour={paymentMethod.BankAccount?.AccountNumber}
              paymentMethodMerchantDisplayType='none'
            />
          </span>
        </span>
      </div>
    )
  }

  return (
    <div className={clsx('font-weight-bold mx-2', isSelected ? 'c-primary-dk-3' : 'c-gray-dk-3')}>
      {paymentMethod.DisplayName}
    </div>
  )
}

export { PaymentMethodSelectionBoxDisplay }
