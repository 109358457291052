import { ReactElement } from 'react'
import clsx from 'clsx'

interface MeteorBadgeProps {
  source: string
  width: string
  xsWidth: string
  title: string
  classes?: string
  xsClasses?: string
}

/*
  This is a component that houses the images for a meteor badge for all window sizes
*/
function MeteorBadge ({ source, width, xsWidth, title, classes, xsClasses }: MeteorBadgeProps): ReactElement {
  return (
    <>
      <img src={`${source}?wid=${width}&fmt=smart-alpha`} className={clsx(classes != null && classes, 'd-none d-sm-inline')} alt={title} title={title} />
      <img src={`${source}?wid=${xsWidth}&fmt=smart-alpha`} className={clsx(xsClasses != null && xsClasses, 'd-inline d-sm-none')} alt={title} title={title} />
    </>
  )
}

export { MeteorBadge }
