import { ProductSearchAttribute } from '@web/shared-data-access-queries'
import { useEventListener } from '@web/shared-util-hooks'
import { SearchAttributeDropdownRow } from '@web/product-navigation-feature'
import clsx from 'clsx'
import { ReactElement, useState } from 'react'
import { usePopper } from 'react-popper'
import { IProductResult } from './ProductResultCard'

interface SearchAttributeDropdownProps<TProductResult extends IProductResult = IProductResult> {
  onSelect?: React.Dispatch<React.SetStateAction<ProductSearchAttribute | undefined>>
  onUnselect?: (attribute: ProductSearchAttribute) => void
  onSearchAttrSelected?: (productResult: TProductResult, position: number, overrideItemId?: string) => void
  productResult: TProductResult
  featuredAttributes: ProductSearchAttribute[]
  selectedAttribute?: ProductSearchAttribute
  attributesElement: HTMLElement | null
  dropdownButtonElement: React.RefObject<HTMLAnchorElement> | null
  handleClose: () => void
  className: string
}

function SearchAttributeDropdown<TProductResult extends IProductResult = IProductResult> ({
  onSelect,
  onUnselect,
  onSearchAttrSelected,
  productResult,
  featuredAttributes,
  selectedAttribute,
  attributesElement,
  dropdownButtonElement,
  handleClose,
  className
}: SearchAttributeDropdownProps<TProductResult>): ReactElement | null {
  const [popperElement, setPopperElement] = useState<HTMLDivElement|null>(null)
  const { styles, attributes } = usePopper(attributesElement, popperElement, {
    placement: 'bottom-end',
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, 8]
        }
      },
      {
        name: 'flip',
        enabled: false
      }
    ]
  })

  useEventListener('click', (event: Event) => {
    if (dropdownButtonElement?.current == null || !dropdownButtonElement?.current.contains(event.target as Node | null)) {
      handleClose()
    }
  })

  return (
    <>
      {(onSelect != null) && onUnselect &&
        <div className={clsx(className, 'attrDropdownScroll u-fancy-scroll')} ref={setPopperElement} style={styles.popper} {...attributes.popper}>
          {featuredAttributes.map((attr, index) =>
            <SearchAttributeDropdownRow
              key={`${productResult.Id}-${attr.ItemId}-dd`}
              onSelect={() => onSelect(attr)}
              onUnselect={() => onUnselect(attr)}
              onSearchAttrSelected={() => onSearchAttrSelected?.(productResult, index, attr.ItemId.toString())}
              attribute={attr}
              className={clsx(attr !== featuredAttributes[featuredAttributes.length - 1] && 'border-bottom')}
            />
          )}
        </div>}
    </>
  )
}

export { SearchAttributeDropdown }
