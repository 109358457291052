import {
  ReactElement,
  ReactNode,
  useContext,
  useMemo
} from 'react'
import { AddPaymentMethodContext } from '@web/payment-methods'
import { faMinus, faPlus } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

interface AddPaymentMethodHeaderProps {
  children: ReactNode
}

/**
 * AddPaymentMethodHeader component
 */
function AddPaymentMethodHeader ({ children }: AddPaymentMethodHeaderProps): ReactElement {
  const { isCollapsed, onToggleCollapse } = useContext(AddPaymentMethodContext)
  const faCollapsibleIcon = useMemo(() => isCollapsed ? faPlus : faMinus, [isCollapsed])

  return (
    <label className='u-flex-grid-row d-flex w-100 flex-column flex-md-row flex-nowrap u-cursor-pointer' onClick={onToggleCollapse}>
      <div className='p-3 u-regular-font-size c-gray-dk-1 d-flex w-100 align-items-start align-items-sm-center'>
        <FontAwesomeIcon icon={faCollapsibleIcon} className='mt-2 mr-3 ml-2 pl-2 t-3 mt-sm-0' />
        <div className='ml-3 d-flex flex-wrap w-100 align-items-center justify-content-between'>
          {children}
        </div>
      </div>
    </label>
  )
}

export {
  AddPaymentMethodHeader
}
