import {
  ReactNode,
  forwardRef,
  useState,
  useContext
} from 'react'
import clsx from 'clsx'
import { CarouselContext, CarouselContextType } from './CarouselContext'

export interface CarouselItemProps extends Pick<CarouselContextType, 'index'> {
  /**
   * Id of element
   */
  id?: string
  /**
   * Additional class name(s) to give to the containing element
   */
  className?: string
  /**
   * Children of element
   */
  children?: ReactNode
  /**
   * Index of the item
   */
  itemIndex?: number
  /**
   * Carousel interval override for the item
   */
  interval?: number
}

/**
 * Carousel item component that holds a single 'slide'
 */
const CarouselItem = forwardRef<HTMLDivElement, CarouselItemProps>(({
  className,
  children,
  index: indexIn,
  itemIndex,
  interval,
  ...otherAttributes
}, ref) => {
  const { index: indexContext } = useContext(CarouselContext)
  const index = indexIn ?? indexContext
  const activeInit = useState(itemIndex === index && 'active')
  const classNames = clsx(
    'carousel-item item',
    activeInit,
    className
  )

  return (
    <div
      className={classNames}
      data-bs-interval={interval}
      ref={ref}
      {...otherAttributes}
    >
      {children}
    </div>
  )
})

export {
  CarouselItem
}
