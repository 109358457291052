import { ReactElement, Dispatch, SetStateAction } from 'react'
import '@web/styles/SideNavigationSection.less'
import { faMinusCircle, faPlusCircle } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

interface SectionHeaderContentProps {
  sectionTitle: string
  sectionTitleCount: number
  collapsed: boolean
  onCollapseToggle: Dispatch<SetStateAction<boolean>>
}

function SectionHeaderContent ({ sectionTitle, sectionTitleCount, collapsed, onCollapseToggle }: SectionHeaderContentProps): ReactElement {
  return (
    <div className='sectionTitle' data-test='side-menu' onClick={() => onCollapseToggle(!collapsed)}>
      {sectionTitle}
      <span className='titleCount ml-2'>({sectionTitleCount})</span>
      <span>
        <FontAwesomeIcon icon={collapsed ? faPlusCircle : faMinusCircle} />
      </span>
    </div>
  )
}

export { SectionHeaderContent }
