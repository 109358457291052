import { StoneForViewAvailableMountingsViewModel } from '@web/shared-data-access-queries'
import clsx from 'clsx'
import { ReactElement, ReactNode } from 'react'
import { useUserContext } from '@web/shared-data-access-context'

export interface StoneForViewAvailableMountingsHeaderProps extends StoneForViewAvailableMountingsViewModel {
  children?: ReactNode
}

function StoneForViewAvailableMountingsHeader (props: StoneForViewAvailableMountingsHeaderProps): ReactElement {
  const { context } = useUserContext()
  const { Price, PriceWithoutDiscount, Shape, Color, StoneQuality, Image, DetailsUrl, SizeMm, Variety, Type } = props
  const description = `${SizeMm} ${Shape} ${Type} ${StoneQuality} ${Color} ${Variety}`

  return (
    <div className='border c-border-gray-lt-2 u-border-radius-medium d-flex flex-row align-items-start align-items-lg-center mb-2 mx-0 mt-0'>
      <a href={DetailsUrl} title='Click for Details' className='p-4'>
        <img alt='stone' className='img-responsive' src={Image.Thumb75Url ?? Image.Thumb165Url} style={{ minWidth: '55px', width: '55px' }} />
      </a>
      <div className='d-flex flex-column flex-lg-row flex-grow-1 align-items-start align-items-lg-center py-4 pr-4 pl-0'>
        <div>
          <div className='u-bold'>This stone will be set in the center location of the mounting you choose
          </div>
          <div className='f300'>
            <div className='f3-5'>{description}</div>
          </div>
        </div>
        {context?.HidePrices === false && (
          <div className='ml-0 ml-lg-auto mt-2 mt-lg-0'>
            {Price !== PriceWithoutDiscount &&
              <div className='f300 u-strikethrough text-left text-lg-right' dangerouslySetInnerHTML={{ __html: PriceWithoutDiscount }} style={{ fontSize: '16px' }} />}
            <div
              className={clsx('f700 text-left text-lg-right ', PriceWithoutDiscount !== Price ? 'discountText' : '')}
              dangerouslySetInnerHTML={{ __html: Price }}
              style={{ fontSize: '18px' }}
            />
          </div>)}
      </div>
    </div>
  )
}

export { StoneForViewAvailableMountingsHeader }
