import Ajv from 'ajv'
import errors from 'ajv-errors'
import formats from 'ajv-formats'
import keywords from 'ajv-keywords'

/*
 * Default ajv instance
 */
const ajv = errors(formats(keywords(new Ajv({
  allErrors: true,
  useDefaults: true,
  coerceTypes: true,
  removeAdditional: true
}))))

export {
  ajv
}
