import { useMemo, ReactElement, useCallback, useState, useRef, RefObject, useEffect } from 'react'
import clsx from 'clsx'
import { NavigationItem } from '@web/shared-data-access-queries'
import { SideNavigationSection as SideNavigationSectionType } from '@web/product-navigation-types'
import { CmsContent, Collapse } from '@web/shared-ui-components'
import { NavigationItemRowWrapper, ImageGridItem, CategoryPopout, CategoryPopoutModel, SectionHeaderContent } from '@web/product-navigation-feature'
import { useDebounce } from '@web/shared-util-hooks'
import '@web/styles/SideNavigationSection.less'
import { useUserContext } from '@web/shared-data-access-context'

interface SideNavigationSectionProps {
  section: SideNavigationSectionType
  position: number
  underCategorySectionContentId?: number | null
  isSmallAndDownViewport: boolean
}

function SideNavigationSection ({
  section,
  position,
  underCategorySectionContentId = null,
  isSmallAndDownViewport
}: SideNavigationSectionProps): ReactElement | null {
  const { context } = useUserContext()
  const collapsed = useMemo(() => section.NavigationItems?.filter(x => x.IsSelected).length === 0 && position >= 5, [section, position])

  const [isCollapseToggled, setCollapseToggle] = useState(collapsed && section.NavigationItems.find(item => item.IsSelected) == null)
  const sectionHeader = section.Title ?? section.TopLabel

  const hasNoOption = useMemo(() =>
    section.NavigationItems.some(n => n.LinkText.toLowerCase() === 'no')
  , [section.NavigationItems])

  const sectionClass: string = useMemo(() => {
    return clsx(
      'menuSection',
      section.CssClass
    )
  }, [section.CssClass])

  const sectionListClass = useMemo(() => {
    const isScrollable = section.Title.toLowerCase() !== 'category' && section.NavigationItems.length > 8
    return clsx(
      'sectionLinks',
      isScrollable && 'isScrollable'
    )
  }, [section.NavigationItems])

  const navigationItemsSorted: NavigationItem[] = useMemo(() => {
    const unsortedItems = [...section.NavigationItems]
    if (section.Title.toLowerCase() !== 'category') {
      return unsortedItems.sort((a, b) => (a.IsSelected) ? -1 : (b.IsSelected) ? 1 : 0)
    } else {
      return unsortedItems
    }
  }, [section.NavigationItems])

  const showItem = useCallback((item: NavigationItem): boolean => {
    let hideForNoImage = false
    switch (section.Layout) {
      case 'text_and_image_sm':
      case 'text_and_image_md':
      case 'text_and_image_lg':
      case 'image_grid':
        hideForNoImage = !(item.ImageAsset?.source !== '' && item.ImageAsset?.source != null)
        break
      default:
        hideForNoImage = false
    }
    return ((section.Title.toLowerCase() === 'category' ||
      section.Title.toLowerCase() === 'shop by' ||
      section.IsMultiSelect ||
      (section.IsSingleSelect && item.LinkText.toLowerCase() !== 'no')) && !hideForNoImage)
  }, [section])

  const navigationItemsFiltered: NavigationItem[] = useMemo(() =>
    navigationItemsSorted.filter(item => showItem(item))
  , [navigationItemsSorted])

  const showSection = useMemo((): boolean => {
    return ((!section.LoggedInOnly || (context?.LoginId != null && context?.LoginId !== '' && context?.IsInShowcase)) &&
    !(section.IsSingleSelect && hasNoOption && section.NavigationItems.length === 1) &&
    !(section.HideForSterling && context?.IsSterling != null && context?.IsSterling))
  }, [section, context, hasNoOption])

  const showDealsCMS = useMemo((): boolean => {
    return context?.ControllerName === 'deals' &&
      !context?.IsInShowcase &&
      context?.ActionName === 'index' &&
      sectionHeader.toLowerCase() === 'category'
  }, [context, sectionHeader])

  const showNoDealsCMS = useMemo((): boolean => {
    return underCategorySectionContentId != null &&
    sectionHeader.toLowerCase() === 'category' &&
    context?.IsInShowcase != null &&
    context?.IsInShowcase &&
    context?.ControllerName != null &&
    context.ControllerName?.toLowerCase() !== 'deals'
  }, [underCategorySectionContentId, context, sectionHeader])

  const [catPopoutProps, setCatPopoutProps] = useState(null as CategoryPopoutModel | null)
  const [activeNavItemTrackingName, setActiveNavItemTrackingName] = useState(null as string | null)

  const debounceCatPopupProps = useDebounce<CategoryPopoutModel | null>(catPopoutProps, 300)

  const showCatPopout = useMemo(() => {
    return context?.IsSterling === false &&
      debounceCatPopupProps?.childrenCats != null &&
      debounceCatPopupProps?.childrenCats.length > 0 &&
      debounceCatPopupProps?.grandchildCatMax != null &&
      debounceCatPopupProps?.navigationItem != null &&
      debounceCatPopupProps?.section != null
  }, [debounceCatPopupProps, context?.IsSterling])

  const toggleCatPopoutProps = useCallback(async (value: CategoryPopoutModel | null) => {
    setCatPopoutProps(value)
    if (value == null) {
      setHoveredRowRef(null)
    }
  }, [])

  useEffect(() => {
    setActiveNavItemTrackingName(debounceCatPopupProps?.navigationItem.TrackingName ?? null)
  }, [debounceCatPopupProps])

  const sectionRef = useRef<HTMLDivElement>(null)
  const catPopoutRef = useRef<HTMLDivElement>(null)
  const [hoveredRowRef, setHoveredRowRef] = useState(null as RefObject<HTMLLIElement> | null)
  const [catPopoutTopPosition, setCatPopoutTopPosition] = useState(0)

  const catPopoutTopPositionCallback = useCallback((): void => {
    if (sectionRef == null || hoveredRowRef == null) {
      setCatPopoutTopPosition(0)
    }
    let value = 0

    // pixel difference from top of window to top of nav section
    const sectionTop = sectionRef?.current?.getBoundingClientRect().top ?? 0
    // pixel difference from top of window to top of hovered row
    const hoveredRowTop = hoveredRowRef?.current?.getBoundingClientRect().top ?? 0
    // height of row
    const hoveredRowHeight = hoveredRowRef?.current?.offsetHeight ?? 0
    // pixel difference from top of window to bottom of hovered row
    const hoveredRowBottom = hoveredRowTop + hoveredRowHeight
    // pixel difference from top of section to bottom of hovered row (only true value when section is below the window)
    const sectionRowDifference = hoveredRowBottom - sectionTop
    // height of the cat popout
    const popoutHeight = catPopoutRef?.current?.offsetHeight ?? 0

    // if top of section is above the window, push down popout same amount
    value = sectionTop < 0 ? Math.abs(sectionTop) : 0

    // if top of section is below the window and the popout height dow not reach the bottom of the hovered row
    // else if top of section is above the window and the popout height does not reach the bottom of the hovered row
    if (value === 0 && sectionRowDifference - popoutHeight > 0) {
      value = sectionRowDifference - popoutHeight
    } else if (value > 0 && hoveredRowBottom > popoutHeight) {
      value = value + hoveredRowBottom - popoutHeight
    }

    setCatPopoutTopPosition(value)
  }, [sectionRef, hoveredRowRef, catPopoutRef, debounceCatPopupProps])

  useEffect(() => {
    if (isSmallAndDownViewport) {
      setCollapseToggle(true)
    } else {
      setCollapseToggle(collapsed && section.NavigationItems.find(item => item.IsSelected) == null)
    }
  }, [isSmallAndDownViewport])

  if (context == null || !showSection) {
    return null
  }

  return (
    <>
      {showCatPopout &&
      debounceCatPopupProps != null &&
        <CategoryPopout props={debounceCatPopupProps} toggleCatPopoutProps={toggleCatPopoutProps} popoutRef={catPopoutRef} topPosition={catPopoutTopPosition} onChange={catPopoutTopPositionCallback} />}
      <div className={sectionClass} onMouseLeave={async () => await toggleCatPopoutProps(null)} ref={sectionRef}>
        <SectionHeaderContent sectionTitle={sectionHeader} sectionTitleCount={navigationItemsFiltered.length} collapsed={isCollapseToggled} onCollapseToggle={setCollapseToggle} />
        <Collapse isCollapsed={isCollapseToggled}>
          <ul className={sectionListClass} data-test={section.CssClass}>
            {section.Layout === 'image_grid'
              ? (
                <li className='item-grid u-flex-grid-row m-0'>
                  {section.NavigationItems.map((navigationItem, index) => (
                    <ImageGridItem
                      key={index} section={section} navigationItem={navigationItem} layout={section.Layout}
                    />
                  ))}
                </li>
                )
              : (
                  navigationItemsFiltered.map((navigationItem, index) => (
                    <NavigationItemRowWrapper
                      key={index}
                      section={section}
                      navigationItem={navigationItem}
                      toggleCatPopoutProps={toggleCatPopoutProps}
                      setRef={setHoveredRowRef}
                      activeItemRowTrackingName={activeNavItemTrackingName}
                      hasCategoryPopout={!context.IsSterling}
                    />
                  ))
                )}
          </ul>
        </Collapse>
      </div>
      {showDealsCMS &&
        <CmsContent contentContainerId={53481} />}
      {showNoDealsCMS &&
        underCategorySectionContentId != null &&
          <CmsContent contentContainerId={underCategorySectionContentId} />}
    </>
  )
}

export { SideNavigationSection }
