import { ReactElement } from 'react'
import { Button, Input } from '@web/shared-ui-components'
import { GetSearchNavigationQuery } from '@web/shared-data-access-queries'

interface CategoryContentBlockCreationProps {
  newContentBlockName: GetSearchNavigationQuery['getSearchNavigation']['NewContentBlockName']
  categoryId: GetSearchNavigationQuery['getSearchNavigation']['CategoryId']
  cmsContentType: GetSearchNavigationQuery['getSearchNavigation']['CmsContentType']
  contentBlockLocation: number
}

function CategoryContentBlockCreation ({ newContentBlockName, categoryId, cmsContentType, contentBlockLocation }: CategoryContentBlockCreationProps): ReactElement | null {
  return (
    <form action='/categories/createnewcontentblock' method='post'>
      <div className='addCMSBlock'>
        <Input type='hidden' name='blockName' value={newContentBlockName} />
        <Input type='hidden' name='categoryId' value={categoryId} />
        <Input type='hidden' name='contentType' value={cmsContentType} />
        <Input type='hidden' name='contentBlockLocation' value={contentBlockLocation} />
        <Button type='submit' className='greenText'>
          Create a content rotator here
        </Button>
      </div>
    </form>
  )
}

export { CategoryContentBlockCreation }
