import {
  ReactElement,
  ReactNode
} from 'react'
import clsx from 'clsx'

interface ContentCardProps {
  /**
     * Color type
     * Default: 'secondary'
     */
  type?: 'primary' | 'secondary' | 'tertiary'
  paddingSize?: 15 | 20
  showBorder?: boolean
  /**
     * Class overrides
     */
  className?: string

  children?: ReactNode
}

/*
   * ContentCard component
   */
function ContentCard ({
  type = 'secondary',
  paddingSize = 20,
  showBorder = false,
  className,
  children
}: ContentCardProps): ReactElement {
  const classes = clsx(
    'u-border-radius-medium',
    type === 'secondary' && 'c-bg-gray-lt-3',
    type === 'primary' && 'c-bg-primary-lt-2',
    type === 'tertiary' && 'c-bg-white u-border-all text-center w-50 mx-auto mt-5 u-padding-all-30',
    showBorder && 'u-border-all',
    className
  )

  const styles = { padding: `${paddingSize}px` }

  return (
    <div
      className={classes}
      style={styles}
    >
      {children}
    </div>
  )
}

export { ContentCard }
