import { faPlusCircle, faMinusCircle } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { CSSProperties, ReactElement, useMemo } from 'react'

interface ExpandCollapseIconProps {
  isCollapsed: boolean
  style?: CSSProperties
  /*
   * All other attributes
   */
  [key: string]: any
}

function ExpandCollapseIcon ({ isCollapsed, style, ...otherAttributes }: ExpandCollapseIconProps): ReactElement | null {
  const icon = useMemo(() => isCollapsed ? faPlusCircle : faMinusCircle, [isCollapsed])

  return (
    <FontAwesomeIcon className='c-primary u-cursor-pointer' icon={icon} style={{ fontSize: '22px', ...style }} fixedWidth {...otherAttributes} />
  )
}

export { ExpandCollapseIcon }
