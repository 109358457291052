import {
  ReactElement,
  useCallback,
  ChangeEvent,
  useMemo
} from 'react'
import { Input } from '@web/shared-ui-components'
import InputMask from 'react-input-mask'
import { CreditCardType } from 'credit-card-type/dist/types'

interface CvvInputProps{
  /**
   * Input name field
   */
  name: string
  /**
   * Input value field
   */
  value?: string | null
  /**
   * Input required field
   */
  required: boolean
  /**
   * Card type from parent
   */
  cardType: CreditCardType | null
  /**
   * Input onChange event
   */
  onChange: (cvv: string) => void
  /**
   * Invalid styling
   */
  invalid?: boolean | string
}

function CvvInput ({ name, value, required, cardType, onChange, invalid }: CvvInputProps): ReactElement {
  const cvvMaxLength = useMemo(() => cardType?.code?.size ?? 3, [cardType])
  const cvvMask = useMemo(() => {
    const mask: RegExp[] = []
    for (let i = 0; i < cvvMaxLength; i++) {
      mask.push(/[0-9|*]/)
    }
    return mask
  }, [cvvMaxLength])
  const cvvPlaceholder = useMemo(() => {
    let value = ''
    for (let i = 0; i < cvvMaxLength; i++) {
      value += '0'
    }
    return value
  }, [cvvMaxLength])

  const handleCvvChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    const cvv = event.target.value

    onChange(cvv)
  }, [onChange])

  return (
    <InputMask
      mask={cvvMask}
      maskPlaceholder={null}
      onChange={handleCvvChange}
      value={value}
    >
      <Input
        name={name}
        placeholder={cvvPlaceholder}
        type='tel'
        maxLength={cvvMaxLength}
        required={required}
        autoComplete='cc-csc'
        autoCorrect='off'
        spellCheck='false'
        className='c-gray-dk-3'
        invalid={invalid}
        data-test='cvv'
      />
    </InputMask>
  )
}

export { CvvInput }
