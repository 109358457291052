import { ReactElement, useEffect, useMemo } from 'react'
import { Button } from '@web/shared-ui-components'
import { NavigationSectionDropdown } from '@web/product-navigation-feature'
import { Link } from 'react-router-dom'
import { NavigationSection } from '@web/shared-data-access-queries'
import clsx from 'clsx'
import { useSvg } from '@web/shared-util-hooks'
import '@web/styles/TopNav.less'

export interface TopNavigationProps{
  topNavigationSections: NavigationSection[]
  clearAllUrl: string
}

function TopNav ({ topNavigationSections, clearAllUrl }: TopNavigationProps): ReactElement | null {
  const [addSvg] = useSvg()

  if (topNavigationSections == null) {
    return null
  }

  const sectionClasses = useMemo(() =>
    clsx(
      'my-4 top-facet-section col-12',
      topNavigationSections.length > 1 ? 'u-flex-grid-col-md-6 u-flex-grid-col-xl-3' : 'u-flex-grid-col-md-12'
    )
  , [topNavigationSections])

  useEffect(() => {
    const svgMap = new Map()
    for (const section of topNavigationSections) {
      for (const item of section.NavigationItems) {
        const imageAsset = item.ImageAsset
        if (imageAsset != null && imageAsset.type === 'Embedded SVG') {
          if (!svgMap.has(imageAsset.source)) {
            svgMap.set(imageAsset.source, true)
            addSvg(imageAsset.source)
          }
        }
      }
    }
  }, [topNavigationSections])

  return (
    <>
      <div className='position-relative px-4'>
        <div className='clear top-facet-container c-bg-gray-lt-3 row'>
          <div className='u-flex-grid-row mx-0'>
            {topNavigationSections.map((section) => (
              <div key={section.Title} className={sectionClasses}>
                <h3 className='t-h3'>{section.Title}</h3>
                <NavigationSectionDropdown section={section} />
              </div>
            ))}
          </div>
          <div className='px-2'>
            <div className='text-right'>
              <Link to={clearAllUrl} className='text-decoration-none'>
                <Button tag='button' color='secondary' size='micro'>Reset Selections</Button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export { TopNav }
