import {
  ReactElement,
  useCallback,
  useMemo
} from 'react'
import clsx from 'clsx'
import Select, { Props, GroupBase, StylesConfig, components, OptionProps } from 'react-select'
import '@web/styles/Select.less'
import { FormError } from '@web/shared-ui-components'
import { faCircleXmark } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

interface FancySelectOption<TValue = string> {
  /*
   * Value of option
   */
  value: TValue
  /*
   * Element to render
   */
  label: ReactElement | string
  /*
   * Is disabled
   */
  isDisabled?: boolean
  /*
  * Child options
  */
  options?: Array<FancySelectOption<TValue>>
}

interface FancySelectProps extends Props<any, boolean, GroupBase<any>> {

  color?: 'primary' | 'secondary'

  /*
   * Wehether or not there is a border bottom between options
   */
  optionBorders?: boolean

  /*
   * Function to call on select change
   */
  onChange?: any

  /*
   * Indicates the select is invalid to show fail style
   */
  invalid?: boolean | string

  /*
   * Indicates the select is disabled
   */
  isDisabled?: boolean

  /**
   * Allows users to type text in the field
   */
  isSearchable?: boolean

  /*
   * Message to be shown if there is an error
   */
  errors?: string
  /*
   * Styles to append to default style config
   */
  styles?: StylesConfig<any, boolean, GroupBase<any>>
}

/*
 * Fancy Select (react-select plugin) input component
 */
function FancySelect ({
  color = 'primary',
  optionBorders = false,
  onChange,
  invalid,
  isDisabled = false,
  isSearchable = true,
  errors,
  className,
  styles,
  ...otherAttributes
}: FancySelectProps): ReactElement {
  const classNames = clsx(
    invalid != null && invalid !== false && invalid !== '' && 'react-fancy-select-validation-input-error',
    className,
    'react-fancy-select',
    optionBorders && 'react-fancy-select-option-borders'
  )

  const classNamePrefix = useMemo(() => clsx(
    color === 'primary' && 'react-fancy-select-primary',
    color === 'secondary' && 'react-fancy-select-secondary'
  ), [color])

  const formatOptionLabel = useCallback(({ label, isDisabled }: FancySelectOption) => {
    if (isDisabled == null || !isDisabled) {
      return label
    }

    return (
      <div className='disabled-option'>
        <div className='disabled-option-label-container'>
          {label}
        </div>
      </div>
    )
  }, [])

  const OptionWithDataTest = function ({ innerProps, ...restOfProps }: OptionProps<FancySelectOption>): ReactElement {
    const newInnerProps = { 'data-test': restOfProps.data.value, ...innerProps }
    return (
      <components.Option {...restOfProps} innerProps={newInnerProps} />
    )
  }

  const ClearIndicatorOverride = function (props: any): ReactElement {
    return (
      <components.ClearIndicator {...props}>
        <FontAwesomeIcon
          icon={faCircleXmark}
          className='mr-1'
        />
      </components.ClearIndicator>
    )
  }

  return (
    <>
      <Select
        menuPortalTarget={document.body}
        className={classNames}
        formatOptionLabel={formatOptionLabel}
        classNamePrefix={classNamePrefix}
        onChange={onChange}
        isDisabled={isDisabled}
        isSearchable={isSearchable}
        styles={styles}
        components={{
          ClearIndicator: ClearIndicatorOverride,
          Option: OptionWithDataTest
        }}
        {...otherAttributes}
      />
      <FormError message={errors} />
    </>
  )
}

export {
  FancySelect,
  FancySelectOption,
  FancySelectProps
}
