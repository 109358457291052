import { ReactElement } from 'react'

interface AddingAHeadHeaderProps {
  productImage: string
  productDescription: string
  productSku: string
}

function AddAHeadHeader ({ productImage, productDescription, productSku }: AddingAHeadHeaderProps): ReactElement | null {
  return (
    <div className='d-flex'>
      {/* Only show product image if viewport is Small & Up */}
      <img src={productImage} alt={productDescription} className='mr-5 d-none d-sm-block' style={{ height: '100px', width: '100px' }} />
      <div className='d-block'>
        {/* Show "Adding a head to" if viewport is Small & Up */}
        <div className='t-h1 pb-0 d-none d-sm-block'>Adding a head to</div>
        {/* Show "Add a head" if viewport is Extra Small */}
        <div className='t-h1 pb-0 d-sm-none'>Add a head</div>
        {/* Only show product description and product sku if viewport is Small & Up */}
        <div className='t-h5 pb-0 d-none d-sm-block'>{productDescription}</div>
        <div className='t-ui-label pb-0 d-none d-sm-block'>Item #{productSku}</div>
      </div>
    </div>
  )
}

export { AddAHeadHeader }
