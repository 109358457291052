import { ReactElement, useMemo } from 'react'
import { SearchResult, CategoryOrSearchViewModel } from '@web/shared-data-access-queries'
import '@web/styles/ProductResult.less'
import { ProductResultImage, BottomBadges } from '@web/product-navigation-feature'
import { SearchResultSelectedEventHandler } from './types'
import clsx from 'clsx'
import { useUserContext } from '@web/shared-data-access-context'

interface ProductResultProps{
  viewModel: CategoryOrSearchViewModel
  productResult: SearchResult
  onProductSelected: SearchResultSelectedEventHandler
}

/*
  Returns string for the discount text of the product result
*/
function getDiscountText (product: SearchResult): string {
  const prefixText = product.MinDiscount !== null && product.MinDiscount === product.MaxDiscount ? 'Save' : 'Save up to'
  const discountText = product.MaxDiscount != null ? Math.round(product.MaxDiscount) : ''
  if (product.MaxDiscount !== null) {
    return `${prefixText} ${discountText}%!`
  } else {
    return ''
  }
}

/*
  Returns the ProductResult component.
  This is a product "card" that contains the product image,
  info about the product, and associated badges
*/
function ProductResult ({ viewModel, productResult, onProductSelected }: ProductResultProps): ReactElement | null {
  const { context } = useUserContext()
  const showDiscountText = useMemo(() => {
    return viewModel.ShowDiscounts &&
    productResult.IsDiscounted &&
    context?.LoginId != null &&
    productResult.MaxDiscount != null &&
    productResult.MaxDiscount > 0
  }, [viewModel.ShowDiscounts, productResult.IsDiscounted, context?.LoginId, productResult.MaxDiscount != null])

  const searchSource = useMemo(() => viewModel.SearchSource ?? '', [viewModel.SearchSource])

  return (
    <>
      <div className='productResult searchColumn text-center h-100 u-flex-grid-col-lg-4 u-flex-grid-col-xl-3 col-6'>
        <div className='groupCode'>
          <div className='italic'>{productResult.Code}</div>
        </div>
        <ProductResultImage
          key={productResult.ImageUrls?.FullSizeUrl}
          productResult={productResult}
          searchSource={searchSource}
          showDiscounts={viewModel.ShowDiscounts}
          onClick={() => onProductSelected(productResult)}
        />
        <div className='caption'>
          <div>
            <a href={productResult.GroupDetailsUrl ?? ''}>
              <span id={`groupTextFor_${productResult.Id}`} className='bold'>{productResult.Description}</span>
            </a>
          </div>
          {((context?.HidePrices) === false) &&
            <div className='groupPrice priceDisplay c-gray-dk-3'>
              {showDiscountText &&
                <div className={clsx(productResult.DiscountIsFlashSaleAndStullerFirst ? 'stullerFirstDiscountText' : 'discountText', 'discountDisplay')}>
                  {getDiscountText(productResult)}
                </div>}
              <div dangerouslySetInnerHTML={{ __html: productResult.DisplayPrice ?? '' }} />
            </div>}
        </div>
        <BottomBadges productResult={productResult} />
      </div>
    </>
  )
}

export { ProductResult }
