import {
  ReactElement,
  useMemo,
  useCallback,
  useContext
} from 'react'
import { PaymentMethod } from '@web/shared-data-access-queries'
import { useLock } from '@web/shared-util-hooks'
import { AddBankAccount, PaymentMethodSelectorContext } from '@web/payment-methods'
import { PaymentMethodSelectionBoxOption } from './types'
import { newBankAccountAllowedMethods } from './helpers'
import { PaymentMethodSelectionBoxesGroup } from './PaymentMethodSelectionBoxesGroup'

/*
 * PaymentMethodSelectionBoxes Props
 */
interface BankSelectionBoxesProps {
  bankPaymentMethods: PaymentMethod[] | null | undefined
  /*
  * Currently selected value
  */
  selectedValue?: PaymentMethod | null
  mapOptions: (paymentMethods: PaymentMethod[] | null | undefined, selectedValue?: PaymentMethod | null) => PaymentMethodSelectionBoxOption[] | undefined
  deleteBank: any
}

/*
 * Payment Method Selector Selection Boxes component
 */
function BankSelectionBoxes ({ bankPaymentMethods, selectedValue, mapOptions, deleteBank }: BankSelectionBoxesProps): ReactElement {
  const {
    userCanUseACH,
    allowAdding,
    refetchPaymentMethods
  } = useContext(PaymentMethodSelectorContext)

  const paymentMethodSelectionBoxOptions = mapOptions(bankPaymentMethods, selectedValue)
  const selectedPaymentMethodSelectionBoxOption = useMemo(() => paymentMethodSelectionBoxOptions?.filter(x => x.paymentMethod.SelectableValue === selectedValue?.SelectableValue)[0], [paymentMethodSelectionBoxOptions])

  const [handleRemoveBank] = useLock(useCallback(async (bankAccountId: string) => {
    $(`#delete-payment-${bankAccountId}`).modal('toggle')
    if (bankAccountId != null) {
      await deleteBank({
        variables: {
          input: {
            bankAccountId
          }
        }
      }).then(refetchPaymentMethods)
    }
  }, [paymentMethodSelectionBoxOptions]))

  return (
    <>
      {userCanUseACH &&
        <>
          <PaymentMethodSelectionBoxesGroup
            paymentMethods={paymentMethodSelectionBoxOptions}
            handleRemoveBank={handleRemoveBank}
            methodLabel='Bank Account'
            selectedValue={selectedPaymentMethodSelectionBoxOption}
            allowAdding={allowAdding}
          />
          {allowAdding &&
            <AddBankAccount newBankAccountAllowedMethods={newBankAccountAllowedMethods} />}
        </>}
    </>
  )
}

export {
  BankSelectionBoxes
}
