import { ReactElement, ReactNode } from 'react'
import { UserContext } from '@web/shared-data-access-context'
import { useGetContextMinimalQuery } from '@web/shared-data-access-queries'

interface QueryContextProviderProps {
  children?: ReactNode
}

function UserContextProvider ({ children }: QueryContextProviderProps): ReactElement {
  const { loading, data } = useGetContextMinimalQuery()

  return (
    <UserContext.Provider value={{ loading, context: data?.context }}>
      {children}
    </UserContext.Provider>
  )
}

export { UserContextProvider }
