import { SearchResult } from '@web/shared-data-access-queries'
import { ReactElement, useMemo, useState } from 'react'
import { LoadingIndicator } from '@web/shared-ui-components'
import { useImagePreloader } from '@web/shared-util-hooks'
import { TopBadge } from '@web/product-navigation-feature'
import { CSSTransition } from 'react-transition-group'
import '@web/styles/ProductResultImage.less'
import { useUserContext } from '@web/shared-data-access-context'

interface ProductResultImageProps{
  productResult: SearchResult
  searchSource: string
  showDiscounts: boolean
  imageOverride?: string
  onClick: () => void
}

/*
  Component that houses the image and associated badges (excluding bottom badges) for the product search result "cards"
*/
function ProductResultImage ({ productResult, searchSource, showDiscounts, imageOverride, onClick }: ProductResultImageProps): ReactElement | null {
  const { context } = useUserContext()
  const userIsInRetailMode = useMemo(() => context?.IsInShowcase, [context])

  const image = useMemo(() => imageOverride ?? productResult.ImageUrls?.Thumb165Url, [productResult.ImageUrls, imageOverride])

  const sideImage = useMemo(() => productResult.ImageUrlsSide?.Thumb165Url, [productResult.ImageUrlsSide])

  const { imagesPreloaded } = useImagePreloader([image ?? ''])

  const hasSide: boolean = useMemo(() => sideImage != null, [sideImage])

  const dealsArray = ['Deals', 'FlashSale', 'StullerFirstSale']
  const searchSourceIsDeals: boolean = useMemo(() => searchSource != null && dealsArray.includes(searchSource), [searchSource])

  const link = useMemo(() => {
    let imgLink = productResult.GroupDetailsUrl
    if (showDiscounts && context?.HidePrices === false && searchSourceIsDeals) {
      imgLink = `/deals/discountedproductsingroup/?groupid=${productResult.Id}&searchsource=${searchSource ?? ''}`
    }
    return imgLink ?? ''
  }, [productResult.GroupDetailsUrl, showDiscounts, searchSource, context?.HidePrices, searchSourceIsDeals])

  const [showSideImage, changeShowSideImage] = useState(false)

  return (
    <LoadingIndicator loading={!imagesPreloaded} position='center'>
      {!imagesPreloaded &&
        <img className='image-responsive' src='https://meteor.stullercloud.com/?$list$' />}
      {imagesPreloaded &&
        <a
          className='productResultImage position-relative logClick containerFix h-100'
          href={link} onClick={() => onClick()}
        >
          {(userIsInRetailMode === false) &&
            <TopBadge productResult={productResult} searchSourceIsDeals={searchSourceIsDeals} />}
          {!hasSide &&
            <img
              className='image-responsive'
              src={image}
              alt={productResult.Description ?? ''}
            />}
          {hasSide &&
            <div
              className='h-100'
              onMouseEnter={() => {
                changeShowSideImage(true)
              }}
              onMouseLeave={() => {
                changeShowSideImage(false)
              }}
            >
              <CSSTransition in={!showSideImage} timeout={300} classNames='result-image-hover'>
                <img
                  className='image-responsive'
                  src={image}
                  alt={productResult.Description ?? ''}
                />
              </CSSTransition>
              <CSSTransition in={showSideImage} timeout={300} classNames='result-image-hover'>
                <img
                  className='image-responsive result-side-image'
                  src={sideImage}
                  alt={productResult.Description ?? ''}
                />
              </CSSTransition>
            </div>}
        </a>}
    </LoadingIndicator>
  )
}

export { ProductResultImage }
