import { useEffect, useState } from 'react'

/**
 * function actually doing the preloading of the image by setting the URL to the source of an image object
 */
async function preloadImage (src: string): Promise<unknown> {
  return await new Promise((resolve, reject) => {
    const img = new Image()
    img.onload = function () {
      resolve(src)
    }
    img.onerror = img.onabort = function () {
      reject(src)
    }
    img.src = src
  })
}

/**
 * Hook for passing in an array of image URLs to be preloaded
 */
function useImagePreloader (imageList: string[]): {imagesPreloaded: boolean} {
  const [imagesPreloaded, setImagesPreloaded] = useState<boolean>(false)

  useEffect(() => {
    let isCancelled = false

    async function effect (): Promise<void> {
      if (isCancelled) {
        return
      }

      const imagesPromiseList: Array<Promise<any>> = []
      for (const i of imageList) {
        imagesPromiseList.push(preloadImage(i))
      }

      await Promise.all(imagesPromiseList)

      if (isCancelled) {
        return
      }

      setImagesPreloaded(true)
    }

    void effect()

    return () => {
      isCancelled = true
    }
  }, [imageList])

  return { imagesPreloaded }
}

export { useImagePreloader }
