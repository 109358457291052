import { ReactElement, useCallback, useState, ChangeEvent } from 'react'
import { Button, Input } from '@web/shared-ui-components'

function SearchBySerialNumber (): ReactElement | null {
  const [searchBySerialInput, setSearchBySerialInput] = useState<string | number>('')

  const searchSN = useCallback((e) => {
    e.preventDefault()
    window.open(`/serializedproduct/${searchBySerialInput}?recommendationSource=SearchBySerialNumber`)
  }, [searchBySerialInput])

  const handleInputChange = useCallback((event: ChangeEvent<HTMLInputElement>) => setSearchBySerialInput(event.target.value), [])

  return (
    <div className='form-horizontal'>
      <div className='bgColor paddingLarge d-flex align-items-center'>
        <h2 className='mb-0 pb-0'>Search by Serial Number</h2>
        <div className='u-flex-grid-col-sm-7 u-flex-grid-col-lg-4 search-by-serial-number-container'>
          <form
            className='d-flex'
            onSubmit={searchSN}
          >
            <Input
              value={searchBySerialInput}
              onChange={handleInputChange}
              id='serialnumber'
              className='form-control'
              placeholder='Serial #'
              style={{ minWidth: '115px' }}
            />
            <Button
              color='primary'
              size='micro'
              onClick={searchSN}
            >
              Search
            </Button>
          </form>
        </div>
      </div>
    </div>
  )
}

export { SearchBySerialNumber }
