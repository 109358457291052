/*
 * Delays with a promise via setTimeout
 */
async function delay (ms: number): Promise<void> {
  return await new Promise(resolve => setTimeout(resolve, ms))
}

export {
  delay
}
