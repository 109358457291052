import {
  ReactElement
} from 'react'
import { SaveCardResponse } from '@web/shared-data-access-queries'
import { Alert } from '@web/shared-ui-components'
import { faCheckCircle } from '@fortawesome/pro-solid-svg-icons'
import { useToggle } from '@web/shared-util-hooks'

interface AddCardSuccessAlertProps {
  /**
   * Is a new card being saved
   */
  saving: boolean
  /**
   * Response from adding a new card
   */
  saveCardResponse?: SaveCardResponse | null
}

function AddCardSuccessAlert ({ saving, saveCardResponse }: AddCardSuccessAlertProps): ReactElement | null {
  const [show, toggleShow] = useToggle(true)

  if (saving || saveCardResponse == null || !saveCardResponse.success || saveCardResponse.errors != null) {
    return null
  }

  return (
    <Alert show={show} onToggle={toggleShow} alertType='success' icon={faCheckCircle} className='my-4' data-test='card-save-successful-alert'>Your card has been saved!</Alert>
  )
}

export {
  AddCardSuccessAlert
}
