import { ReactElement, useCallback, useState, ChangeEvent } from 'react'
import { Button, Modal, ModalBody, ModalHeader, ModalFooter, Table, Input } from '@web/shared-ui-components'
import { useToast } from '@web/shared-util-hooks'
import { dispatchStullerEvent } from '@web/shared-util-stuller-events'

interface AddCategoryToProductFeedModalProps {
  /**
   * The id of the current category for the page
   */
  categoryId: number
}

interface ProductDataFeed {
  EcommerceFeedId?: number
  Name: string
  Description: string
  Date: string
  ProcessFeedUrl: string
  Process: boolean
  ProcessComplete?: boolean
  Url: string
  ProductCount: number
  ProductCountFinished: number
}

function AddCategoryToProductFeedModal ({ categoryId }: AddCategoryToProductFeedModalProps): ReactElement {
  const [productFeeds, setProductFeed] = useState<ProductDataFeed[]>([])
  const [loading, setIsLoading] = useState(false)
  const [exceededExportCount, setExceededExportCount] = useState(false)
  const [canDisplayFeedModal, setCanDisplayFeedModal] = useState(false)
  const [newProductFeedName, setNewProductFeedName] = useState('')

  const handleAddProductFeed = useCallback((): void => {
    getCategories()
    dispatchStullerEvent('track-universal-event', {
      category: 'CTA',
      action: 'AddCategoryToProductFeed',
      label: `${categoryId}`
    })
  }
  , [])

  const handleToggle = useCallback(() => {
    $('#add-category-to-product-modal').modal('toggle')
  }, [])
  const handleSetNewProductName = useCallback((e: ChangeEvent<HTMLInputElement>): void => setNewProductFeedName(e.target.value), [])
  const baseUrl = '/myaccount/ecommerceselfservice'

  const createToast = useToast({ status: 'error' })
  const getCategories = useCallback(() => {
    const getCategoryUrl = `${baseUrl}/getcategories`
    setIsLoading(true)

    fetch(`${getCategoryUrl}?catId=${categoryId}`)
      .then(async resp => {
        if (!resp.ok) {
          setIsLoading(false)
          throw new Error(resp.statusText)
        }
        return await resp.json()
      })
      .then(resp => {
        if (resp.exceededExportCount === true) {
          setExceededExportCount(true)
          setIsLoading(false)
          createToast({ message: 'This category has too many products in it. Please narrow down your selection by choosing from the subcategories.' })
          return
        }
        setCanDisplayFeedModal(true)
        handleToggle()
        setProductFeed(resp.savedExports)
        setIsLoading(false)
      })
      .catch(err => {
        throw new Error(err)
      })
  }, [categoryId])

  const createCategory = useCallback((e): void => {
    e.preventDefault()
    setIsLoading(true)

    if (newProductFeedName.length === 0) {
      createToast({ message: 'The name of the product feed cannot be empty. Product feed was not created.' })
      setIsLoading(false)
      return
    }

    if (productFeeds.some(x => x.Name === newProductFeedName.trim())) {
      createToast({ message: 'The name of the product feed already exists. Product feed was not created.' })
      setIsLoading(false)
      return
    }

    const createCategoryUrl = `${baseUrl}/createfeed`
    const options = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        catId: categoryId,
        name: newProductFeedName.trim()
      })
    }

    fetch(createCategoryUrl, options)
      .then(async resp => {
        if (!resp.ok) {
          throw new Error(resp.statusText)
        }
        return await resp.json()
      })
      .then(resp => {
        setProductFeed([...productFeeds, resp.newExport])
        setNewProductFeedName('')
        handleSuccessOrErrorMessages(resp)
      })
      .catch(err => {
        setIsLoading(false)
        throw new Error(err)
      })
  }, [newProductFeedName])

  const saveToProductFeed = useCallback((e, id) => {
    setIsLoading(true)
    e.preventDefault()
    const saveToProductFeedUrl = `${baseUrl}/savecategory`
    const options = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        searchId: id,
        catId: categoryId
      })
    }

    fetch(saveToProductFeedUrl, options)
      .then(async resp => {
        if (!resp.ok) {
          throw new Error(resp.statusText)
        }
        return await resp.json()
      })
      .then(resp => {
        handleSuccessOrErrorMessages(resp)
      })
      .catch(err => {
        setIsLoading(false)
        throw new Error(err)
      })
  }, [])

  const handleSuccessOrErrorMessages = useCallback((data) => {
    const toastType = data.success === true ? 'success' : 'error'
    createToast({ status: toastType, message: data.message })
    setIsLoading(false)
  }, [productFeeds])

  return (
    <>
      <div id='catitems' className='ml-0 ml-3 d-none d-md-block'>
        <Button
          size='micro'
          color='secondary-inverse'
          data-test='add-category-to-product-feed-button'
          onClick={handleAddProductFeed}
          disabled={loading}
          rounded

        >
          Add Category to Product Feed
        </Button>
      </div>

      {!exceededExportCount && canDisplayFeedModal &&
        <Modal id='add-category-to-product-modal' size='default'>
          <ModalHeader title='Add Category to Product Feed' onToggle={handleToggle} />
          <ModalBody className='d-flex flex-column'>

            {productFeeds.length > 0 &&
              <div className='u-fancy-scroll' style={{ maxHeight: '300px', overflowY: 'auto' }}>
                <Table tableStyle='white'>
                  <thead>
                    <tr>
                      <th className='font-weight-bold'>Name</th>
                      <th />
                    </tr>
                  </thead>
                  <tbody>
                    {productFeeds.map((x: ProductDataFeed, index: number) => (
                      <tr key={index}>
                        <td><span>{x.Name}</span></td>
                        <td className='text-right'>
                          <Button
                            size='micro'
                            color='secondary-inverse'
                            disabled={loading}
                            onClick={(e) => saveToProductFeed(e, x.EcommerceFeedId)}
                            rounded
                          >
                            Add Category to Product Feed
                          </Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>}
            <div className='mt-5 px-3'>After a category is added, you can process it on the <a href='/myaccount/ecommerceselfservice/productdatafeeds/'>saved product feed page</a>.</div>
            <form onSubmit={createCategory}>
              <div className='mt-3 text-center'>
                <div>Please enter the name of the product feed.</div>
                <div className='d-flex mt-3 align-items-center justify-content-center'>
                  <label className='mr-3 mb-0'>Name: </label>
                  <Input type='text' name='product-feed-name' data-test='product-feed-name' minLength={1} maxLength={50} autoFocus value={newProductFeedName} onChange={handleSetNewProductName} />
                  <Button className='ml-3' data-test='create-button' tag='button' color='primary' size='micro' type='submit' disabled={loading}>Create</Button>
                </div>
              </div>
            </form>
          </ModalBody>
          <ModalFooter>
            <div className='float-right d-flex flex-row align-items-center'>
              <Button color='secondary' onClick={handleToggle}>Close</Button>
            </div>
          </ModalFooter>
        </Modal>}
    </>
  )
}

export { AddCategoryToProductFeedModal }
