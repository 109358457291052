import { RefObject, useEffect, useRef } from 'react'

/**
 * Default `useMutationObserver` options
 */
const defaultOptions = {
  childList: true,
  subtree: true
}

/**
 * Set a mutation observer on the ref given
 */
function useMutationObserver (
  handler: MutationCallback,
  element?: RefObject<Node>,
  options: MutationObserverInit = defaultOptions
): void {
  // Create a ref that stores handler
  const savedHandler = useRef<MutationCallback>()

  // Update savedHandler ref on changes to use latest without needing to pass to `useEffect` deps
  useEffect(() => {
    savedHandler.current = handler
  }, [handler])

  useEffect(() => {
    // Define the listening target
    const targetElement = element?.current != null ? element.current : document.body
    if (targetElement == null) {
      return
    }

    // Create callback that calls handler function stored in ref
    const callback: MutationCallback = (...args) => {
      if (savedHandler?.current != null) {
        savedHandler.current(...args)
      }
    }

    const observer = new MutationObserver(callback)
    observer.observe(targetElement, options)

    // Remove callback on cleanup
    return () => {
      observer.disconnect()
    }
  }, [element, options])
}

export {
  useMutationObserver
}
