import {
  ReactElement,
  ReactNode,
  forwardRef,
  ForwardedRef,
  useState
} from 'react'
import {
  Input,
  InputProps
} from '@web/shared-ui-components'
import clsx from 'clsx'

interface RadioButtonProps extends InputProps {
  /*
   * Options for the select as children
   */
  children?: ReactNode
  /*
   * Class override for container
   */
  containerClassName?: string
}

function RadioButton ({
  id,
  name,
  value,
  checked,
  className,
  containerClassName,
  onChange,
  children,
  onMouseEnter,
  onMouseLeave,
  ...otherAttributes
}: RadioButtonProps, ref: ForwardedRef<HTMLInputElement>): ReactElement {
  const containerClassNames = clsx(containerClassName, 'radio-item')
  const inputClassNames = clsx(className, 'w-100 h-100 u-cursor-pointer')

  const isActive: boolean = checked === true
  const [isHovered, setIsHovered] = useState<boolean>(false)

  const handleMouseEnter = (): void => {
    setIsHovered(true)
    onMouseEnter?.()
  }
  const handleMouseLeave = (): void => {
    setIsHovered(false)
    onMouseLeave?.()
  }

  return (
    <li className={containerClassNames}>
      <Input
        type='radio'
        id={id}
        name={name}
        value={value}
        checked={checked}
        className={inputClassNames}
        onChange={onChange}
        ref={ref}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        {...otherAttributes}
      />
      <div
        className={clsx(
          'd-flex mb-2 py-3 pl-2 u-border-radius-small',
          !isHovered && !isActive && 'c-bg-gray-lt-3',
          isHovered && !isActive && 'c-bg-gray-lt-1 text-white',
          isActive && 'c-bg-primary-dk-1 text-white')}
      >
        {children}
      </div>
    </li>
  )
}

/* RadioButton with forwardRef */
const RadioButtonWrapped = forwardRef(RadioButton)

export { RadioButtonWrapped as RadioButton }
