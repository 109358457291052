import {
  ReactElement, useCallback, useContext, useMemo, useState
} from 'react'
import { PaymentMethodType, CreditCardParams, SaveCardResponse, SaveCardMutation, useGetContextMinimalQuery, PaymentMethod, CreditCard } from '@web/shared-data-access-queries'
import { useToggle } from '@web/shared-util-hooks'
import { AddPaymentMethodWrapper, AddPaymentMethodHeader, CardForm, PaymentMethodSelectorContext, AddCardSuccessAlert, AddCardErrorAlert } from '@web/payment-methods'
import { AddPaymentMethodBody } from '../AddPaymentMethodBody'
import { cardTypesAndIcons } from 'libs/paymentmethods/util/constants'
import { mapPaymentMethod } from '../../payment-method-selector/helpers'

interface AddCardProps {
  /**
   * Allowed payment methods for when adding a new card
   */
  newCardAllowedMethods: PaymentMethodType[]
  label: string
}

const newCreditCardParams: CreditCardParams = {
  CardType: '',
  CardNumber: '',
  CardHolderName: '',
  ExpirationMonth: null,
  ExpirationYear: null,
  Zip: '',
  Cvv: ''
}

/**
 * AddCard component
 */
function AddCard ({ newCardAllowedMethods, label }: AddCardProps): ReactElement | null {
  const {
    allowedCreditCardTypes,
    allowedMethods,
    allowedMethodsForConsultantOnly,
    refetchPaymentMethods,
    collapseAddCard,
    handleSelectedPaymentMethod
  } = useContext(PaymentMethodSelectorContext)
  const { data: userContextData } = useGetContextMinimalQuery()
  const [isCollapsed, onToggleCollapse] = useToggle(collapseAddCard)
  const allowedCardIconsAndTypes = useMemo(() => cardTypesAndIcons.filter(x => allowedCreditCardTypes.map(y => y.Id).includes(x.type)), [allowedCreditCardTypes])
  const actualCardAllowedMethods = useMemo(() => {
    const allAllowedMethods = userContextData?.context.IsInConsultantMode === true ? Array.from(new Set(allowedMethods.concat(allowedMethodsForConsultantOnly))) : allowedMethods
    return newCardAllowedMethods.filter(x => allAllowedMethods.includes(x))
  }, [newCardAllowedMethods, allowedMethods, allowedMethodsForConsultantOnly])
  const canAddNewCard = useMemo(() => actualCardAllowedMethods.length > 0, [actualCardAllowedMethods])
  const [isSaving, setIsSaving] = useState<boolean>(false)
  const [saveCardResponse, setSaveCardResponse] = useState<SaveCardResponse | null | undefined>(null)

  const afterSave = useCallback((saving: boolean, data?: SaveCardMutation | null | undefined) => {
    setIsSaving(saving)
    setSaveCardResponse(data?.saveCard)
    if (data?.saveCard != null && data.saveCard.success) {
      void refetchPaymentMethods().then((paymentMethods: PaymentMethod[]) => {
        const card: CreditCard | undefined | null = data.saveCard?.creditCard

        if (card != null) {
          const paymentMethod: PaymentMethod | undefined = paymentMethods.find(x => x.CreditCard?.Id === card.Id)

          if (paymentMethod != null) {
            handleSelectedPaymentMethod(mapPaymentMethod(paymentMethod))
          }
        }
      })
    } else if (data === undefined) {
      setSaveCardResponse(
        {
          success: false,
          errors: ['An error occurred while saving the card.']
        })
    }
  }, [refetchPaymentMethods])

  if (!canAddNewCard) {
    return null
  }

  return (
    <>
      <AddPaymentMethodWrapper isCollapsed={isCollapsed} onToggleCollapse={onToggleCollapse} allowedMethods={newCardAllowedMethods}>
        <AddPaymentMethodHeader>
          <div className='u-flex-grid-col-12 px-0 u-flex-grid-col-sm-5' data-test='add-a-new-credit-or-debit-card'>
            Add New {label}
          </div>
          <div className='text-sm-right u-flex-grid-col-12 pt-2 pt-sm-0 px-0 u-flex-grid-col-sm-7'>
            {allowedCardIconsAndTypes.map((ct, i) =>
              <img key={i} src={ct.icon} alt={ct.type} className='mr-2' style={{ width: '42px' }} />
            )}
          </div>
        </AddPaymentMethodHeader>

        {!isCollapsed &&
          <AddPaymentMethodBody>
            <div className='u-flex-grid-col-md-8 offset-md-2'>
              <AddCardErrorAlert saving={isSaving} saveCardResponse={saveCardResponse} />
            </div>
            <CardForm
              saveType='new'
              initialCreditCardParams={newCreditCardParams}
              isDefault={false}
              newCardAllowedMethods={actualCardAllowedMethods}
              onToggleCollapse={onToggleCollapse}
              afterSave={afterSave}
            />
          </AddPaymentMethodBody>}
      </AddPaymentMethodWrapper>
      <AddCardSuccessAlert saving={isSaving} saveCardResponse={saveCardResponse} />
    </>
  )
}

export {
  AddCard
}
