import {
  ReactElement,
  ReactNode,
  forwardRef,
  ForwardedRef,
  useMemo
} from 'react'
import {
  Input, InputProps
} from '@web/shared-ui-components'
import clsx from 'clsx'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircle, faCircleDot, faToggleLargeOn } from '@fortawesome/pro-regular-svg-icons'
import { FlipProp } from '@fortawesome/fontawesome-svg-core'
import '@web/styles/Radio.less'

interface RadioProps extends InputProps {
  /*
   * Indicates the input should be rendered as a switch (for checkbox, radio)
   */
  isSwitch?: boolean
  /*
   * Options for the select as children
   */
  children?: ReactNode
}

/*
 * Creates a radio out of the Input wrapper component
 */
function Radio ({
  id,
  value,
  name,
  checked = false,
  className,
  iconFixedWidth = false,
  disabled = false,
  onChange,
  children,
  isSwitch = false,
  ...otherAttributes
}: RadioProps, ref: ForwardedRef<HTMLInputElement>): ReactElement {
  const containerClasses = clsx('react-radio', disabled && 'react-radio-disabled')
  const labelClasses = clsx('d-flex align-items-center u-cursor-pointer', className)

  const icon = useMemo(() => {
    if (isSwitch) {
      return faToggleLargeOn
    }

    return checked ? faCircleDot : faCircle
  }, [isSwitch, checked])

  const iconClasses = clsx(children != null && 'mr-3', isSwitch && 'react-radio-switch')

  const iconStyles = {
    fontSize: isSwitch ? '22px' : '18px'
  }

  const iconFlip = useMemo<FlipProp | undefined>(() => isSwitch && !checked ? 'horizontal' : undefined, [isSwitch, checked])

  return (
    <div className={containerClasses}>
      <Input
        type='radio'
        id={id}
        name={name}
        value={value}
        checked={checked}
        onChange={onChange}
        ref={ref}
        {...otherAttributes}
      />
      <label
        className={labelClasses}
        htmlFor={id}
      >
        <FontAwesomeIcon
          icon={icon}
          className={iconClasses}
          style={iconStyles}
          flip={iconFlip}
          fixedWidth={iconFixedWidth}
        />
        {children != null &&
          <div>{children}</div>}
      </label>
    </div>
  )
}

/* Radio with forwardRef */
const RadioWrapped = forwardRef(Radio)

export { RadioWrapped as Radio }
