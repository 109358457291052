import { ReactElement, useMemo, useState, useCallback, useRef } from 'react'
import { useQueryParams } from '@web/shared-util-hooks'
import { useGetArticleResultsQuery, GetArticleResultsQuery, ArticleResult, ArticleResultsParams } from '@web/shared-data-access-queries'
import { LoadingIndicator, Pager } from '@web/shared-ui-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle, faAngleRight } from '@fortawesome/pro-solid-svg-icons'
import { faFileText, faComments, faFileVideo } from '@fortawesome/pro-regular-svg-icons'
import { TrackEvent } from '@web/shared-util-google-analytics'
import clsx from 'clsx'

function InformatioAndVideos (): ReactElement|null {
  const { urlSearchParams } = useQueryParams()
  const searchQuery = useMemo(() => urlSearchParams.get('query') ?? '', [urlSearchParams])
  const [articleSearchParams, setArticleSearchParams] = useState<ArticleResultsParams>({ q: searchQuery, page: 1, pageSize: 10 })

  const onPageChange = useCallback((page: number) => {
    setArticleSearchParams({ ...articleSearchParams, page: page })
  }, [articleSearchParams])

  const { loading, previousData, data = previousData } = useGetArticleResultsQuery({ fetchPolicy: 'cache-first', variables: { params: articleSearchParams } })

  return (
    <LoadingIndicator loading={loading}>
      {data != null && <InformationAndVideosInner articleProps={data.getArticleResults} onPageChange={onPageChange} />}
    </LoadingIndicator>
  )
}

interface InformationAndVideosInnerProps{
  articleProps: GetArticleResultsQuery['getArticleResults']
  onPageChange: (page: number) => void
}

const ArticleTypes = {
  Video: 'Video',
  BenchArticle: 'BenchArticle',
  SocialMediaPromotion: 'SocialMediaPromotion'
}

function InformationAndVideosInner ({ articleProps, onPageChange }: InformationAndVideosInnerProps): ReactElement| null {
  const query = useMemo(() => articleProps.Query, [articleProps])
  const pager = useMemo(() => articleProps.Pager, [articleProps])
  const totalRecords = useMemo(() => pager.TotalRecords, [pager])
  const searchResults = useMemo(() => articleProps.SearchResults ?? [], [articleProps])

  const topOfResults = useRef<null | HTMLDivElement>(null)

  useMemo(() => {
    topOfResults?.current?.scrollIntoView({ behavior: 'smooth' })
  }, [pager.CurrentPage])

  return (
    <div className='mb-4'>
      <div ref={topOfResults} className='u-bold c-gray-dk-2 t-3 mt-4'>Your search for "{query}" returned {totalRecords} results.</div>
      {totalRecords > 0 && <p><em>{pager.ShowingText} results.</em></p>}

      <div className='alternate-bg-color'>
        {searchResults.map((result, index) => (
          <Article className={index % 2 === 0 ? '' : 'c-bg-gray-lt-3'} key={index} article={result} />
        ))}
      </div>

      <div className='d-flex'>
        <Pager page={pager.CurrentPage} perPage={pager.RecordsPerPage} onChange={onPageChange} total={pager.TotalRecords} className='ml-0 ml-md-auto mx-auto mx-md-0' />
      </div>
    </div>
  )
}

interface ArticleProps{
  article: ArticleResult
  className: string
}

function Article ({ article, className }: ArticleProps): ReactElement {
  const icon = useMemo(() => {
    if (article.ContentType === ArticleTypes.Video) {
      return <FontAwesomeIcon icon={faFileVideo} title='Video' />
    }

    if (article.ContentType === ArticleTypes.BenchArticle) {
      return <FontAwesomeIcon icon={faFileText} title='Article' />
    }

    if (article.ContentType === ArticleTypes.SocialMediaPromotion) {
      return <FontAwesomeIcon icon={faComments} title='Promotion' />
    }

    return <FontAwesomeIcon icon={faInfoCircle} title='Information' />
  }, [article.ContentType])

  const handleTracking = useCallback(() => TrackEvent('Search Tab', 'Information & Videos', `${article.ContentType}-${article.Title}`), [article.ContentType, article.Title])

  return (
    <div className={clsx(className, 'px-4 py-5 px-sm-5 d-flex')}>
      <div className='t-1 c-primary'>
        {icon}
      </div>
      <div className='ml-4 ml-sm-5'>
        <div className='u-bold t-4 mb-2'>
          <a href={article.Url} onClick={handleTracking}>{article.Title}</a>
        </div>
        <div>
          <span className='mr-3'>{article.Teaser}</span><a className='d-inline-block' href={article.Url} onClick={handleTracking}>Read more<FontAwesomeIcon className='ml-2' icon={faAngleRight} /></a>
        </div>
      </div>
    </div>
  )
}

export { InformatioAndVideos }
