import { SearchResult } from '@web/shared-data-access-queries'
import clsx from 'clsx'
import { ReactElement } from 'react'
import { MeteorBadge } from '@web/product-navigation-feature'

interface BottomBadgesProps {
  productResult: SearchResult
}
/*
  This is a component for the badges at the bottom of the product result
 */
function BottomBadges ({ productResult }: BottomBadgesProps): ReactElement | null {
  if (!productResult.IsValidForDieStruckBadge && !productResult.IsValidForMetalMoldBadge) {
    return null
  }
  return (
    <div className={clsx('bottomBadges d-flex', !(productResult.IsValidForDieStruckBadge && productResult.IsValidForMetalMoldBadge) && 'oneBadge')}>
      {productResult.IsValidForDieStruckBadge &&
        <MeteorBadge source='//meteor.stullercloud.com/das/31449205' classes={clsx(productResult.IsValidForMetalMoldBadge && 'leftBottomBadge')} width='90' xsWidth='100' title='DieStruck' />}
      {productResult.IsValidForMetalMoldBadge &&
        <MeteorBadge source='//meteor.stullercloud.com/das/31449202' classes={clsx(productResult.IsValidForDieStruckBadge && 'rightBottomBadge')} width='90' xsWidth='100' title='Metal Mold' />}
    </div>
  )
}

export { BottomBadges }
