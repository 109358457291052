import { ApolloError } from '@apollo/client'
import { Sort, useAddAHeadQuery, ProductCard } from '@web/products'
import { GetAddAHeadFacetsAndResultsQuery } from '@web/shared-data-access-queries'
import { EmbeddedSvgLoader, LoadingIndicator } from '@web/shared-ui-components'
import { AddAHeadFilterOptions, AddAHeadHeader } from 'libs/products/add-a-head/feature'
import { ReactElement, useCallback, useEffect, useMemo, useReducer } from 'react'
import { ProductCardProps } from 'libs/products/shared/ProductCard'
import { AddAHeadSortingAndPaging } from './AddAHeadSortingAndPaging'
import { AddAHeadStep, SearchType } from './types'
import { ClientSideImageUrls } from '../../../../../requirejs/ImageUrls'
import { placeholderImage } from '@web/product-navigation-feature'
import { dispatchStullerEvent } from '@web/shared-util-stuller-events'

interface AddAHeadProps {
  onPegHeadSelect: (inventoryItemId: number) => void
  onPegHeadRemove: () => void
  selectedPegHeadId: number | null
  configATO?: string
  productId?: string | null
  iterationId?: string | null
  productImage?: string
  productDescription?: string
  productMetalQuality?: string
  productSku?: string
  excludedIterationIds: string[] | null
  shouldFilterOnIteration?: boolean
  useIterationsSystemSetting?: boolean
}

interface AddAHeadFilters {
  atoModelNumber: string
  pageSize: number
  page: number
  productId: string | null
  orderBy: Sort
  series: string | null
  metalQuality: string | null
  stoneShape: string | null
  stoneSize: string | null
  productState: string | null
  prongCount: string | null
  iterationId: string | null
  excludedIterationIds: string[] | null
  shouldFilterOnIteration?: boolean
  useIterationsSystemSetting?: boolean
}

interface AddAHeadState {
  currentStep: AddAHeadStep
  searchType: SearchType
  filters: AddAHeadFilters
}

interface ReducerActionBase {
  type: string
  loading: boolean
  data?: GetAddAHeadFacetsAndResultsQuery
  error?: ApolloError
}

interface FilterChangeAction extends ReducerActionBase {
  type: 'filter-change'
  newFilters: Partial<AddAHeadFilters>
}

interface ResetAction extends ReducerActionBase {
  type: 'reset'
  searchType?: SearchType
  newFilters?: Pick<AddAHeadFilters, 'atoModelNumber' | 'productId' | 'iterationId' | 'excludedIterationIds'>
}

type AddAHeadAction = FilterChangeAction | ResetAction

function AddAHeadStateReducer (state: AddAHeadState, action: AddAHeadAction): AddAHeadState {
  let { filters: newFilters, searchType: newSearchType, currentStep: newStep } = state

  if (action.type === 'reset') {
    newSearchType = action.searchType ?? newSearchType
    newStep = newSearchType === 'series' ? 'pick-series' : 'get-options'
    newFilters = {
      ...newFilters,
      page: 1,
      pageSize: 0,
      metalQuality: null,
      stoneShape: null,
      stoneSize: null,
      productState: null,
      prongCount: null,
      series: null
    }
  }

  if (action.type === 'filter-change' && !action.loading) {
    newFilters = { ...newFilters, ...action.newFilters }
    if (action.error != null) {
      newStep = 'no-results'
    } else {
      switch (state.currentStep) {
        case 'get-options': {
          newStep = 'pick-metal'
          break
        }
        case 'pick-series': {
          if (newFilters.series != null) {
            newStep = 'get-options'
          }
          break
        }
        case 'pick-metal': {
          if (state.filters.metalQuality == null && newFilters.metalQuality != null) {
            newStep = 'pick-stone'
          }
          break
        }
        case 'pick-stone': {
          if (state.filters.stoneShape == null && newFilters.stoneShape != null) {
            newFilters = { ...newFilters, pageSize: 12 }
            newStep = 'results'
          }
          break
        }
        case 'results': {
          if (action.data == null) {
            newStep = 'no-results'
          } else if (state.searchType === 'series' && state.filters.series !== newFilters.series) {
            newStep = 'get-options'
            newFilters = {
              ...newFilters,
              page: 1,
              pageSize: 0,
              metalQuality: null,
              stoneShape: null,
              stoneSize: null,
              productState: null,
              prongCount: null
            }
          }
          break
        }
      }
    }
  }

  return {
    filters: newFilters,
    currentStep: newStep,
    searchType: newSearchType
  }
}

function AddAHead ({
  onPegHeadSelect,
  onPegHeadRemove,
  selectedPegHeadId,
  productId,
  iterationId,
  productImage,
  productDescription,
  productMetalQuality,
  productSku,
  configATO,
  excludedIterationIds,
  shouldFilterOnIteration,
  useIterationsSystemSetting
}: AddAHeadProps): ReactElement | null {
  const [state, dispatch] = useReducer(AddAHeadStateReducer, {
    currentStep: 'get-options',
    searchType: 'filter',
    filters: {
      atoModelNumber: configATO ?? '',
      pageSize: 0,
      page: 1,
      productId: productId ?? null,
      orderBy: Sort.Newest,
      series: null,
      metalQuality: productMetalQuality ?? null,
      stoneShape: null,
      stoneSize: null,
      productState: null,
      prongCount: null,
      iterationId: iterationId ?? null,
      excludedIterationIds: excludedIterationIds ?? [],
      shouldFilterOnIteration,
      useIterationsSystemSetting
    }
  })

  const skipQuery = useMemo(() => state.currentStep === 'pick-series', [state.currentStep])

  // @todo: need to say when to use iterationId here
  const { loading, previousData, data = previousData, error } = useAddAHeadQuery({
    atoModelNumber: state.filters.atoModelNumber,
    pageSize: state.filters.pageSize,
    page: state.filters.page,
    productId: state.filters.productId,
    iterationId: state.filters.iterationId,
    sort: state.filters.orderBy,
    metalQuality: state.filters.metalQuality,
    stoneShape: state.filters.stoneShape,
    stoneSize: state.filters.stoneSize,
    productState: state.filters.productState,
    prongCount: state.filters.prongCount,
    series: state.filters.series,
    excludedIterationIds: state.filters.excludedIterationIds,
    shouldFilterOnIteration: state.filters.shouldFilterOnIteration,
    useIterationsSystemSetting: state.filters.useIterationsSystemSetting
  }, skipQuery)

  const dispatchFilterChange = useCallback((newFilters: Partial<AddAHeadFilters>) => {
    const action: FilterChangeAction = { type: 'filter-change', loading, data, error, newFilters }
    dispatch(action)
  }, [loading, data, error])

  const dispatchReset = useCallback((searchType?: SearchType, newProductFilters?: { atoModelNumber: string, productId: string | null, iterationId: string | null, excludedIterationIds: string[] | null }) => {
    const action: ResetAction = { type: 'reset', loading, data, error, searchType }
    if (newProductFilters !== undefined) {
      action.newFilters = newProductFilters
    }
    dispatch(action)
  }, [loading, data, error])

  const isItemSelected = (inventoryItemId: number | null | undefined): boolean => selectedPegHeadId === inventoryItemId

  const handleCardClick = (inventoryItemId: number): void => {
    if (isItemSelected(inventoryItemId)) {
      onPegHeadRemove()
    } else {
      onPegHeadSelect(inventoryItemId)
    }
  }

  const dispatchOptionsLoaded = (): void => dispatchFilterChange({})

  const handleSortChange = (sort: Sort): void => dispatchFilterChange({ orderBy: sort, page: 1 })
  const handlePageChange = (page: number): void => dispatchFilterChange({ page })
  const handleSearchTypeChange = (searchType: SearchType): void => dispatchReset(searchType)
  const handleFilterChange = (facet: string, value: string | null): void => dispatchFilterChange({ page: 1, [facet]: value !== '' ? value : null })
  const handleResetFilters = (atoModelNumber: string, productId: string | null, iterationId: string | null, excludedIterationIds: string[] | null): void => dispatchReset('filter', { atoModelNumber, productId, iterationId, excludedIterationIds })
  const handleProductChange = (atoModelNumber: string, productId: string | null, iterationId: string | null, excludedIterationIds: string[] | null): void => dispatchReset('filter', { atoModelNumber, productId, iterationId, excludedIterationIds })

  const nullOption: Array<string | null> = [null]

  const metalQuality = useMemo(() => ({
    options: data?.getAddAHeadFacetsAndResults?.Facets?.attr_string_Quality ?? nullOption,
    selectedValue: state.filters.metalQuality
  }), [data, state.filters])

  const stoneShape = useMemo(() => ({
    options: data?.getAddAHeadFacetsAndResults?.Facets?.attr_string_Stone_Shape ?? nullOption,
    selectedValue: state.filters.stoneShape
  }), [data, state.filters])

  const stoneSize = useMemo(() => ({
    options: nullOption.concat(data?.getAddAHeadFacetsAndResults?.Facets?.attr_string_Stone_Size ?? nullOption),
    selectedValue: state.filters.stoneSize
  }), [data, state.filters])

  const productState = useMemo(() => ({
    options: nullOption.concat(data?.getAddAHeadFacetsAndResults?.Facets?.attr_string_Product_State ?? nullOption),
    selectedValue: state.filters.productState
  }), [data, state.filters])

  const prongCount = useMemo(() => ({
    options: nullOption.concat(data?.getAddAHeadFacetsAndResults?.Facets?.attr_string_Prong_Count ?? nullOption),
    selectedValue: state.filters.prongCount
  }), [data, state.filters])

  // If the product changes we need to reset the filters
  useEffect(() => {
    handleProductChange(configATO ?? '', productId ?? null, iterationId ?? null, excludedIterationIds ?? [])
  }, [productSku])

  // When data loads check if any automatic defaulting needs to be applied based on the current step
  useEffect(() => {
    if (loading || data == null) {
      return
    }
    switch (state.currentStep) {
      case 'get-options': {
        dispatchOptionsLoaded()
        break
      }
      case 'pick-metal' : {
        const metalQualities = data?.getAddAHeadFacetsAndResults?.Facets?.attr_string_Quality?.filter(x => x != null) ?? []
        const shankMetalQuality = metalQualities.find(x => x === productMetalQuality)
        const metalQuality = shankMetalQuality ?? metalQualities[0] ?? null
        dispatchFilterChange({ metalQuality })
        break
      }
      case 'pick-stone' : {
        const stoneShapes = data?.getAddAHeadFacetsAndResults?.Facets?.attr_string_Stone_Shape?.filter(x => x != null) ?? []
        const round = stoneShapes.find(x => x === 'Round')
        const stoneShape = round ?? stoneShapes[0] ?? null
        dispatchFilterChange({ stoneShape })
        break
      }
    }
  }, [loading, data, state.currentStep])

  function getImage (rawImage: string | null): string | null {
    return rawImage != null ? new ClientSideImageUrls(rawImage).Image.Thumb100Url : null
  }

  const productCardPropsList: ProductCardProps[] = useMemo(() => data?.getAddAHeadFacetsAndResults?.Items?.map(item => {
    const mainImage = getImage(item?.Product.attrs_string_img_ViewE?.RawUrl ?? item?.Product.attrs_string_img_B?.RawUrl ?? item?.Product.image.RawUrl ?? null) ?? ''
    const sideImage = getImage(item?.Product.attrs_string_img_AngleA?.RawUrl ?? item?.Product.attrs_string_img_A?.RawUrl ?? null)
    return {
      image: mainImage,
      sideImage: mainImage !== sideImage ? sideImage : null,
      placeholderImage: placeholderImage.Thumb100Url,
      price: item?.AddToCartViewModel?.Product?.JsonPrice,
      itemNumber: item?.Product?.item_number ?? '',
      description: item?.Product?.attr_string_Title ?? '',
      buttonText: 'Add Head',
      detailsUrl: item?.DetailsUrl,
      inventoryItemId: item?.Product?.product_id ?? 0,
      secondaryStoneType: item?.Product?.attr_string_Secondary_Stone_Type ?? null,
      secondaryStoneOrigin: item?.Product?.attr_string_Secondary_Stone_Origin ?? null
    }
  }) ?? [], [data?.getAddAHeadFacetsAndResults?.Items])

  useEffect(() => {
    dispatchStullerEvent('add-a-head-modal-scrolltop')
  }, [state])

  return (
    <>
      <AddAHeadHeader
        productImage={productImage ?? ''}
        productDescription={productDescription ?? ''}
        productSku={productSku ?? ''}
      />
      <EmbeddedSvgLoader />
      <LoadingIndicator loading={loading} position='center'>
        <AddAHeadFilterOptions
          metalQuality={metalQuality}
          stoneShape={stoneShape}
          stoneSize={stoneSize}
          productState={productState}
          prongCount={prongCount}
          onFilterChange={handleFilterChange}
          onResetFilters={handleResetFilters}
          onSearchTypeChange={handleSearchTypeChange}
          searchType={state.searchType}
          series={state.filters.series}
          productId={productId ?? null}
          iterationId={iterationId ?? null}
          atoModelNumber={configATO ?? ''}
          loadingOptions={loading}
          excludedIterationIds={excludedIterationIds}
          useIterationsSystemSetting={state.filters.useIterationsSystemSetting ?? false}
          shouldFilterOnIteration={state.filters.shouldFilterOnIteration ?? false}
        />
      </LoadingIndicator>
      <LoadingIndicator loading={loading} position='center'>
        {state.currentStep !== 'pick-series' &&
          <div className='mt-5'>
            <AddAHeadSortingAndPaging
              pageSize={12}
              sortOptions={[Sort.Name, Sort.Newest, Sort.PriceAsc, Sort.PriceDesc]}
              currentSort={state.filters.orderBy}
              onSortChange={handleSortChange}
              totalRecords={data?.getAddAHeadFacetsAndResults?.Total ?? 0}
              currentPage={state.filters.page}
              onPageChange={handlePageChange}
            >
              <div className='u-flex-grid-row'>
                {productCardPropsList.map((props, index) =>
                  <div key={props.itemNumber + index.toString()} data-test='product-card' className='product-card-wrapper mb-sm-3 mb-0 u-flex-grid-col-xl-3 u-flex-grid-col-lg-4 u-flex-grid-col-sm-6 col-12'>
                    <ProductCard {...props} onSelect={handleCardClick} isSelected={isItemSelected(props.inventoryItemId)} selectedButtonText='Remove' />
                  </div>
                )}
              </div>
            </AddAHeadSortingAndPaging>
          </div>}
      </LoadingIndicator>
    </>
  )
}

export {
  AddAHead
}
