import { ReactElement, useMemo } from 'react'
import { ImageAsset } from '@web/shared-data-access-queries'
import clsx from 'clsx'

interface SearchImageAssetProps extends ImageAsset {
  className?: string
  AttributeValue?: string
}

function SearchImageAsset ({ id, source, type, className, AttributeValue }: SearchImageAssetProps): ReactElement {
  const isSrcImageType = useMemo(() => type === 'URL' || type === 'DAS', [type])
  const imgClassName = clsx(!isSrcImageType ? 'featured-attribute-svg' : '', className)

  return (
    <>
      {isSrcImageType && (
        <img src={source} className={imgClassName} alt={AttributeValue} />
      )}
      {type === 'Embedded SVG' && (
        <svg className={imgClassName}>
          <title>{AttributeValue}</title>
          <use xlinkHref={`#${id}`} />
        </svg>
      )}
    </>
  )
}

export { SearchImageAsset }
