import { AddAHeadQuery } from '../hooks'

export enum ShapeType {
  Round,
  Princess,
  Square,
  Oval,
  Marquise,
  Emerald,
  Heart,
  Pear,
  Triangle,
  Trillion,
  StraightBaguette,
  TaperedBaguette,
  Baguettes,
  Radiant,
  AntiqueCushion,
  Cushion,
  Asscher,
  HalfMoon,
  Trapezoid,
  Hexagon,
  Kite,
  AntiqueSquare
}

export enum StoneType {
  Diamond,
  Gemstone,
  MountingLocation,
  Unknown
}

export enum ProductStateType {
  Set = 'Set',
  Unset = 'Unset',
  SemiSet = 'Semi-Set'
}

export type AddAHeadFilterKey = keyof(Pick<AddAHeadQuery, 'metalQuality' | 'stoneShape' | 'stoneSize' | 'productState' | 'prongCount' | 'series'>)

export type SearchType = 'filter' | 'series'

export type AddAHeadStep = 'get-options' | 'pick-series' | 'pick-metal' | 'pick-stone' | 'results' | 'no-results'
