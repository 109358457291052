import { useChildrenProps } from '@web/shared-util-hooks'
import {
  ReactElement, ReactNode, useCallback, useMemo
} from 'react'
import uuid from 'react-uuid'
import { AddToCartRequest, AddToCartData } from './types'

interface AddToCartModalTriggerProps {
  addToCartUrl: string
  addToCartData: AddToCartData[]
  ignoreWebEnabledFlag?: boolean
  autoReorder?: boolean
  /*
   * Children of element
   */
  children: ReactNode
}

function AddToCartModalTrigger ({ addToCartData, addToCartUrl, ignoreWebEnabledFlag = false, autoReorder = false, children }: AddToCartModalTriggerProps): ReactElement {
  const uniqueId: string = useMemo(() => uuid(), [])
  const id: string = useMemo(() => `${uniqueId}-atc`, [])
  const addRequest: AddToCartRequest = {
    requests: addToCartData,
    ignoreWebEnabledFlag: ignoreWebEnabledFlag,
    autoReorder: autoReorder
  }
  const stringRequest = JSON.stringify(addRequest)
  const onClick = useCallback(() => {
    $(`#${id}`).modal('toggle')
    window.$.ajaxModal(
      {
        url: addToCartUrl,
        data: stringRequest,
        modalCss: 'genericAddToCardModal'
      })
  }, [addToCartUrl])
  const mappedChildren = useChildrenProps(children, { onClick: () => onClick() })

  return (
    <>
      {mappedChildren}
    </>
  )
}

export { AddToCartModalTrigger }
