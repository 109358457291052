import { ReactElement, ReactNode, forwardRef, ForwardedRef, MouseEventHandler } from 'react'
import { NavLink } from 'react-router-dom'
import clsx from 'clsx'

interface TabProps {
  /*
   * Name of tab
   */
  name: string
  /*
   * Url to redirect to
   */
  route: string
  /*
   * Class overrides
   */
  className?: string
  /*
   * Gives it the active class
   */
  active?: boolean
  /**
   * function to call when the tab is clicked
   */
  onClick?: MouseEventHandler
}

interface TabsProps {
  children: ReactNode
}

/*
 * Tab compontent to be passed into the tabs group
 */
function Tab ({
  name,
  route,
  className,
  active = false,
  onClick
}: TabProps): ReactElement {
  return (
    <li key={route} onClick={onClick}>
      <NavLink exact to={route} activeClassName='router-link-active' className={clsx(className, active && 'router-link-active')}>
        {name}
      </NavLink>
    </li>
  )
}

/*
 * Group for the tab component
 */
function Tabs ({
  children
}: TabsProps, ref: ForwardedRef<HTMLElement>): ReactElement {
  return (
    <nav ref={ref}>
      <ul className='tab-list'>
        {children}
      </ul>
    </nav>
  )
}

/*
 * Input with forwardRef
 */
const TabsWrapped = forwardRef(Tabs)

export { TabsWrapped as Tabs, Tab, TabProps }
